import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalizedDate2FromUTC } from 'common/DateTimeUtils'
import { Customer } from 'Component/CourseRegistration/Models'

interface TableCourseMemberProp {
  filteredRegistrants?: Customer[]
  isLoading: boolean
  pageNo: number
  rowsPerPage: number
  setCurrentParticipant?: (value: any) => void
  setIsPopupOpen?: (value: boolean) => void
  renderMemberDetail: (open: boolean, index: number) => void
}

const TableCourseMember = ({
  pageNo,
  rowsPerPage,
  filteredRegistrants,
  isLoading,
  setCurrentParticipant,
  setIsPopupOpen,
  renderMemberDetail,
}: TableCourseMemberProp) => {
  const [toggleIcon, setToggleIcon] = useState([
    { filter: false },
    { filter: false },
    { filter: false },
    { filter: false },
  ])

  const { t } = useTranslation()

  const changeIcon = useCallback(() => {
    setToggleIcon(() => {
      return [...toggleIcon]
    })
  }, [toggleIcon])

  const sortByFirstName = (index: number) => {
    if (toggleIcon[index].filter === true) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let firstNameOne = (a.first_name || '').toLowerCase(),
          firstNameTwo = (b.first_name || '').toLowerCase()

        if (firstNameOne < firstNameTwo) {
          return 1
        }
        if (firstNameOne > firstNameTwo) {
          return -1
        }
        return 0
      })
    } else if (toggleIcon[index].filter === false) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let firstNameOne = (a.first_name || '').toLowerCase(),
          firstNameTwo = (b.first_name || '').toLowerCase()

        if (firstNameOne < firstNameTwo) {
          return -1
        }
        if (firstNameOne > firstNameTwo) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByLastName = (index: number) => {
    if (toggleIcon[index].filter === true) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let lastNameOne = (a.last_name || '').toLowerCase(),
          lastNameTwo = (b.last_name || '').toLowerCase()

        if (lastNameOne < lastNameTwo) {
          return 1
        }
        if (lastNameOne > lastNameTwo) {
          return -1
        }
        return 0
      })
    } else if (toggleIcon[index].filter === false) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let lastNameOne = (a.last_name || '').toLowerCase(),
          lastNameTwo = (b.last_name || '').toLowerCase()

        if (lastNameOne < lastNameTwo) {
          return -1
        }
        if (lastNameOne > lastNameTwo) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByEmail = (index: number) => {
    if (toggleIcon[index].filter === true) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let emailOne = a.email.toLowerCase(),
          emailTwo = b.email.toLowerCase()

        if (emailOne < emailTwo) {
          return 1
        }
        if (emailOne > emailTwo) {
          return -1
        }
        return 0
      })
    } else if (toggleIcon[index].filter === false) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let emailOne = a.email.toLowerCase(),
          emailTwo = b.email.toLowerCase()

        if (emailOne < emailTwo) {
          return -1
        }
        if (emailOne > emailTwo) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByRegistrationDate = (index: number) => {
    if (toggleIcon[index].filter === true) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let date_One = new Date(a.registration_date) as any,
          date_Two = new Date(b.registration_date) as any
        return date_One - date_Two
      })
    } else if (toggleIcon[index].filter === false) {
      filteredRegistrants?.sort((a: any, b: any) => {
        let date_One = new Date(a.registration_date) as any,
          date_Two = new Date(b.registration_date) as any
        return date_Two - date_One
      })
    }
  }

  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <span
                  className="courseTh"
                  onClick={() => {
                    toggleIcon[0].filter = !toggleIcon[0].filter
                    changeIcon()
                    sortByFirstName(0)
                  }}
                >
                  {t('settings.FirstName')}&nbsp;
                  {toggleIcon[0].filter === false && (
                    <i className="fas fa-long-arrow-alt-down"></i>
                  )}
                  {toggleIcon[0].filter === true && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </span>
              </th>
              <th scope="col">
                <span
                  className="courseTh"
                  onClick={() => {
                    toggleIcon[1].filter = !toggleIcon[1].filter
                    changeIcon()
                    sortByLastName(1)
                  }}
                >
                  {t('settings.LastName')}&nbsp;
                  {toggleIcon[1].filter === false && (
                    <i className="fas fa-long-arrow-alt-down"></i>
                  )}
                  {toggleIcon[1].filter === true && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </span>
              </th>
              <th scope="col">
                <span
                  className="courseTh"
                  onClick={() => {
                    toggleIcon[2].filter = !toggleIcon[2].filter
                    changeIcon()
                    sortByEmail(2)
                  }}
                >
                  {t('courses.Emailid')}&nbsp;
                  {toggleIcon[2].filter === false && (
                    <i className="fas fa-long-arrow-alt-down"></i>
                  )}
                  {toggleIcon[2].filter === true && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </span>
              </th>

              <th scope="col">
                <span
                  className="courseTh"
                  onClick={() => {
                    toggleIcon[3].filter = !toggleIcon[3].filter
                    changeIcon()
                    sortByRegistrationDate(3)
                  }}
                >
                  {t('courses.RegistrationDate')}&nbsp;
                  {toggleIcon[3].filter === false && (
                    <i className="fas fa-long-arrow-alt-down"></i>
                  )}
                  {toggleIcon[3].filter === true && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </span>
              </th>

              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredRegistrants &&
              filteredRegistrants
                .slice(pageNo * rowsPerPage - rowsPerPage, pageNo * rowsPerPage)
                .map((data: any, index: number) => (
                  <tr key={index}>
                    <td>{data.first_name}</td>
                    <td>{data.last_name}</td>
                    <td>{data.email}</td>
                    <td>{getLocalizedDate2FromUTC(data.registration_date)}</td>
                    {setIsPopupOpen && setCurrentParticipant && (
                      <td>
                        <button
                          className="btn text-danger"
                          onClick={() => {
                            setCurrentParticipant(data)
                            setIsPopupOpen(true)
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </button>
                      </td>
                    )}
                    <td>
                      <button
                        className="btn text-secondary"
                        onClick={() => {
                          renderMemberDetail(
                            true,
                            pageNo * rowsPerPage - rowsPerPage + index
                          )
                          console.log(
                            'numero',
                            pageNo * rowsPerPage - rowsPerPage + index
                          )
                        }}
                      >
                        <InfoIcon />
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {filteredRegistrants?.length === 0 && !isLoading && (
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ebedef',
          }}
        >
          {t('NoRecordFound')}
        </h1>
      )}
    </>
  )
}

export default TableCourseMember
