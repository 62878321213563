const initialState = {
  shouldUpdateSurveys: true,
  shouldUpdateTemplates: true,
};

const SurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SURVEY_CREATED':
      return { ...state, shouldUpdateSurveys: true };
    case 'SURVEYS_FETCHED':
      return { ...state, shouldUpdateSurveys: false };
    case 'TEMPLATE_CREATED':
      return { ...state, shouldUpdateTemplates: true };
    case 'TEMPLATES_FETCHED':
      return { ...state, shouldUpdateTemplates: false };
    default:
      return state;
  }
};

export default SurveyReducer;
