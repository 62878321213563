import { Stack } from '@mui/material'
import SelectTime from 'Component/SelectTime/SelectTime'

interface DateAndTimePickerProp {
  date?: string
  time?: string
  serverTime?: string
  minDate?: string
  disabled?: boolean
  normalSpace?: boolean
  onDateChange?: (date: string) => void
  onTimeChange?: (time: string) => void
}

function DateAndTimePicker({
  date,
  time,
  serverTime,
  minDate,
  disabled,
  normalSpace,
  onDateChange,
  onTimeChange,
}: DateAndTimePickerProp) {
  const classnames = () => {
    if (!date || date === 'Invalid date') {
      return 'dateInput'
    }
    return 'dateInputBlack'
  }

  return (
    <div>
      <Stack
        direction="row"
        justifyContent={normalSpace ? 'normal' : 'space-between'}
        alignItems="center"
      >
        <input
          className={classnames()}
          placeholder="gg/mm/aaaa"
          type="date"
          onChange={(e) => onDateChange?.(e.target.value)}
          value={date}
          min={minDate}
          disabled={disabled}
          required
        />
        <SelectTime
          onChangeState={(time) => onTimeChange?.(time)}
          disable={disabled}
          timeSelected={time}
          serverTime={serverTime}
        />
      </Stack>
    </div>
  )
}

export default DateAndTimePicker
