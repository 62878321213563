import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface CourseDesignState {
  shouldFetchDesign: boolean
  shouldPreselectSessionListTab: boolean
}

const initialState: CourseDesignState = {
  shouldFetchDesign: true,
  shouldPreselectSessionListTab: false,
}

export const CourseDesignSlice = createSlice({
  name: 'course-design',
  initialState,
  reducers: {
    designFetched: (state) => {
      state.shouldFetchDesign = false
    },
    setShouldPreselectSessionListTab: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shouldPreselectSessionListTab = action.payload
    },
    sessionAdded: (state) => {
      state.shouldFetchDesign = true
    },
    sessionEdited: (state) => {
      state.shouldFetchDesign = true
    },
  },
})

// SELECTORS
export const selectShouldFetchDesign = (state: RootState) =>
  state.CourseDesign.shouldFetchDesign

export const selectShouldPreselectSessionListTab = (state: RootState) =>
  state.CourseDesign.shouldPreselectSessionListTab

export const {
  designFetched,
  setShouldPreselectSessionListTab,
  sessionAdded,
  sessionEdited,
} = CourseDesignSlice.actions

export default CourseDesignSlice.reducer
