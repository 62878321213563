import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import {
  HiOutlineBanknotes,
  HiOutlineCalendarDays,
  HiOutlineDocumentText,
} from 'react-icons/hi2'
import styles from './CardCatalog.module.css'
import { ServerCourseDesign } from 'models/course'

interface CourseDesignCatalogCardProps {
  design: ServerCourseDesign
}

const CourseDesignCatalogCard = ({ design }: CourseDesignCatalogCardProps) => {
  const { name, description, course_Link, banner_url } = design

  const imagePlaceholder: string =
    'https://media.istockphoto.com/photos/elearning-education-concept-learning-online-picture-id1290864946?b=1&k=20&m=1290864946&s=170667a&w=0&h=zZq7rG5McSptSIpEm9f8iTGd3Mrdkcslakr91T7qTYM='

  return (
    <Card sx={{ width: 350 }}>
      <CardActionArea
        href={course_Link || ''}
        className={styles.card_action_area}
      >
        <CardMedia
          className={styles.card_media}
          component="img"
          alt="image header"
          src={banner_url || imagePlaceholder}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            align="left"
            className={styles.title}
          >
            {name}
          </Typography>

          {description && (
            <Box className={styles.flex_container}>
              {' '}
              <HiOutlineDocumentText color="rgba(0, 0, 0, 0.6)" />
              <Typography
                variant="subtitle1"
                align="left"
                className={styles.subtitle}
              >
                {description}
              </Typography>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CourseDesignCatalogCard
