import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignsTable } from 'Component/Mail/Campaigns/CampaignsTable'
import { Campaign } from 'Component/Mail/NotificationServiceTypes'
import { useEffect, useState, useCallback } from 'react'
import useApi from 'api/UseApi'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import Loader from 'Component/Loader'

export function Campaigns() {
  const { t } = useTranslation()
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  const getCampaigns = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api
          .get(`campaigns`, {
            params: {
              organizationUuid: organization.notificationId,
            },
          })
          .then(
            (response) => {
              setCampaigns(response.data.data)
              setIsLoaderVisible(false)
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        setIsLoaderVisible(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getCampaigns()
  }, [getCampaigns])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('mail.campaigns')}
        </Typography>
        <CampaignsTable
          campaigns={campaigns}
          onCampaignDeleted={() => {
            getCampaigns()
          }}
        />
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </Box>
  )
}
