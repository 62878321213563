import useApi from 'api/UseApi'
import BasicTabs from 'Component/BasicTabs/BasicTabs'
import Footer from 'Component/CourseRegistration/Footer'
import {
  OrganizationInfoModel,
  RouteParams,
} from 'Component/CourseRegistration/Models'
import OrganizationInfo from 'Component/CourseRegistration/OrganizationInfo'
import Loader from 'Component/Loader'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Catalog from './Catalog'
import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'

const CatalogPage = () => {
  const [organizationInfo, setOrganizationInfo] =
    useState<OrganizationInfoModel>()
  const [isLoading, setIsLoading] = useState(true)

  let { slug } = useParams<RouteParams>()
  const api = useApi(false, true)
  const { t } = useTranslation()

  const getCourseRegistration = async () => {
    setIsLoading(true)
    api.get(`organizations/by-slug/${slug}/org`).then(
      (response) => {
        const organization = response.data

        setOrganizationInfo(organization)
        setIsLoading(false)
      },
      (error: any) => {
        console.log(`Error while getting Course with Slug "${slug}" : ${error}`)
        setIsLoading(false)
      }
    )
  }

  useEffect(() => {
    getCourseRegistration()
  }, [])

  return (
    <div>
      {organizationInfo ? (
        <>
          <Container maxWidth="md">
            <OrganizationInfo organizationInfo={organizationInfo} />
            <BasicTabs
              labels={[
                t('course-design.live-courses'),
                t('course-design.catalog'),
              ]}
              values={[
                <Catalog slug={slug} />,
                <Catalog isDesignCatalog={true} slug={slug} />,
              ]}
            />
          </Container>
          <Footer />
        </>
      ) : (
        <Loader isVisible={isLoading} />
      )}
    </div>
  )
}

export default CatalogPage
