//type
import { IUserAccountChangePassword } from 'Component/AccountChangePassword/AccountChangePassword'
import { IUserSocialLink } from 'Component/AccountGeneral/AccountSocialLinks'
//redux
import {
  Organization,
  selectDashboardReportLink,
  setEmail,
  setFacebook,
  setFirstName,
  setInstagram,
  setJobPosition,
  setLanguage,
  setLastName,
  setLinkedin,
  setMobile,
  setTwitter,
} from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'
//library
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
//hook
import useApi from 'api/UseApi'
import { UserValuesProps } from 'Component/AccountGeneral/AccountGeneral'
import i18next from 'i18next'
import { localization } from 'survey-creator-core'
import { setTimezone } from 'common/DateTimeUtils'
import { useTranslation } from 'react-i18next'
import { UserInfo } from 'Component/CourseRegistration/Models'

type ChangePasswordProps = IUserAccountChangePassword
type SocialLinksProps = IUserSocialLink

const MeService = () => {
  const api = useApi(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const dashboardReportLink = useAppSelector(selectDashboardReportLink)

  const getMe = async () => {
    try {
      const response = await api.get('users/me')
      const user: UserInfo = response.data.users
      return user
    } catch (error) {
      console.log(error)
    }
  }

  const getUserPermissions = async () => {
    try {
      const response = await api.get('users/me')
      const responseData: { organizations: Organization[]; users: UserInfo } =
        response.data
      const user = responseData.users

      const permissions = responseData.organizations.flatMap((org) => {
        let userPermissionsForOrg = org.lstpermissions.map((data) => {
          return data.permission_name.trim()
        })

        let userPermissions = user.permissions.map((data) => {
          return data.permission_name.trim()
        })

        userPermissionsForOrg.push.apply(userPermissionsForOrg, userPermissions)

        return userPermissionsForOrg
      })
      return permissions
    } catch (error) {
      console.log(error)
    }
  }

  const putSocialLinks = async (data: SocialLinksProps) => {
    const { facebookLink, linkedinLink, twitterLink, instagramLink } = data
    try {
      await api.put('users/me', {
        linkedin: linkedinLink,
        facebook: facebookLink,
        twitter: twitterLink,
        instagram: instagramLink,
      })
      toast.success(t('user-account.social-update'), { theme: 'colored' })
      dispatch(setFacebook(facebookLink))
      dispatch(setLinkedin(linkedinLink))
      dispatch(setTwitter(twitterLink))
      dispatch(setInstagram(instagramLink))
    } catch (error: any) {
      toast.error(error.response.data.message, { theme: 'colored' })
    }
  }

  const patchChangePassword = async (data: ChangePasswordProps) => {
    try {
      await api.patch('users/me/change_password', {
        password: data.newPassword,
      })
      toast.success(t('user-account.password-update'), { theme: 'colored' })
      setTimeout(() => {
        if (dashboardReportLink) {
          history.push('/Dashboard')
        } else {
          history.push('/Courses')
        }
      }, 1000)
    } catch (error: any) {
      toast.error(error.response.data.message, { theme: 'colored' })
    }
  }

  const putUser = async (data: UserValuesProps) => {
    try {
      const formData = new FormData()
      if (data.avatar) formData.append('avatar', data.avatar)
      formData.append('email', data.email)
      formData.append('first_name', data.displayName)
      formData.append('last_name', data.surname)
      formData.append('language', data.language)
      formData.append('timezone', data.timezones.id)
      formData.append('job_position', data.role ? data.role : '')
      formData.append('mobilenumber', data.phoneNumber ? data.phoneNumber : '')

      await api.put('users/me', formData)
      toast.success(t('user-account.user-update'), { theme: 'colored' })
      i18next.changeLanguage(data.language)
      localization.currentLocale = data.language

      dispatch(setFirstName(data.displayName))
      dispatch(setLastName(data.surname))
      dispatch(setEmail(data.email))
      dispatch(setMobile(data.phoneNumber))
      dispatch(setJobPosition(data.role))
      dispatch(setLanguage(data.language))
      setTimezone(data.timezones.id)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    putSocialLinks,
    patchChangePassword,
    putUser,
    getMe,
    getUserPermissions,
  }
}

export default MeService
