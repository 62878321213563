import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useApi from '../api/UseApi'

import { Fade, Tooltip, Menu, MenuItem } from '@mui/material'

import Loader from './Loader'
import { toast } from 'react-toastify'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import SurveyCreatorDialog from 'View/survey/SurveyCreatorDialog'
import { templateCreated, templatesFetched } from 'Actions/Survey'
import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/FileCopy'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Typography } from '@mui/material'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { useHistory } from 'react-router-dom'

type Template =
  | any
  | {
      id: string
      name: string
      description: string
      body: string
    }

const Templates = () => {
  const { t } = useTranslation()
  const apiNoAuth = useApi(false, true)
  const api = useApi(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Template>(null)
  const [templates, setTemplates] = useState<any>([])
  const [wybloTemplates, setWybloTemplates] = useState([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(true)
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false)
  const [isEditTemplateDialogOpen, setIsEditTemplateDialogOpen] =
    useState(false)
  const [isPreviewTemplateDialogOpen, setIsPreviewTemplateDialogOpen] =
    useState(false)
  const [templateMenuAnchor, setTemplateMenuAnchor] = React.useState<any>(null)
  const [wybloTemplateMenuAnchor, setWybloTemplateMenuAnchor] =
    React.useState(null)
  const organization = useAppSelector(selectCurrentOrganization)
  const surveySelector = useAppSelector((state) => state.Survey)
  const dispatch = useDispatch()
  const history = useHistory()

  const onTemplateMenuClose = () => {
    setTemplateMenuAnchor(null)
  }

  const onWybloTemplateMenuClose = () => {
    setWybloTemplateMenuAnchor(null)
  }

  const onTemplateSettingsClick = (template: Template, currentTarget: any) => {
    setTemplateMenuAnchor(currentTarget)
    setSelectedTemplate(template)
  }

  const onWybloTemplateSettingsClick = (
    template: Template,
    currentTarget: any
  ) => {
    setWybloTemplateMenuAnchor(currentTarget)
    setSelectedTemplate(template)
  }

  const getTemplates = useCallback(() => {
    setIsLoaderVisible(true)
    try {
      api.get(`organizations/${organization?.id}/templates`).then(
        (response) => {
          setTemplates(response.data.templates)
          setIsLoaderVisible(false)
        },
        (error) => {
          console.log(error)
          setIsLoaderVisible(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }, [api, organization])

  const getWybloTemplates = useCallback(() => {
    setIsLoaderVisible(true)
    try {
      apiNoAuth
        .get('survey-service/templates', {
          params: { wybloTemplatesOnly: true },
        })
        .then(
          (response) => {
            setWybloTemplates(response.data)
            setIsLoaderVisible(false)
          },
          (error) => {
            console.log(error)
            setIsLoaderVisible(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }, [apiNoAuth])

  const createSurveyFromTemplate = (isWybloTemplate = false) => {
    const templateId = isWybloTemplate ? undefined : selectedTemplate.id
    const externalTemplateID = isWybloTemplate
      ? selectedTemplate._id
      : undefined

    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/surveys/from_template`, {
          template_id: templateId,
          external_template_id: externalTemplateID,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)
          },
          (error) => {
            toast.error(error.message, { theme: 'colored' })
            setIsLoaderVisible(false)
            console.log(error)
          }
        )
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
      setIsLoaderVisible(false)
      console.log(`Error creating survey from template: ${e}`)
    }
  }

  const updateTemplate = (
    title: string,
    description: string,
    redirectUrl: string | null,
    body: any,
    isMarkdownEnabled: boolean
  ) => {
    const templateId = selectedTemplate.external_id || selectedTemplate._id

    setIsLoaderVisible(true)
    try {
      apiNoAuth
        .put(`templates/${templateId}`, {
          name: title,
          description: description,
          redirectUrl: redirectUrl,
          body: body,
          isMarkdownEnabled: isMarkdownEnabled,
        })
        .then(
          (response) => {
            setIsLoaderVisible(false)
            toast.success(response.data.message, { theme: 'colored' })
            setSelectedTemplate({
              id: selectedTemplate.id,
              name: title,
              description: description,
              body: body,
              isMarkdownEnabled: isMarkdownEnabled,
            })
            getTemplates()
            getWybloTemplates()
          },
          (error) => {
            console.log(error)
            setIsLoaderVisible(false)
          }
        )
    } catch (e) {
      console.log(e)
    }
  }

  const deleteTemplate = () => {
    setIsLoaderVisible(true)
    try {
      api.delete(`templates/${selectedTemplate.id}`).then(
        (response) => {
          toast.success(response.data.message, {
            theme: 'colored',
          })
          getTemplates()
          setIsLoaderVisible(false)
        },
        (error) => {
          toast.error(error.message, { theme: 'colored' })
          console.log(error)
          setIsLoaderVisible(false)
        }
      )
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
      console.log(`Error deleting survey: ${e}`)
      setIsLoaderVisible(false)
    }
  }

  const cloneTemplate = (isWybloTemplate = false) => {
    const templateId = isWybloTemplate ? undefined : selectedTemplate.id
    const externalTemplateID = isWybloTemplate
      ? selectedTemplate._id
      : undefined

    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/templates/clone`, {
          template_id: templateId,
          external_template_id: externalTemplateID,
          name: `${selectedTemplate.name} ${t('survey.clone_title_suffix')}`,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)

            dispatch(templateCreated())
          },
          (error) => {
            toast.error(error.message, { theme: 'colored' })
            setIsLoaderVisible(false)
            console.log(error)
          }
        )
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
      setIsLoaderVisible(false)
      console.log(`Error cloning template: ${e}`)
    }
  }

  useEffect(() => {
    getTemplates()
  }, [getTemplates])

  useEffect(() => {
    getWybloTemplates()
  }, [getWybloTemplates])

  useEffect(() => {
    if (surveySelector.shouldUpdateTemplates) {
      dispatch(templatesFetched())
      getTemplates()
    }
  }, [dispatch, getTemplates, surveySelector.shouldUpdateTemplates])

  return (
    <>
      {wybloTemplates && wybloTemplates.length > 0 && (
        <>
          <Box sx={{ mr: 1, mb: 3, color: '#157aff' }}>
            <Typography variant="h5">{t('survey.wyblo-templates')}</Typography>
          </Box>
          <Box
            className="d-flex flex-wrap"
            sx={{
              borderBottom: 0.5,
              borderBottomColor: 'rgba(196, 196, 196, 0.5)',
              borderBottomStyle: 'solid',
              mb: 3,
            }}
          >
            {wybloTemplates.map((template: any) => (
              <Box sx={{ width: '20%' }} className="survey-template-box p-4">
                <div>
                  <div className="d-flex justify-content-between">
                    <Tooltip title={template.name} arrow>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedTemplate(template)
                          history.push(`/Template/${template._id}`)
                        }}
                      >
                        {template.name}
                      </span>
                    </Tooltip>
                    <MoreVertIcon
                      style={{
                        cursor: 'pointer',
                        color: '#808080',
                        position: 'relative',
                      }}
                      onClick={(e) => {
                        onWybloTemplateSettingsClick(template, e.currentTarget)
                      }}
                    />
                  </div>
                  <Typography className="description" variant="body1">
                    {template.description}
                  </Typography>
                </div>
              </Box>
            ))}
          </Box>
        </>
      )}

      {templates && templates.length > 0 && (
        <>
          <Box sx={{ mr: 1, mb: 3, color: '#44a96c' }}>
            <Typography variant="h5">{t('survey.my-templates')}</Typography>
          </Box>
          <div className="d-flex flex-wrap">
            {templates.map((template: any) => (
              <Box sx={{ width: '20%' }} className="survey-template-box p-4">
                <div>
                  <div className="d-flex justify-content-between">
                    <Tooltip title={template.name} arrow>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedTemplate(template)
                          history.push(`/Template/${template.external_id}`)
                        }}
                      >
                        {template.name}
                      </span>
                    </Tooltip>
                    <MoreVertIcon
                      style={{
                        cursor: 'pointer',
                        color: '#808080',
                        position: 'relative',
                      }}
                      onClick={(e) => {
                        onTemplateSettingsClick(template, e.currentTarget)
                      }}
                    />
                  </div>
                  <Typography className="description" variant="body1">
                    {template.description}
                  </Typography>
                </div>
              </Box>
            ))}
          </div>
        </>
      )}

      <Menu
        id="fade-menu"
        anchorEl={templateMenuAnchor}
        keepMounted
        open={Boolean(templateMenuAnchor)}
        onClose={onTemplateMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            history.push(`/Template/${selectedTemplate.external_id}/edit`)
            onTemplateMenuClose()
          }}
        >
          <div>
            <EditIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.edit')}</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            createSurveyFromTemplate()
          }}
        >
          <div>
            <AddIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>
              {t('survey.create-survey')}
            </span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            cloneTemplate()
            onTemplateMenuClose()
          }}
        >
          <div>
            <ContentCopyIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.clone')}</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsDeleteConfirmationDialogOpen(true)
            onTemplateMenuClose()
          }}
        >
          <div>
            <DeleteIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.delete')}</span>
          </div>
        </MenuItem>
      </Menu>

      {/* ----- WYBLO TEMPLATE MENU -----*/}
      <Menu
        id="fade-menu"
        anchorEl={wybloTemplateMenuAnchor}
        keepMounted
        open={Boolean(wybloTemplateMenuAnchor)}
        onClose={onWybloTemplateMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            createSurveyFromTemplate(true)
          }}
        >
          <div>
            <AddIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>
              {t('survey.create-survey')}
            </span>
          </div>
        </MenuItem>
        <MenuItem
          hidden={
            !organization?.userPermissions.includes('manage:wyblo-templates')
          }
          onClick={() => {
            setIsEditTemplateDialogOpen(true)
            onWybloTemplateMenuClose()
          }}
        >
          <div>
            <EditIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.edit')}</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            cloneTemplate(true)
            onWybloTemplateMenuClose()
          }}
        >
          <div>
            <ContentCopyIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.clone')}</span>
          </div>
        </MenuItem>
      </Menu>
      {/* ----- END WYBLO TEMPLATE MENU -----*/}

      <SurveyCreatorDialog
        type="template"
        isOpen={isEditTemplateDialogOpen}
        data={
          selectedTemplate
            ? {
                title: selectedTemplate.name,
                description: selectedTemplate.description,
                redirectUrl: selectedTemplate?.redirect_url || null,
                json: selectedTemplate.body,
                isMarkdownEnabled: selectedTemplate.is_markdown_enabled,
              }
            : null
        }
        onClose={() => {
          setIsEditTemplateDialogOpen(false)
        }}
        onSave={(data) => {
          updateTemplate(
            data.title,
            data.description,
            data.redirectUrl,
            data.json,
            data.isMarkdownEnabled
          )
        }}
      />

      <SurveyCreatorDialog
        type="template"
        isPreview={true}
        isOpen={isPreviewTemplateDialogOpen}
        data={
          selectedTemplate
            ? {
                title: selectedTemplate.name,
                description: selectedTemplate.description,
                redirectUrl: selectedTemplate?.redirect_url || null,
                json: selectedTemplate.body,
                isMarkdownEnabled: selectedTemplate.is_markdown_enabled,
              }
            : null
        }
        onClose={() => {
          setIsPreviewTemplateDialogOpen(false)
        }}
      />

      <ConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => {
          setIsDeleteConfirmationDialogOpen(false)
        }}
        i18nKey="survey.template-delete-confirmation"
        entityName={selectedTemplate?.name}
        onConfirm={() => {
          deleteTemplate()
        }}
      />
      <Loader isVisible={isLoaderVisible} />
    </>
  )
}

export default Templates
