import { Fade, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import ArchiveIcon from '@mui/icons-material/Archive'
import PublishIcon from '@mui/icons-material/Publish'
import { Course } from 'Component/CourseRegistration/Models'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import EditSettings from 'Actions/EditSettings'
import { toast } from 'react-toastify'

interface DropdownTableCoursesProps {
  organization: any
  course: Course
  anchorEl: any
  setIsPublishConfirmationDialogOpen: (value: boolean) => void
  setIsPopupOpen: (value: boolean) => void
  setDeleteTag: (value: string) => void
  handleClose: () => void
  archiveCourse: () => void
}

const DropdownTableCourses = ({
  organization,
  course,
  anchorEl,
  setIsPublishConfirmationDialogOpen,
  setIsPopupOpen,
  setDeleteTag,
  handleClose,
  archiveCourse,
}: DropdownTableCoursesProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Menu
      className="viewSessionMenu"
      id="fade-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <div>
        {organization?.userPermissions.includes('edit:courses') &&
          !course.publish_at && (
            <MenuItem
              onClick={() => {
                handleClose()
                setIsPublishConfirmationDialogOpen(true)
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PublishIcon className="me-1" style={{ color: '#157AFF' }} />
              <span className="mt-1">{t('courses.Publish')}</span>
            </MenuItem>
          )}

        {organization?.userPermissions.includes('edit:courses') &&
          !course.publish_at && (
            <MenuItem
              onClick={() => {
                handleClose()
                setTimeout(() => {
                  history.push(`/Courses/${course.id}`)
                  dispatch(EditSettings(true))
                }, 200)
              }}
            >
              <i className="far fa-edit ms-1" style={{ color: '#157AFF' }}></i>
              {t('courses.Edit')}
            </MenuItem>
          )}

        {organization?.userPermissions.includes('edit:courses') && (
          <MenuItem
            onClick={() => {
              handleClose()
              archiveCourse()
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ArchiveIcon className="me-1" style={{ color: '#157AFF' }} />
            <span className="mt-1">{t('courses.Archive')}</span>
          </MenuItem>
        )}

        {organization?.userPermissions.includes('delete:course') && (
          <MenuItem
            onClick={() => {
              setIsPopupOpen(true)
              handleClose()
              setDeleteTag('course')
            }}
          >
            <i
              className="fas fa-trash-alt ms-1"
              style={{ color: '#157AFF' }}
            ></i>
            {t('courses.Delete')}
          </MenuItem>
        )}

        {course.publish_at && (
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(course.course_Link)
              toast.success(t('common.copied-to-clipboard-message'), {
                theme: 'colored',
              })
              handleClose()
            }}
          >
            <i className="fa fa-copy ms-1" style={{ color: '#157AFF' }}></i>
            {t('courses.copy-registration-link')}
          </MenuItem>
        )}
      </div>
    </Menu>
  )
}

export default DropdownTableCourses
