import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'
import RootReducer from './Reducers/index'

const middlewares = [createStateSyncMiddleware()]

const store = configureStore({
  reducer: RootReducer,
  middleware: middlewares,
})

initMessageListener(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof RootReducer>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
