import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { TableSortLabel } from '@mui/material'
import { useEffect, useState } from 'react'

export enum Order {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}
export interface UserTableData {
  first_name: string
  last_name: string
  email: string
  role_type: string
}

interface HeadCell {
  id: keyof UserTableData
  label: string
}

interface TableUsersProp {
  filteredAuth0Users: UserTableData[]
  pageNo: number
  rowsPerPage: number
  valueToOrderBy: string
  orderDirection: Order.DESCENDING | Order.ASCENDING
  rowCount?: any
  handleClick?: (e: any) => void
  setSingleAuth0User?: (data: any) => void
  onRequestSort: (event: any, property: keyof UserTableData) => void
}

const TableUsers = (prop: TableUsersProp) => {
  const {
    filteredAuth0Users,
    pageNo,
    rowsPerPage,
    valueToOrderBy,
    orderDirection,
    handleClick,
    setSingleAuth0User,
    onRequestSort,
  } = prop

  const { t } = useTranslation()

  const [headCells, setHeadCells] = useState<HeadCell[]>([])

  const createSortHandler =
    (property: keyof UserTableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  function descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray: any, comparator: any) => {
    const stybilizedRowArray: any[] = rowArray.map((el: any, index: any) => [
      el,
      index,
    ])
    stybilizedRowArray.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stybilizedRowArray.map((el: any) => el[0])
  }

  useEffect(() => {
    setHeadCells([
      {
        id: 'first_name',
        label: t('settings.FirstName'),
      },
      {
        id: 'last_name',
        label: t('settings.LastName'),
      },
      {
        id: 'email',
        label: t('settings.Emailid'),
      },
      {
        id: 'role_type',
        label: t('settings.Usertype'),
      },
    ])
  }, [t])

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 800 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                padding={'normal'}
                sortDirection={
                  valueToOrderBy === headCell.id ? orderDirection : false
                }
              >
                <TableSortLabel
                  active={valueToOrderBy === headCell.id}
                  direction={
                    valueToOrderBy === headCell.id ? orderDirection : 'asc'
                  }
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell className="tableHeader"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRowInformation(
            filteredAuth0Users,
            getComparator(orderDirection, valueToOrderBy)
          )
            .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
            .map((data, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>{data.first_name}</TableCell>
                  <TableCell>{data.last_name}</TableCell>
                  <TableCell>{data.email}</TableCell>
                  <TableCell>{data.role_type}</TableCell>
                  <TableCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      paddingBottom: '1.02rem !important',
                    }}
                  >
                    <DeleteOutlinedIcon
                      className="text-danger"
                      style={{
                        cursor: 'pointer',
                        right: '95px',
                        left: 'none',
                      }}
                      onClick={(e) => {
                        if (handleClick && setSingleAuth0User) {
                          handleClick(e)
                          setSingleAuth0User(data)
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableUsers
