// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { FormHelperText } from '@mui/material'
//
import UploadAvatar, { UploadProps } from 'Component/UploadAvatar/UploadAvatar'

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
  name: string
  avatarUrl?: string | null
  multiple?: boolean
}

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, avatarUrl, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar
            accept={{
              'image/png': ['.jpeg', '.jpg', '.png'],
            }}
            error={!!error}
            file={field.value || avatarUrl}
            {...other}
          />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  )
}
