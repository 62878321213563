import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  TextField,
  InputAdornment,
  Stack,
  Button,
  Typography,
  Menu,
  Link,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'
import { MenuItem, TableSortLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { StyledButton } from 'common/styled-component/StyledButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Fade from '@mui/material/Fade'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { AnchorEl } from 'common/types/MaterialUI'
import Loader from 'Component/Loader'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import { useHistory } from 'react-router-dom'
import { Project } from 'Component/FundedTraining/types'
import { getLocalizedDateFromUTC } from 'common/DateTimeUtils'

type Order = 'asc' | 'desc'

type HeadCellId = 'id' | 'title' | 'start_date' | 'end_date' | 'notice_title'

interface HeadCell {
  id: HeadCellId
  label: string
}

interface Props {
  projects: Project[]
  onProjectDeleted: () => void
}

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#0d6efd',
    fontSize: 16,
  },
  '.MuiTableSortLabel-root.MuiTableSortLabel-active': {
    color: '#0d6efd',
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: '#5e5e5e',
    fontSize: 15,
  },
}))

export const ProjectsTable = (props: Props) => {
  const { projects, onProjectDeleted } = props

  const { t } = useTranslation()
  const api = useApi(false)
  let history = useHistory()

  const [headCells, setHeadCells] = useState<HeadCell[]>([])
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([])
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<HeadCellId>('title')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [searchText, setSearchText] = useState('')
  const [anchorEl, setAnchorEl] = useState<AnchorEl>(null)
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false)
  const [selectedProject, setSelectedProject] = useState<null | Project>(null)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  function stringComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => stringComparator(a, b, orderBy)
      : (a: any, b: any) => -stringComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: HeadCellId
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const createSortHandler =
    (property: HeadCellId) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setHeadCells([
      {
        id: 'title',
        label: t('common.title'),
      },
      {
        id: 'notice_title',
        label: t('funded-training.notice'),
      },
      {
        id: 'start_date',
        label: t('common.start'),
      },
      {
        id: 'end_date',
        label: t('common.end'),
      },
    ])
  }, [t])

  useEffect(() => {
    const cleanInputSearchText = searchText.toLowerCase().trim()
    let filtered: Project[] = projects

    if (cleanInputSearchText) {
      filtered = projects.filter((project) => {
        const matchesName =
          project.title &&
          project.title.toLowerCase().includes(cleanInputSearchText)

        return matchesName
      })
    }
    setFilteredProjects(filtered)
    setPage(0)
  }, [searchText, projects])

  const deleteProject = () => {
    if (!selectedProject) {
      return
    }

    setIsLoaderVisible(true)

    try {
      api.delete(`funded_training/projects/${selectedProject.uuid}`).then(
        (response) => {
          toast.success(response.data.message, { theme: 'colored' })
          onProjectDeleted()
          setIsLoaderVisible(false)
        },
        (error) => {
          toast.error(error.message, { theme: 'colored' })
          console.log(error)
          setIsLoaderVisible(false)
        }
      )
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message, { theme: 'colored' })
      }
      setIsLoaderVisible(false)
      console.log(e)
    }
  }

  const onItemMenuClosed = () => {
    setAnchorEl(null)
  }

  const onItemClicked = (anchor: AnchorEl) => {
    setAnchorEl(anchor)
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          mb: 2,
          pr: 2,
        }}
      >
        <Box
          component="form"
          sx={{
            width: '25ch',
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="search-project-input"
            placeholder={t('common.search')}
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            inputProps={{
              sx: {
                p: 1,
              },
            }}
            InputProps={{
              sx: { backgroundColor: '#fefefe' },

              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <StyledButton
          sx={{
            backgroundColor: '#0d6efd !important',
          }}
          endIcon={<AddIcon />}
          onClick={(event) => {
            history.push(`projects/new`)
          }}
        >
          <Typography sx={{ mt: 0.5 }}>
            {t('funded-training.new-project')}
          </Typography>
        </StyledButton>
      </Stack>

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 800, tableLayout: 'auto' }}
          aria-label="Projects table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <StyledTableHeadCell
                  key={headCell.id}
                  padding={'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </StyledTableHeadCell>
              ))}
              <StyledTableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredProjects, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((project, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index}>
                    <StyledTableCell>
                      <Link
                        sx={{ cursor: 'pointer' }}
                        underline="none"
                        onClick={() => {
                          history.push(
                            `/funded-training/projects/${project.uuid}`,
                            {
                              projectTitle: project.title,
                            }
                          )
                        }}
                      >
                        {project.title}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      {project.notice?.title || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {project.start_date
                        ? getLocalizedDateFromUTC(project.start_date)
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {project.end_date
                        ? getLocalizedDateFromUTC(project.end_date)
                        : '-'}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <MoreHoriz
                        style={{
                          cursor: 'pointer',
                          right: '95px',
                          left: 'none',
                        }}
                        onClick={(e) => {
                          onItemClicked(e.currentTarget)
                          setSelectedProject(project)
                        }}
                      />
                    </StyledTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredProjects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onItemMenuClosed}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            setIsDeleteConfirmationDialogOpen(true)
            onItemMenuClosed()
          }}
        >
          <DeleteIcon
            fontSize="small"
            className="me-2"
            style={{ color: '#157AFF' }}
          />
          <span style={{ color: '#157aff' }}>{t('common.delete')}</span>
        </MenuItem>
      </Menu>

      <ConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => {
          setIsDeleteConfirmationDialogOpen(false)
        }}
        i18nKey="funded-training.project-detail.delete-confirmation"
        entityName={selectedProject?.title}
        onConfirm={() => {
          deleteProject()
        }}
      />
      <Loader isVisible={isLoaderVisible} />
    </>
  )
}
