import { useTranslation } from 'react-i18next'

type Props = {
  templates: any[]
  onChange(value: string): void
  formID?: string
}

const TemplateSelect = ({ templates, onChange, formID }: Props) => {
  const { t } = useTranslation()

  return (
    <select
      className="form-select"
      value={formID}
      style={{ color: formID ? 'black' : 'rgba(21, 122, 255, 0.45)' }}
      onChange={(e) => {
        if (e.target.value === '') {
          e.target.style.color = 'rgba(21, 122, 255, 0.45)'
        } else {
          e.target.style.color = 'black'
        }
        onChange(e.target.value)
      }}
    >
      <option value="">{t('common.select-template')}</option>
      {templates &&
        templates.map((template, index) => (
          <option value={template.external_id || template._id} key={index}>
            {template.name}
          </option>
        ))}
    </select>
  )
}

export default TemplateSelect
