import clock from 'assets/icon/clock.svg'
import location from 'assets/icon/location.svg'
import { Session } from 'Component/CourseRegistration/Models'
import { getLocalizedTimeFromUTC } from 'common/DateTimeUtils'
import { useTranslation } from 'react-i18next'
import DevicesIcon from '@mui/icons-material/Devices'
import { useAppSelector } from 'Store'
import { selectTimezone } from 'Reducers/CourseRegistrationReducer'
import { getTimezoneAbbreviation } from 'common/DateTimeUtils'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

type Props = {
  session: Session
}

const SessionDetail = ({ session }: Props) => {
  const { t } = useTranslation()

  const timezone = useAppSelector(selectTimezone)

  return (
    <div className="session-detail">
      {((session.dateTimeFrom && session.dateTimeTo) ||
        session.dateTimeFrom) && (
        <div className="session-detail-time">
          <img src={clock} alt="Session time icon" />
          {session.dateTimeFrom && session.dateTimeTo ? (
            <span>
              {getLocalizedTimeFromUTC(session.dateTimeFrom || '', timezone)}
              {' - '}
              {getLocalizedTimeFromUTC(session.dateTimeTo, timezone)} (
              {getTimezoneAbbreviation(timezone)})
            </span>
          ) : (
            <span>
              {getLocalizedTimeFromUTC(session.dateTimeFrom || '', timezone)} (
              {session.dateTimeFrom && getTimezoneAbbreviation(timezone)})
            </span>
          )}
        </div>
      )}
      <div className="session-detail-title">
        <span>{session.title}</span>
      </div>
      <div className="session-detail-description">
        <ReactQuill
          theme="bubble"
          readOnly={true}
          value={session.descriptionMarkup}
        />
      </div>

      <div hidden={!session.isOnline} className="session-detail-location">
        {/* <img src={location} alt="Share icon" /> */}
        <DevicesIcon />
        <span>{t('courses.Online')}</span>
      </div>
      <div
        hidden={!session.locationAddress}
        className="session-detail-location"
      >
        <img src={location} alt="Share icon" />
        <span>{session.locationAddress}</span>
      </div>
    </div>
  )
}

export default SessionDetail
