import { Fade, Menu, MenuItem } from '@mui/material'
import PreviewIcon from '@mui/icons-material/Preview'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CopyLinkIcon from '@mui/icons-material/Link'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { AnchorEl } from 'common/types/MaterialUI'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Survey } from 'Component/CourseRegistration/Models'

interface DropdownSurveyProp {
  courseId?: number
  anchorEl: AnchorEl
  selectedSurvey: Survey
  handleMenuClose: () => void
  createTemplateFromSurvey: () => void
  cloneSurvey: () => void
  setIsDeleteConfirmationDialogOpen: (value: boolean) => void
}

const DropdownSurvey = ({
  courseId,
  anchorEl,
  selectedSurvey,
  handleMenuClose,
  createTemplateFromSurvey,
  cloneSurvey,
  setIsDeleteConfirmationDialogOpen,
}: DropdownSurveyProp) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            history.push(`/Survey/${selectedSurvey.external_id}/edit`)
            handleMenuClose()
          }}
        >
          <div>
            <EditIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.edit')}</span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose()
            history.push(`/Survey/${selectedSurvey.external_id}`)
          }}
        >
          <div>
            <PreviewIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.preview')}</span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose()
            window.open(selectedSurvey.link, '_blank')
          }}
        >
          <div style={{ alignItems: 'flex-end' }}>
            <ArrowForwardIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>
              {t('survey.go-to-submission')}
            </span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose()
            if (selectedSurvey.link)
              navigator.clipboard.writeText(selectedSurvey.link)
          }}
        >
          <div style={{ alignItems: 'flex-end' }}>
            <CopyLinkIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>{t('survey.copy-link')}</span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose()
            createTemplateFromSurvey()
          }}
        >
          <div style={{ alignItems: 'flex-end' }}>
            <SaveIcon
              fontSize="small"
              className="me-2"
              style={{ color: '#157AFF' }}
            />
            <span style={{ color: '#157aff' }}>
              {t('survey.save-as-template')}
            </span>
          </div>
        </MenuItem>

        {!courseId && (
          <MenuItem
            onClick={() => {
              cloneSurvey()
              handleMenuClose()
            }}
          >
            <div>
              <FileCopyIcon
                fontSize="small"
                className="me-2"
                style={{ color: '#157AFF' }}
              />
              <span style={{ color: '#157aff' }}>{t('survey.clone')}</span>
            </div>
          </MenuItem>
        )}

        {!courseId && (
          <MenuItem
            onClick={() => {
              setIsDeleteConfirmationDialogOpen(true)
              handleMenuClose()
            }}
          >
            <div>
              <DeleteIcon
                fontSize="small"
                className="me-2"
                style={{ color: '#157AFF' }}
              />
              <span style={{ color: '#157aff' }}>{t('survey.delete')}</span>
            </div>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
export default DropdownSurvey
