import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from 'Component/Loader';
import useApi from '../../../api/UseApi';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import ConfirmationDialog from 'Component/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

export default function OrganizationMemberPopup({
  user,
  onUserDeleted,
  isConfirmationDialogVisible,
  setIsConfirmationDialogVisible,
}) {
  const [isLoaderVisible, setIsLoaderVisible] = React.useState(false);
  const organization = useAppSelector(selectCurrentOrganization);

  const api = useApi(false);
  const { t } = useTranslation();

  const deleteUser = () => {
    setIsLoaderVisible(true);
    try {
      api
        .delete(`users/${user.id}`, {
          params: {
            organization_id: organization?.id,
          },
        })
        .then(
          (response) => {
            toast.success(t('common.deleted-user'), { theme: 'colored' });
            onUserDeleted();
            setIsLoaderVisible(false);
          },
          (error) => {
            console.log(error.response);
            toast.error(error.response.data.message, { theme: 'colored' });
            setIsLoaderVisible(false);
          }
        );
    } catch (e) {
      console.log(e.message);
    }
    setIsConfirmationDialogVisible(false);
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmationDialogVisible}
        onClose={() => {
          setIsConfirmationDialogVisible(false);
        }}
        i18nKey="settings.user-delete-confirmation"
        entityName={user.email}
        onConfirm={deleteUser}
      />

      <Loader isVisible={isLoaderVisible} />
    </>
  );
}
