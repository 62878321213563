import { styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'

export const StyledButton = styled(LoadingButton)({
  backgroundColor: 'rgb(33, 43, 54)',
  padding: '6px 12px',
  lineHeight: '1.71429',
  fontSize: '0.875rem',
  fontFamily: 'sans-serif',
  fontWeight: '400',
  borderRadius: '0.5rem',
})
