import * as Yup from 'yup'
import { useCallback, useEffect, useState } from 'react'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Box, Grid, Stack, Typography } from '@mui/material'
// components
import { CustomFile } from 'Component/UploadAvatar/UploadAvatar'
import FormProviderCustom from 'Component/ReactHookFormComponents/FormProviderCustom'
import { RHFUploadAvatar } from 'Component/ReactHookFormComponents/RHFUploadAvatar'
import RHFTextField from 'Component/ReactHookFormComponents/RHFTextField'
import { RHFSelect } from 'Component/ReactHookFormComponents/RHFSelect'
import { StyledButton } from 'common/styled-component/new-template/StyledButton'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
import RHFAutocomplete from 'Component/ReactHookFormComponents/RHFAutocomplete'
//hook
import { useTranslation } from 'react-i18next'
//redux
import { useAppSelector } from 'Store'
import {
  selectAvatarUrl,
  selectEmail,
  selectFirstName,
  selectJobPosition,
  selectLanguage,
  selectLastName,
  selectMobile,
} from 'Reducers/UserConfigReducer'
//utils
import { getTimezone, getTimezoneLabel } from 'common/DateTimeUtils'
//service
import MeService from 'Service/MeService'
import { objectIsEmpty } from 'utils/utils'
import { toast } from 'react-toastify'

// ----------------------------------------------------------------------

export type UserValuesProps = {
  displayName: string
  email: string
  avatar: CustomFile
  phoneNumber: string
  timezones: { id: string; label: string }
  surname: string
  language: string
  role: string
}

export default function AccountGeneral() {
  const currentEmail = useAppSelector(selectEmail)
  const currentFirstName = useAppSelector(selectFirstName)
  const currentLastName = useAppSelector(selectLastName)
  const currentLanguage = useAppSelector(selectLanguage)
  const currentMobile = useAppSelector(selectMobile)
  const currentJobPosition = useAppSelector(selectJobPosition)
  const currentAvatarUrl = useAppSelector(selectAvatarUrl)

  const { putUser } = MeService()
  const { t } = useTranslation()

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required(t('user-account.required-name')),
    email: Yup.string()
      .required(t('user-account.required-email'))
      .email(t('user-account.valid-email')),
    timezones: Yup.mixed().required(t('user-account.required-timezones')),
    surname: Yup.string().required(t('user-account.required-surname')),
    language: Yup.string().required(t('user-account.required-language')),
  })

  const defaultValues = {
    displayName: currentFirstName,
    surname: currentLastName,
    email: currentEmail,
    phoneNumber: currentMobile,
    timezones: { id: getTimezone(), label: getTimezoneLabel(getTimezone()) },
    role: currentJobPosition,
    language: currentLanguage,
  }

  const languages = ['it', 'en']

  const fileMaxSize = 3145728

  const methods = useForm<UserValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  })

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
    control,
  } = methods

  const onSubmit = async (data: UserValuesProps) => {
    try {
      await putUser(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      let uploadFailed = false
      let newFile = null

      try {
        newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      } catch (error) {
        uploadFailed = true
        toast.warning(t('user-account.max-size'), {
          theme: 'colored',
        })
      }

      if (!uploadFailed && file && newFile) {
        setValue('avatar', newFile, { shouldValidate: true })
      }
    },
    [setValue]
  )

  useEffect(() => {
    reset({
      displayName: currentFirstName,
      surname: currentLastName,
      email: currentEmail,
      phoneNumber: currentMobile,
      timezones: { id: getTimezone(), label: getTimezoneLabel(getTimezone()) },
      role: currentJobPosition,
      language: currentLanguage,
    })
  }, [
    currentFirstName,
    currentLastName,
    currentEmail,
    currentMobile,
    currentJobPosition,
    currentLanguage,
    reset,
  ])

  return (
    <FormProviderCustom methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <StyledCard
            sx={{
              py: 10,
              px: 3,
              textAlign: 'center',
            }}
          >
            <RHFUploadAvatar
              name="avatar"
              avatarUrl={currentAvatarUrl}
              maxSize={fileMaxSize}
              onDrop={handleDrop}
              disabled={isSubmitting}
              helperText={
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.primary',
                  }}
                >
                  {t('user-account.max-size')}
                  <br /> {t('user-account.allowed-format')}
                </Typography>
              }
            />
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledCard
            sx={{
              p: 3,
            }}
          >
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="displayName"
                label={t('user-account.name')}
                disabled={isSubmitting}
              />

              <RHFTextField
                name="surname"
                label={t('user-account.surname')}
                disabled={isSubmitting}
              />

              <RHFTextField
                name="email"
                label={t('user-account.email')}
                disabled={isSubmitting}
              />

              <RHFTextField
                name="phoneNumber"
                label={t('user-account.number')}
                disabled={isSubmitting}
              />

              <RHFAutocomplete
                name="timezones"
                label={t('user-account.timezone')}
                control={control}
                disabled={isSubmitting}
              />

              <RHFSelect
                native
                name="language"
                label={t('user-account.language')}
                disabled={isSubmitting}
              >
                <option value="" />
                {languages.map((language) => (
                  <option key={language} value={language}>
                    {language}
                  </option>
                ))}
              </RHFSelect>

              <RHFTextField
                name="role"
                label={t('user-account.role')}
                disabled={isSubmitting}
              />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <StyledButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting || objectIsEmpty(errors)}
              >
                {t('common.save-changes')}
              </StyledButton>
            </Stack>
          </StyledCard>
        </Grid>
      </Grid>
    </FormProviderCustom>
  )
}
