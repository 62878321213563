import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Button } from 'react-bootstrap'
import 'assets/scss/survey.scss'
import { useTranslation } from 'react-i18next'
import SurveyCreator from 'Component/SurveyCreator'
import { JSONValue } from 'common/types/Json'
import { toast } from 'react-toastify'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { Checkbox, Link, Stack, Typography } from '@mui/material'

type SurveyCreatorDialogData = {
  title: string
  description: string
  redirectUrl: null | string
  json: JSONValue
  isMarkdownEnabled: boolean
}

export type SurveyCreatorDialogType = 'survey' | 'template'

type Props = {
  isOpen: boolean
  isPreview?: boolean
  data?: SurveyCreatorDialogData | null
  type: SurveyCreatorDialogType
  onClose(): void
  onSave?: (survey: SurveyCreatorDialogData) => void
}

const SurveyCreatorDialog = ({
  isOpen,
  isPreview = false,
  data,
  type,
  onClose,
  onSave,
}: Props) => {
  const { t } = useTranslation()
  const [surveyTitle, setSurveyTitle] = useState<string>('')
  const [surveyDescription, setSurveyDescription] = useState('')
  const [redirectUrl, setRedirectUrl] = useState<null | string>(null)
  const [surveyJson, setSurveyJson] = useState<null | JSONValue>(null)
  const [isMarkdownEnabled, setIsMarkdownEnabled] = useState(false)
  const [
    isCloseEditorConfirmationDialogOpen,
    setIsCloseEditorConfirmationDialogOpen,
  ] = useState(false)

  useEffect(() => {
    if (data) {
      setSurveyTitle(data.title)
      setSurveyDescription(data.description)
      setRedirectUrl(data.redirectUrl)
      setSurveyJson(data.json)
      setIsMarkdownEnabled(data.isMarkdownEnabled)
    } else {
      setDefaultFieldValues()
    }
  }, [type, data])

  const setDefaultFieldValues = () => {
    const defaultTitle =
      type === 'survey'
        ? t('survey.default-survey-title')
        : t('survey.default-template-title')
    setSurveyTitle(defaultTitle)
    setSurveyDescription('')
    setRedirectUrl(null)
    setSurveyJson(null)
    setIsMarkdownEnabled(false)
  }

  const handleClose = () => {
    setDefaultFieldValues()
    onClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          if (isPreview) {
            handleClose()
            return
          }
          setIsCloseEditorConfirmationDialogOpen(true)
        }}
        fullWidth={true}
        maxWidth={'xl'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div></div>
            <i
              className="fa fa-times"
              style={{ cursor: 'pointer', color: 'gray', marginLeft: '46rem' }}
              aria-hidden="true"
              onClick={() => {
                if (isPreview) {
                  handleClose()
                  return
                }
                setIsCloseEditorConfirmationDialogOpen(true)
              }}
            ></i>
          </div>
          {surveyTitle && <hr />}
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '6rem' }}>
            <Stack>
              <Typography
                sx={{ ml: 0.5, color: '#0d6efd' }}
                fontSize={16}
                fontFamily="nexa"
              >
                {t('survey.title')}&nbsp;
              </Typography>
              <input
                hidden={isPreview && !surveyTitle}
                type="text"
                className="form-control mb-4"
                placeholder={t('survey.title')}
                defaultValue={surveyTitle}
                onBlur={(e) => {
                  setSurveyTitle(e.target.value)
                }}
                readOnly={isPreview}
              />
            </Stack>

            <Stack hidden={isPreview && !surveyDescription}>
              <Typography
                sx={{ ml: 0.5, color: '#0d6efd' }}
                fontSize={16}
                fontFamily="nexa"
              >
                {t('survey.description')}&nbsp;
              </Typography>
              <input
                type="text"
                className="form-control mb-4"
                placeholder={t('survey.description')}
                defaultValue={surveyDescription}
                onBlur={(e) => {
                  setSurveyDescription(e.target.value)
                }}
                readOnly={isPreview}
              />
            </Stack>

            <Stack hidden={isPreview && !redirectUrl}>
              <Typography
                sx={{ ml: 0.5, color: '#0d6efd' }}
                fontSize={16}
                fontFamily="nexa"
              >
                {t('survey.redirect-url')}&nbsp;
              </Typography>
              <Typography fontSize={16} sx={{ ml: 0.5 }} fontFamily="nexalight">
                {t('survey.redirect-url-description')}&nbsp;
              </Typography>
              <input
                type="text"
                className="form-control mb-4"
                placeholder={t('survey.redirect-url')}
                defaultValue={redirectUrl || ''}
                onBlur={(e) => {
                  setRedirectUrl(e.target.value)
                }}
                readOnly={isPreview}
              />
            </Stack>

            {!isPreview && (
              <Stack sx={{ py: 1, mb: 3, backgroundColor: '#f4f4f4' }}>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Checkbox
                    color="primary"
                    checked={isMarkdownEnabled}
                    onChange={(e, checked) => {
                      setIsMarkdownEnabled(checked)
                    }}
                  />
                  <Typography sx={{ mt: 1 }} fontSize={16} fontFamily="nexa">
                    {t('survey.enable-markdown')}&nbsp;
                  </Typography>
                </Stack>
                <Stack sx={{ px: 2, mt: 0.5 }} direction="row">
                  <Typography fontSize={16} fontFamily="nexalight">
                    {t('survey.enable-markdown-description')}&nbsp;
                  </Typography>
                  <Link
                    sx={{ fontWeight: 'bold' }}
                    href={'https://www.markdownguide.org/cheat-sheet'}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('survey.enable-markdown-more-info')}
                  </Link>
                </Stack>
              </Stack>
            )}
            <SurveyCreator
              surveyJSON={surveyJson}
              onSurveySaved={(body) => {
                setSurveyJson(body)
              }}
              isPreview={isPreview}
              isMarkdownEnabled={isMarkdownEnabled}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ marginRight: '5rem', marginBottom: '2rem' }}>
            {!isPreview && (
              <Button
                onClick={() => {
                  if (!surveyJson) {
                    toast.warning(t('survey.warning.at-least-one-question'), {
                      theme: 'colored',
                    })
                    return
                  }

                  setTimeout(() => {
                    onSave &&
                      onSave({
                        title: surveyTitle,
                        description: surveyDescription,
                        redirectUrl: redirectUrl,
                        json: surveyJson,
                        isMarkdownEnabled: isMarkdownEnabled,
                      })
                    handleClose()
                  }, 200)
                }}
              >
                {t('survey.save')}
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        isOpen={isCloseEditorConfirmationDialogOpen}
        onClose={() => {
          setIsCloseEditorConfirmationDialogOpen(false)
        }}
        i18nKey="survey.close-editor-confirmation"
        onConfirm={() => {
          setTimeout(() => {
            handleClose()
          }, 500)
        }}
      />
    </>
  )
}

export default SurveyCreatorDialog
