import { Button, Grid, Switch, TextField, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddIcon from '@mui/icons-material/Add'
import OrganizationsService from 'Service/OrganizationsService'
import { useAppSelector } from 'Store'
import Loader from 'Component/Loader'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { FormInputFieldType } from 'Component/CourseRegistration/RegistrationModal/FormFields/FormInputField'
import { StyledBoxInputNumber } from 'common/styled-component/StyledInputBase'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
import useWindowDimensions from 'common/hooks/useWindowDimensions'

export type Config = {
  id?: string | null
  name: string
  kind: FormInputFieldType | string
  active?: boolean
  default?: boolean
  _destroy?: boolean
  platform_default?: boolean
  mandatory?: boolean
  position: string
}

const options = ['text', 'date', 'number', 'email']

const ConfigRegistration = () => {
  const [configActive, setConfigActive] = useState<Config[]>([])
  const [customConfig, setCustomConfig] = useState<Config[]>([])
  const [indexMandatorySwitch, setIndexMandatorySwitch] = useState<number>()
  const [isLoading, setIsLoading] = useState(true)

  const organizationId = useAppSelector(
    (state) => state.UserConfig.currentOrganization?.id
  )

  const { width } = useWindowDimensions()

  const { t, i18n } = useTranslation()

  const { getCustomFields, postCustomFields } = OrganizationsService()

  const onChangeActive = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setConfigActive((state) => {
      const newState = [...(state ?? [])]
      //mandatory element must be true
      indexMandatorySwitch === index
        ? (newState[indexMandatorySwitch].active = true)
        : (newState[index].active = checked)
      return newState
    })
  }

  const onChangeRequired = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setConfigActive((state) => {
      const newState = [...(state ?? [])]
      //mandatory element must be true
      indexMandatorySwitch === index
        ? (newState[indexMandatorySwitch].mandatory = true)
        : (newState[index].mandatory = checked)
      return newState
    })
  }

  const onChangePosition = (
    { target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const regex = /^[0-9][0-9]*$/
    if (value === '' || regex.test(value)) {
      setConfigActive((state) => {
        const newState = [...(state ?? [])]
        newState[index].position = value
        return newState
      })
    }
  }

  const handleChange = (
    {
      target: { value, name },
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    setCustomConfig((state) => {
      const newState = [...(state ?? [])]
      if (name === 'label') newState[index].name = value
      else newState[index].kind = value
      return newState
    })
  }

  const handleChangePosition = (
    { target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setCustomConfig((state) => {
      const newState = [...(state ?? [])]
      newState[index].position = value
      return newState
    })
  }

  const submitActive = async () => {
    setIsLoading(true)

    const configActiveCopy = [...configActive]

    //filter array by true element of checkboxes array
    const dataSubmitActive: Config[] = configActiveCopy
      .filter((ca, index) => ca.active)
      .map((data) => ({
        id: data.id ?? null,
        name: data.name,
        kind: data.kind,
        position: data.position,
        mandatory: data.mandatory || false,
        default: true,
      }))
    //filter array by false element of checkboxes array and add field _destroy to remove the elemnt
    const dataSubmitDestroyed: Config[] = configActiveCopy
      .filter((ca, index) => !ca.active)
      .map((data) => ({
        id: data.id ?? null,
        name: data.name,
        kind: data.kind,
        position: data.position,
        mandatory: data.mandatory || false,
        default: true,
        _destroy: true,
      }))

    const custom_fields_attributes: Config[] = [
      ...dataSubmitActive,
      ...dataSubmitDestroyed,
      ...customConfig,
    ]

    if (organizationId) {
      await postCustomFields(organizationId, custom_fields_attributes)
      console.log({ custom_fields_attributes })
    }

    if (organizationId) getConfig(organizationId)

    setIsLoading(false)
  }

  const addCustomConfig = () => {
    setCustomConfig([
      ...customConfig,
      { id: null, name: '', kind: '', position: '' },
    ])
  }

  function getLabel(name: string) {
    return i18n.exists(`course-registration.modal.form.${name}`)
      ? t(`course-registration.modal.form.${name}`)
      : name
  }

  const getConfig = async (organizationId: number) => {
    setIsLoading(true)
    const defaultConfigServer: Config[] = []
    const customConfigServer: Config[] = []
    const configServer = await getCustomFields(organizationId)

    //splitting configServer array
    if (configServer) {
      for (const config of configServer) {
        if (config.default) {
          //array with default fields
          defaultConfigServer.push(config)
        } else {
          //array with custom fields
          customConfigServer.push(config)
        }
      }

      const indexMandatory = defaultConfigServer.findIndex((d) => d.mandatory)

      //adding field active to elements with id
      const mapConfigServer = defaultConfigServer.map((config) => {
        if (config.id) {
          return { ...config, active: true }
        } else return { ...config, active: false }
      })

      setConfigActive(mapConfigServer)
      setCustomConfig(customConfigServer)
      setIndexMandatorySwitch(indexMandatory)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (organizationId) {
      getConfig(organizationId)
    }
  }, [organizationId])

  const renderDefaultConfig = () => {
    if (!isLoading && configActive.length === 0) {
      return (
        <Typography sx={{ fontSize: '12px', padding: '14px 0px 0px 14px' }}>
          {t('configRegistration.no-config-default')}
        </Typography>
      )
    } else {
      const newConfig = configActive.map((c, i) => {
        return (
          <>
            <Grid item xs={5} sm={6}>
              <label className="form-label checkbox-button cursorPointer">
                {getLabel(c.name)}
              </label>
            </Grid>
            <Grid item xs={2}>
              <Switch
                className="cursorPointer"
                checked={c.active}
                onChange={(e) => onChangeActive(e, i)}
                name={c.name}
              />
            </Grid>
            <Grid item xs={2}>
              <Switch
                className="cursorPointer"
                checked={c.mandatory}
                onChange={(e) => onChangeRequired(e, i)}
                name={c.name}
              />
            </Grid>
            <Grid item xs={3} sm={2} sx={{ textAlign: 'center' }}>
              <StyledBoxInputNumber
                type="number"
                onChange={(e) => onChangePosition(e, i)}
                value={c.position || ''}
              />
            </Grid>
          </>
        )
      })
      return newConfig
    }
  }

  const renderCustomConfig = () => {
    const newConfig = customConfig.map((c, i) => {
      return (
        !c._destroy && (
          <CSSTransition
            key={i}
            timeout={500}
            classNames="config_registration_animation"
          >
            <div className="">
              <Grid container rowSpacing={1} columnSpacing={0.5}>
                <Grid item sm={5}>
                  <label className="form-label">
                    {t('configRegistration.label')}
                  </label>
                </Grid>
                <Grid item sm={5}>
                  <label className="form-label" id="types">
                    {t('configRegistration.type')}
                  </label>
                </Grid>
                <Grid item sm={1}>
                  <label className="form-label" id="types">
                    {t('course-registration.config.position_short')}
                  </label>
                </Grid>
                <Grid item sm={5}>
                  <input
                    className="form-control form-control addCoursesInput"
                    type="text"
                    name="label"
                    value={c.name}
                    onChange={(e) => handleChange(e, i)}
                  />
                </Grid>
                <Grid item sm={5}>
                  <select
                    className="form-select conf-select"
                    name="types"
                    id="types"
                    value={c.kind}
                    onChange={(e) => handleChange(e, i)}
                  >
                    <option value="">{t('common.select')}</option>
                    {options.map((option, index) => (
                      <option key={`${option}${index}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item sm={1}>
                  <StyledBoxInputNumber
                    sx={{ height: '38px' }}
                    type="number"
                    onChange={(e) => handleChangePosition(e, i)}
                    value={c.position || ''}
                  />
                </Grid>
                <Grid item sm={1}>
                  <button
                    className="border-0 ms-3 bg-body text-danger"
                    onClick={() => {
                      setCustomConfig((state) => {
                        const newState = [...(state ?? [])]
                        newState[i] = { ...newState[i], _destroy: true }
                        return newState
                      })
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                </Grid>
              </Grid>
            </div>
          </CSSTransition>
        )
      )
    })
    return newConfig
  }

  return (
    <StyledCard
      sx={{
        py: 2,
        px: 4,
        mr: 1,
      }}
    >
      <div className="mb-5">
        <Typography sx={{ paddingLeft: '14px' }} className="mb-3">
          {t('configRegistration.config-default')}
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={0.5}
          sx={{ alignItems: 'baseline' }}
        >
          <Grid item xs={5} sm={6}></Grid>
          <Grid item xs={2} sm={2} sx={{ textAlign: 'center' }}>
            <label className="form-label" id="types">
              {width >= 600
                ? t('course-registration.config.active')
                : t('course-registration.config.active_short')}
            </label>
          </Grid>
          <Grid item xs={2} sm={2} sx={{ textAlign: 'center' }}>
            <label className="form-label" id="types">
              {width >= 600
                ? t('course-registration.config.required')
                : t('course-registration.config.required_short')}
            </label>
          </Grid>
          <Grid item xs={3} sm={2} sx={{ textAlign: 'center' }}>
            <label className="form-label" id="types">
              {width >= 600
                ? t('course-registration.config.position')
                : t('course-registration.config.position_short')}
            </label>
          </Grid>
          {renderDefaultConfig()}
        </Grid>
      </div>
      <div className="mb-2">
        <div className="d-flex align-items-center mt-5">
          <Typography sx={{ paddingLeft: '14px' }}>
            {t('configRegistration.config-custom')}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              marginX: '16px !important',
              padding: '0px',
              borderRadius: '50%',
              minWidth: '40px !important',
              height: '40px',
            }}
            onClick={addCustomConfig}
            disabled={customConfig.filter((c) => !c._destroy).length >= 5}
          >
            <AddIcon />
          </Button>
        </div>
        {!isLoading && customConfig.filter((c) => !c._destroy).length === 0 && (
          <Typography sx={{ fontSize: '12px', padding: '14px 0px 0px 14px' }}>
            {t('configRegistration.no-config-custom')}
          </Typography>
        )}
        <TransitionGroup>{renderCustomConfig()}</TransitionGroup>
      </div>
      <div className="w-100 mt-5">
        <Button
          variant="contained"
          sx={{
            marginX: '16px !important',
            marginBottom: '16px',
            float: 'right',
          }}
          onClick={submitActive}
        >
          {t('settings.Update')}
        </Button>
      </div>
      <Loader isVisible={isLoading} />
    </StyledCard>
  )
}

export default ConfigRegistration
