import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  TextField,
  InputAdornment,
  Stack,
  Typography,
  Checkbox,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'
import { TableSortLabel } from '@mui/material'
import { useEffect, useReducer, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { StyledButton } from 'common/styled-component/StyledButton'
import AttendanceSelectTime from 'Component/SelectTime/AttendanceSelectTime'
import { AttendanceRequestItem, AttendanceResult } from './types'
import moment from 'moment'
import i18next from 'i18next'
import { getUTCDateTime } from 'common/DateTimeUtils'
import { AttendanceSession } from './SessionAttendance'

type Order = 'asc' | 'desc'

interface HeadCell {
  id: keyof AttendanceResult
  label: string
}

type AttendanceData = {
  id: number | null
  entranceTime: string
  exitTime: string
  isSelected: boolean
}

interface Props {
  session: AttendanceSession
  sessionStartTime: string
  sessionEndTime: string
  attendanceResults: AttendanceResult[]
  onAttendanceResultUpdated: (
    attendanceRequestItems: AttendanceRequestItem[]
  ) => void
}

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#0d6efd',
    fontSize: 16,
  },
  '.MuiTableSortLabel-root.MuiTableSortLabel-active': {
    color: '#0d6efd',
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: '#5e5e5e',
    fontSize: 15,
  },
}))

export const SessionAttendanceTable = (props: Props) => {
  const {
    session,
    attendanceResults,
    sessionStartTime,
    sessionEndTime,
    onAttendanceResultUpdated,
  } = props

  const { t } = useTranslation()

  const [headCells, setHeadCells] = useState<HeadCell[]>([])
  const [filteredAttendanceResults, setFilteredAttendanceResults] = useState<
    AttendanceResult[]
  >([])
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [searchText, setSearchText] = useState('')

  const [isRevertConfirmationDialogOpen, setIsRevertConfirmationDialogOpen] =
    useState(false)

  const [initialUserAttendanceMap, setInitialUserAttendanceMap] = useState<
    Record<number, AttendanceData>
  >([])
  const [userAttendanceMap, setUserAttendanceMap] = useState<
    Record<number, AttendanceData>
  >([])

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)

  function revertChanges() {
    let deepCopy = JSON.parse(JSON.stringify(initialUserAttendanceMap))
    setUserAttendanceMap(deepCopy)
  }

  function convertToRequest() {
    let tempRequestItems: AttendanceRequestItem[] = []

    Object.entries(userAttendanceMap).forEach(([userIdKey, attendanceData]) => {
      const userId = Number(userIdKey)

      const attendanceId = initialUserAttendanceMap[userId].id

      let convertedEntranceTime = attendanceData.entranceTime
      let convertedExitTime = attendanceData.exitTime

      let startDate = session.start_date.split('T')[0]
      let endDate = session.end_date.split('T')[0]

      if (convertedEntranceTime) {
        let time = null
        if (i18next.language !== 'it') {
          time = moment(convertedEntranceTime, 'HH:mm A').format('HH:mm')
        } else {
          time = convertedEntranceTime
        }
        convertedEntranceTime = `${startDate.split('T')[0]}T${time}:00`
        convertedEntranceTime = getUTCDateTime(convertedEntranceTime)
      }

      if (convertedExitTime) {
        let time = null
        if (i18next.language !== 'it') {
          time = moment(convertedExitTime, 'HH:mm A').format('HH:mm')
        } else {
          time = convertedExitTime
        }
        convertedExitTime = `${endDate.split('T')[0]}T${time}:00`
        convertedExitTime = getUTCDateTime(convertedExitTime)
      }

      if (attendanceId != null) {
        if (!userAttendanceMap[userId].isSelected) {
          // console.log('REMOVE!', userId)
          // Item has been removed
          tempRequestItems.push({
            id: attendanceId,
            user_id: userId,
            _destroy: true,
          })
        } else {
          // console.log('UPDATE!', userId)
          // Item has been updated (Pass the ID)
          tempRequestItems.push({
            id: attendanceId,
            user_id: userId,
            entrance_time: convertedEntranceTime,
            exit_time: convertedExitTime,
          })
        }
      } else if (userAttendanceMap[userId].isSelected) {
        // console.log('CREATE!', userId)
        // Item is created
        tempRequestItems.push({
          user_id: userId,
          entrance_time: convertedEntranceTime,
          exit_time: convertedExitTime,
        })
      }
    })

    console.log('tempRequestItems', tempRequestItems)

    onAttendanceResultUpdated(tempRequestItems)
  }

  useEffect(() => {
    let newUserAttendanceMap: Record<number, AttendanceData> = {}

    attendanceResults.forEach((attendanceResult) => {
      newUserAttendanceMap[attendanceResult.user_id] = {
        id: attendanceResult.id || null,
        isSelected: !!attendanceResult.id,
        entranceTime: attendanceResult.entrance_time || sessionStartTime,
        exitTime: attendanceResult.exit_time || sessionEndTime,
      }
    })

    let deepCopy1 = JSON.parse(JSON.stringify(newUserAttendanceMap))
    let deepCopy2 = JSON.parse(JSON.stringify(newUserAttendanceMap))

    setInitialUserAttendanceMap(deepCopy1)
    setUserAttendanceMap(deepCopy2)
  }, [attendanceResults, sessionEndTime, sessionStartTime])

  function stringComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => stringComparator(a, b, orderBy)
      : (a: any, b: any) => -stringComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const createSortHandler =
    (property: keyof AttendanceResult) =>
    (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setHeadCells([
      {
        id: 'name',
        label: t('settings.Name'),
      },
      {
        id: 'email',
        label: t('common.mail'),
      },
      {
        id: 'entrance_time',
        label: t('common.access'),
      },
      {
        id: 'exit_time',
        label: t('common.exit'),
      },
    ])
  }, [t])

  useEffect(() => {
    const cleanInputSearchText = searchText.toLowerCase().trim()
    let filtered: AttendanceResult[] = attendanceResults

    if (cleanInputSearchText) {
      filtered = attendanceResults.filter((attendanceResult) => {
        const matchesName =
          attendanceResult.name &&
          attendanceResult.name.toLowerCase().includes(cleanInputSearchText)

        return matchesName
      })
    }
    setFilteredAttendanceResults(filtered)
    setPage(0)
  }, [searchText, attendanceResults])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          mb: 2,
          pr: 2,
        }}
      >
        <Box
          component="form"
          sx={{
            width: '25ch',
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="search-attendance-input"
            placeholder={t('common.search')}
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            inputProps={{
              sx: {
                p: 1,
              },
            }}
            InputProps={{
              sx: { backgroundColor: '#fefefe' },

              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Stack direction="row">
          <StyledButton
            sx={{
              backgroundColor: '#0d6efd !important',
              mr: 2,
            }}
            onClick={(event) => {
              setIsRevertConfirmationDialogOpen(true)
            }}
          >
            <Typography sx={{ mt: 0.5 }}>{t('common.revert')}</Typography>
          </StyledButton>

          <StyledButton
            sx={{
              backgroundColor: '#218a4d !important',
            }}
            onClick={(event) => {
              convertToRequest()
            }}
          >
            <Typography sx={{ mt: 0.5 }}>{t('common.confirm')}</Typography>
          </StyledButton>
        </Stack>
      </Stack>

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 800, tableLayout: 'auto' }}
          aria-label="Attendance table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <StyledTableHeadCell
                  key={headCell.id}
                  padding={'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.id === 'name' || headCell.id === 'email' ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <Typography variant="subtitle1">
                      {headCell.label}
                    </Typography>
                  )}
                </StyledTableHeadCell>
              ))}

              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={
                    Object.values(userAttendanceMap).filter(
                      (userAttendanceItem) => {
                        return userAttendanceItem.isSelected
                      }
                    ).length === attendanceResults.length
                  }
                  onChange={(event, checked) => {
                    attendanceResults.forEach((attendanceResult) => {
                      userAttendanceMap[attendanceResult.user_id].isSelected =
                        checked
                      forceUpdate()
                    })
                  }}
                  inputProps={{
                    'aria-label': 'Select all',
                  }}
                />
              </TableCell>
              <StyledTableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              filteredAttendanceResults,
              getComparator(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((attendanceResult, index) => {
                const isItemSelected: boolean =
                  userAttendanceMap[attendanceResult.user_id].isSelected

                return (
                  <TableRow tabIndex={-1} key={index}>
                    <StyledTableCell>
                      {' '}
                      <Typography sx={{ mt: 0.5 }}>
                        {attendanceResult.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {' '}
                      <Typography sx={{ mt: 0.5 }}>
                        {attendanceResult.email}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      {' '}
                      <AttendanceSelectTime
                        onChangeState={(time) => {
                          userAttendanceMap[
                            attendanceResult.user_id
                          ].entranceTime = time
                          forceUpdate()
                        }}
                        isEnabled={
                          userAttendanceMap[attendanceResult.user_id].isSelected
                        }
                        timeSelected={
                          userAttendanceMap[attendanceResult.user_id]
                            .entranceTime
                        }
                        serverTime={
                          initialUserAttendanceMap[attendanceResult.user_id]
                            .entranceTime
                        }
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      {' '}
                      <AttendanceSelectTime
                        onChangeState={(time) => {
                          userAttendanceMap[attendanceResult.user_id].exitTime =
                            time
                          forceUpdate()
                        }}
                        isEnabled={
                          userAttendanceMap[attendanceResult.user_id].isSelected
                        }
                        timeSelected={
                          userAttendanceMap[attendanceResult.user_id].exitTime
                        }
                        serverTime={
                          initialUserAttendanceMap[attendanceResult.user_id]
                            .exitTime
                        }
                      />
                    </StyledTableCell>

                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event: any) => {
                          userAttendanceMap[
                            attendanceResult.user_id
                          ].isSelected = !isItemSelected
                          forceUpdate()
                        }}
                      />
                    </TableCell>

                    <StyledTableCell />
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredAttendanceResults.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ConfirmationDialog
        isOpen={isRevertConfirmationDialogOpen}
        onClose={() => {
          setIsRevertConfirmationDialogOpen(false)
        }}
        i18nKey="attendance.revert-attendance-changes-confirmation"
        onConfirm={() => {
          revertChanges()
        }}
      />
    </>
  )
}
