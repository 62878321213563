import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

interface SessionInfoTabsProps {
  children?: React.ReactNode
  value: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
}

const SessionInfoTabs = styled((props: SessionInfoTabsProps) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'transparent',
  },
})

interface SessionInfoTabLabelProps {
  title: string
  subTitle: string
}

const SessionInfoTabLabel = (props: SessionInfoTabLabelProps) => {
  return (
    <>
      <span className="session-info-tab-label-title">{props.title}</span>
      <span className="session-info-tab-label-subtitle">{props.subTitle}</span>
    </>
  )
}
interface SessionInfoTabProps {
  label: React.ReactNode
  hidden?: boolean
}

const SessionInfoTab = styled((props: SessionInfoTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(26),
  marginRight: theme.spacing(1),
  paddingRight: '45px',
  paddingLeft: '45px',
  color: 'rgb(185, 185, 185);',
  '&.Mui-selected': {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgba(61, 79, 123, 1)',
    borderRadius: '10px',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(89, 89, 89, 0.3)',
  },
}))

export { SessionInfoTabs, SessionInfoTab, SessionInfoTabLabel }
