import { Box, Link, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Company } from 'Component/FundedTraining/types'
import { CompaniesTable } from './CompaniesTable'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import useApi from 'api/UseApi'
import Loader from 'Component/Loader'

interface Props {
  projectUuid: string
}

export function Companies(props: Props) {
  const { t } = useTranslation()
  const api = useApi(false)

  const [companies, setCompanies] = useState<Company[]>([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const getCompanies = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api
          .get('funded_training/companies.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              setCompanies(response.data)
              setIsLoaderVisible(false)
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        setIsLoaderVisible(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getCompanies()
  }, [getCompanies])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {t('funded-training.companies')}
      </Typography>
      <NavLink to={`/funded-training`} exact>
        <Link variant="body1">
          {t('funded-training.go-to-funded-training-main-menu')}
        </Link>
      </NavLink>

      <Box sx={{ mt: 2 }}>
        <CompaniesTable
          items={companies}
          onItemDeleted={() => {
            getCompanies()
          }}
        />
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </Box>
  )
}
