import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import Loader from 'Component/Loader';
import filtericon from '../../assets/icon/global/filtericon.svg';
import downloadicon from '../../assets/icon/global/download_icon.png';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import useApi from '../../api/UseApi';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import ConfirmationDialog from 'Component/ConfirmationDialog';
import moment from 'moment';
import AddMemberDialog from 'Component/AddMemberDialog/AddMemberDialog';
import FilterDialog from 'Component/FilterDialog/FilterDialog';
import MemberDetailDialog from 'Component/MemberDetailDialog/MemberDetailDialog';
import TableCourseMember from 'Component/TableCourseMember/TableCourseMember';
import Pagination from 'common/Pagination';

const CourseRegistrantsTab = ({ course }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [registrants, setRegistrants] = useState([]);
  const [filteredRegistrants, setFilteredRegistrants] = useState([]);
  const [isInvitePopupVisible, setIsInvitePopupVisible] = useState(false);
  const [registrationDateFilter, setRegistrationDateFilter] = useState(null);
  const [file, setFile] = useState();
  const [fileVal, setFileVal] = useState();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [openMemberDialog, setOpenMemberDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailIndex, setDetailIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const organization = useAppSelector(selectCurrentOrganization);
  const { t } = useTranslation();
  const api = useApi(false);

  const getTrainees = useCallback(() => {
    setIsLoading(true);
    try {
      api.get(`courses/${course.id}/people.json`).then(
        (response) => {
          setIsLoading(false);
          setRegistrants(response.data.customers);
          setFilteredRegistrants(response.data.customers);
        },
        (error) => {
          setIsLoading(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }, [course.id, api]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openInvite = () => {
    setIsInvitePopupVisible(true);
  };

  const closeInvite = () => {
    setIsInvitePopupVisible(false);
  };

  const uploadFile = async () => {
    var f = fileVal[0];
    const reader = new FileReader();
    var result = [];
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */

      var lines = data.split('\n');

      var headers = lines[0].split(',');

      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(',');

        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }

        result.push(obj);
      }

      sentConvertedArry(result);
    };

    reader.readAsBinaryString(f);
  };

  const sentConvertedArry = async (result) => {
    setIsLoading(true);

    let newResult = result.filter((data) => {
      if (data.Email !== undefined) {
        return data.Email;
      }
    });

    let mapResult = newResult.map((data) => {
      return {
        email: data.Email,
        customer_attributes: {
          first_name: data.First_Name,
          last_name: data.Last_Name,
        },
      };
    });

    try {
      api
        .post(`courses/${course.id}/invite`, {
          participants: mapResult,
        })
        .then(
          (response) => {
            setIsLoading(false);
            toast.success(response.data.message, { theme: 'colored' });
            closeInvite();
            getTrainees();
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
            toast.error(error.response.data.message, { theme: 'colored' });
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const inviteSingleParticipants = async () => {
    setIsLoading(true);

    try {
      api
        .post(`courses/${course.id}/invite`, {
          participants: [
            {
              email: email,
              customer_attributes: {
                first_name: firstName,
                last_name: lastName,
              },
            },
          ],
        })
        .then(
          (response) => {
            setIsLoading(false);
            toast.success(response.data.message, { theme: 'colored' });
            setFirstName('');
            setLastName('');
            closeInvite();
            getTrainees();
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
            toast.error(error.response.data.message, { theme: 'colored' });
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const exportParticipants = async () => {
    setIsLoading(false);

    try {
      api
        .get(`courses/${course.id}/people.xlsx`, { responseType: 'blob' })
        .then(
          (response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);
            link.download = `Attendees-${+new Date()}.xlsx`;
            link.click();

            setIsLoading(false);
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const filterParticipants = () => {
    const newFilteredRegistrants = registrants.filter((registrant) => {
      const matchesRegistrationDate =
        !registrationDateFilter ||
        moment(registrant.registration_date).isSame(
          registrationDateFilter,
          'day'
        );

      const matchesFilters = matchesRegistrationDate;

      return matchesFilters;
    });

    setFilteredRegistrants(newFilteredRegistrants);
  };

  const deleteRegistrant = async () => {
    setIsLoading(true);

    try {
      api
        .delete(`courses/${course.id}/people/${currentParticipant.user_id}`)
        .then(
          (response) => {
            toast.success(response.data.message, { theme: 'colored' });
            setIsLoading(false);
            getTrainees();
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const participantName = () => {
    if (
      currentParticipant.first_name?.trim() &&
      currentParticipant.last_name?.trim()
    ) {
      return currentParticipant.first_name + ' ' + currentParticipant.last_name;
    }
    return currentParticipant.email;
  };

  const renderMemberDetail = (open, index) => {
    setOpenMemberDialog(open);
    setDetailIndex(index);
  };

  useEffect(() => {
    getTrainees();
  }, [getTrainees]);

  return (
    <div>
      <ConfirmationDialog
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        i18nKey="courses.remove-participant-confirmation"
        entityName={currentParticipant && participantName()}
        onConfirm={deleteRegistrant}
      />
      {organization?.userPermissions.includes('read:participants') && (
        <div className="RegistrantsFilters">
          <div className="submitSection">
            <button
              className="btn graytxt filterbtn me-4"
              aria-describedby={id}
              onClick={handleClick}
            >
              <img src={filtericon} alt="filtericon" className="filtericon" />{' '}
              &nbsp;{t('courses.Filter')}
            </button>
            {course.publish_at && (
              <>
                <button
                  className="btn btn-primary me-4"
                  onClick={() => {
                    exportParticipants();
                  }}
                >
                  {t('courses.Export')}
                </button>
                <button className="btn btn-primary" onClick={openInvite}>
                  {t('courses.Invite')}
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {organization?.userPermissions.includes('read:participants') && (
        <div className="Registrants">
          <TableCourseMember
            filteredRegistrants={filteredRegistrants}
            isLoading={isLoading}
            rowsPerPage={rowsPerPage}
            pageNo={pageNo}
            setCurrentParticipant={setCurrentParticipant}
            setIsPopupOpen={setIsPopupOpen}
            renderMemberDetail={renderMemberDetail}
          />
          <Pagination
            numberOfItems={filteredRegistrants?.length}
            rowsPerPage={rowsPerPage}
            pageNo={pageNo}
            onPageChanged={(pageNo) => {
              setPageNo(pageNo);
            }}
            onRowsPerPageChanged={(rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
            }}
          />
        </div>
      )}

      <div>
        <AddMemberDialog
          isInvitePopupVisible={isInvitePopupVisible}
          downloadicon={downloadicon}
          file={file}
          firstName={firstName}
          lastName={lastName}
          isLoading={isLoading}
          closeInvite={closeInvite}
          uploadFile={uploadFile}
          setFile={setFile}
          setFileVal={setFileVal}
          inviteSingleParticipants={inviteSingleParticipants}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
        />
      </div>

      <FilterDialog
        id={id}
        open={open}
        registrants={registrants}
        registrationDateFilter={registrationDateFilter}
        anchorEl={anchorEl}
        setFilteredRegistrants={setFilteredRegistrants}
        setRegistrationDateFilter={setRegistrationDateFilter}
        handleClose={handleClose}
        filterParticipants={filterParticipants}
      />

      <MemberDetailDialog
        open={openMemberDialog}
        setOpen={setOpenMemberDialog}
        registrant={filteredRegistrants[detailIndex]}
      />

      <Loader zIndex={9999} isVisible={isLoading} />
    </div>
  );
};

export default CourseRegistrantsTab;
