import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import { FundingChannelForm } from './FundingChannelForm'

type RouteParams = {
  funderUuid: string
}

export function NewFundingChannel() {
  const { funderUuid } = useParams<RouteParams>()

  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20, mr: 5 }}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('funded-training.new-funding-channel')}
        </Typography>
        <NavLink to={`/funded-training/funders/${funderUuid}`} exact>
          <Link variant="body1">{t('funded-training.go-to-funder')}</Link>
        </NavLink>
        <FundingChannelForm funderUuid={funderUuid} isNew={true} />
      </Stack>
    </Box>
  )
}
