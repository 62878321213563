import { Stack, Typography, InputLabel, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'
import FormTextField from 'Component/form-fields/FormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunderWithChannels } from 'Component/FundedTraining/types'
import {
  FunderFormData,
  FunderFormValidation,
} from 'common/form-validation/FunderFormValidation'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'

type Props = {
  currentFunder?: FunderWithChannels
  isNew?: boolean
  onFunderUpdated?(funder: FunderWithChannels): void
}

export function FunderForm(props: Props) {
  const { currentFunder = null, isNew = false, onFunderUpdated } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formMethods = useForm<FunderFormData>({
    resolver: yupResolver(FunderFormValidation(t)),
  })

  const updateFunder = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.put(`funded_training/funders/${currentFunder?.uuid}`, data).then(
        (response) => {
          toast.success(t('funded-training.funder-updated'), {
            theme: 'colored',
          })
          if (onFunderUpdated) {
            onFunderUpdated(response.data.funder)
          }

          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createFunder = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.post('funded_training/funders', data).then(
        (response) => {
          toast.success(t('funded-training.funder-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.goBack()
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: FunderFormData) => {
    const params: Record<string, any> = {}

    params['name'] = data.name

    if (organization) {
      params['organization_id'] = organization.id
    }

    if (isNew) {
      createFunder(params)
    } else {
      updateFunder(params)
    }
  }

  useEffect(() => {
    if (currentFunder) {
      formMethods.setValue('name', currentFunder.name)
    }
  }, [formMethods, currentFunder])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          <Stack
            mt={4}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Stack>
              <FormTextField
                name="name"
                type="text"
                label={t('common.name')}
                mandatory={true}
              />
              <Box sx={{ mt: 2 }} />
            </Stack>
            <StyledButton
              type="submit"
              startIcon={<SaveIcon />}
              hidden={isLoading}
              sx={{
                backgroundColor: '#0d6efd !important',
              }}
            >
              <Typography sx={{ mt: 0.5 }}>
                {isNew ? t('common.create') : t('common.update')}
              </Typography>
            </StyledButton>
            <Loader isVisible={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}
