import { useState } from 'react'
// @mui
import { Container, Tab, Tabs, Box, Typography } from '@mui/material'
//hook
import useWindowDimensions from 'common/hooks/useWindowDimensions'
// icons
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import ShareIcon from '@mui/icons-material/Share'
import KeyIcon from '@mui/icons-material/Key'
// components
import AccountGeneral from 'Component/AccountGeneral/AccountGeneral'
import AccountSocialLinks from 'Component/AccountGeneral/AccountSocialLinks'
import AccountChangePassword from 'Component/AccountChangePassword/AccountChangePassword'
//redux
import { useAppSelector } from 'Store'
import {
  selectFacebook,
  selectInstagram,
  selectLinkedin,
  selectTwitter,
} from 'Reducers/UserConfigReducer'
//translation
import { useTranslation } from 'react-i18next'
import { selectLoader } from 'Reducers/LoaderReducer'
import Loader from 'Component/Loader'

// ----------------------------------------------------------------------

export default function UserAccount() {
  const [currentTab, setCurrentTab] = useState('general')

  const currentFacebook = useAppSelector(selectFacebook)
  const currentTwitter = useAppSelector(selectTwitter)
  const currentLinkedin = useAppSelector(selectLinkedin)
  const currentInstagram = useAppSelector(selectInstagram)
  const isLoading = useAppSelector(selectLoader)

  const { width } = useWindowDimensions()
  const { t } = useTranslation()

  const TABS = [
    {
      value: 'general',
      label: t('user-account.general'),
      icon: <AccountBoxIcon />,
      component: <AccountGeneral />,
    },
    {
      value: 'social_links',
      label: t('user-account.social-links'),
      icon: <ShareIcon />,
      component: (
        <AccountSocialLinks
          socialLinks={{
            facebookLink: currentFacebook ? currentFacebook : '',
            instagramLink: currentInstagram ? currentInstagram : '',
            linkedinLink: currentLinkedin ? currentLinkedin : '',
            twitterLink: currentTwitter ? currentTwitter : '',
          }}
        />
      ),
    },
    {
      value: 'security',
      label: t('user-account.security'),
      icon: <KeyIcon />,
      component: <AccountChangePassword />,
    },
  ]

  return isLoading ? (
    <Loader isVisible={isLoading}></Loader>
  ) : (
    <Container
      maxWidth={width >= 1200 ? 'lg' : false}
      sx={{ display: 'flex', marginTop: '100px' }}
    >
      <Box sx={{ flexGrow: 1, padding: '0px 0px 0px 1rem' }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: '1.5rem',
            fontFamily: 'sans-serif',
            fontWeight: 500,
            lineHeight: '1.5',
            paddingLeft: '1rem',
            marginBottom: '2rem',
          }}
        >
          Account
        </Typography>
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          sx={{
            paddingLeft: '16px',
            '&.MuiTabs-root .MuiTabs-indicator': {
              backgroundColor: 'rgb(33, 43, 54)',
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
              sx={{
                color: 'rgb(99, 115, 129)',
                lineHeight: '1.25',
                fontSize: '0.875rem',
                fontFamily: 'sans-serif',
                fontWeight: '400',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minHeight: '0px',
                textTransform: 'capitalize',
                padding: '1rem 0px 0.5rem 0px',
                marginRight: '2rem',
                '&.MuiButtonBase-root .MuiSvgIcon-root': {
                  margin: '0px 0.5rem 0px 0px',
                },
                '&.MuiButtonBase-root.Mui-selected': {
                  color: 'rgb(33, 43, 54)',
                },
              }}
            />
          ))}
        </Tabs>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </Box>
    </Container>
  )
}
