import { combineReducers } from 'redux'
import { withReduxStateSync } from 'redux-state-sync'
import FilterRegistrants from './FilterRegistrants'
import SettingsRedirect from './SettingsRedirect'
import DownloadSingleFile from './DownloadSingleFile'
import UploadSingleFile from './UploadSingleFile'
import SurveyReducer from './SurveyReducer'
import ConfigReducer from 'Reducers/UserConfigReducer'
import CourseDetailReducer from './CourseDetailReducer'
import CourseRegistrationReducer from './CourseRegistrationReducer'
import CourseDesignReducer from './CourseDesignReducer'
import LoaderReducer from './LoaderReducer'
import ProjectReducer from './FundedTraining/ProjectReducer'
import ActivityReducer from './FundedTraining/ActivityReducer'
import CompanyReducer from './FundedTraining/CompanyReducer'
import FunderReducer from './FundedTraining/FunderReducer'
import EmployeeReducer from './FundedTraining/EmployeeReducer'

const RootReducer = combineReducers({
  FilterRegistrants: FilterRegistrants,
  SettingsRedirect: SettingsRedirect,
  DownloadSingleFile: DownloadSingleFile,
  UploadSingleFile: UploadSingleFile,
  Survey: SurveyReducer,
  UserConfig: ConfigReducer,
  CourseDetail: CourseDetailReducer,
  CourseDesign: CourseDesignReducer,
  Project: ProjectReducer,
  Activity: ActivityReducer,
  Company: CompanyReducer,
  Employee: EmployeeReducer,
  Funder: FunderReducer,
  CourseRegistration: CourseRegistrationReducer,
  Loader: LoaderReducer,
})

export default withReduxStateSync(RootReducer)
