import React, { useState, useEffect, useCallback } from 'react'
import { styled } from '@mui/material'
import { Drawer, List } from '@mui/material'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getUTCDateTime } from 'common/DateTimeUtils'
import { courseEdited } from 'Reducers/CourseDetailReducer'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import AddSessionForm, {
  AddSessionFormProp,
} from 'Component/AddSessionForm/AddSessionForm'
import SessionsService from 'Service/SessionsService'
import { DraftSession } from '../AddSession/AddSession'
import {
  convertDraftSessionsToSessionModels,
  convertSessionModelsToDraftSessions,
} from 'utils/type-conversions/session'
import TemplatesService from 'Service/TemplatesService'

const StyledDivList = styled('div')(({ theme }) => ({
  width: 550,
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    width: 350,
  },
}))

export default function EditSession({
  singleSession,
  serverSingleSession,
  getAllcourse,
}: {
  singleSession?: DraftSession
  serverSingleSession?: DraftSession
  getAllcourse: any
}) {
  const [state, setState] = React.useState({
    right: false,
  })
  let location = useLocation()

  const dispatch = useDispatch()
  const [visibleLoader, setvisibleLoader] = useState(false)
  const [sessions, setSessions] = useState<DraftSession[]>([])
  const [serverSessions, setServerSessions] = useState<DraftSession[]>([])

  const { t } = useTranslation()
  const organization = useAppSelector(selectCurrentOrganization)
  const { putSession } = SessionsService()
  const [templates, setTemplates] = useState<any[]>([])

  const { getTemplates } = TemplatesService()

  const [
    isCloseConfirmationDialogVisible,
    setIsCloseConfirmationDialogVisible,
  ] = useState(false)

  //map Session into DraftSession and then set it
  useEffect(() => {
    if (singleSession) {
      setSessions(
        convertSessionModelsToDraftSessions([
          {
            ...singleSession,
            wyblo_link: singleSession.wyblo_hosting ? singleSession.link : '',
          },
        ])
      )
    }
  }, [singleSession])

  useEffect(() => {
    if (serverSingleSession) {
      setServerSessions(
        convertSessionModelsToDraftSessions([
          {
            ...serverSingleSession,
            wyblo_link: serverSingleSession.wyblo_hosting
              ? serverSingleSession.link
              : '',
          },
        ])
      )
    }
  }, [serverSingleSession])

  const onSessionFieldChanged: AddSessionFormProp['onSessionFieldChanged'] = (
    fieldName,
    value,
    sessionIndex
  ) => {
    const updatedSessions = [...sessions]
    updatedSessions[sessionIndex][fieldName] = value
    setSessions(updatedSessions)
  }

  const onConfirm = () => {
    if (serverSingleSession)
      setSessions(
        convertSessionModelsToDraftSessions([{ ...serverSingleSession }])
      )
    setTimeout(() => {
      toggleDrawer('right', false)
    }, 500)
  }
  const fetchTemplates = useCallback(async (organizationId: number) => {
    const templates = await getTemplates(organizationId)
    if (templates) setTemplates(templates)
  }, [])

  useEffect(() => {
    if (organization) {
      fetchTemplates(organization.id)
    }
  }, [fetchTemplates, organization])

  const onClose = () => {
    setIsCloseConfirmationDialogVisible(true)
    // TODO: Only show the confirmation dialog if something has been changed
    // const formValues = getValues();
    // if (
    //   Object.values(formValues).some((item) => {
    //     return item;
    //   })
    // ) {
    //   setIsCloseConfirmationDialogVisible(true);
    // } else {
    //   toggleDrawer('right', false);
    // }
  }

  const toggleDrawer = (anchor: string, open: any) => {
    setState({ ...state, [anchor]: open })
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    setvisibleLoader(true)
    const sessionModels = convertDraftSessionsToSessionModels(sessions)
    let newSessions = sessionModels.map((sessionModel) => {
      const { start_date, end_date } = sessionModel
      return {
        ...sessionModel,
        start_date: start_date ? getUTCDateTime(start_date) : null,
        end_date: end_date ? getUTCDateTime(end_date) : null,
      }
    })

    try {
      if (singleSession) {
        const updatedCourse = await putSession(singleSession, newSessions)
        setvisibleLoader(false)

        if (updatedCourse) {
          dispatch(courseEdited())
          toggleDrawer('right', false)
          if (location.pathname === '/Courses') {
            getAllcourse()
          }
        }
      }
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
      setvisibleLoader(false)
    }
  }

  const list = (anchor: string) => (
    <StyledDivList role="presentation">
      <form onSubmit={onSubmit}>
        <List>
          <div className="AddCourse" id="bottomscroll">
            {organization && (
              <AddSessionForm
                organization={organization}
                sessions={sessions}
                templates={templates}
                serverSessions={serverSessions}
                onSessionFieldChanged={onSessionFieldChanged}
                removeSession={onClose}
              />
            )}

            <div className="fixSubmit">
              <button type="button" className="btn addSessionbtn"></button>
              <button type="submit" className="btn courseSubmit">
                {t('courses.Update')}
              </button>
            </div>
          </div>
        </List>
      </form>

      <div
        style={{
          position: 'fixed',
          zIndex: '999',
          top: '50%',
          left: '88%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader
          type="Oval"
          color="#2477bc"
          height={60}
          width={60}
          visible={visibleLoader}
        />
      </div>
    </StyledDivList>
  )

  return (
    <div className="w-100" style={{ height: '20px' }}>
      <React.Fragment>
        <div
          onClick={() => {
            toggleDrawer('right', true)
          }}
          style={{
            cursor: 'pointer',
          }}
          className="w-100 position-absolute"
        >
          <i className="far fa-edit" style={{ color: '#157AFF' }}></i>
          {t('courses.Edit')}
        </div>

        <Drawer
          anchor={'right'}
          open={state['right']}
          onClose={() => {
            onClose()
          }}
        >
          {list('right')}
        </Drawer>
        <ConfirmationDialog
          i18nKey="courses.close-drawer-confirmation"
          isOpen={isCloseConfirmationDialogVisible}
          onClose={() => {
            setIsCloseConfirmationDialogVisible(false)
          }}
          onConfirm={onConfirm}
        />
      </React.Fragment>
    </div>
  )
}
