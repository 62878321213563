import useApi from 'api/UseApi'
import { AxiosResponse } from 'axios'
import { Course, SessionListDate } from 'Component/CourseRegistration/Models'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { setLoader } from 'Reducers/LoaderReducer'

export type CourseData = {
  title: string
  organization_id: number | null
  survey_template_id: any
  description_markup: any
  sessions_attributes: any
}

type ResourcesResponse = {
  resources: ResourceServer[]
  message: string
}

export type ResourceServer = {
  id: number
  url: string
  filename: string
  bytesize: number
}

const CourseService = () => {
  const api = useApi(false)
  const apiSkip = useApi(false, true)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //GET

  const getAllCourses = async (organization_id: number): Promise<Course[]> => {
    try {
      const response = await api.get('courses.json', {
        params: {
          organization_id,
        },
      })
      return response.data.courses
    } catch (error: any) {
      toast.error(error.message, { theme: 'colored' })
      return []
    }
  }

  const getResources = async (
    course_slug: string
  ): Promise<ResourceServer[]> => {
    try {
      dispatch(setLoader(true))
      const response: AxiosResponse<ResourcesResponse> = await apiSkip.get(
        `courses/${course_slug}/resources`
      )
      toast.success(response.data.message, { theme: 'colored' }) //ADD MESSAGE
      dispatch(setLoader(false))
      return response.data.resources
    } catch (error: any) {
      toast.error(error.message, { theme: 'colored' })
      dispatch(setLoader(false))
      return []
    }
  }

  //POST

  const publishCourse = async (singleCourse: Course) => {
    try {
      const response = await api.post(`courses/${singleCourse?.id}/publish`)
      if (
        response.data.message === 'Courses published successfully!' ||
        response.data.message === 'Course is already published!'
      ) {
        toast.success(response.data.message, { theme: 'colored' })
      } else {
        toast.warning(response.data.message, { theme: 'colored' })
      }
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
    }
  }

  const archiveCourse = async (singleCourse: Course) => {
    try {
      const response = await api.post(`courses/${singleCourse?.id}/archives`)
      toast.success(response.data.message, { theme: 'colored' })
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
    }
  }

  const postCourses = async (courseFormData: CourseData) => {
    try {
      return await api
        .post(`courses`, {
          title: courseFormData.title,
          organization_id: courseFormData.organization_id,
          survey_template_id: courseFormData.survey_template_id,
          description_markup: courseFormData.description_markup,
          sessions_attributes: courseFormData.sessions_attributes,
        })
        .then(
          (response) => {
            toast.success(response.data.message, { theme: 'colored' })
            return response.data.course
          },
          (error) => {
            toast.error(error.response.data.message, { theme: 'colored' })
          }
        )
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
    }
  }

  const postSessionDates = async (
    sessions_attributes: SessionListDate[],
    course_id: string
  ) => {
    try {
      const response = await api.put(`courses/${course_id}`, {
        sessions_attributes: sessions_attributes,
      })
      toast.success(response.data.message, { theme: 'colored' })
      return response.data.course
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
    }
  }

  //DELETE

  const deleteCourse = async (singleCourse: Course) => {
    try {
      await api.delete(`courses/${singleCourse?.id}`)
      toast.success(t('courses.course-has-been-deleted-successfully'), {
        theme: 'colored',
      })
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
    }
  }

  return {
    getAllCourses,
    postCourses,
    postSessionDates,
    deleteCourse,
    archiveCourse,
    publishCourse,
    getResources,
  }
}

export default CourseService
