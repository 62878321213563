import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'
import {
  FunderWithChannels,
  FundingChannel,
} from 'Component/FundedTraining/types'
import { useEffect } from 'react'

const StyledAutocomplete = styled(Autocomplete)({
  '.MuiInputBase-root': {
    padding: 7,
  },

  '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
    '&:hover': {
      borderColor: '#73b0ff',
    },
  },

  '.MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px 0px 5px',
    fontSize: 16,
    '&::placeholder': {
      color: '#157aff66',
      opacity: 1,
      padding: 0,
    },
  },

  '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
  },

  '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
  },
})

type Props = {
  selectedItem: null | FundingChannel
  funder: null | FunderWithChannels
  allFundingChannels: FundingChannel[]
  onUpdated: (selectedItem: null | FundingChannel) => void
}

export default function FundingChannelAutoComplete(props: Props) {
  const { selectedItem, funder, allFundingChannels, onUpdated } = props
  const { t } = useTranslation()

  return (
    <>
      <Stack spacing={3} sx={{ maxWidth: '30rem' }}>
        <StyledAutocomplete
          id="funding-channels-outlined"
          disabled={!funder}
          options={allFundingChannels.sort((a, b) => {
            return -b.name.localeCompare(a.name)
          })}
          getOptionLabel={(option: any) => {
            return option.name
          }}
          isOptionEqualToValue={(item: any, value: any) => item === value}
          defaultValue={null}
          value={selectedItem}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ backgroundColor: 'white' }}
              placeholder={t(
                'funded-training.funding-channel-selector-placeholder'
              )}
            />
          )}
          onChange={(event, newItem: any) => {
            onUpdated(newItem)
          }}
        />
      </Stack>
    </>
  )
}
