import { Box, Typography } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { Campaign } from 'Component/Mail/NotificationServiceTypes'
import { CampaignForm } from 'Component/Mail/Campaigns/CampaignForm'

type RouteParams = {
  campaignId: string
}

type LocationState = {
  campaignName: string
}

export function CampaignDetail() {
  const { campaignId } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { t } = useTranslation()

  const [campaignName, setCampaignName] = useState<string>('')
  const [currentCampaign, setCurrentCampaign] = useState<null | Campaign>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  useEffect(() => {
    if (currentCampaign) {
      if (campaignName === '') {
        setCampaignName(currentCampaign.name)
      }
    } else if (state) {
      setCampaignName(state.campaignName)
    }
  }, [state, currentCampaign])

  const getCampaign = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`campaigns/${campaignId}`).then(
        (response) => {
          setCurrentCampaign(response.data.data)
          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, campaignId])

  useEffect(() => {
    getCampaign()
  }, [getCampaign, campaignId])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
          <Trans i18nKey={'mail.campaign-detail-title'}>
            {'placeholder '}
            <strong style={{ color: '#0d6efd' }}>
              <>{{ campaignName }}</>
            </strong>
            {' placeholder'}
          </Trans>
        </Typography>

        {currentCampaign && (
          <CampaignForm
            currentCampaign={currentCampaign}
            onCampaignNameUpdated={(name) => {
              setCampaignName(name)
            }}
          />
        )}
      </Box>
    </Box>
  )
}
