import { InputBase, TableCell } from '@mui/material'
import { styled } from '@mui/system'

export const StyledInputBase = styled(InputBase)({
  backgroundColor: 'rgba(21, 122, 255, 0.1)',
  fontFamily: 'nexa',
  borderRadius: '4px',
  border: '1px solid #73b0ff',
  width: '100%',
  '&:focus, &.Mui-focused': {
    backgroundColor: 'white',
    boxShadow: '0 0 0 4px rgba(21, 122, 255, 0.4)',
  },
})

export const StyledTableCell = styled(TableCell)({
  color: 'rgb(128, 128, 128)',
})

export const StyledBoxInputNumber = styled(InputBase)({
  backgroundColor: 'rgba(21, 122, 255, 0.1)',
  paddingTop: '0.25rem',
  fontFamily: 'nexa',
  fontSize: '1rem',
  borderRadius: '4px',
  border: '1px solid #73b0ff',
  height: '1.5rem',
  width: '2rem',
  '.MuiInputBase-input': {
    textAlign: 'center',
  },
  '&:focus, &.Mui-focused': {
    backgroundColor: 'white',
    boxShadow: '0 0 0 4px rgba(21, 122, 255, 0.4)',
  },
  '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
    {
      display: 'none',
    },
})
