import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import errorTriangle from '../../assets/icon/global/errorTriangle.svg';
import Loader from 'Component/Loader';
import TimeAgo from 'Component/TimeAgo';
import EditSession from '../course/EditSession/EditSession';
import { Fade, MenuItem, Menu, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import filterIcon from '../../assets/icon/ordered.svg';
import { useTranslation } from 'react-i18next';
import useApi from '../../api/UseApi';
import { getLocalizedDateTimeFromUTC } from 'common/DateTimeUtils';
import ConfirmationDialog from 'Component/ConfirmationDialog';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import { courseEdited } from 'Reducers/CourseDetailReducer';
import {
  getSessionStatusStyle,
  getSessionStatusText,
} from 'common/SessionStatus';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';

const CourseSessionsTab = ({
  sessions,
  serverSessions,
  courseId,
  visibleLoader,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [singleSession, setSingleSession] = React.useState([]);
  const [serverSingleSession, setServerSingleSession] = React.useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const organization = useAppSelector(selectCurrentOrganization);
  const { t } = useTranslation();

  let history = useHistory();

  const api = useApi(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteSession = async () => {
    setIsLoaderVisible(true);
    try {
      api.delete(`courses/${courseId}/sessions/${singleSession.id}`).then(
        (response) => {
          if (response.status === 204) {
            dispatch(courseEdited());
            setIsLoaderVisible(false);
            toast.success(t('courses.session-has-been-deleted-successfully'), {
              theme: 'colored',
            });
          }
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <ConfirmationDialog
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        i18nKey="courses.session-delete-confirmation"
        entityName={singleSession.sessionname}
        onConfirm={deleteSession}
      />

      {organization?.userPermissions.includes('read:sessions') && sessions && (
        <Stack>
          {sessions.map((session, index) => (
            <Grid
              key={index}
              className="sessionBox"
              container
              rowSpacing={1}
              columnSpacing={1}
              columns={12}
            >
              <Grid item xs={2.5}>
                <Tooltip title={session.sessionname} arrow>
                  <span
                    style={{
                      color: '#808080',
                      overflow: 'hidden',
                      display: 'inline-block',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '14vw',
                    }}
                  >
                    {session.sessionname}
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={1.5}>
                <span style={getSessionStatusStyle(session.status)}>
                  {getSessionStatusText(t, session.status)}
                </span>
              </Grid>
              <Grid item xs={4.5}>
                <span style={{ fontSize: '0.9rem', color: '#808080' }}>
                  {getLocalizedDateTimeFromUTC(session.start_date)}
                  {session.end_date !== null && (
                    <>
                      &nbsp;{'>'}&nbsp;{' '}
                      {getLocalizedDateTimeFromUTC(session.end_date)}
                    </>
                  )}
                </span>
              </Grid>
              <Grid item xs={2}>
                <span style={{ color: '#157AFF' }}>
                  {session.start_date && (
                    <TimeAgo
                      startDate={session.start_date}
                      endDate={session.end_date}
                    />
                  )}
                </span>
              </Grid>

              <Grid item xs={1}>
                {session.meetings_warning === true ? (
                  <Tooltip title={session.meetings_warningDetails} arrow>
                    <img src={errorTriangle} alt="errorTriangle" />
                  </Tooltip>
                ) : null}
              </Grid>

              <Grid item xs={0.5}>
                <MoreVertIcon
                  style={{ cursor: 'pointer', color: '#808080' }}
                  onClick={(e) => {
                    handleClick(e);
                    setSingleSession(session);
                    setServerSingleSession(session);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      )}

      {organization?.userPermissions.includes('edit:session') && (
        <div>
          <Menu
            className="viewSessionMenu"
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <EditSession
                serverSingleSession={serverSingleSession}
                singleSession={singleSession}
              />
            </MenuItem>

            {singleSession.start_date && singleSession.end_date && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push(
                    `/Courses/${courseId}/Session/${singleSession.id}/Attendance`
                  );
                }}
              >
                <img src={filterIcon} alt="uploadIcon" />
                {t('courses.manage-attendance')}
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                setIsPopupOpen(true);
                handleClose();
              }}
            >
              <i className="fas fa-trash-alt" style={{ color: '#157AFF' }}></i>
              {t('courses.Delete')}
            </MenuItem>
          </Menu>
        </div>
      )}

      <Loader isVisible={isLoaderVisible} />
    </div>
  );
};

export default CourseSessionsTab;
