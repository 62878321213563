import React, { useState, useEffect, useCallback } from 'react'
import '../../../assets/scss/addCourses/addCourses.scss'
import { Drawer } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { getUTCDateTime } from 'common/DateTimeUtils'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import AddCourseForm from 'Component/AddCourseForm/AddCourseForm'
import { AddSessionFormProp } from 'Component/AddSessionForm/AddSessionForm'
import { DraftSession, emptySession } from '../AddSession/AddSession'
import TemplatesService from '../../../Service/TemplatesService'
import CourseService from '../../../Service/CourseService'
import { convertDraftSessionsToSessionModels } from 'utils/type-conversions/session'

export default function AddCourse({
  onCourseCreated,
}: {
  onCourseCreated: any
}) {
  const [state, setState] = React.useState({
    right: false,
  })
  const [sessions, setSessions] = useState<DraftSession[]>([
    { ...emptySession() },
  ])
  const { reset } = useForm()
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [
    isCloseConfirmationDialogVisible,
    setIsCloseConfirmationDialogVisible,
  ] = useState(false)
  const [templates, setTemplates] = useState<any>([])
  const [templateID, setTemplateID] = useState(null)

  const organization = useAppSelector(selectCurrentOrganization)

  const { t } = useTranslation()
  const { postCourses } = CourseService()
  const { getTemplates } = TemplatesService()

  const getSurveyTemplates = useCallback(async (organizationId: number) => {
    try {
      const templates = await getTemplates(organizationId)
      if (templates) setTemplates(templates)
    } catch (e: any) {
      console.log(e.message)
    }
  }, [])

  const onSessionFieldChanged: AddSessionFormProp['onSessionFieldChanged'] = (
    fieldName,
    value,
    sessionIndex
  ) => {
    const updatedSessions = [...sessions]
    updatedSessions[sessionIndex][fieldName] = value
    setSessions(updatedSessions)
  }

  const removeSession = (sessionIndex: number) => {
    const updatedSessions = [...sessions]
    updatedSessions.splice(sessionIndex, 1)
    setSessions(updatedSessions)
  }

  useEffect(() => {
    if (organization) {
      getSurveyTemplates(organization.id)
    }
  }, [getSurveyTemplates, organization])

  const addSession = () => {
    setSessions((currentSessions) => {
      return [...currentSessions, { ...emptySession() }]
    })
  }

  const onSubmit = async (courseFormData: any) => {
    setIsLoaderVisible(true)
    setDisableSubmit(true)
    const sessionModels = convertDraftSessionsToSessionModels(sessions)
    let meetings = sessionModels.map((session) => {
      return {
        sessionname: session.sessionname,
        link: session.link,
        start_date: session.start_date
          ? getUTCDateTime(session.start_date)
          : null,
        end_date: session.end_date ? getUTCDateTime(session.end_date) : null,
        description_markup: session.description_markup,
        inpersonmeetingaddress: session.inpersonmeetingaddress,
        meetingscheduletime: null,
        survey_template_id: session.survey_template_id,
        wyblo_hosting: session.wyblo_hosting,
      }
    })

    if (organization) {
      let newCourse = {
        title: courseFormData.courseName,
        organization_id: organization.id,
        survey_template_id: templateID,
        description_markup: courseFormData.editorDescription,
        sessions_attributes: meetings,
      }

      try {
        const addCourse = await postCourses(newCourse)
        setIsLoaderVisible(false)
        setDisableSubmit(false)

        if (addCourse) {
          onCourseCreated(addCourse)
          setState({
            right: false,
          })
          setSessions([])
        }
      } catch (e: any) {
        toast.error(e.message, { theme: 'colored' })
        setIsLoaderVisible(false)
        setDisableSubmit(false)
      }
    }
  }

  const toggleDrawer = (anchor: string, open: any) => {
    setTemplateID(null)
    setState({ ...state, [anchor]: open })
    reset()
    setSessions([])
  }

  const onClose = () => {
    setIsCloseConfirmationDialogVisible(true)
  }

  const list = (anchor: string) =>
    organization && (
      <AddCourseForm
        anchor={anchor}
        disableSubmit={disableSubmit}
        isLoaderVisible={isLoaderVisible}
        organization={organization}
        templates={templates}
        sessions={sessions}
        addSession={addSession}
        setIsCloseConfirmationDialogVisible={
          setIsCloseConfirmationDialogVisible
        }
        onSessionFieldChanged={onSessionFieldChanged}
        onSubmit={onSubmit}
        removeSession={removeSession}
        setTemplateID={setTemplateID}
        toggleDrawer={toggleDrawer}
      />
    )

  return (
    <div>
      <React.Fragment>
        <button
          className="btn addcoursebtn"
          onClick={() => {
            toggleDrawer('right', true)
          }}
        >
          <AddIcon />
          <span className="ms-3 add_course">{t('courses.AddCourse')}</span>
        </button>
        <Drawer anchor={'right'} open={state['right']} onClose={onClose}>
          {list('right')}
        </Drawer>
        <ConfirmationDialog
          i18nKey="courses.close-drawer-confirmation"
          isOpen={isCloseConfirmationDialogVisible}
          onClose={() => {
            setIsCloseConfirmationDialogVisible(false)
          }}
          onConfirm={() => {
            setTimeout(() => {
              toggleDrawer('right', false)
            }, 500)
          }}
        />
      </React.Fragment>
    </div>
  )
}
