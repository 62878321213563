import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CompleteProfile({ open, onClose }) {
  let history = useHistory();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            borderBottom: '1px solid #d9d4d4',
            padding: '0px',
            margin: '1rem 2rem',
            paddingBottom: '9px',
            fontFamily: 'nexa',
            color: 'gray',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>
            {t('CompleteProfile.Welcome')},{' '}
            <span className="text-primary"></span>
          </span>
          <CloseIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontFamily: 'nexa' }}
            className="text-primary mt-3 mb-3 mx-2"
          >
            <span>{t('CompleteProfile.successMsg')}</span>
          </DialogContentText>
          <DialogContentText
            style={{ fontFamily: 'nexa' }}
            className="mx-2 mb-3"
          >
            {t('CompleteProfile.invitedText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mt-3 mb-3 mx-3">
          <Button
            variant="text"
            onClick={handleClose}
            style={{ color: 'rgba(0, 0, 0, 0.6)' }}
          >
            {t('CompleteProfile.Skip')}
          </Button>
          <button
            className="btn btn-primary"
            style={{
              borderRadius: '8px',
              padding: '6px 1.2rem',
              paddingTop: '9px',
            }}
            onClick={() => {
              history.push('/Settings');
              setTimeout(() => {
                handleClose();
              }, 500);
            }}
          >
            {t('CompleteProfile.Completeyourprofile')}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
