import { Stack, Typography, InputLabel, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'
import FormTextField from 'Component/form-fields/FormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Company } from 'Component/FundedTraining/types'
import {
  CompanyFormData,
  CompanyFormValidation,
} from 'common/form-validation/CompanyFormValidation'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'

type Props = {
  currentCompany?: Company
  isNew?: boolean
  onCompanyUpdated?(company: Company): void
}

export function CompanyForm(props: Props) {
  const { currentCompany = null, isNew = false, onCompanyUpdated } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formMethods = useForm<CompanyFormData>({
    resolver: yupResolver(CompanyFormValidation(t)),
  })

  const updateCompany = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.put(`funded_training/companies/${currentCompany?.uuid}`, data).then(
        (response) => {
          toast.success(t('funded-training.company-updated'), {
            theme: 'colored',
          })
          if (onCompanyUpdated) {
            onCompanyUpdated(response.data.company)
          }

          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createCompany = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.post('funded_training/companies', data).then(
        (response) => {
          toast.success(t('funded-training.company-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.goBack()
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: CompanyFormData) => {
    const params: Record<string, any> = {}

    params['name'] = data.name
    params['vat_number'] = data.vat_number
    params['fiscal_code'] = data.fiscal_code

    if (organization) {
      params['organization_id'] = organization.id
    }

    if (isNew) {
      createCompany(params)
    } else {
      updateCompany(params)
    }
  }

  useEffect(() => {
    if (currentCompany) {
      formMethods.setValue('name', currentCompany.name)
      formMethods.setValue('vat_number', currentCompany.vat_number)
      formMethods.setValue('fiscal_code', currentCompany.fiscal_code)
    }
  }, [formMethods, currentCompany])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          <Stack
            mt={4}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Stack>
              <FormTextField
                name="name"
                type="text"
                label={t('common.name')}
                mandatory={true}
              />

              <FormTextField
                name="vat_number"
                type="text"
                label={t('common.vat-number')}
                mandatory={true}
              />

              <FormTextField
                name="fiscal_code"
                type="text"
                label={t('common.fiscal-code')}
                mandatory={true}
              />

              <Box sx={{ mt: 2 }} />
            </Stack>
            <StyledButton
              type="submit"
              startIcon={<SaveIcon />}
              hidden={isLoading}
              sx={{
                backgroundColor: '#0d6efd !important',
              }}
            >
              <Typography sx={{ mt: 0.5 }}>
                {isNew ? t('common.create') : t('common.update')}
              </Typography>
            </StyledButton>
            <Loader isVisible={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}
