import { Box, Typography, Tabs, Tab, Link } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { FundingChannel } from 'Component/FundedTraining/types'
import { useDispatch } from 'react-redux'
import { FundingChannelForm } from './FundingChannelForm'

type RouteParams = {
  funderUuid: string
  fundingChannelUuid: string
}

type LocationState = {
  fundingChannelName: string
}

type Props = {
  isNew?: boolean
}

function a11yProps(index: number) {
  return {
    id: `funding-channel-tab-${index}`,
    'aria-controls': `funding-channel-tabpanel-${index}`,
  }
}

export function FundingChannelDetail(props: Props) {
  const { funderUuid, fundingChannelUuid } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { isNew = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [fundingChannelName, setFundingChannelName] = useState<string>('')
  const [currentFundingChannel, setCurrentFundingChannel] =
    useState<null | FundingChannel>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  useEffect(() => {
    if (currentFundingChannel) {
      if (fundingChannelName === '') {
        setFundingChannelName(currentFundingChannel.name)
      }
    } else if (state) {
      setFundingChannelName(state.fundingChannelName)
    }
  }, [state, currentFundingChannel, fundingChannelName])

  const getFundingDetail = useCallback(() => {
    setIsLoading(true)
    try {
      api
        .get(
          `funded_training/funders/${funderUuid}/funding_channels/${fundingChannelUuid}`
        )
        .then(
          (response) => {
            setCurrentFundingChannel(response.data)
            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, fundingChannelUuid])

  useEffect(() => {
    getFundingDetail()
  }, [getFundingDetail, fundingChannelUuid])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
        <Trans i18nKey={'funded-training.funding-channel-detail.title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd' }}>
            <>{{ fundingChannelName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>

      <NavLink to={`/funded-training/funders/${funderUuid}`} exact>
        <Link variant="body1">{t('funded-training.go-to-funder')}</Link>
      </NavLink>

      {currentFundingChannel && (
        <>
          <Tabs
            sx={{ mt: 4 }}
            value={tabIndex}
            onChange={onTabChange}
            aria-label="funding channel tabs"
          >
            <Tab label={t('common.config')} {...a11yProps(1)} />
          </Tabs>

          <FundingChannelForm
            funderUuid={funderUuid}
            currentFundingChannel={currentFundingChannel}
            isNew={isNew}
            onFundingChannelUpdated={(fundingChannel: FundingChannel) => {
              setFundingChannelName(fundingChannel.name)
            }}
          />
        </>
      )}
    </Box>
  )
}
