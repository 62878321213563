import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface EmployeeState {
  shouldFetchEmployee: boolean
  shouldPreselectActivitiesTab: boolean
}

const initialState: EmployeeState = {
  shouldFetchEmployee: true,
  shouldPreselectActivitiesTab: false,
}

export const EmployeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    employeeFetched: (state) => {
      state.shouldFetchEmployee = false
    },
    setShouldPreselectActivitiesTab: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shouldPreselectActivitiesTab = action.payload
    },
  },
})

// SELECTORS
export const selectShouldFetchEmployee = (state: RootState) =>
  state.Employee.shouldFetchEmployee

export const selectShouldPreselectActivitiesTab = (state: RootState) =>
  state.Employee.shouldPreselectActivitiesTab

export const { employeeFetched, setShouldPreselectActivitiesTab } =
  EmployeeSlice.actions

export default EmployeeSlice.reducer
