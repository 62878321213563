import React, { useState, useEffect, useCallback } from 'react'
import { styled } from '@mui/material'
import { List, Drawer } from '@mui/material'
import Loader from 'react-loader-spinner'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useHistory } from 'react-router-dom'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getTimezone, getUTCDateTime } from 'common/DateTimeUtils'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import { courseEdited } from 'Reducers/CourseDetailReducer'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import AddSessionForm, {
  AddSessionFormProp,
} from 'Component/AddSessionForm/AddSessionForm'
import TemplatesService from '../../../Service/TemplatesService'
import { toast } from 'react-toastify'
import SessionsService from '../../../Service/SessionsService'
import { convertDraftSessionsToSessionModels } from 'utils/type-conversions/session'
import i18next from 'i18next'

const StyledDivList = styled('div')(({ theme }) => ({
  width: 550,
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    width: 350,
  },
}))

export interface Session {
  id?: number
  sessionname: string
  link: string
  email: string
  start_date: any
  end_date: any
  time: string
  created_at: string
  updated_at: string
  deleted_at: string
  description_markup: any
  course_id: string
  inpersonmeetingaddress: string
  meetingscheduletime: any
  survey_template_id: any
  survey_link: any
  wyblo_hosting: boolean
  wyblo_link: string
  unset_feedback_form?: boolean
}

export interface DraftSession extends Session {
  is_feedback_form_checked?: boolean | null
  start_time?: string
  end_time?: string
}

export const emptySession = (): DraftSession => ({
  sessionname: '',
  link: '',
  email: '',
  start_date: null,
  end_date: null,
  time: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
  survey_link: '',
  description_markup: { ops: [] },
  course_id: '',
  inpersonmeetingaddress: '',
  meetingscheduletime: null,
  survey_template_id: null,
  wyblo_hosting: false,
  wyblo_link: '',
  start_time: new Date().toLocaleTimeString(i18next.language, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: getTimezone(),
  }),
  end_time: new Date().toLocaleTimeString(i18next.language, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: getTimezone(),
  }),
  is_feedback_form_checked: null,
})

export default function AddSession({
  getAllcourse,
  courseId,
}: {
  getAllcourse: any
  courseId: any
}) {
  const [state, setState] = React.useState({
    right: false,
  })
  let history = useHistory()
  const dispatch = useDispatch()
  const [sessions, setSessions] = useState<DraftSession[]>([
    { ...emptySession() },
  ])

  const [
    isCloseConfirmationDialogVisible,
    setIsCloseConfirmationDialogVisible,
  ] = useState(false)

  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [templates, setTemplates] = useState<any[]>([])

  const organization = useAppSelector(selectCurrentOrganization)

  const { t } = useTranslation()

  const { getTemplates } = TemplatesService()
  const { postSessions } = SessionsService()

  const fetchTemplates = useCallback(async (organizationId: number) => {
    const templates = await getTemplates(organizationId)
    if (templates) setTemplates(templates)
  }, [])

  const onClose = () => {
    setIsCloseConfirmationDialogVisible(true)
    // TODO: Only show the confirmation dialog if something has been changed
    // const formValues = getValues();
    // if (
    //   Object.values(formValues).some((item) => {
    //     return item;
    //   })
    // ) {
    //   setIsCloseConfirmationDialogVisible(true);
    // } else {
    //   toggleDrawer('right', false);
    // }
  }

  const addSession = () => {
    setSessions((prevVal) => {
      return [...prevVal, { ...emptySession() }]
    })
  }

  useEffect(() => {
    if (organization) {
      fetchTemplates(organization.id)
    }
  }, [fetchTemplates, organization])

  const onSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoaderVisible(true)
    setDisableSubmit(true)
    const sessionModels = convertDraftSessionsToSessionModels(sessions)
    let newSessions = sessionModels.map((session) => {
      return {
        sessionname: session.sessionname,
        link: session.link,
        start_date: session.start_date
          ? getUTCDateTime(session.start_date)
          : null,
        end_date: session.end_date ? getUTCDateTime(session.end_date) : null,
        description_markup: session.description_markup,
        inpersonmeetingaddress: session.inpersonmeetingaddress,
        survey_template_id: session.survey_template_id,
        wyblo_hosting: session.wyblo_hosting,
      }
    })
    try {
      const createdSessions = await postSessions(courseId, newSessions)
      setIsLoaderVisible(false)
      setDisableSubmit(false)

      if (createdSessions) {
        setSessions([{ ...emptySession() }])
        history.location.pathname === '/Courses' && getAllcourse()
        toggleDrawer('right', false)
        dispatch(courseEdited())
      }
    } catch (e: any) {
      toast.error(e.message, {
        theme: 'colored',
      })
      setIsLoaderVisible(false)
      setDisableSubmit(false)
    }
  }

  const toggleDrawer = (anchor: string, open: any) => {
    setState({ ...state, [anchor]: open })
    setSessions([{ ...emptySession() }])
  }

  const onSessionFieldChanged: AddSessionFormProp['onSessionFieldChanged'] = (
    fieldName,
    value,
    sessionIndex
  ) => {
    const updatedSessions = [...sessions]
    updatedSessions[sessionIndex][fieldName] = value
    setSessions([...updatedSessions])
  }

  const removeSession = (sessionIndex: number) => {
    if (sessions.length === 1) {
      onClose()
      return
    }
    const updatedSessions = [...sessions]
    updatedSessions.splice(sessionIndex, 1)
    setSessions(updatedSessions)
  }

  const list = (anchor: string) => (
    <StyledDivList role="presentation">
      <form onSubmit={onSubmit}>
        <List>
          <div className="AddCourse" id="bottomscroll">
            {organization && (
              <AddSessionForm
                organization={organization}
                sessions={sessions}
                templates={templates}
                onSessionFieldChanged={onSessionFieldChanged}
                removeSession={removeSession}
              />
            )}

            <div className="fixSubmit">
              <button
                type="button"
                className="btn addSessionbtn"
                disabled={disableSubmit}
                onClick={() => {
                  addSession()

                  setTimeout(() => {
                    var element = document.getElementById(
                      (sessions.length + 1).toString()
                    )
                    element?.scrollIntoView()
                  }, 100)
                }}
              >
                {t('courses.AddSession')}
              </button>
              <button
                type="submit"
                className="btn courseSubmit"
                disabled={disableSubmit}
              >
                {t('courses.Confirm')}
              </button>
            </div>
          </div>
        </List>
      </form>

      <div
        style={{
          position: 'fixed',
          zIndex: '999',
          top: '50%',
          left: '88%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader
          type="Oval"
          color="#2477bc"
          height={60}
          width={60}
          visible={isLoaderVisible}
        />
      </div>
    </StyledDivList>
  )

  return (
    <div>
      <React.Fragment>
        {history.location.pathname === '/Courses' && (
          <>
            <AddCircleIcon
              onClick={(event) => {
                toggleDrawer('right', true)
              }}
              style={{
                color: '#73B0FF',
                cursor: 'pointer',
              }}
            />
          </>
        )}

        {history.location.pathname === `/Courses/${courseId}` && (
          <>
            <button
              className="btn mainbtnColor me-4 align-items-center"
              onClick={(event) => {
                toggleDrawer('right', true)
              }}
            >
              <AddOutlinedIcon
                className="me-2"
                style={{
                  width: '30px',
                  marginBottom: '6px',
                }}
              />
              {t('courses.AddSession')}
            </button>
          </>
        )}

        <Drawer
          disableEnforceFocus
          anchor={'right'}
          open={state['right']}
          onClose={(event) => {
            onClose()
          }}
        >
          {list('right')}
        </Drawer>
        <ConfirmationDialog
          i18nKey="courses.close-drawer-confirmation"
          isOpen={isCloseConfirmationDialogVisible}
          onClose={() => {
            setIsCloseConfirmationDialogVisible(false)
          }}
          onConfirm={() => {
            setTimeout(() => {
              toggleDrawer('right', false)
            }, 500)
          }}
        />
      </React.Fragment>
    </div>
  )
}
