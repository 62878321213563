import { Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { ViewState, AppointmentModel } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  MonthView,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  CurrentTimeIndicator,
  AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui'
import { useTranslation } from 'react-i18next'
import CourseService from 'Service/CourseService'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { convertMeetingsToAppointments } from 'utils/type-conversions/calendar'
import Loader from 'Component/Loader'
import { Course, Meeting } from 'Component/CourseRegistration/Models'
import i18next from 'i18next'

const CoursesSchedule = () => {
  const [data, setData] = useState<AppointmentModel[]>()
  const [currentViewName, setCurrentViewName] = useState('')
  const [isLoaing, setIsLoaing] = useState(true)

  const { t } = useTranslation()
  const organization = useAppSelector(selectCurrentOrganization)

  const { getAllCourses } = CourseService()

  const getCourses = async (organization_id: number) => {
    try {
      setIsLoaing(true)
      const courses: Course[] = await getAllCourses(organization_id)
      //Flatting meetings array
      const meetings: Meeting[] = courses.flatMap((course) => course.meetings)
      const appointments: AppointmentModel[] =
        convertMeetingsToAppointments(meetings)
      setData(appointments)
      setIsLoaing(false)
    } catch (error) {
      setIsLoaing(false)
    }
  }

  const currentViewNameChange = (currentViewName: string) => {
    setCurrentViewName(currentViewName)
  }

  useEffect(() => {
    if (organization) getCourses(organization?.id)
  }, [organization])

  return (
    <div className="">
      <div className="">
        <div className="survey-title-container">
          <h4 className="mb-4">{t('common.calendar')}</h4>
        </div>
        {isLoaing ? (
          <Loader isVisible={isLoaing} />
        ) : (
          <Paper>
            <Scheduler
              data={data}
              height="auto"
              locale={i18next.language}
              firstDayOfWeek={1}
            >
              <ViewState
                currentViewName={currentViewName}
                onCurrentViewNameChange={currentViewNameChange}
              />
              <WeekView
                displayName={t('coursesSchedule.week')}
                cellDuration={60}
              />
              <MonthView displayName={t('coursesSchedule.month')} />
              <DayView
                displayName={t('coursesSchedule.day')}
                cellDuration={60}
              />
              <Toolbar />
              <DateNavigator />
              <TodayButton
                messages={{ today: `${t('coursesSchedule.today')}` }}
              />
              <ViewSwitcher />
              <Appointments />
              <AllDayPanel
                messages={{ allDay: `${t('coursesSchedule.all-day')}` }}
              />
              <AppointmentTooltip showCloseButton />
              <CurrentTimeIndicator
                shadePreviousCells
                shadePreviousAppointments
              />
            </Scheduler>
          </Paper>
        )}
      </div>
    </div>
  )
}

export default CoursesSchedule
