import { DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Customer } from 'Component/CourseRegistration/Models'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import { useMemo } from 'react'

interface MemberDetailDialogProp {
  open: boolean
  setOpen: (value: boolean) => void
  registrant?: Customer
}

export default function MemberDetailDialog({
  open,
  setOpen,
  registrant,
}: MemberDetailDialogProp) {
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  const customData = useMemo(() => {
    if (registrant && registrant.custom_data) {
      return Object.entries<string>(registrant.custom_data).map(
        ([key, value]) => {
          return {
            name: key,
            value,
          }
        }
      )
    }
  }, [registrant])

  const renderValue = (value: string) => {
    if (moment(value, true).isValid()) {
      return new Date(value).toLocaleDateString()
    } else return value
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="registrantsInvite">
        <DialogTitle id="alert-dialog-title" className="mb-4">
          {t('courses.registrantDetail')}
          <CloseIcon className="cursorPointer" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <div className="registrantsInvite">
            <DialogContentText id="alert-dialog-description">
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                <div className="flexonaddtrinees flex-column">
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.first_name')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.first_name ?? ''}
                        disabled
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.last_name')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.last_name ?? ''}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.address')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.address ?? ''}
                        disabled
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.birthday')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.birthday ?? ''}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.cellphone')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.cellphone ?? ''}
                        disabled
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.city')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.city ?? ''}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.country')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.country ?? ''}
                        disabled
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.email')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.email ?? ''}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.fiscal_code')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.fiscal_code ?? ''}
                        disabled
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.gender')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.gender ?? ''}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.facebook')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.facebook ?? ''}
                        disabled
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.job_title')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.job_title ?? ''}
                        disabled
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        {t('course-registration.modal.form.organization')}
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control addCoursesInput mb-2"
                        value={registrant?.organization ?? ''}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {customData &&
                    customData.map((c: any) => {
                      return (
                        <div className="col-sm-6 mb-3">
                          <label className="form-label">{c.name}</label>
                          <input
                            type="text"
                            required
                            className="form-control addCoursesInput mb-2"
                            value={renderValue(c.value) ?? ''}
                            disabled
                          />
                        </div>
                      )
                    })}
                </div>
              </form>
            </DialogContentText>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
