import React from 'react'

const EnvIdentifier = () => {
  const color =
    process.env.REACT_APP_ENVIRONMENT === 'development' ? 'red' : 'yellow'
  const isVisible =
    process.env.REACT_APP_ENVIRONMENT === 'production' ? 'none' : 'flex'

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '5px',
        top: '0',
        left: '0',
        backgroundColor: color,
        zIndex: '9999',
        display: isVisible,
      }}
    ></div>
  )
}

export default EnvIdentifier
