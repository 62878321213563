import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SessionDesignForm } from './SessionDesignForm'
import { useParams } from 'react-router-dom'

type RouteParams = {
  designId: string
}

export function NewSessionDesign() {
  const { t } = useTranslation()
  const { designId } = useParams<RouteParams>()

  return (
    <Box sx={{ mb: 20, mr: 5 }} className="">
      <Stack className="">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('course-design.new-session')}
        </Typography>

        <SessionDesignForm courseDesignId={designId} isNewDesign={true} />
      </Stack>
    </Box>
  )
}
