import { Stack, Typography, InputLabel, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'
import FormTextField from 'Component/form-fields/FormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ActivityParticipant, Company } from 'Component/FundedTraining/types'
import {
  ActivityParticipantFormData,
  ActivityParticipantFormValidation,
} from 'common/form-validation/ActivityParticipantFormValidation'
import CompanyAutoComplete from '../../CompanyAutocomplete'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import ConfirmationDialog from 'Component/ConfirmationDialog'

type Props = {
  projectUuid: string
  activityUuid: string
  currentActivityParticipant?: ActivityParticipant
  isNew?: boolean
  onActivityParticipantUpdated?(activityParticipant: ActivityParticipant): void
}

type EmployeeUpdateError = {
  code: number
  message: string
  name?: string
  surname?: string
}

export function ActivityParticipantForm(props: Props) {
  const {
    projectUuid,
    activityUuid,
    currentActivityParticipant = null,
    isNew = false,
    onActivityParticipantUpdated,
  } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [participantFormData, setParticipantFormData] = useState<
    Record<string, any>
  >({})
  const [previousFullName, setPreviousFullName] = useState<string>('')
  const [selectedCompany, setSelectedCompany] = useState<null | Company>(null)
  const [companies, setCompanies] = useState<Company[]>([])
  const [
    isUpdateEmployeeConfirmationDialogOpen,
    setIsUpdateEmployeeConfirmationDialogOpen,
  ] = useState(false)

  const organization = useAppSelector(selectCurrentOrganization)

  const formMethods = useForm<ActivityParticipantFormData>({
    resolver: yupResolver(ActivityParticipantFormValidation(t)),
  })

  function handleError(error: EmployeeUpdateError) {
    if (error.code === 103) {
      setPreviousFullName(`${error.name} ${error.surname}`)
      setIsUpdateEmployeeConfirmationDialogOpen(true)
    } else {
      toast.error(error.message, { theme: 'colored' })
    }
  }

  const updateActivityParticipant = (forceEmployeeUpdate: boolean = false) => {
    setIsLoading(true)

    const requestObject = {
      ...participantFormData,
      'force-employee-update': forceEmployeeUpdate,
    }

    try {
      api
        .put(
          `funded_training/projects/${projectUuid}/activities/${activityUuid}/employees/${currentActivityParticipant?.uuid}`,
          requestObject
        )
        .then(
          (response) => {
            toast.success(t('funded-training.participant-updated'), {
              theme: 'colored',
            })
            if (onActivityParticipantUpdated) {
              onActivityParticipantUpdated(response.data)
            }

            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            handleError(error)
            // handleError({
            //   code: 103,
            //   message: 'ERROR',
            //   name: 'Davide',
            //   surname: 'Barbara',
            // })
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createActivityParticipant = (forceEmployeeUpdate: boolean = false) => {
    setIsLoading(true)

    const requestObject = {
      ...participantFormData,
      force_employee_update: forceEmployeeUpdate,
    }

    try {
      api
        .post(
          `funded_training/projects/${projectUuid}/activities/${activityUuid}/employees`,
          requestObject
        )
        .then(
          (response) => {
            toast.success(
              t('funded-training.activity-detail.participant-added'),
              {
                theme: 'colored',
              }
            )
            setIsLoading(false)
            history.goBack()
          },
          (error) => {
            console.log(error)
            handleError(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: ActivityParticipantFormData) => {
    const params: Record<string, any> = {}

    params['name'] = data.name
    params['surname'] = data.surname
    params['fiscal_code'] = data.fiscal_code
    params['hourly_cost'] = data.hourly_cost

    setParticipantFormData(params)

    if (isNew) {
      createActivityParticipant()
    } else {
      updateActivityParticipant()
    }
  }

  useEffect(() => {
    if (currentActivityParticipant) {
      formMethods.setValue('name', currentActivityParticipant.name)
      formMethods.setValue('surname', currentActivityParticipant.surname)
      formMethods.setValue(
        'fiscal_code',
        currentActivityParticipant.fiscal_code
      )
      formMethods.setValue(
        'hourly_cost',
        currentActivityParticipant.hourly_cost
      )
      setSelectedCompany(currentActivityParticipant.company)
      setCompanies(currentActivityParticipant.activity.companies)
    }
  }, [formMethods, currentActivityParticipant])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          <Stack
            mt={4}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Stack>
              <FormTextField
                name="name"
                type="text"
                label={t('common.name')}
                mandatory={true}
              />

              <FormTextField
                name="surname"
                type="text"
                label={t('common.surname')}
                mandatory={true}
              />
              <FormTextField
                name="fiscal_code"
                type="text"
                label={t('common.fiscal-code')}
                mandatory={true}
              />

              <InputLabel sx={{ color: '#1976d2' }}>
                <Typography variant="h6">
                  {t('funded-training.company')}
                </Typography>
              </InputLabel>
              <Box sx={{ mb: 2 }}>
                <CompanyAutoComplete
                  selectedCompany={selectedCompany}
                  // TODO: the companies here need to be those of the project
                  allCompanies={companies}
                  onCompanyUpdated={(newCompany: null | Company) => {
                    setSelectedCompany(newCompany)
                  }}
                ></CompanyAutoComplete>
              </Box>

              <FormTextField
                name="hourly_cost"
                type="number"
                label={t(
                  'funded-training.activity-detail.participant-hourly-cost'
                )}
                mandatory={false}
              />
            </Stack>
            <StyledButton
              type="submit"
              startIcon={<SaveIcon />}
              hidden={isLoading}
              sx={{
                backgroundColor: '#0d6efd !important',
              }}
            >
              <Typography sx={{ mt: 0.5 }}>
                {isNew ? t('common.create') : t('common.update')}
              </Typography>
            </StyledButton>
            <Loader isVisible={isLoading} />
          </Stack>
        </form>
      </FormProvider>

      <ConfirmationDialog
        isOpen={isUpdateEmployeeConfirmationDialogOpen}
        onClose={() => {
          setIsUpdateEmployeeConfirmationDialogOpen(false)
        }}
        i18nKey="funded-training.activity-participant-detail.employee-update-confirmation"
        entityName={previousFullName}
        onConfirm={() => {
          if (isNew) {
            createActivityParticipant(true)
          } else {
            updateActivityParticipant(true)
          }
        }}
      />
    </>
  )
}
