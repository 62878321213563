// form
import { FormProvider, useForm } from 'react-hook-form'
// @mui
import { Stack, InputAdornment } from '@mui/material'
import { StyledButton } from 'common/styled-component/new-template/StyledButton'
// @types
import RHFTextField from 'Component/ReactHookFormComponents/RHFTextField'
//icons
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
//hook
import { useTranslation } from 'react-i18next'
import {
  CompanyProfileSocialData,
  CompanyProfileSocialValidationSchema,
} from 'common/form-validation/CompanyProfileValidation'
import { useCallback, useEffect, useState } from 'react'
import useApi from 'api/UseApi'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { toast } from 'react-toastify'
import Loader from 'Component/Loader'
import { yupResolver } from '@hookform/resolvers/yup'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'

// ----------------------------------------------------------------------

const SOCIAL_LINKS = [
  {
    value: 'facebook',
    icon: <FacebookIcon width={24} sx={{ color: 'rgb(24, 119, 242)' }} />,
  },
  // {
  //   value: 'instagram',
  //   icon: <InstagramIcon width={24} sx={{ color: 'rgb(223, 62, 48)' }} />,
  // },
  {
    value: 'linkedin',
    icon: <LinkedInIcon width={24} sx={{ color: 'rgb(0, 96, 151)' }} />,
  },
  {
    value: 'twitter',
    icon: <TwitterIcon width={24} sx={{ color: 'rgb(28, 156, 234)' }} />,
  },
] as const

// ----------------------------------------------------------------------
export type IUserSocialLink = {
  facebookLink: string
  instagramLink: string
  linkedinLink: string
  twitterLink: string
}

type ApiData = {
  id?: number
  auth0_org_id?: string
}

const CompanySocialLinks = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [apiData, setApiData] = useState<ApiData>({})

  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const formMethods = useForm<CompanyProfileSocialData>({
    resolver: yupResolver(CompanyProfileSocialValidationSchema(t)),
  })

  const {
    formState: { isSubmitting },
  } = formMethods

  const getApiData = useCallback(async () => {
    setIsLoading(true)
    try {
      api.get(`organizations/${organization?.id}`).then(
        (response) => {
          setApiData(response.data.organization)

          formMethods.setValue('facebook', response.data.organization.facebook)
          formMethods.setValue('linkedin', response.data.organization.linkedin)
          formMethods.setValue('twitter', response.data.organization.twitter)

          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }, [formMethods, api, organization?.id])

  useEffect(() => {
    getApiData()
  }, [getApiData])

  const onSubmit = (data: CompanyProfileSocialData) => {
    const { facebook, linkedin, twitter } = data
    console.log(data)

    setIsLoading(true)
    try {
      api
        .put(`organizations/${apiData.id}`, {
          facebook: facebook,
          linkedin: linkedin,
          twitter: twitter,
        })
        .then(
          (response) => {
            setIsLoading(false)
            getApiData()
            toast.success(response.data.message, { theme: 'colored' })
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return (
    <>
      <div style={{ maxWidth: '1080px' }}>
        {organization?.userPermissions.includes('read:organization') && (
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <StyledCard sx={{ p: 3 }}>
                <Stack spacing={3} alignItems="flex-end">
                  {SOCIAL_LINKS.map((link) => (
                    <RHFTextField
                      key={link.value}
                      name={link.value}
                      disabled={isSubmitting}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {link.icon}
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                  {organization?.userPermissions.includes(
                    'edit:organization'
                  ) && (
                    <StyledButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {t('common.save-changes')}
                    </StyledButton>
                  )}
                </Stack>
              </StyledCard>
            </form>
          </FormProvider>
        )}
      </div>
      <Loader isVisible={isLoading} />
    </>
  )
}

export default CompanySocialLinks
