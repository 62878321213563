// @mui
import { Theme } from '@mui/material/styles'
import {
  Box,
  SxProps,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material'

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const

// ----------------------------------------------------------------------

type Props = {
  order?: 'asc' | 'desc'
  orderBy?: string
  headLabel: any[]
  rowCount?: number
  numSelected?: number
  onSort?: (id: string) => void
  onSelectAllRows?: (checked: boolean) => void
  sx?: SxProps<Theme>
}

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
}: Props) {
  return (
    <TableHead
      sx={{
        ...sx,
        backgroundColor: 'rgb(230 234 238)',
      }}
    >
      <TableRow>
        {/*         {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}
 */}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              '& .MuiTableRow-root .MuiTableCell-root': {
                width: headCell.width,
                minWidth: headCell.minWidth,
                lineHeight: '1.5rem',
                fontFamily: 'sans-serif',
                display: 'table-cell',
                verticalAlign: 'inherit',
                borderBottom: '1px dashed rgb(241, 243, 244)',
                textAlign: 'left',
                padding: '0px 0px 0px 8px',
                fontSize: '14px',
                color: 'rgb(99, 115, 129)',
                fontWeight: '600',
              },
            }}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
                sx={{ textTransform: 'capitalize' }}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
