import { Box, styled } from '@mui/material'
import { List } from '@mui/material'
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import TemplateSelect from 'View/course/TemplateSelect'
import { Organization } from 'Reducers/UserConfigReducer'
import AddSessionForm from '../AddSessionForm/AddSessionForm'
import Loader from 'Component/Loader'
import { DraftSession } from 'View/course/AddSession/AddSession'
import { useState } from 'react'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { editorModules } from 'Component/EditorDescrition/ReactQuillEditor'
import { Delta } from 'quill'

interface AddCourseFormProp {
  anchor: string
  templates: any[]
  sessions: DraftSession[]
  organization: Organization
  disableSubmit: boolean
  isLoaderVisible: boolean
  removeSession: (index: number) => void
  onSessionFieldChanged: <T extends keyof DraftSession>(
    name: T,
    value: DraftSession[T],
    index: number
  ) => void
  onSubmit: (courseFormData: any) => void
  setTemplateID: (value: any) => void
  addSession: () => void
  setIsCloseConfirmationDialogVisible: (value: boolean) => void
  toggleDrawer: (anchor: string, open: boolean) => void
}

const StyledDivList = styled('div')(({ theme }) => ({
  width: 550,
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    width: 350,
  },
}))

const AddCourseForm = (prop: AddCourseFormProp) => {
  const {
    anchor,
    templates,
    sessions,
    organization,
    disableSubmit,
    isLoaderVisible,
    onSubmit: pOnSubmit,
    setTemplateID,
    removeSession,
    onSessionFieldChanged,
    addSession,
    setIsCloseConfirmationDialogVisible,
    toggleDrawer,
  } = prop

  const initialDelta: any = { ops: [] }
  const [editorContent, setEditorContent] = useState<Delta>(initialDelta)

  const { register, handleSubmit, getValues } = useForm()
  const { t } = useTranslation()

  const onClose = () => {
    const formValues = getValues()
    if (
      sessions.length > 0 ||
      Object.values(formValues).some((item) => {
        return item
      })
    ) {
      setIsCloseConfirmationDialogVisible(true)
    } else {
      toggleDrawer('right', false)
    }
  }

  const handleEditorChange = (
    _content: string,
    _delta: Delta,
    _source: string,
    editor: UnprivilegedEditor
  ) => {
    setEditorContent(editor.getContents())
  }

  const onSubmit = (data: Record<string, any>) => {
    pOnSubmit({ ...data, editorDescription: editorContent })
  }

  return (
    <StyledDivList role="presentation">
      <form onSubmit={handleSubmit(onSubmit)}>
        <List>
          <div className="AddCourse" id="bottomscroll">
            <div className="coursehead">
              <h1>{t('courses.AddCourse')}</h1>
              <CloseIcon
                onClick={() => {
                  onClose()
                }}
              />
            </div>
            <div className="mb-3 mt-5">
              <label className="form-label">
                {t('courses.CourseName')}{' '}
                <span style={{ color: '#FF5050' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control addCoursesInput"
                required
                {...register('courseName')}
                placeholder={t('courses.Typethenamehere')}
              />
            </div>
            <div className="mb-5">
              <label className="form-label">{t('common.description')}</label>
              <Box
                sx={{
                  '.ql-toolbar.ql-snow': {
                    borderColor: '#9bc6fe',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    background: '#ffffff',
                  },
                  '.ql-header.ql-picker': {
                    zIndex: 1000,
                  },
                  '.ql-container.ql-snow': {
                    borderColor: '#9bc6fe',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    background: '#ffffff',
                  },
                  '.ql-editor': {
                    maxHeight: 250,
                    overflowY: 'auto',
                  },
                }}
              >
                <ReactQuill
                  theme="snow"
                  value={editorContent}
                  onChange={handleEditorChange}
                  modules={editorModules}
                />
              </Box>
            </div>

            <div className="mb-5">
              <label className="form-label">
                {t('courses.Preassessment')}{' '}
                <span style={{ color: '#FF5050' }}></span>
              </label>
              <TemplateSelect
                templates={templates}
                onChange={(value) => {
                  setTemplateID(value)
                }}
              />
            </div>

            <AddSessionForm
              sessions={sessions}
              templates={templates}
              organization={organization}
              removeSession={removeSession}
              onSessionFieldChanged={onSessionFieldChanged}
            />

            <div className="fixSubmit">
              <button
                type="button"
                className="btn addSessionbtn"
                disabled={disableSubmit}
                onClick={() => {
                  addSession()

                  setTimeout(() => {
                    var element = document.getElementById(
                      (sessions.length + 1).toString()
                    )
                    element && element.scrollIntoView()
                  }, 100)
                }}
              >
                {t('courses.AddSession')}
              </button>
              <button
                type="submit"
                className="btn courseSubmit"
                disabled={disableSubmit}
              >
                {t('courses.Confirm')}
              </button>
            </div>
          </div>
        </List>
      </form>

      <Loader isVisible={isLoaderVisible} />
    </StyledDivList>
  )
}

export default AddCourseForm
