import useApi from 'api/UseApi'
import { useTranslation } from 'react-i18next'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'

interface ExportButtonProp {
  fileName: string
  route: string
  setIsLoaderVisible: (value: boolean) => void
}

const ExportButton = ({
  fileName,
  route,
  setIsLoaderVisible,
}: ExportButtonProp) => {
  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const exportCSV = async () => {
    setIsLoaderVisible(true)

    try {
      api
        .get(`organizations/${organization?.id}/${route}.xlsx`, {
          responseType: 'blob',
        })
        .then(
          (response) => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(response.data)
            link.download = `${fileName}-${+new Date()}.xlsx`
            link.click()

            setIsLoaderVisible(false)
          },
          (error) => {
            console.log(error)
            setIsLoaderVisible(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return (
    <div>
      {organization?.userPermissions.includes('read:participants') && (
        <button className="btn mainbtnColor" onClick={exportCSV}>
          <svg
            width="15"
            height="15"
            style={{
              marginRight: '.7rem',
            }}
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.2 12.6H9V4.5H11.7L8.1 0L4.5 4.5H7.2V12.6Z"
              fill="#157AFF"
            />
            <path
              d="M1.8 17.9998H14.4C15.3927 17.9998 16.2 17.1925 16.2 16.1998V8.0998C16.2 7.1071 15.3927 6.2998 14.4 6.2998H10.8V8.0998H14.4V16.1998H1.8V8.0998H5.4V6.2998H1.8C0.8073 6.2998 0 7.1071 0 8.0998V16.1998C0 17.1925 0.8073 17.9998 1.8 17.9998Z"
              fill="#157AFF"
            />
          </svg>

          {t('courses.Export')}
        </button>
      )}
    </div>
  )
}
export default ExportButton
