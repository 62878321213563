import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Tooltip, Tab, Tabs, AppBar } from '@mui/material';

import CourseSessionsTab from '../View/ViewcourseTab/CourseSessionsTab';
import CourseRegistrantsTab from '../View/ViewcourseTab/CourseRegistrantsTab';
import CourseSettingsTab from '../View/ViewcourseTab/CourseSettingsTab';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import AddSession from '../View/course/AddSession/AddSession';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'Component/Loader';
import { clearfilterRegistrants } from '../Actions/FilterRegistrants';
import { useTranslation } from 'react-i18next';
import useApi from 'api/UseApi';
import Surveys from './Surveys';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import { useParams, useHistory } from 'react-router-dom';
import {
  courseFetched,
  selectShouldUpdateCourse,
} from 'Reducers/CourseDetailReducer';
import { a11yProps, TabPanel } from './TabPanel/TabPanel';

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

const CourseDetail = () => {
  const [value, setValue] = React.useState(0);
  const [sessions, setSessions] = useState([]);
  const [serverSessions, setServerSessions] = useState([]);
  const [course, setCourse] = useState(null);
  const [isRegistrationLinkCopied, setIsRegistrationLinkCopied] =
    useState(false);
  const { t } = useTranslation();
  const shouldUpdateCourse = useAppSelector(selectShouldUpdateCourse);
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const SettingsRedirect = useSelector((state) => state.SettingsRedirect);
  const organization = useAppSelector(selectCurrentOrganization);
  const FilterRegistrants = useSelector((state) => state.FilterRegistrants);
  const api = useApi(false);

  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (courseId && organization) {
      setIsLoaderVisible(true);

      try {
        api.get(`courses/${courseId}`).then(
          (response) => {
            if (!response.data.courses) {
              console.log('Course detail error: no courses');
              return;
            }

            const fetchedCourse = response.data.courses[0];

            if (!fetchedCourse) {
              console.log('Course detail error: no course');
              return;
            }

            let shouldRedirect =
              fetchedCourse.organization_id !== organization.id;
            if (shouldRedirect) {
              history.push('/Courses');
              return;
            }

            if (!response.data.courses[0].meetings) {
              console.log('Course detail error: no sessions');
              return;
            }

            setSessions(response.data.courses[0].meetings);
            setServerSessions(response.data.courses[0].meetings);

            setIsLoaderVisible(false);
            setCourse(response.data.courses[0]);
            dispatch(courseFetched());
          },
          (error) => {
            console.log(error);
            setIsLoaderVisible(false);
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [api, shouldUpdateCourse, courseId, dispatch, history, organization?.id]);

  useEffect(() => {
    if (FilterRegistrants.redirect === true) {
      setValue(1);
    }
  }, [FilterRegistrants]);

  useEffect(() => {
    if (course) {
      let shouldRedirect = course.organization_id !== organization.id;
      if (shouldRedirect) {
        history.push('/Courses');
        return;
      }
    }
  }, [course, history, organization?.id]);

  useEffect(() => {
    if (value !== 1) {
      dispatch(
        clearfilterRegistrants({
          redirect: false,
          participated_id: '',
          attendess_id: '',
        })
      );
    }
  }, [dispatch, value]);

  useEffect(() => {
    if (SettingsRedirect === true) {
      setValue(3);
    }
  }, [SettingsRedirect]);

  return (
    <div className="">
      <div className="viewCourse">
        <div className="search_addCourse">
          <div className="inputSearch"></div>
        </div>

        <div className="upTable">
          <div className="tableHeadFlex flex-wrap mb-2">
            <h4 className="mb-0">{course?.title}</h4>

            <div className="d-flex">
              <button
                className="btn mainbtnColor me-4 align-items-center position-relative"
                onClick={() => history.push(`/session-dates-list/${courseId}`)}
              >
                {t('courses.edit-dates')}
              </button>
              {course?.publish_at != null && (
                <Tooltip
                  placement="bottom"
                  title={
                    isRegistrationLinkCopied
                      ? t('common.copied-to-clipboard-message')
                      : t('common.copy-to-clipboard')
                  }
                  arrow
                >
                  <button
                    className="btn mainbtnColor me-4 align-items-center position-relative"
                    onMouseLeave={() => setIsRegistrationLinkCopied(false)}
                    onClick={() => {
                      navigator.clipboard.writeText(course?.course_Link);
                      setIsRegistrationLinkCopied(true);
                    }}
                  >
                    {' '}
                    <FileCopyOutlinedIcon
                      className="me-2"
                      style={{
                        width: '30px',
                        marginBottom: '6px',
                      }}
                    />{' '}
                    {t('courses.copy-registration-link')}
                  </button>
                </Tooltip>
              )}
              {organization?.userPermissions.includes('add:session') && (
                <AddSession courseId={courseId} />
              )}
            </div>
          </div>

          <div className="TabStart">
            <StyledDiv>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={t('courses.Sessions')} {...a11yProps(0)} />
                  <Tab label={t('registrants')} {...a11yProps(1)} />
                  <Tab label={t('survey.survey')} {...a11yProps(2)} />
                  <Tab label={t('courses.Settings')} {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              {course && (
                <>
                  <TabPanel value={value} index={0}>
                    <CourseSessionsTab
                      sessions={sessions}
                      serverSessions={serverSessions}
                      courseId={courseId}
                      visibleLoader={isLoaderVisible}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <CourseRegistrantsTab course={course} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Surveys courseId={courseId} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <CourseSettingsTab
                      course={course}
                      visibleLoader={isLoaderVisible}
                    />
                  </TabPanel>
                </>
              )}
            </StyledDiv>
          </div>
        </div>
      </div>

      <Loader isVisible={isLoaderVisible} />
    </div>
  );
};

export default CourseDetail;
