import { Box, Typography, Tabs, Tab, Link } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { Notice } from 'Component/FundedTraining/types'
import { NoticeForm } from './NoticeForm'
import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'

type RouteParams = {
  noticeUuid: string
}

type LocationState = {
  noticeTitle: string
}

type Props = {
  isNew?: boolean
}

function a11yProps(index: number) {
  return {
    id: `notice-tab-${index}`,
    'aria-controls': `notice-tabpanel-${index}`,
  }
}

export function NoticeDetail(props: Props) {
  const { noticeUuid } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { isNew = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [noticeTitle, setNoticeTitle] = useState<string>('')
  const [currentNotice, setCurrentNotice] = useState<null | Notice>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  // const shouldPreselectProjectsTab = useAppSelector(
  //   selectShouldPreselectProjectsTab
  // )

  // useEffect(() => {
  //   if (shouldPreselectProjectsTab) {
  //     setTabIndex(1)
  //     dispatch(setShouldPreselectProjectsTab(false))
  //   }
  // }, [shouldPreselectProjectsTab])

  useEffect(() => {
    if (currentNotice) {
      if (noticeTitle === '') {
        setNoticeTitle(currentNotice.title)
      }
    } else if (state) {
      setNoticeTitle(state.noticeTitle)
    }
  }, [state, currentNotice])

  const getNotice = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`funded_training/notices/${noticeUuid}`).then(
        (response) => {
          setCurrentNotice(response.data)
          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, noticeUuid])

  useEffect(() => {
    getNotice()
  }, [getNotice, noticeUuid])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
        <Trans i18nKey={'funded-training.notice-detail.title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd' }}>
            <>{{ noticeTitle }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>

      <NavLink to={`/funded-training/notices`} exact>
        <Link variant="body1">{t('funded-training.go-to-notices-list')}</Link>
      </NavLink>

      {currentNotice && (
        <>
          <NoticeForm
            currentNotice={currentNotice}
            isNew={isNew}
            onItemUpdated={(notice: Notice) => {
              setNoticeTitle(notice.title)
            }}
          />
          {/* <Tabs
              sx={{ mt: 4 }}
              value={tabIndex}
              onChange={onTabChange}
              aria-label="notice tabs"
            >
              <Tab label={t('common.config')} {...a11yProps(1)} />
              <Tab label={t('funded-training.projects')} {...a11yProps(0)} />
            </Tabs>

            <NoticeTabPanel value={tabIndex} index={0}>
              <NoticeForm
                currentNotice={currentNotice}
                isNew={isNew}
                onNoticeUpdated={(notice: Notice) => {
                  setNoticeTitle(notice.name)
                }}
              />
            </NoticeTabPanel>
            <NoticeTabPanel value={tabIndex} index={1}>
              <NoticeProjects
                noticeUuid={noticeUuid}
                items={currentNotice.projects}
                onItemDeleted={() => {}}
              />
            </NoticeTabPanel> */}
        </>
      )}
    </Box>
  )
}
