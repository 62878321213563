import { Box, Link, Typography } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useLocation, useParams, NavLink } from 'react-router-dom'
import useApi from 'api/UseApi'
import { SessionDesign } from '../Types'
import Loader from 'Component/Loader'
import { useTranslation } from 'react-i18next'
import { SessionDesignForm } from './SessionDesignForm'

type RouteParams = {
  designId: string
  sessionDesignId: string
}

type LocationState = {
  designName: string
}

type Props = {
  isNewDesign?: boolean
}

export function SessionDesignDetail(props: Props) {
  const { isNewDesign = false } = props

  const { t } = useTranslation()

  const { designId, sessionDesignId } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()

  const api = useApi(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentDesign, setCurrentDesign] = useState<null | SessionDesign>(null)
  const [screenTitle, setScreenTitle] = useState<string>('')

  const getSessionDesign = useCallback(() => {
    setIsLoading(true)
    try {
      api
        .get(`course_designs/${designId}/session_designs/${sessionDesignId}`)
        .then(
          (response) => {
            setIsLoading(false)
            setCurrentDesign(response.data)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, designId])

  useEffect(() => {
    getSessionDesign()
  }, [getSessionDesign, designId])

  useEffect(() => {
    if (currentDesign) {
      if (screenTitle === '') {
        setScreenTitle(currentDesign.name)
      }
    } else if (state) {
      if (screenTitle === '') {
        setScreenTitle(state.designName)
      }
    }
  }, [state, currentDesign, screenTitle])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
          <Trans i18nKey={'course-design.session-design-detail-title'}>
            {'placeholder '}
            <strong style={{ color: '#0d6efd', fontSize: '2rem' }}>
              <>{{ screenTitle }}</>
            </strong>
            {' placeholder'}
          </Trans>
        </Typography>
        <NavLink to={`/Design/${designId}`} exact>
          <Link variant="h6">{t('course-design.go-to-course-design')}</Link>
        </NavLink>
        {currentDesign && (
          <SessionDesignForm
            courseDesignId={designId}
            currentDesign={currentDesign}
            isNewDesign={isNewDesign}
            onDesignUpdated={(newDesign: SessionDesign) => {
              setScreenTitle(newDesign.name)
            }}
          />
        )}
      </Box>
      <Loader isVisible={isLoading} />
    </Box>
  )
}
