import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Activity } from '../../types'
import { ActivitiesTable } from './ActivitiesTable'

interface Props {
  projectUuid: string
  activities: Activity[]
  onItemDeleted: () => void
}

export function Activities(props: Props) {
  const { projectUuid, activities, onItemDeleted } = props

  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5">{t('funded-training.activities')}</Typography>

      <ActivitiesTable
        projectUuid={projectUuid}
        items={activities}
        onItemDeleted={() => {
          onItemDeleted()
        }}
      />
    </Box>
  )
}
