import CodiceFiscale from 'codice-fiscale-js'
import { TFunction } from 'i18next'
import { string, object, SchemaOf } from 'yup'
import * as CodiceFiscaleUtils from '@marketto/codice-fiscale-utils'

export type EmployeeFormData = {
  name: string
  surname: string
  fiscal_code: string | null
}

export const EmployeeFormValidation = (t: TFunction) => {
  const { Validator } = CodiceFiscaleUtils

  const schema: SchemaOf<EmployeeFormData> = object().shape({
    name: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.name'))
      .test(
        'is-valid-for-fiscal-code',
        t('forms.validation.fiscal-code.no-match.first-name'),
        (name, testContext) => {
          if (!name) {
            return false
          }

          const fiscalCode = testContext.parent.fiscal_code
          const isCFValid = Validator.codiceFiscale(fiscalCode).valid

          if (!isCFValid) return true

          const nameMatchesCF =
            Validator.codiceFiscale(fiscalCode).matchFirstName(name)

          return nameMatchesCF
        }
      ),
    surname: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.surname'))
      .test(
        'is-valid-for-fiscal-code',
        t('forms.validation.fiscal-code.no-match.last-name'),
        (surname, testContext) => {
          if (!surname) {
            return false
          }

          const fiscalCode = testContext.parent.fiscal_code
          const isCFValid = Validator.codiceFiscale(fiscalCode).valid

          if (!isCFValid) return true

          const surnameMatchesCF =
            Validator.codiceFiscale(fiscalCode).matchLastName(surname)

          return surnameMatchesCF
        }
      ),
    fiscal_code: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.fiscal-code'))
      .test(
        'is-valid-fiscal-code',
        t('forms.validation.fiscal-code.not-valid'),
        (value) => {
          if (!value) {
            return false
          }
          try {
            new CodiceFiscale(value)
          } catch (e: any) {
            return false
          }
          return true
        }
      ),
  })

  return schema
}
