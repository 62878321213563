import { memo, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  Box,
  ClickAwayListener,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
} from '@mui/material'
import { FormTextFieldInputBase } from 'common/styled-component/FormTextFieldInputBase'
import { Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { orange } from '@mui/material/colors'

type FieldType = 'text' | 'email' | 'number' | 'date'

type Props = {
  name: string
  label: string
  type: FieldType
  subLabel?: string | null
  placeholder?: string
  mandatory?: boolean
  tooltipDescription?: string | null
}

/**
 * React-hook-form wrapped component to be included in a FormProvider.
 *
 * Used to represent text or email input.
 */
const FormTextField = memo(
  ({
    name,
    label,
    type,
    mandatory = false,
    placeholder = '',
    subLabel = null,
    tooltipDescription = null,
  }: Props) => {
    const {
      control,
      formState: { errors },
    } = useFormContext()

    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleTooltipClose = () => {
      setTooltipOpen(false)
    }

    const handleTooltipOpen = () => {
      setTooltipOpen(true)
    }

    return (
      <>
        <InputLabel>
          <Stack direction="row" spacing={1}>
            <Typography variant="h6" sx={{ color: '#1976d2' }}>
              <span style={{ fontFamily: 'nexa' }}>{label}</span>
              <span hidden={!mandatory} style={{ color: '#FF5050' }}>
                *
              </span>
            </Typography>

            {tooltipDescription && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  placement="right"
                  onClose={handleTooltipClose}
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltipDescription}
                >
                  <IconButton
                    sx={{ px: 0, pt: 0, pb: 1 }}
                    disableFocusRipple={true}
                    disableRipple={true}
                    onClick={handleTooltipOpen}
                  >
                    <HelpOutlineIcon
                      sx={{
                        width: '0.8em',
                        color: orange[400],
                        ':hover': {
                          color: orange[600],
                        },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            )}
          </Stack>
        </InputLabel>
        {subLabel && (
          <InputLabel sx={{ color: 'black' }}>
            <Typography sx={{ fontFamily: 'nexalight' }} variant="subtitle1">
              {subLabel}
            </Typography>
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <Box
                sx={{
                  width: type === 'number' ? 100 : type === 'date' ? 200 : 400,
                }}
              >
                <FormTextFieldInputBase
                  {...field}
                  type={type}
                  placeholder={placeholder}
                ></FormTextFieldInputBase>
              </Box>
              <FormHelperText error id="form-text-field-error">
                {(errors[name]?.message as string) || <br />}
              </FormHelperText>
            </>
          )}
        />
      </>
    )
  }
)

export default FormTextField
