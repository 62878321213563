import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import styles from './Catalog.module.css'
import { ServerCourse, ServerCourseDesign } from 'models/course'
import CardCatalog from 'Component/CardCatalog/CardCatalog'
import CatalogService from 'Service/CatalogService'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CourseDesignCatalogCard from 'Component/CardCatalog/CourseDesignCatalogCard'

//needing prop to make the api, just for react usage
interface CatalogProp {
  slug: string
  isDesignCatalog?: boolean
}

const Catalog = ({ slug, isDesignCatalog = false }: CatalogProp) => {
  const [courses, setCourses] = useState<ServerCourse[]>([])
  const [designs, setDesigns] = useState<ServerCourseDesign[]>([])
  const { getDesignCatalog, getCourseCatalog } = CatalogService()

  const { t } = useTranslation()

  //render a list of cards
  const renderCards = () => {
    if (isDesignCatalog) {
      return designs?.map((design) => (
        <CourseDesignCatalogCard key={design.uuid} design={design} />
      ))
    } else {
      return courses?.map((course) => (
        <CardCatalog key={course.id} course={course} />
      ))
    }
  }

  //fetch courses
  const getCourses = async () => {
    const catalogCourses = await getCourseCatalog(slug)
    setCourses(catalogCourses)
  }

  //fetch courses design
  const getDesigns = async () => {
    const catalogDesigns = await getDesignCatalog(slug)
    console.log('catalogDesigns', catalogDesigns)
    setDesigns(catalogDesigns)
  }

  useEffect(() => {
    isDesignCatalog ? getDesigns() : getCourses()
    // eslint-disable-next-line
  }, [])

  return (
    <Box className={styles.container}>
      {courses ? (
        renderCards()
      ) : (
        <Typography variant="h6">{t('common.no-courses')}</Typography>
      )}
    </Box>
  )
}
export default Catalog
