import { useState, useEffect, useCallback, ChangeEventHandler } from 'react'
import Loader from 'Component/Loader'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import useApi from '../../api/UseApi'
import { useDispatch } from 'react-redux'
import company_logo from 'assets/icon/company_logo.png'
import { useAppSelector } from 'Store'
import {
  selectCurrentOrganization,
  onCompanyLogoUpdated,
} from 'Reducers/UserConfigReducer'
import { Grid, InputLabel, Stack, Tooltip, Typography } from '@mui/material'
import theme from 'theme'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CompanyProfileData,
  CompanyProfileValidationSchema,
} from 'common/form-validation/CompanyProfileValidation'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { RHFUploadAvatar } from 'Component/ReactHookFormComponents/RHFUploadAvatar'
import RHFTextField from 'Component/ReactHookFormComponents/RHFTextField'
import { Box } from '@mui/system'
import { StyledButton } from 'common/styled-component/new-template/StyledButton'
import { CustomFile } from 'utils/utils'

import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { editorModules } from 'Component/EditorDescrition/ReactQuillEditor'
import { Delta } from 'quill'

type ApiData = {
  id?: number
  auth0_org_id?: string
}

const CompanyProfile = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [apiData, setApiData] = useState<ApiData>({})
  const [Website, setWebsite] = useState('')

  const [primaryColor, setPrimaryColor] = useState('')

  const [organizationLoginLink, setOrganizationLoginLink] = useState('')
  const [isOrganizationLoginLinkCopied, setIsOrganizationLoginLinkCopied] =
    useState(false)

  const initialDelta: any = { ops: [] }
  const [editorContent, setEditorContent] = useState<Delta>(initialDelta)
  const handleEditorChange = (
    _content: string,
    _delta: Delta,
    _source: string,
    editor: UnprivilegedEditor
  ) => {
    setEditorContent(editor.getContents())
  }

  const organization = useAppSelector(selectCurrentOrganization)

  const { t } = useTranslation()
  const api = useApi(false)

  const dispatch = useDispatch()

  const fileMaxSize = 3145728

  const formMethods = useForm<CompanyProfileData>({
    resolver: yupResolver(CompanyProfileValidationSchema(t)),
  })

  const getApiData = useCallback(async () => {
    setIsLoading(true)
    try {
      api.get(`organizations/${organization?.id}`).then(
        (response) => {
          setApiData(response.data.organization)
          setWebsite(response.data.organization.website)
          setPrimaryColor(response.data.organization.primary_color)

          formMethods.setValue('name', response.data.organization.name)
          formMethods.setValue('address', response.data.organization.address)
          formMethods.setValue(
            'vatNumber',
            response.data.organization.vat_number
          )
          formMethods.setValue(
            'supportEmail',
            response.data.organization.support_email || ''
          )
          formMethods.setValue('email', response.data.organization.email)

          setEditorContent(response.data.organization.description_markup)

          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }, [formMethods, api, organization?.id])

  const {
    setValue,
    formState: { isSubmitting },
  } = formMethods

  useEffect(() => {
    getApiData()
  }, [getApiData])

  const handleColorChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPrimaryColor(e.target.value)
  }

  const onSubmit = (data: CompanyProfileData) => {
    const { logo, name, vatNumber, address, supportEmail, email } = data
    console.log(data)

    setIsLoading(true)

    if (logo) uploadLogo(logo)

    try {
      api
        .put(`organizations/${apiData.id}`, {
          name: name,
          address: address,
          vat_number: vatNumber,
          description_markup: editorContent,
          email: email,
          website: Website,
          support_email: supportEmail,
          primary_color: primaryColor,
        })
        .then(
          (response) => {
            setIsLoading(false)
            getApiData()
            toast.success(response.data.message, { theme: 'colored' })
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  // Save chosen image on screen
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      let uploadFailed = false
      let newFile = null

      try {
        newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      } catch (error) {
        uploadFailed = true
        toast.warning(t('user-account.max-size'), {
          theme: 'colored',
        })
      }

      if (!uploadFailed && file && newFile) {
        setValue('logo', newFile, { shouldValidate: true })
      }
    },
    [setValue]
  )

  // Upload image to database
  const uploadLogo = async (logo: CustomFile) => {
    const formData = new FormData()
    formData.append('logo', logo)
    setIsLoading(true)

    try {
      api
        .put(`organizations/${apiData.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(
          (response) => {
            dispatch(onCompanyLogoUpdated(URL.createObjectURL(logo)))
            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            toast.error(error.response.data.message, { theme: 'colored' })
            setIsLoading(false)
          }
        )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="General">
      <div>
        {organization?.userPermissions.includes('read:organization') && (
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',

                  '@media only screen and (max-width: 1080px)': {
                    flexDirection: 'column',
                  },

                  maxWidth: '1080px',
                }}
              >
                <Stack>
                  <StyledCard
                    sx={{
                      py: 2,
                      px: 4,
                      mr: 1,
                      textAlign: 'center',
                      minWidth: 200,
                      maxHeight: 300,
                      '@media only screen and (max-width: 1080px)': {
                        mb: 1,
                        mr: 0,
                      },
                    }}
                  >
                    <RHFUploadAvatar
                      name="logo"
                      avatarUrl={
                        organization?.logoUrl
                          ? organization.logoUrl
                          : company_logo
                      }
                      maxSize={fileMaxSize}
                      onDrop={handleDrop}
                      disabled={isSubmitting}
                      helperText={
                        <Typography
                          variant="subtitle1"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.primary',
                          }}
                        >
                          {t('user-account.max-size')}
                          <br /> {t('user-account.allowed-format')}
                        </Typography>
                      }
                    />
                  </StyledCard>
                  <StyledCard
                    sx={{
                      pt: 2,
                      pr: 2,
                      pl: 2,
                      pb: 0,
                      mt: 1,
                      mr: 1,
                      mb: 0,
                      display: 'none',
                    }}
                  >
                    <Stack
                      direction="row"
                      className="flexonCopy"
                      marginBottom={2}
                    >
                      <RHFTextField
                        name=""
                        label={t('settings.organization-login-link')}
                        id="organizationLoginLink"
                        value={organizationLoginLink || ''}
                        type="text"
                        disabled
                        style={{ border: 'none' }}
                      />
                      <Tooltip
                        placement="bottom"
                        title={
                          (isOrganizationLoginLinkCopied
                            ? t('common.copied-to-clipboard-message')
                            : t('common.copy-to-clipboard')) as string
                        }
                        arrow
                      >
                        <button
                          className="btn"
                          type="button"
                          onMouseLeave={() =>
                            setIsOrganizationLoginLinkCopied(false)
                          }
                          onClick={() => {
                            const copyText: HTMLInputElement =
                              document.getElementById(
                                'organizationLoginLink'
                              ) as HTMLInputElement
                            copyText.select()
                            navigator.clipboard.writeText(copyText.value)
                            setIsOrganizationLoginLinkCopied(true)
                          }}
                        >
                          <FileCopyOutlinedIcon />
                        </button>
                      </Tooltip>
                    </Stack>
                  </StyledCard>
                </Stack>
                <StyledCard
                  sx={{
                    px: 3,
                    minHeight: 300,
                  }}
                >
                  <Stack direction="column">
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <Stack direction="column" width="100%">
                          {/* Name field */}
                          <Box sx={{ mb: 2, mt: 2 }}>
                            <Controller
                              name="name"
                              render={({ field: { value } }) => (
                                <RHFTextField
                                  name="name"
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value?.length > 0,
                                  }}
                                  value={value}
                                  disabled={isSubmitting}
                                  label={t('settings.FirstName')}
                                />
                              )}
                            />
                          </Box>
                          {/* Email field */}
                          <Box sx={{ mb: 2 }}>
                            <Controller
                              name="email"
                              render={({ field: { value } }) => (
                                <RHFTextField
                                  name="email"
                                  type="email"
                                  InputLabelProps={{
                                    shrink: value?.length > 0,
                                  }}
                                  value={value}
                                  disabled={isSubmitting}
                                  label={t('settings.Emailid')}
                                />
                              )}
                            />
                          </Box>
                          {/* Address field */}
                          <Controller
                            name="address"
                            render={({ field: { value } }) => (
                              <RHFTextField
                                name="address"
                                type="text"
                                InputLabelProps={{ shrink: value?.length > 0 }}
                                value={value}
                                disabled={isSubmitting}
                                label={t('settings.Address')}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item md={8} sx={{ pt: 0, pr: 0 }}>
                        {/* The height is considered after the toolbar, making it pass the container as 100% */}
                        <Box
                          maxWidth={555}
                          sx={{
                            maxHeight: 300,
                            height: `calc(100% - 65px)`,
                            '@media only screen and (max-width: 395px)': {
                              height: `calc(100% - 85px)`,
                            },
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: 13,
                              ml: 1,
                              color: 'rgb(145, 158, 171)',
                              fontFamily: 'sans-serif',
                            }}
                          >
                            {t('settings.Description')}
                          </InputLabel>
                          <Box
                            sx={{
                              '.ql-toolbar.ql-snow': {
                                borderColor: 'rgba(145, 158, 171, 0.2)',
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                              },
                              '.ql-header.ql-picker': {
                                zIndex: 1000,
                              },
                              '.ql-container.ql-snow': {
                                borderColor: 'rgba(145, 158, 171, 0.2)',
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8,
                              },
                              '.ql-editor': {
                                maxHeight: 155,
                                overflowY: 'auto',
                              },
                            }}
                          >
                            <ReactQuill
                              theme="snow"
                              value={editorContent}
                              onChange={handleEditorChange}
                              modules={editorModules}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container padding={0}>
                      <Grid item md={4} marginTop={1}>
                        <Controller
                          name="vatNumber"
                          render={({ field: { value } }) => (
                            <RHFTextField
                              name="vatNumber"
                              type="text"
                              InputLabelProps={{ shrink: value?.length > 0 }}
                              value={value}
                              disabled={isSubmitting}
                              label={t('settings.vat-number')}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={4} marginTop={1}>
                        <Controller
                          name="supportEmail"
                          render={({ field: { value } }) => (
                            <RHFTextField
                              name="supportEmail"
                              type="email"
                              InputLabelProps={{ shrink: value?.length > 0 }}
                              value={value}
                              disabled={isSubmitting}
                              label={t('settings.SupportEmail')}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={4} marginTop={1}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            maxWidth: 177,
                            minWidth: 177,
                          }}
                        >
                          <RHFTextField
                            name="primaryColor"
                            label={t('settings.PrimaryColor')}
                            type="color"
                            disabled={isSubmitting}
                            value={primaryColor}
                            onChange={handleColorChange}
                            sx={{
                              padding: 0,
                            }}
                          />
                          <label className="ms-2" style={{ fontSize: 14 }}>
                            {primaryColor}
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid
                    sx={{ marginTop: theme.spacing(2), pb: 2 }}
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    {organization?.userPermissions.includes(
                      'edit:organization'
                    ) && (
                      <StyledButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={{
                          marginRight: '16px !important',
                          marginLeft: '16px !important',
                        }}
                      >
                        {t('common.save-changes')}
                      </StyledButton>
                    )}
                  </Grid>
                </StyledCard>
              </Stack>
            </form>
          </FormProvider>
        )}
      </div>

      <Loader isVisible={isLoading} />
    </div>
  )
}

export default CompanyProfile
