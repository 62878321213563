import { TFunction } from 'i18next'

export type CourseRegistrationModel = {
  courseName: string
  companyName: string
  location: string
  sessions: Session[]
  companyLogo?: any
  overview?: Overview
  speakers?: Speaker[]
  price?: Price
  timezone?: string
  banner_url?: any
  status?: string
  stripePriceId: string | null
}

export type Overview = {
  text: any
  extraInfos: ExtraInfo[]
}

export type ExtraInfo = {
  text: string
  highlightedText?: string
}

export type Session = {
  id: string
  title: string
  descriptionMarkup: any
  dateTimeFrom?: string
  dateTimeTo?: string
  speakers?: Speaker[]
  link: string | null
  isOnline?: boolean
  locationAddress: string | null
  wyblo_hosting: boolean
  wyblo_link: string | null
}

export function getCourseLocation(sessions: Session[], t?: TFunction): string {
  let hasOnlineSessions: boolean = false
  let hasInPersonSessions: boolean = false

  const hybridText = t ? t('courses.Hybrid') : 'Hybrid'
  const onlineText = t ? t('courses.Online') : 'Online'
  const inPersonText = t ? t('courses.Inperson') : 'In Person'

  for (const session of sessions) {
    if (session.isOnline) hasOnlineSessions = true
    if (session.locationAddress) hasInPersonSessions = true

    if (hasOnlineSessions && hasInPersonSessions) {
      return hybridText
    }
  }

  return hasOnlineSessions ? onlineText : inPersonText
}

export type Speaker = {
  id: string
  name: string
}

export type Price = {
  amount: string
  currency: string
}

export type OrganizationInfoModel = {
  description: string
  description_markup: any
  logo_url: string
  name: string
  facebook: string
  twitter: string
  linkedin: string
  image?: string
  id?: number
}

export type RouteParams = {
  slug: string
}

export type UserInfo = {
  first_name?: string
  last_name?: string
  email: string
  address?: string
  birthday?: string
  cellphone?: string
  city?: string
  fiscal_code?: string
  country?: string
  facebook?: string
  gender?: string
  job_title?: string
  linkedin?: string
  organization?: string
  partita_iva?: string
  permissions: { permission_name: string; resource_server_identifier: string }[]
}

export type Customer = {
  address?: string
  birthday?: string
  cellphone?: string
  city?: string
  country?: string
  email?: string
  facebook?: string
  first_name?: string
  fiscal_code?: string
  gender?: string
  job_title?: string
  last_name?: string
  linkedin?: string
  organization?: string
  vat_number?: string
  website?: string
  custom_data?: any
}

export type Survey = {
  id?: number
  _id?: string
  external_id?: string
  org_id?: number
  user_id?: number
  created_at?: string
  updated_at?: string
  name: string
  description: string
  is_markdown_enabled: boolean
  redirect_url?: string
  link?: string
  body?: any //null | JSONValue
}

export type Course = {
  meetings: Meeting[]
  status: CourseStatusEnum
  title: string
  course_begin_date: string
  course_end_date: string
  course_total_registrants: number
  trainer: any
  id: number
  tablecollapse?: boolean
  publish_at?: string
  course_Link: string
  sessionname: string
}

export type Meeting = {
  start_date: string
  end_date: string
  id: number
  session_trainer: any[]
  hosting_url: string
  sessionname: string
  link: string
}

export interface MeetingDateTime extends Meeting {
  start_date_time: { date: string | undefined; time: string | undefined }
  end_date_time: { date: string | undefined; time: string | undefined }
}

export enum CourseStatusEnum {
  LIVE = 'Live',
  DRAFT = 'Draft',
  COMPLETED = 'Completed',
  COMING_NEXT = 'Coming Next',
}

export type SessionListDate = {
  id: number
  start_date: string
  end_date: string
}
