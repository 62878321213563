import * as React from 'react';
import { Box } from '@mui/material';
import { ProgressBar } from 'react-bootstrap';

export default function ApiProgressBar({
  progress,
  setProgress,
  visibleLoader2,
}) {
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 100;
        } else {
          const diff = Math.floor(Math.random() * 10);
          return Math.min(oldProgress + diff, 98);
        }
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [visibleLoader2]);

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      <ProgressBar animated now={progress} label={`${progress}%`} />
    </Box>
  );
}
