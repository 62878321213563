// @mui
import { Theme } from '@mui/material/styles'
import {
  Box,
  Switch,
  SxProps,
  TablePagination,
  FormControlLabel,
  TablePaginationProps,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
//

// ----------------------------------------------------------------------

type Props = {
  dense?: boolean
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  ...other
}: Props & TablePaginationProps) {
  const { t } = useTranslation()
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={t('common.rows-per-page')}
        component="div"
        sx={{
          '&.MuiTablePagination-root': {
            lineHeight: '1.57143',
            fontSize: '0.875rem',
            fontWeight: '400',
          },
          '&.MuiTablePagination-root .MuiTablePagination-selectLabel': {
            fontFamily: 'sans-serif',
          },
          '&.MuiTablePagination-root .MuiInputBase-root': {
            fontFamily: 'sans-serif',
          },
          '&.MuiTablePagination-root .MuiTablePagination-displayedRows': {
            fontFamily: 'sans-serif',
          },
        }}
        {...other}
      />

      {onChangeDense && (
        <FormControlLabel
          label={t('common.dense')}
          control={
            <AntSwitch
              checked={dense}
              onChange={onChangeDense}
              sx={{ mr: '0.5rem', ml: 3 }}
            />
          }
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: 'absolute',
            },
            '&.MuiFormControlLabel-root span': {
              lineHeight: '1.57143',
              fontSize: '0.875rem',
              fontFamily: 'sans-serif',
              fontWeight: '400',
            },
          }}
        />
      )}
    </Box>
  )
}
