import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';

import {
  Tab,
  Tabs,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@mui/material';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Files from '../View/Library/Files';
import Folders from '../View/Library/Folders';
import PublishIcon from '@mui/icons-material/Publish';
import { toast } from 'react-toastify';
import Loader from 'Component/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { clearDownloadFile } from '../Actions/Downloadfile';
import ApiProgressBar from '../View/ApiProgressBar';
import RenameFile from '../View/Library/Models/RenameFile';
import { useTranslation } from 'react-i18next';
import useApi from 'api/UseApi';
import SearchIcon from '@mui/icons-material/Search';
import { FILES } from 'common/LocalStorageKeys';
import ConfirmationDialog from './ConfirmationDialog';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import { a11yProps, TabPanel } from './TabPanel/TabPanel';

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  minHeight: '55vh',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const Library = () => {
  const [value, setValue] = React.useState(0);
  const [loaderVisible, setloaderVisible] = useState(false);
  const [fileLoader, setfileLoader] = useState(false);
  const [openMenu, setopenMenu] = React.useState(false);
  const [refresh, setrefresh] = useState(false);
  const [searchText, setSearchText] = useState('');
  const anchorRef = React.useRef(null);
  const downloadData = useSelector((state) => state.DownloadSingleFile);
  const folderName = useSelector((state) => state.UploadSingleFile);
  const dispatch = useDispatch();
  const [progress, setProgress] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [renameModelShow, setrenameModelShow] = React.useState(false);
  const { t } = useTranslation();
  const [messageToShow, setmessageToShow] = useState({
    value: false,
    message: t('library.DeleteFileMsg'),
  });
  const [inputData, setinputData] = useState('');
  const organization = useAppSelector(selectCurrentOrganization);

  const api = useApi();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteLibrary = () => {
    if (messageToShow.value === false) {
      deleteFile(
        downloadData.folderpath,
        downloadData.filename,
        downloadData.bucketname
      );
      setModalShow(false);
    } else if (messageToShow.value === true) {
      setModalShow(false);
      fileOperation(inputData);
    }
  };

  const handleToggle = () => {
    setopenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setopenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setopenMenu(false);
    }
  }

  const fileOperation = async (formData) => {
    setfileLoader(true);
    setProgress(0);

    try {
      api
        .post(`S3/AddFile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(
          (response) => {
            setrefresh(true);
            setProgress(100);
            setTimeout(() => {
              setfileLoader(false);
            }, 500);
            toast.success(t('library.Fileuploadsuccessful'), {
              theme: 'colored',
            });
            setmessageToShow({
              value: false,
              message: t('library.DeleteFileMsg'),
            });
          },
          (error) => {
            console.log(error);
            setfileLoader(false);
          }
        )
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  const uploadFile = async (e) => {
    var formData = new FormData();
    let getFile = e.target.files;

    formData.append('file', getFile[0]);
    formData.append('folderPath', folderName);
    formData.append('bucketName', 'org-wyblo-1');

    const files = JSON.parse(window.localStorage.getItem(FILES));

    const newfiles = files.map((data) => {
      return data.filename;
    });

    if (newfiles.includes(getFile[0].name) === true) {
      setModalShow(true);
      setmessageToShow({
        value: true,
        message: t('library.UploadFileMsg'),
      });

      setinputData(formData);
    } else {
      fileOperation(formData);
    }
  };

  const downloadFile = async () => {
    setloaderVisible(true);
    try {
      api
        .post(
          `S3/DownloadFile`,
          {
            folderPath:
              downloadData.folderpath !== null
                ? downloadData.folderpath !== ''
                  ? `${downloadData.folderpath}`
                  : ''
                : '',
            fileName: downloadData.filename,
            bucketName: downloadData.bucketname,
          },
          {
            responseType: 'blob',
          }
        )
        .then(
          (response) => {
            setloaderVisible(false);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadData.filename);
            document.body.appendChild(link);
            link.click();
            dispatch(clearDownloadFile());
          },
          (error) => {
            console.log(error);
            setloaderVisible(false);
            toast.error(error.response.data.message, { theme: 'colored' });
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteFile = async (folderpath, filename, bucketname) => {
    setloaderVisible(true);
    try {
      api
        .post(`S3/DeleteFile`, {
          folderPath:
            folderpath !== null
              ? folderpath !== ''
                ? `${folderpath}`
                : ''
              : '',
          fileName: filename,
          bucketName: bucketname,
        })
        .then(
          (response) => {
            setloaderVisible(false);
            toast.success(response.data.message, { theme: 'colored' });
            dispatch(clearDownloadFile());
            setrefresh(true);
          },
          (error) => {
            console.log(error);
            setloaderVisible(false);
            toast.error(error.response.data.message, { theme: 'colored' });
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const fileMoveToBin = async (folderpath, filename, bucketname) => {
    setloaderVisible(true);
    try {
      api
        .post('S3/MoveToBin', {
          folderPath:
            folderpath !== null
              ? folderpath !== ''
                ? `${folderpath}`
                : ''
              : '',
          fileName: filename,
        })
        .then(
          (response) => {
            setloaderVisible(false);
            toast.success(response.data.message, { theme: 'colored' });
            dispatch(clearDownloadFile());
            setrefresh(true);
          },
          (error) => {
            console.log(error);
            setloaderVisible(false);
            toast.error(error.response.data.message, { theme: 'colored' });
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const SearchOnVal = (value) => {
    setSearchText(value);
  };

  return (
    <div className="">
      <div className="">
        {organization?.userPermissions.includes('view:library') && (
          <div className="liabrary">
            <div className="search_addCourse">
              <div className="inputSearch">
                <SearchIcon style={{ color: '#DEDEDE' }} />
                <div className="mx-2 inputWidth">
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t('courses.Search')}
                    onChange={(e) => {
                      SearchOnVal(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="upTable">
              <div className="tableHeadFlex mb-2">
                <h4 className="mb-0">{t('library.Library')}</h4>

                <div>
                  {downloadData.download === true && (
                    <button
                      className="btn mainbtnColor me-4 align-items-center"
                      onClick={() => {
                        downloadFile();
                      }}
                    >
                      {t('library.Download')}
                    </button>
                  )}

                  {organization?.userPermissions.includes('manage:library') &&
                    downloadData.download === true && (
                      <button
                        className="btn mainbtnColor me-4 align-items-center"
                        onClick={() => {
                          setrenameModelShow(true);
                        }}
                      >
                        {t('library.Rename')}
                      </button>
                    )}

                  {organization?.userPermissions.includes('manage:library') &&
                    downloadData.download === true && (
                      <button
                        className="btn mainbtnColor me-4 align-items-center"
                        onClick={() => {
                          fileMoveToBin(
                            downloadData.folderpath,
                            downloadData.filename,
                            downloadData.bucketname
                          );
                        }}
                      >
                        {t('library.MoveToBin')}
                      </button>
                    )}

                  {organization?.userPermission.includes('manage:library') &&
                    downloadData.download === true && (
                      <button
                        className="btn mainbtnColor me-4 align-items-center"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      >
                        {t('library.Delete')}
                      </button>
                    )}

                  <button
                    className="btn mainbtnColor me-4 align-items-center"
                    ref={anchorRef}
                    aria-controls={openMenu ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <AddIcon
                      className="me-2"
                      style={{
                        marginTop: '-6px',
                      }}
                    />

                    {t('library.Add')}
                  </button>
                </div>
              </div>

              <div className="TabStart">
                {fileLoader && (
                  <div className="mb-3">
                    <ApiProgressBar
                      progress={progress}
                      setProgress={setProgress}
                      visibleLoader2={fileLoader}
                    />
                  </div>
                )}

                <div className="tabSettings">
                  <StyledDiv>
                    <StyledTabs
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                    >
                      <Tab
                        label={t('library.Files')}
                        icon={
                          <>
                            <DescriptionOutlinedIcon className="me-2" />
                          </>
                        }
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={t('library.Folders')}
                        icon={
                          <>
                            <i
                              className="far fa-folder-open me-2"
                              style={{
                                fontSize: '19px',
                              }}
                            ></i>
                          </>
                        }
                        {...a11yProps(1)}
                      />
                    </StyledTabs>

                    <TabPanel value={value} index={0}>
                      <Files
                        refresh={refresh}
                        setrefresh={setrefresh}
                        searchText={searchText}
                      />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                      <Folders
                        refresh={refresh}
                        setrefresh={setrefresh}
                        searchText={searchText}
                      />
                    </TabPanel>
                  </StyledDiv>
                </div>
              </div>
            </div>
          </div>
        )}

        <Popper
          open={openMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={openMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {organization?.userPermission.includes(
                      'manage:library'
                    ) && (
                      <MenuItem>
                        <div
                          className="onRelativefileupload"
                          style={{ color: '#157aff' }}
                        >
                          <input
                            type="file"
                            onChange={(e) => {
                              uploadFile(e);
                            }}
                          />
                          <PublishIcon className="mr-2" />{' '}
                          {t('library.UploadFile')}
                        </div>
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Loader isVisible={loaderVisible} />

        <RenameFile
          show={renameModelShow}
          onHide={() => {
            setrenameModelShow(false);
          }}
          setvisibleLoader={setloaderVisible}
          setrefresh={setrefresh}
          data={{
            folderpath: downloadData.folderpath,
            filename: downloadData.filename,
            bucketname: downloadData.bucketname,
          }}
        />
        <ConfirmationDialog
          isOpen={modalShow}
          onClose={() => {
            setModalShow(false);
          }}
          i18nKey="library.DeleteFileMsg"
          entityName={downloadData.filename}
          onConfirm={deleteLibrary}
        />
      </div>
    </div>
  );
};

export default Library;
