import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../assets/scss/survey.scss'
import { styled } from '@mui/system'
import SurveyCreatorDialog, {
  SurveyCreatorDialogType,
} from 'View/survey/SurveyCreatorDialog'
import { AppBar, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/material'
import Surveys from './Surveys'
import Templates from './Templates'
import { JSONValue } from 'common/types/Json'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import useApi from 'api/UseApi'
import { toast } from 'react-toastify'
import Loader from 'Component/Loader'
import { useDispatch } from 'react-redux'
import { surveyCreated, templateCreated } from 'Actions/Survey'
import { a11yProps, TabPanel } from './TabPanel/TabPanel'
import { useHistory } from 'react-router-dom'

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  padding: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 0.5rem',
  },
}))

const SurveySection = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isSurveyCreatorDialogOpen, setIsSurveyCreatorDialogOpen] =
    useState(false)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const [creatorDialogType, setCreatorDialogType] =
    useState<SurveyCreatorDialogType>('survey')
  const organization = useAppSelector(selectCurrentOrganization)
  const api = useApi(false)
  const dispatch = useDispatch()

  const onTabChanged = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex)
  }

  const onCreateSurveyButtonClicked = () => {
    setIsSurveyCreatorDialogOpen(true)
    setCreatorDialogType('survey')
  }

  const onCreateTemplateButtonClicked = () => {
    setIsSurveyCreatorDialogOpen(true)
    setCreatorDialogType('template')
  }

  const onSurveyCreatorDialogClose = () => {
    setIsSurveyCreatorDialogOpen(false)
  }

  const onSurveyCreatorDialogSave = (
    title: string,
    description: string,
    redirectUrl: string | null,
    body: JSONValue,
    isMarkdownEnabled: boolean
  ) => {
    if (creatorDialogType === 'survey') {
      createSurvey(title, description, redirectUrl, body, isMarkdownEnabled)
    } else if (creatorDialogType === 'template') {
      createTemplate(title, description, redirectUrl, body, isMarkdownEnabled)
    }
  }

  const createSurvey = (
    title: string,
    description: string,
    redirectUrl: string | null,
    body: JSONValue,
    isMarkdownEnabled: boolean
  ) => {
    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/surveys`, {
          name: title,
          description: description,
          redirect_url: redirectUrl,
          body: body,
          is_markdown_enabled: isMarkdownEnabled,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)
            dispatch(surveyCreated())
          },
          (error) => {
            console.log(error)
            setIsLoaderVisible(false)
            toast.error(error.message, { theme: 'colored' })
          }
        )
    } catch (e) {
      console.log(`Error creating survey: ${e}`)
    }
  }

  const createTemplate = (
    name: string,
    description: string,
    redirectUrl: string | null,
    body: JSONValue,
    isMarkdownEnabled: boolean
  ) => {
    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/templates`, {
          name: name,
          description: description,
          redirect_url: redirectUrl,
          body: body,
          is_markdown_enabled: isMarkdownEnabled,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)
            dispatch(templateCreated())
          },
          (error) => {
            setIsLoaderVisible(false)
            console.log(error)
          }
        )
    } catch (e) {
      console.log(`Error creating template: ${e}`)
    }
  }

  return (
    <>
      <Box className="">
        <Box className="">
          <div className="survey-title-container flex-wrap d-flex justify-content-between">
            <div>
              <h4>{t('survey.survey')} </h4>
            </div>
            <div className="survey-buttons">
              <button
                className="btn mainbtnColor me-4 mb-1 align-items-center"
                onClick={(event) => history.push(`/Survey/create`)}
              >
                <i className="fas fa-plus"></i>&nbsp;&nbsp;
                {t('survey.create-survey')}
              </button>
              <button
                className="btn mainbtnColor me-4 mb-1 align-items-center"
                onClick={(event) => history.push(`/Template/create`)}
              >
                <i className="fas fa-plus"></i>&nbsp;&nbsp;
                {t('survey.create-template')}
              </button>
            </div>
          </div>
          {/*           <SurveyCreatorDialog
            type={creatorDialogType}
            isOpen={isSurveyCreatorDialogOpen}
            onClose={() => {
              onSurveyCreatorDialogClose()
            }}
            onSave={(survey) => {
              onSurveyCreatorDialogSave(
                survey.title,
                survey.description,
                survey.redirectUrl,
                survey.json,
                survey.isMarkdownEnabled
              )
            }}
          />
 */}{' '}
          <div className="survey-content-container">
            <div>
              <StyledDiv className={'mb-4'}>
                <AppBar position="static">
                  <Tabs
                    value={tabIndex}
                    onChange={onTabChanged}
                    aria-label="Survey section tabs"
                    TabIndicatorProps={{
                      color: 'none',
                    }}
                  >
                    <Tab label={t('survey.survey')} {...a11yProps(0)} />
                    <Tab label={t('survey.template')} {...a11yProps(1)} />
                  </Tabs>
                </AppBar>

                <TabPanel value={tabIndex} index={0}>
                  <Surveys />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <Templates />
                </TabPanel>
              </StyledDiv>
            </div>
          </div>
        </Box>
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </>
  )
}

export default SurveySection
