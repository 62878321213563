export type Project = {
  uuid: string
  title: string
  description: string
  duration_hours: null | number
  start_date: null | string
  end_date: null | string
  notice: Notice
  cup: null | string
  funder_project_id: null | string
  min_attendance_pct: null | number
  attendance_pct: null | number
  min_participants_pct_with_min_attendance: null | number
  activities: Activity[]
  companies: Company[]
  status: null | ProjectStatus
}

export type Notice = {
  id: number
  uuid: string
  title: string
  notice_code: string
  valid_participant_min_attended_hours_pct: number
  valid_course_min_valid_participants: number
  funding_channel: FundingChannel
}

export type FundingChannel = {
  id: number
  uuid: string
  name: string
  funder: FunderWithChannels
}

export type Activity = {
  uuid: string
  title: string
  description: string
  funder_activity_id: null | string
  course: ActivityCourse | null
  projects: Project[]
  companies: Company[]
}

export type EmployeeActivity = {
  uuid: string
  title: string
  description: string
  funder_activity_id: null | string
  course: ActivityCourse | null
  companies: Company[]
}

export enum ProjectStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}
export type ActivityCourse = {
  id: number
  title: string
}

export type ActivityParticipant = {
  id: number
  uuid: string
  name: string
  surname: string
  fiscal_code: string
  hourly_cost: null | number
  company: Company
  activity: Activity
}

export type FunderWithChannels = {
  uuid: string
  name: string
  funding_channels: FundingChannel[]
}

export type Employee = {
  id?: string
  uuid: string
  name: string
  surname: string
  fiscal_code: string
  activities: EmployeeActivity[]
}

export type Company = {
  id: number
  uuid: string
  name: string
  fiscal_code: string
  vat_number: string
  projects: Project[]
}
