import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'
import { Company } from 'Component/FundedTraining/types'

const CustomAutocomplete = styled(Autocomplete)({
  '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
    '&:hover': {
      borderColor: '#73b0ff',
    },
  },

  '.MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: 0.3,
    fontSize: 16,
    '&::placeholder': {
      color: '#157aff66',
      opacity: 1,
    },
  },

  '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
  },

  '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
  },
})

type Props = {
  selectedCompany: null | Company
  allCompanies: Company[]
  onCompanyUpdated: (newCompany: null | Company) => void
}

export default function CompanyAutoComplete(props: Props) {
  const { selectedCompany, allCompanies, onCompanyUpdated } = props
  const { t } = useTranslation()

  return (
    <>
      <Stack spacing={3} sx={{ maxWidth: '30rem' }}>
        <CustomAutocomplete
          id="companies-outlined"
          options={allCompanies.sort((a, b) => {
            return -b.name.localeCompare(a.name)
          })}
          getOptionLabel={(option: any) => {
            return option.name
          }}
          isOptionEqualToValue={(company: any, value: any) =>
            company.name === value.name
          }
          defaultValue={null}
          value={selectedCompany}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ backgroundColor: 'white' }}
              placeholder={t(
                'funded-training.project-detail.companies-field-placeholder'
              )}
            />
          )}
          onChange={(event, newCompany: any) => {
            onCompanyUpdated(newCompany)
          }}
        />
      </Stack>
    </>
  )
}
