import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'
import { TableSortLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import SubscriberTagsPopup from './SubscriberTagsPopup'
import { Subscriber } from 'Component/Mail/NotificationServiceTypes'
import SearchIcon from '@mui/icons-material/Search'

type Order = 'asc' | 'desc'

interface HeadCell {
  id: keyof Subscriber
  label: string
}

interface TableUsersProps {
  subscribers: Subscriber[]
  onTagsUpdated: () => void
}

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#0d6efd',
    fontSize: 16,
  },
  '.MuiTableSortLabel-root.MuiTableSortLabel-active': {
    color: '#0d6efd',
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: '#5e5e5e',
    fontSize: 15,
  },
}))

export const SubscribersTable = (props: TableUsersProps) => {
  const { subscribers, onTagsUpdated } = props

  const { t } = useTranslation()

  const [headCells, setHeadCells] = useState<HeadCell[]>([])
  const [filteredSubscribers, setFilteredSubscribers] = useState<Subscriber[]>(
    []
  )
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Subscriber>('firstName')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [searchText, setSearchText] = useState('')

  function stringComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => stringComparator(a, b, orderBy)
      : (a: any, b: any) => -stringComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Subscriber
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const createSortHandler =
    (property: keyof Subscriber) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setHeadCells([
      {
        id: 'firstName',
        label: t('settings.FirstName'),
      },
      {
        id: 'lastName',
        label: t('settings.LastName'),
      },
      {
        id: 'email',
        label: t('settings.Emailid'),
      },
      {
        id: 'tags',
        label: t('mail.tags'),
      },
    ])
  }, [t])

  useEffect(() => {
    const cleanInputSearchText = searchText.toLowerCase().trim()
    let filteredSubs: Subscriber[] = subscribers

    if (cleanInputSearchText) {
      filteredSubs = subscribers.filter((subscriber) => {
        const matchesFirstName =
          subscriber.firstName &&
          subscriber.firstName.toLowerCase().includes(cleanInputSearchText)

        const matchesLastName =
          subscriber.lastName &&
          subscriber.lastName.toLowerCase().includes(cleanInputSearchText)

        const matchesEmail =
          subscriber.email &&
          subscriber.email.toLowerCase().includes(cleanInputSearchText)

        return matchesFirstName || matchesLastName || matchesEmail
      })
    }
    setFilteredSubscribers(filteredSubs)
    setPage(0)
  }, [searchText, subscribers])

  return (
    <>
      <Box
        component="form"
        sx={{
          mb: 2,
          width: '25ch',
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="search-subscriber-input"
          placeholder={t('common.search')}
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          inputProps={{
            sx: {
              p: 1,
            },
          }}
          InputProps={{
            sx: { backgroundColor: '#fefefe' },

            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 800, tableLayout: 'auto' }}
          aria-label="Subscribers table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <StyledTableHeadCell
                  key={headCell.id}
                  padding={'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredSubscribers, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((subscriber, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index}>
                    <StyledTableCell>{subscriber.firstName}</StyledTableCell>
                    <StyledTableCell>{subscriber.lastName}</StyledTableCell>
                    <StyledTableCell>{subscriber.email}</StyledTableCell>
                    <StyledTableCell align="left" sx={{ pl: 2 }}>
                      <SubscriberTagsPopup
                        subscriberId={subscriber.uuid}
                        subscriberTags={subscriber.tags}
                        onTagsUpdated={() => {
                          onTagsUpdated()
                        }}
                      />
                    </StyledTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredSubscribers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}
