import { memo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { FormHelperText } from '@mui/material'

export type FormInputFieldType = 'text' | 'email' | 'number' | 'date'

type Props = {
  name: string
  label: string
  type: FormInputFieldType
  defaultValue?: string
  className?: string
  required?: boolean
}

/**
 * React-hook-form wrapped component to be included in a FormProvider.
 *
 * Used to represent text or email input.
 */
const FormInputField = memo((props: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => (
        <div {...field} className={props.className || ''}>
          <label className="form-label">
            {props.label}
            {props.required && <span className="text-danger">*</span>}
          </label>

          <input type={props.type} name={props.name} className="form-control" />
          <FormHelperText error id="form-text-field-error">
            {(errors[props.name]?.message as string) || <br />}
          </FormHelperText>
        </div>
      )}
    />
  )
})

export default FormInputField
