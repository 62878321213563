//react-hook-form
import { Controller } from 'react-hook-form'
//mui
import { Autocomplete, TextFieldProps } from '@mui/material'
import {
  StyledOptionAutocomplete,
  StyledTextField,
} from 'common/styled-component/new-template/StyledTextField'
//util
import { getTimezoneOptions } from 'common/DateTimeUtils'

// ----------------------------------------------------------------------

type RHFAutocompleteProp = TextFieldProps & {
  name: string
  label: string
  control: any
}

export default function RHFAutocomplete({
  name,
  label,
  helperText,
  control,
  disabled,
  ...other
}: RHFAutocompleteProp) {
  const timezoneOptions = getTimezoneOptions()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <Autocomplete
          {...field}
          disabled={disabled}
          options={timezoneOptions}
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, option) => {
            onChange(option)
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledOptionAutocomplete sx={{ color: 'black' }}>
                {option.label}
              </StyledOptionAutocomplete>
            </li>
          )}
          renderInput={(params) => (
            <StyledTextField
              type="text"
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...other}
              {...params}
              sx={{
                input: {
                  '&::placeholder': {
                    color: 'rgb(145 158 171)',
                    opacity: 1,
                  },
                  color: 'rgb(33, 43, 54) !important', //remove important
                },
              }}
            />
          )}
        />
      )}
    />
  )
}
