import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import '../assets/scss/deletePopup.scss'
import { Trans } from 'react-i18next'

/**
 * i18nKey: Key of confirmation message in translation.json.
 * entityName: Name of the entity(user, session etc) to which the action is applied.
 *
 * If the confirmation message contains a variable,
 * entityName will be interpolated into it.
 */
type props = {
  isOpen: boolean
  i18nKey: string
  entityName?: string
  onClose(): void
  onConfirm(): void
}

const ConfirmationDialog = ({
  i18nKey,
  entityName,
  onClose,
  isOpen,
  onConfirm,
}: props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="confirmation-dialog"
      disableEnforceFocus
    >
      <DialogContent style={{ padding: '2rem' }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontSize: 'larger' }}
        >
          {entityName ? (
            <Trans i18nKey={i18nKey}>
              {'placeholder '}
              <strong className="item-span">
                <>{{ entityName }}</>
              </strong>
              {' placeholder'}
            </Trans>
          ) : (
            t(i18nKey)
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onClose()
          }}
          className="no-button"
        >
          {t('common.no')}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            onConfirm()
            onClose()
          }}
          className="yes-button"
        >
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
