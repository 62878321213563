import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface FunderState {
  shouldFetchFunder: boolean
  shouldPreselectFundingChannelsTab: boolean
}

const initialState: FunderState = {
  shouldFetchFunder: true,
  shouldPreselectFundingChannelsTab: false,
}

export const FunderSlice = createSlice({
  name: 'funder',
  initialState,
  reducers: {
    funderFetched: (state) => {
      state.shouldFetchFunder = false
    },
    setShouldPreselectFundingChannelsTab: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shouldPreselectFundingChannelsTab = action.payload
    },
  },
})

// SELECTORS
export const selectShouldFetchProject = (state: RootState) =>
  state.Funder.shouldFetchFunder

export const selectShouldPreselectFundingChannelsTab = (state: RootState) =>
  state.Funder.shouldPreselectFundingChannelsTab

export const { funderFetched, setShouldPreselectFundingChannelsTab } =
  FunderSlice.actions

export default FunderSlice.reducer
