import { Box, Typography } from '@mui/material'
import { StyledButton } from 'common/styled-component/StyledButton'
import { PDFDocument, StandardFonts, rgb, PDFFont } from 'pdf-lib'
import { saveAs } from 'file-saver'
import baseCertificate from '../../assets/document/certificate.pdf'

export function EditPDF() {
  function getXCoordinateToCenterTextInPage(
    pageWidth: number,
    text: string,
    font: PDFFont,
    fontSize: number
  ) {
    const textWidth = font.widthOfTextAtSize(text, fontSize)
    const xCoordinate = (pageWidth - textWidth) / 2
    return xCoordinate
  }

  function getAdjustedFontSize(
    pageWidth: number,
    text: string,
    font: PDFFont,
    fontSize: number
  ) {
    const textWidth = font.widthOfTextAtSize(text, fontSize)
    const maxSize = pageWidth * 0.9

    if (textWidth > maxSize) {
      const adjustedFontSize = (fontSize * maxSize) / textWidth
      return adjustedFontSize
    }
    return fontSize
  }

  async function createPdf() {
    const pdfDoc = await PDFDocument.create()
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    const page = pdfDoc.addPage([750, 550])
    const { width, height } = page.getSize()
    const fontSize = 30

    const text = 'Wyblo Certificate of Attendance'
    const xCoordinate = getXCoordinateToCenterTextInPage(
      750,
      text,
      timesRomanFont,
      fontSize
    )

    page.drawText(text, {
      x: xCoordinate,
      y: height - 4 * fontSize,
      size: fontSize,
      font: timesRomanFont,
      color: rgb(0, 0.53, 0.71),
    })

    const pdfBytes = await pdfDoc.save()

    var blob = new Blob([pdfBytes], { type: 'pdf' })
    saveAs(blob, 'base-certificate-' + Date.now() + '.pdf')
  }

  async function modifyPdf(text: string) {
    const url = baseCertificate
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { width, height } = firstPage.getSize()

    const adjustedFontSize = getAdjustedFontSize(750, text, helveticaFont, 30)

    const xCoordinate = getXCoordinateToCenterTextInPage(
      750,
      text,
      helveticaFont,
      adjustedFontSize
    )

    firstPage.drawText(text, {
      x: xCoordinate,
      y: height / 2,
      size: adjustedFontSize,
      font: helveticaFont,
      color: rgb(0.95, 0.1, 0.1),
    })

    const pdfBytes = await pdfDoc.save()

    var blob = new Blob([pdfBytes], { type: 'pdf' })
    saveAs(blob, 'certificate-' + Date.now() + '.pdf')
  }

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography sx={{ mb: 5 }} variant="h5">
          {'EDIT PDF'}
        </Typography>

        <StyledButton
          type="button"
          onClick={() => {
            createPdf()
          }}
          sx={{
            backgroundColor: '#0d6efd !important',
            mr: 5,
          }}
        >
          <Typography sx={{ mt: 0.5 }}>{'Base Template'}</Typography>
        </StyledButton>

        <StyledButton
          type="button"
          onClick={() => {
            const text = 'Kvicha Kvaratskhelia'
            modifyPdf(text)
          }}
          sx={{
            backgroundColor: '#218a4d !important',
            mr: 5,
          }}
        >
          <Typography sx={{ mt: 0.5 }}>{'Certificate'}</Typography>
        </StyledButton>

        <StyledButton
          type="button"
          onClick={() => {
            const longText =
              'KvichaKvaratskheliaKvicha KvaratskheliaKvichaKvaratskhelia KvichaKvaratskheliaKvichaKvaratskhelia'
            modifyPdf(longText)
          }}
          sx={{
            backgroundColor: '#218a4d !important',
          }}
        >
          <Typography sx={{ mt: 0.5 }}>
            {'Certificate(long participant name)'}
          </Typography>
        </StyledButton>
      </Box>
    </Box>
  )
}
