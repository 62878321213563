import { TablePagination } from '@mui/material'

type props = {
  numberOfItems: number
  pageNo: number
  rowsPerPage: number
  onPageChanged(event: any, pageNo: number): void
  onRowsPerPageChanged(event: any): void
}

const TableViewPagination = ({
  numberOfItems,
  pageNo,
  rowsPerPage,
  onPageChanged,
  onRowsPerPageChanged,
}: props) => {
  return (
    <>
      {numberOfItems > 0 && (
        <>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={numberOfItems}
            rowsPerPage={rowsPerPage}
            page={pageNo}
            onPageChange={onPageChanged}
            onRowsPerPageChange={onRowsPerPageChanged}
            className="tablePagination"
          />
        </>
      )}
    </>
  )
}
export default TableViewPagination
