import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TemplateForm } from './TemplateForm'

export function NewTemplate() {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20, mr: 5 }} className="">
      <Stack className="">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('mail.new-template')}
        </Typography>

        <TemplateForm isNewTemplate={true} />
      </Stack>
    </Box>
  )
}
