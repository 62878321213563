import { styled } from '@mui/system'
import { Select } from '@mui/material'

export const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-input': {
    border: '1px solid #73b0ff',
    padding: '0.42rem 0.75rem',
    backgroundColor: 'rgba(21, 122, 255, 0.1) !important',
  },

  '& .MuiOutlinedInput-input.Mui-disabled': {
    border: '1px solid #e9ecef',
    backgroundColor: '#e9ecef !important',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})
