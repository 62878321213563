import React from 'react';
import { styled } from '@mui/system';

import { AppBar, Tab, Tabs } from '@mui/material';
import Completed from '../View/Feedback/Completed';
import Pending from '../View/Feedback/Pending';
import Upcoming from '../View/Feedback/Upcoming';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { a11yProps, TabPanel } from './TabPanel/TabPanel';

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: 'white',
}));

const Feedback = () => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="">
        <div className="Feedback sidebar_padding">
          <div className="liabrary">
            <div className="search_addCourse">
              <div className="inputSearch">
                <SearchIcon style={{ color: '#DEDEDE' }} />
                <div className="mx-2 inputWidth">
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t('courses.Search')}
                  />
                </div>
              </div>

              <button className="btn addcoursebtn">
                <AddIcon />
                <span className="ms-3">{t('courses.AddCourse')}</span>
              </button>
            </div>

            <div className="upTable">
              <div className="tableHeadFlex mb-2">
                <h4 className="mb-0">{t('feedback.Feedback')}</h4>
              </div>

              <div className="feedBack_container">
                <StyledDiv>
                  <AppBar position="static">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab
                        label={t('feedback.Pending')}
                        {...a11yProps(0)}
                        className="tabs"
                      />
                      <Tab
                        label={t('feedback.Completed')}
                        {...a11yProps(1)}
                        className="tabs"
                      />
                      <Tab
                        label={t('feedback.Upcoming')}
                        {...a11yProps(2)}
                        className="tabs"
                      />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <Pending />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Completed />
                  </TabPanel>

                  <TabPanel value={value} index={2}>
                    <Upcoming />
                  </TabPanel>
                </StyledDiv>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
