const GetfilterRegistrants = (data) => {
  return {
    type: 'GET_FILTER_REGISTRANTS',
    payload: data,
  };
};

const clearfilterRegistrants = (data) => {
  return {
    type: 'CLEAR_FILTER_REGISTRANTS',
    payload: data,
  };
};

export { GetfilterRegistrants, clearfilterRegistrants };
