import { default as ReactLoaderSpinner } from 'react-loader-spinner'
import { styled } from '@mui/system'

type Props = {
  isVisible: boolean
  zIndex?: number
  padding?: string
}

export enum PaddingLoader {
  SIDEBAR = '180px',
  NONE = 'none',
}

const Loader = ({
  zIndex = 9999,
  isVisible,
  padding = PaddingLoader.SIDEBAR,
}: Props) => {
  const StyledDiv = styled('div')(({ theme }) => ({
    position: 'fixed',
    zIndex: zIndex,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'none',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: padding,
    },
  }))

  return (
    <StyledDiv>
      <ReactLoaderSpinner
        type="Oval"
        color="#2477bc"
        height={60}
        width={60}
        visible={isVisible}
      />
    </StyledDiv>
  )
}

export default Loader
