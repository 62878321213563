import {
  Box,
  Card,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import {
  Course,
  Meeting,
  MeetingDateTime,
} from 'Component/CourseRegistration/Models'
import Loader from 'Component/Loader'
import SessionDatesListTable from 'Component/SessionDatesListTable/SessionDatesListTable'
import useTable from 'Component/Table/useTable'
import { selectLoader } from 'Reducers/LoaderReducer'
import CourseService from 'Service/CourseService'
import { useAppSelector } from 'Store'
import useApi from 'api/UseApi'
import { StyledButton } from 'common/styled-component/new-template/StyledButton'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  convertDateTimePickerModelToDateTimeStringOptional,
  convertDateTimeStringToDateTimePickerModel,
} from 'utils/type-conversions/datetime'
import { getComparator } from 'utils/utils'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'

function SessionDatesList() {
  const [tableData, setTableData] = useState<MeetingDateTime[]>([])
  const [serverData, setServerData] = useState<Meeting[]>([])
  const [course, setCourse] = useState<Course>()
  const [filterName, setFilterName] = useState('')

  const table = useTable({ defaultRowsPerPage: 10 })
  const api = useApi(false)
  const isloading = useAppSelector(selectLoader)
  const { courseId } = useParams<{ courseId: string }>()
  const { t } = useTranslation()
  const history = useHistory()

  const { postSessionDates } = CourseService()

  const getCourse = async (id: string) => {
    try {
      const response = await api.get(`courses/${courseId}`)
      return response.data.courses
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const dataFiltered = applyFilter({
    filterName: filterName,
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  })

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    table.setPage(0)
    setFilterName(event.target.value)
  }

  const postSessionAttributes = () => {
    const filteredTableData = tableData.map((t) => {
      const data = {
        id: t.id,
        start_date: convertDateTimePickerModelToDateTimeStringOptional(
          t.start_date_time
        ),
        end_date: convertDateTimePickerModelToDateTimeStringOptional(
          t.end_date_time
        ),
      }
      return data
    })
    postSessionDates(filteredTableData, courseId)
    history.push(`/Courses/${courseId}`)
  }

  const getMeetings = async () => {
    const course: Course[] = await getCourse(courseId)
    const formatDateMeetings = course[0].meetings.map((m) => {
      const data: MeetingDateTime = {
        ...m,
        start_date_time: convertDateTimeStringToDateTimePickerModel(
          m.start_date
        ),
        end_date_time: convertDateTimeStringToDateTimePickerModel(m.end_date),
      }
      return data
    })
    setTableData(formatDateMeetings)
    setServerData(course[0].meetings)
    setCourse(course[0])
    console.log({ formatDateMeetings })
  }

  useEffect(() => {
    getMeetings()
  }, [])

  return isloading ? (
    <Loader isVisible={isloading}></Loader>
  ) : (
    <Container sx={{ mt: '5rem' }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: '700',
          lineHeight: '1.5',
          fontSize: '1.5rem',
          fontFamily: 'sans-serif',
          mb: '2rem',
          pl: '1rem',
        }}
      >
        {course?.title}
      </Typography>
      <Card sx={{ padding: '1rem', borderRadius: '1rem' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 0.5rem 1rem 0.5rem',
          }}
        >
          <TextField
            fullWidth
            sx={{
              maxWidth: '35%',
              '.MuiInputBase-root': {
                borderRadius: '0.5rem',
              },
              '.MuiOutlinedInput-input': {
                '&::placeholder': {
                  lineHeight: '1.71429',
                  fontSize: '0.875rem',
                  fontFamily: 'sans-serif',
                  fontWeight: '400',
                },
              },
            }}
            value={filterName}
            onChange={handleFilterName}
            placeholder={`${t('common.search')}...`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} /> */}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            type="submit"
            variant="contained"
            onClick={postSessionAttributes}
            //loading={isSubmitting}
            //disabled={isSubmitting || objectIsEmpty(errors)}
          >
            {t('common.save-all')}
          </StyledButton>
        </Box>
        <SessionDatesListTable
          table={table}
          tableData={tableData}
          setTableData={setTableData}
          dataFiltered={dataFiltered}
          onDeleteRow={() => {}}
          isNotFound={false}
        />
      </Card>
    </Container>
  )
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
}: {
  inputData: MeetingDateTime[]
  comparator: (a: any, b: any) => number
  filterName: string
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const)

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map((el) => el[0])

  if (filterName) {
    inputData = inputData.filter(
      (meeting) =>
        meeting.sessionname.toLowerCase().indexOf(filterName.toLowerCase()) !==
        -1
    )
  }

  return inputData
}

export default SessionDatesList
