import React, { useState, useEffect } from 'react';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import Loader from 'Component/Loader';
import { useDispatch } from 'react-redux';
import { DownloadFile } from '../../Actions/Downloadfile';
import { uploadFile } from '../../Actions/UploadFile';
import { clearDownloadFile } from '../../Actions/Downloadfile';
import { useTranslation } from 'react-i18next';
import useApi from '../../api/UseApi';
import Folders from './Folders';
import { FILES } from 'common/LocalStorageKeys';
const Files = ({ refresh, setrefresh, searchText }) => {
  const [filterFiles, setfilterFiles] = useState([]);
  const [files, setfiles] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const api = useApi();

  const getAllFiles = async () => {
    setIsLoaderVisible(true);

    try {
      api.get(`S3/GetFiles`).then(
        (response) => {
          response.data.data.forEach((element) => {
            element.color = false;
          });
          response.data.data.forEach((element) => {
            try {
              element.ext = element.filename.split('.')[1];
            } catch (error) {
              console.log(error);
            }
          });
          setfilterFiles(response.data.data);
          setfiles(response.data.data);
          window.localStorage.setItem(
            FILES,
            JSON.stringify(response.data.data)
          );
          dispatch(uploadFile(''));
          dispatch(clearDownloadFile());
          setrefresh(false);
          setIsLoaderVisible(false);
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
          window.localStorage.setItem(FILES, JSON.stringify(''));
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getAllFiles();
  }, [refresh]);

  const getFilterIndex = (index) => {
    filterFiles.forEach((element) => {
      element.color = false;
    });

    filterFiles[index].color = true;
    setfiles(() => {
      return [...filterFiles];
    });
  };

  useEffect(() => {
    const lowercasedValue = searchText.toLowerCase().trim();
    if (lowercasedValue === '') {
      setfiles(filterFiles);
    } else {
      const filteredData = filterFiles.filter((item) => {
        return (
          item.filename && item.filename.toLowerCase().includes(lowercasedValue)
        );
      });
      setfiles(filteredData);
    }
  }, [searchText]);

  return (
    <div className="files">
      <div className="subFiles">
        <h5 className="mb-4">
          <QueryBuilderOutlinedIcon /> {t('library.AllFiles')}
        </h5>

        <Folders
          refresh={refresh}
          setrefresh={setrefresh}
          searchText={searchText}
        />
        <hr />
        <div className="row m-0">
          {files.map(
            (data, index) =>
              data.filename !== '' &&
              data.filename !== null && (
                <div className="col-md-3 mb-3" key={index}>
                  <div
                    className="card"
                    style={{
                      cursor: 'pointer',
                      background: data.color === false ? '#fff' : '#80808045',
                    }}
                    onClick={() => {
                      dispatch(
                        DownloadFile({
                          download: true,
                          folderpath: data.folderName,
                          filename: data.filename,
                          bucketname: data.bucketname,
                        })
                      );
                      getFilterIndex(index);
                    }}
                  >
                    <div className="card-body">
                      <h2>{data.ext}</h2>
                    </div>

                    <div
                      className="card-footer"
                      style={{
                        minHeight: '43px',
                      }}
                    >
                      <p className="mb-0">{data.filename}</p>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      <Loader isVisible={isLoaderVisible} />
    </div>
  );
};

export default Files;
