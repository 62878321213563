import React, { useEffect, useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CustomDialog from './CustomDialog';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Loader from 'Component/Loader';
import { useTranslation } from 'react-i18next';
import useApi from '../../../api/UseApi';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';

const InviteUserPopup = ({ onUserCreated }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [roles, setRoles] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = React.useState(false);

  const organization = useAppSelector(selectCurrentOrganization);
  const { t } = useTranslation();

  const api = useApi(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    try {
      api.get(`roles`).then(
        (response) => {
          const rolesToShow = response.data.roles.filter(
            (role) => role.name !== 'Owner'
          );

          setRoles(rolesToShow);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }, [api]);

  const onSubmit = async (data, e) => {
    setIsLoaderVisible(true);

    try {
      api
        .post(`users`, {
          first_name: data.Name,
          last_name: data.Surname,
          email: data.Email,
          auth0roleid: data.role,
          organization_id: organization?.id,
        })
        .then(
          (response) => {
            setIsLoaderVisible(false);
            setIsOpen(false);
            toast.success(response.data.message, { theme: 'colored' });
            onUserCreated();
            reset();
          },
          (error) => {
            console.log(error.response);
            toast.error(error.response.data.message, { theme: 'colored' });
            setIsLoaderVisible(false);
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="InviteUserPopup">
      <button
        className="btn btn-primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <PersonAddIcon /> {t('settings.Invite')}
      </button>

      <CustomDialog
        model={isOpen}
        close={() => {
          setIsOpen(false);
        }}
        title={t('settings.inviteNewMembers')}
      >
        <div className="negotiationModel">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex pb-2">
                <div className="w-100">
                  <div className="copylink">
                    <input
                      placeholder={t('settings.EnterName')}
                      className="addCoursesInput form-control"
                      autoComplete="none"
                      type="text"
                      style={{
                        height: '44px',
                      }}
                      {...register('Name', { required: true })}
                    />
                  </div>
                  <small style={{ color: 'red' }}>
                    {errors.Name && t('settings.Thisfieldisrequired')}
                  </small>
                </div>
                &nbsp;&nbsp;
                <div className="w-100">
                  <div className="copylink">
                    <input
                      placeholder={t('settings.EnterSurname')}
                      className="addCoursesInput form-control"
                      autoComplete="none"
                      type="text"
                      style={{
                        height: '44px',
                      }}
                      {...register('Surname', { required: true })}
                    />
                  </div>
                  <small style={{ color: 'red' }}>
                    {errors.Surname && t('settings.Thisfieldisrequired')}
                  </small>
                </div>
              </div>
              <div className="w-100 mb-2">
                <div className="copylink">
                  <input
                    placeholder={t('settings.EnterEmailaddress')}
                    className="addCoursesInput form-control"
                    autoComplete="none"
                    type="email"
                    style={{ height: '44px' }}
                    {...register('Email', { required: true })}
                  />
                </div>
                <small style={{ color: 'red' }}>
                  {errors.Email && t('settings.Thisfieldisrequired')}
                </small>
              </div>

              <div className="pb-3">
                <select
                  className="form-select"
                  {...register('role', { required: true })}
                >
                  <option value="">{t('common.user-type')}</option>
                  {roles.map((data, index) => (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <small style={{ color: 'red' }}>
                  {errors.role && t('settings.Thisfieldisrequired')}
                </small>
              </div>

              <div style={{ textAlign: 'center' }}>
                <button
                  disabled={isLoaderVisible}
                  type="submit"
                  className="btn"
                  style={{ background: '#157AFF', color: 'white' }}
                >
                  {t('settings.Invite')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </CustomDialog>

      <Loader isVisible={isLoaderVisible} />
    </div>
  );
};

export default InviteUserPopup;
