import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface ProjectState {
  shouldFetchProject: boolean
  shouldPreselectActivitiesTab: boolean
}

const initialState: ProjectState = {
  shouldFetchProject: true,
  shouldPreselectActivitiesTab: false,
}

export const ProjectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectFetched: (state) => {
      state.shouldFetchProject = false
    },
    setShouldPreselectActivitiesTab: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shouldPreselectActivitiesTab = action.payload
    },
    activityAdded: (state) => {
      state.shouldPreselectActivitiesTab = true
    },
    activityEdited: (state) => {
      state.shouldPreselectActivitiesTab = true
    },
  },
})

// SELECTORS
export const selectShouldFetchProject = (state: RootState) =>
  state.Project.shouldFetchProject

export const selectShouldPreselectActivitiesTab = (state: RootState) =>
  state.Project.shouldPreselectActivitiesTab

export const {
  projectFetched,
  setShouldPreselectActivitiesTab,
  activityAdded,
  activityEdited,
} = ProjectSlice.actions

export default ProjectSlice.reducer
