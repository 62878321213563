import { TableSortLabel } from '@mui/material'
import useWindowDimensions from 'common/hooks/useWindowDimensions'
import {
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
} from 'common/styled-component/StyledTable'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Breakpoints, Order } from './TableCourses'

interface TableCoursesHeaderProps {
  order: Order
  orderBy: string
  createSortHandler: (
    cellId: string
  ) => (event: React.MouseEvent<unknown>) => void
}

export type HeadCell = {
  id: string
  label: string
  sort?: boolean
  viewport: number
}

const TableCoursesHeader = ({
  order,
  orderBy,
  createSortHandler,
}: TableCoursesHeaderProps) => {
  const [headCells, setHeadCells] = useState<HeadCell[]>([])

  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  useEffect(() => {
    setHeadCells([
      {
        id: 'title',
        label: t('tableCourses.course-name'),
        sort: true,
        viewport: Breakpoints.XS,
      },
      {
        id: 'status',
        label: t('tableCourses.status'),
        sort: true,
        viewport: Breakpoints.XS,
      },
      {
        id: 'trainers',
        label: t('tableCourses.trainers'),
        viewport: Breakpoints.SM,
      },
      {
        id: 'course_begin_date',
        label: t('tableCourses.time'),
        sort: true,
        viewport: Breakpoints.SM,
      },
      {
        id: 'meetings',
        label: t('tableCourses.sessions'),
        sort: true,
        viewport: Breakpoints.XS,
      },
      {
        id: 'course_total_registrants',
        label: t('tableCourses.trainees'),
        sort: true,
        viewport: Breakpoints.LG,
      },
      {
        id: 'option',
        label: '',
        viewport: Breakpoints.XS,
      },
    ])
  }, [t])

  return (
    <StyledTableHead>
      <StyledTableHeadRow>
        {headCells.map((headCell) =>
          headCell.sort
            ? headCell.viewport <= width && (
                <StyledTableHeadCell
                  key={headCell.id}
                  padding={'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </StyledTableHeadCell>
              )
            : headCell.viewport <= width && (
                <StyledTableHeadCell key={headCell.id}>
                  {headCell.label}
                </StyledTableHeadCell>
              )
        )}
      </StyledTableHeadRow>
    </StyledTableHead>
  )
}

export default TableCoursesHeader
