import { Box } from '@mui/material'
import { Popover } from '@mui/material'
import { Button, Stack } from '@mui/material'
import DatePicker from 'Component/DatePicker'
import { useTranslation } from 'react-i18next'

interface FilterDialogProp {
  id: 'simple-popover' | undefined
  open: boolean
  anchorEl?: Element | ((element: Element) => Element) | null
  registrationDateFilter: any
  registrants: any
  handleClose: () => void
  filterParticipants: () => void
  setRegistrationDateFilter: (value: any) => void
  setFilteredRegistrants: (value: any) => void
}

const FilterDialog = ({
  id,
  open,
  anchorEl,
  registrationDateFilter,
  registrants,
  handleClose,
  filterParticipants,
  setRegistrationDateFilter,
  setFilteredRegistrants,
}: FilterDialogProp) => {
  const { t } = useTranslation()

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Stack sx={{ p: 2, mb: 2 }}>
        <div className="addCoursesDate">
          <label className="form-label">{t('courses.RegistrationDate')}</label>
          <Box sx={{ mb: 4 }}>
            <DatePicker
              value={registrationDateFilter}
              onDateChange={(newDate) => {
                setRegistrationDateFilter(newDate)
              }}
            />
          </Box>
        </div>

        <Button sx={{ mb: 2 }} variant="contained" onClick={filterParticipants}>
          {t('courses.Apply')}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setRegistrationDateFilter(null)
            setFilteredRegistrants(registrants)
          }}
        >
          {t('course-filter.clearFilter')}
        </Button>
      </Stack>
    </Popover>
  )
}

export default FilterDialog
