import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useTranslation } from 'react-i18next'

type props = {
  numberOfItems?: number
  pageNo: number
  rowsPerPage: number
  onPageChanged(pageNo: number): void
  onRowsPerPageChanged(rowsPerPage: number): void
}

const Pagination = ({
  numberOfItems,
  pageNo,
  rowsPerPage,
  onPageChanged,
  onRowsPerPageChanged,
}: props) => {
  const { t } = useTranslation()

  return (
    <>
      {!!numberOfItems && numberOfItems > 0 && (
        <div className="mainPagination">
          <div className="pagination">
            <p className="mb-0">{t('common.rows-per-page')}</p>
            <select
              onChange={(e) => {
                onRowsPerPageChanged(Number(e.target.value))
                onPageChanged(1)
              }}
              defaultValue="10"
              style={{ background: 'transparent', border: 0, color: '#b9b9b9' }}
            >
              <option value="100">100</option>
              <option value="50">50</option>
              <option value="25">25</option>
              <option value="10">10</option>
            </select>
            &nbsp;
            <p className="mb-0">
              {numberOfItems} {t('common.results')} - {t('common.page')}{' '}
              {t('common.page-n-of', {
                pageNo: pageNo,
                pageCount: Math.ceil(numberOfItems / rowsPerPage),
              })}
            </p>
            <div>
              <button
                className="btn p-0"
                disabled={pageNo === 1}
                onClick={() => {
                  if (pageNo > 1) {
                    onPageChanged(pageNo - 1)
                  }
                }}
              >
                <NavigateBeforeIcon />
              </button>
              &nbsp;
              <button
                className="btn p-0"
                disabled={pageNo === Math.ceil(numberOfItems / rowsPerPage)}
                id="next"
                onClick={() => {
                  if (pageNo <= Math.ceil(numberOfItems / rowsPerPage)) {
                    onPageChanged(pageNo + 1)
                  }
                }}
              >
                <NavigateNextIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Pagination
