import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/Group'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import InsightsIcon from '@mui/icons-material/Insights'
import { green, orange, yellow } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'
import NoticeIcon from '@mui/icons-material/Assignment'

export function FundedTraining() {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box>
      <Typography variant="h5">{t('common.funded-training')}</Typography>

      <Stack
        height="100%"
        hidden={true}
        direction="column"
        sx={{
          mt: 3,
          mb: 1,
          pt: 1,
          pb: 1,
          px: 2,
          background: '#d5f4e7',
          borderRadius: 3,
          height: '100%',
          width: 'fit-content',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            mt: 1,
            lineHeight: 2,
            color: '#407e7c',
            fontFamily: 'nexa',
            fontSize: 16,
          }}
        >
          {t('common.feature-under-development')}
          <br />
          <Link
            href={`mailto:${process.env.REACT_APP_WYBLO_EMAIL}`}
            rel="noreferrer"
            target="_blank"
          >
            {t('funded-training.info-banner.contact-us')}
          </Link>{' '}
          {t('funded-training.info-banner.for-more-info')}
        </Typography>
      </Stack>

      <Stack sx={{ pl: 0, mt: 2, maxWidth: 500 }} spacing={3}>
        <Button
          sx={{
            py: 1,
            width: '100%',
            color: yellow[900],
            borderColor: yellow[800],
            ':hover': {
              borderColor: yellow[900],
              backgroundColor: alpha(yellow[800], 0.1),
            },
          }}
          variant="outlined"
          startIcon={<InsightsIcon />}
          onClick={() => {
            history.push('funded-training/projects')
          }}
        >
          <Typography sx={{ mt: 0.5 }} variant="h6">
            {t('funded-training.projects')}
          </Typography>
        </Button>
        <Button
          sx={{
            py: 1,
            width: '100%',
          }}
          variant="outlined"
          startIcon={<NoticeIcon />}
          onClick={() => {
            history.push('funded-training/notices')
          }}
        >
          <Typography sx={{ mt: 0.5 }} variant="h6">
            {t('funded-training.notices')}
          </Typography>
        </Button>
        <Button
          sx={{
            py: 1,
            width: '100%',
          }}
          variant="outlined"
          startIcon={<EuroSymbolIcon />}
          onClick={() => {
            history.push('funded-training/funders')
          }}
        >
          <Typography sx={{ mt: 0.5 }} variant="h6">
            {t('funded-training.funders')}
          </Typography>
        </Button>
        <Button
          sx={{
            py: 1,
            width: '100%',
          }}
          variant="outlined"
          startIcon={<CorporateFareIcon />}
          onClick={() => {
            history.push('funded-training/companies')
          }}
        >
          <Typography sx={{ mt: 0.5 }} variant="h6">
            {t('funded-training.companies')}
          </Typography>
        </Button>
        <Button
          sx={{
            py: 1,
            width: '100%',
          }}
          variant="outlined"
          startIcon={<PeopleIcon />}
          onClick={() => {
            history.push('funded-training/employees')
          }}
        >
          <Typography sx={{ mt: 0.5 }} variant="h6">
            {t('funded-training.employees')}
          </Typography>
        </Button>
      </Stack>
    </Box>
  )
}
