import { useRef, useEffect } from 'react'
import axios from 'axios'

import { useAuth0 } from '@auth0/auth0-react'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import {
  getShouldUpdateAccessTokenOrg,
  SHOULD_UPDATE_ACCESS_TOKEN_ORG,
} from 'common/LocalStorageKeys'

const AUTH0_MANAGEMENT_API_ID = process.env.REACT_APP_AUTH0_MANAGEMENT_API_ID

const useApi = (isOldBackend = true, skipAuthentication = false) => {
  const { getAccessTokenSilently } = useAuth0()
  const currentOrganization = useAppSelector(selectCurrentOrganization)

  let baseURL = process.env.REACT_APP_API_BASE_URL + '/'

  if (isOldBackend) {
    baseURL += 'backend/api/'
  }

  const api = useRef(
    axios.create({
      baseURL: baseURL,
    })
  )
  useEffect(() => {
    const currentAPI = api.current

    const requestInterceptorId = currentAPI.interceptors.request.use(
      async (config) => {
        if (skipAuthentication) {
          return config
        }

        let accessToken: string | null = null

        if (!currentOrganization) {
          accessToken = await getAccessTokenSilently({
            audience: `https://${AUTH0_MANAGEMENT_API_ID}/api/v2/`,
            scope: 'read:current_user',
          })
        } else {
          let shouldIgnoreCache = getShouldUpdateAccessTokenOrg()

          if (shouldIgnoreCache) {
            window.localStorage.setItem(SHOULD_UPDATE_ACCESS_TOKEN_ORG, 'false')
          }

          accessToken = await getAccessTokenSilently({
            audience: `https://${AUTH0_MANAGEMENT_API_ID}/api/v2/`,
            scope: 'read:current_user',
            organization: currentOrganization.auth0OrgId,
            ignoreCache: shouldIgnoreCache,
          })
        }

        config.headers.authorization = `Bearer ${accessToken}`
        return config
      }
    )

    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId)
      //currentAPI.interceptors.response.eject(responseInterceptorId);
    }
  }, [currentOrganization, getAccessTokenSilently, skipAuthentication])
  return api.current
}

export default useApi
