import { TFunction } from 'i18next'

export const getSessionStatusText = (t: TFunction, status: string) => {
  if (status === 'Live') {
    return t('common.live')
  }
  if (status === 'Completed') {
    return t('common.completed')
  }
  if (status === 'Coming Next') {
    return t('common.coming-next')
  }
  if (status === 'Draft') {
    return t('common.draft')
  }

  return ''
}

const statusCss = {
  color: '#ed4c5c',
  backgroundColor: 'rgba(255, 80, 80, 0.3)',
  borderRadius: '3px',
  padding: '0.3rem 0.5rem',
  margin: 0,
  fontFamily: 'nexa',
  fontSize: '0.8rem',
}

// if start date > now 'rgb(169 206 255 / 54%)'

export const getSessionStatusStyle = (status: string) => {
  if (status === 'Live') {
    return { ...statusCss, color: '#f44336', backgroundColor: '#ffebee' }
  }
  if (status === 'Completed') {
    return { ...statusCss, color: '#43a047', backgroundColor: '#e8f5e9' }
  }
  if (status === 'Coming Next') {
    return { ...statusCss, color: '#157AFF', backgroundColor: '#e3f2fd' }
  }
  if (status === 'Draft') {
    return { ...statusCss, color: '#757575', backgroundColor: '#f5f5f5' }
  }

  return statusCss
}
