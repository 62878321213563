import React, { useState } from 'react';
import {
  Editor,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  EditorState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';

const DescriptionMarkupEditor = (props) => {
  const {
    light,
    editorState,
    sessionDescription: descriptionMarkup,
    readOnly,
    index,
    setEditorState,
    onSessionFieldChanged, //used to call the server
  } = props;

  //State just for session
  //I need to initialized the component inside the state
  const [descriptionMarkupEditorState, setDescriptionMarkupEditorState] =
    useState(() =>
      descriptionMarkup ? descriptionMarkup : EditorState.createEmpty()
    );

  const onChange = (value) => {
    if (setEditorState) {
      setEditorState(value);
    } else {
      setDescriptionMarkupEditorState(value);
      onSessionFieldChanged(
        'description_markup',
        convertToRaw(value.getCurrentContent()),
        index
      );
    }
  };

  //We can observe and handle key commands via the handleKeyCommand prop
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    const newSessionState = RichUtils.handleKeyCommand(
      descriptionMarkupEditorState,
      command
    );
    if (newState) {
      if (setEditorState) {
        setEditorState(newState);
      } else if (onSessionFieldChanged) {
        setDescriptionMarkupEditorState(newSessionState);
        onSessionFieldChanged(
          'description_markup',
          convertToRaw(newSessionState.getCurrentContent()),
          index
        );
      }
      return true;
    }
    return false;
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4 /* maxDepth */
      );
      if (newEditorState !== editorState) {
        if (setEditorState) {
          setEditorState(newEditorState);
        } else if (onSessionFieldChanged) {
          setDescriptionMarkupEditorState(newEditorState);
          onSessionFieldChanged(
            'description_markup',
            convertToRaw(newEditorState.getCurrentContent()),
            index
          );
        }
      }
      return;
    }
    if (e.keyCode === 9 /* TAB */) {
      const newEditorSessionState = RichUtils.onTab(
        e,
        descriptionMarkupEditorState,
        4 /* maxDepth */
      );
      if (newEditorSessionState !== descriptionMarkupEditorState) {
        if (setEditorState) {
          setEditorState(newEditorSessionState);
        } else if (onSessionFieldChanged) {
          setDescriptionMarkupEditorState(newEditorSessionState);
          onSessionFieldChanged(
            'description_markup',
            convertToRaw(newEditorSessionState.getCurrentContent()),
            index
          );
        }
      }
      return;
    }

    return getDefaultKeyBinding(e);
  };

  //Toggle style button
  const toggleBlockType = (blockType) => {
    if (setEditorState) {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    } else if (onSessionFieldChanged) {
      setDescriptionMarkupEditorState(
        RichUtils.toggleBlockType(descriptionMarkupEditorState, blockType)
      );
      onSessionFieldChanged(
        'description_markup',
        convertToRaw(
          RichUtils.toggleBlockType(
            descriptionMarkupEditorState,
            blockType
          ).getCurrentContent()
        ),
        index
      );
    }
  };

  const toggleInlineStyle = (inlineStyle) => {
    if (setEditorState) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    } else if (onSessionFieldChanged) {
      setDescriptionMarkupEditorState(
        RichUtils.toggleInlineStyle(descriptionMarkupEditorState, inlineStyle)
      );
      onSessionFieldChanged(
        'description_markup',
        convertToRaw(
          RichUtils.toggleBlockType(
            descriptionMarkupEditorState,
            inlineStyle
          ).getCurrentContent()
        ),
        index
      );
    }
  };

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor';
  var contentState = editorState
    ? editorState.getCurrentContent()
    : descriptionMarkupEditorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <div
      className={`${
        readOnly ? 'richEditor_style_readOnly' : 'richEditor_style'
      }`}
    >
      <div className={light ? 'RichEditor-root-light' : 'RichEditor-root'}>
        {/* Toolbar */}
        {!readOnly && (
          <>
            <BlockStyleControls
              editorState={
                editorState ? editorState : descriptionMarkupEditorState
              }
              onToggle={toggleBlockType}
            />
            <InlineStyleControls
              editorSessionDescription={descriptionMarkupEditorState}
              editorState={
                editorState ? editorState : descriptionMarkupEditorState
              }
              onToggle={toggleInlineStyle}
            />
          </>
        )}
        {/* Text Editor */}
        <div className={className}>
          <Editor
            readOnly={readOnly}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={
              setEditorState ? editorState : descriptionMarkupEditorState
            }
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            spellCheck={true}
          />
        </div>
      </div>
    </div>
  );
};

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  const currentStyle =
    (props.editorState && props.editorState.getCurrentInlineStyle()) ||
    (props.editorSessionDescription &&
      props.editorSessionDescription.getCurrentInlineStyle()) ||
    (props.sessionDescription &&
      props.sessionDescription.getCurrentInlineStyle()) ||
    undefined;

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={
            (props.editorState && currentStyle.has(type.style)) ||
            (props.editorSessionDescription && currentStyle.has(type.style)) ||
            (props.sessionDescription &&
              props.sessionDescription.getCurrentInlineStyle()) ||
            undefined
          }
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default DescriptionMarkupEditor;
