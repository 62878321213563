import { ListItemButton } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { NavItemProps } from 'Component/NavVertical/NavItem'
import { NAV } from 'Component/NavVertical/NavVertical'
import { yellow } from '@mui/material/colors'

type StyledItemProps = Omit<NavItemProps, 'item'> & {
  caption?: boolean
  disabled?: boolean
}

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'caption',
})<StyledItemProps>(
  ({ active, disabled, highlighted, depth, caption, theme }) => {
    const isLight = theme.palette.mode === 'light'

    const subItem = depth !== 1

    const activeStyle = {
      color: highlighted ? yellow[800] : theme.palette.primary.main,
      backgroundColor: alpha(
        highlighted ? yellow[600] : theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      ),
      ...(!isLight && {
        color: highlighted ? yellow[800] : theme.palette.primary.light,
      }),
    }

    const activeSubStyle = {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    }

    const hoverStyle = {
      color: highlighted ? yellow[800] : theme.palette.text.primary,
      backgroundColor: highlighted
        ? alpha(
            highlighted ? yellow[600] : theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          )
        : theme.palette.action.hover,
    }

    return {
      position: 'relative',
      textTransform: 'capitalize',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1.5),
      marginBottom: theme.spacing(0.5),
      color: theme.palette.text.secondary,
      backgroundColor: 'transparent',
      borderRadius: theme.shape.borderRadius,
      height: NAV.H_DASHBOARD_ITEM,
      '&:hover': hoverStyle,
      // Sub item
      ...(subItem && {
        height: NAV.H_DASHBOARD_ITEM_SUB,
        ...(depth > 2 && {
          paddingLeft: theme.spacing(depth),
        }),
        ...(caption && {
          height: NAV.H_DASHBOARD_ITEM,
        }),
      }),
      // Active item
      ...(active && {
        ...activeStyle,
        '&:hover': {
          ...activeStyle,
        },
      }),
      // Active sub item
      ...(subItem &&
        active && {
          ...activeSubStyle,
          '&:hover': {
            ...activeSubStyle,
          },
        }),
      // Disabled
      ...(disabled && {
        '&.Mui-disabled': {
          opacity: 0.64,
        },
        ...(!active && hoverStyle),
      }),
    }
  }
)

//---------------------------------------------

type StyledItemMiniProps = Omit<NavItemProps, 'item'>

export const StyledItemMini = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'open',
})<StyledItemMiniProps>(
  ({ active, highlighted, disabled, open, depth, theme }) => {
    const isLight = theme.palette.mode === 'light'

    const subItem = depth !== 1

    const activeStyle = {
      color: highlighted ? yellow[800] : theme.palette.primary.main,
      backgroundColor: alpha(
        highlighted ? yellow[600] : theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      ),
      ...(!isLight && {
        color: highlighted ? yellow[800] : theme.palette.primary.light,
      }),
    }

    const activeSubStyle = {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    }

    const hoverStyle = {
      color: highlighted ? yellow[800] : theme.palette.text.primary,
      backgroundColor: highlighted
        ? alpha(
            highlighted ? yellow[600] : theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          )
        : theme.palette.action.hover,
    }

    return {
      flexDirection: 'column',
      textTransform: 'capitalize',
      padding: theme.spacing(1, 0, 0.5, 0),
      color: theme.palette.text.secondary,
      borderRadius: theme.shape.borderRadius,
      '&:hover': hoverStyle,
      // Sub item
      ...(subItem && {
        flexDirection: 'row',
        padding: theme.spacing(1),
      }),
      // Active item
      ...(active && {
        ...activeStyle,
        '&:hover': {
          ...activeStyle,
        },
      }),
      // Active sub item
      ...(subItem &&
        active && {
          ...activeSubStyle,
          '&:hover': {
            ...activeSubStyle,
          },
        }),
      // Open
      ...(open && !active && hoverStyle),
      // Disabled
      ...(disabled && {
        '&.Mui-disabled': {
          opacity: 0.64,
        },
      }),
    }
  }
)
