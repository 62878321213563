import { Box, Checkbox, Link, Stack, Typography } from '@mui/material'
import { Button } from 'react-bootstrap'
import SurveyCreator from 'Component/SurveyCreator'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SurveysService from 'Service/SurveyService'
import { useLocation, useParams } from 'react-router-dom'
import { Survey } from 'Component/CourseRegistration/Models'
import useApi from 'api/UseApi'
import Loader from 'Component/Loader'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { templateCreated } from 'Actions/Survey'
import { useDispatch } from 'react-redux'
import { JSONValue } from 'common/types/Json'
import SurveyForm from 'Component/SurveyForm/SurveyForm'
import { useHistory } from 'react-router-dom'

const TemplateDetail = () => {
  const { t } = useTranslation()
  const { templateId } = useParams<{ templateId: string }>()
  const { pathname } = useLocation()
  const apiNoAuth = useApi(false, true)
  const api = useApi(false)
  const dispatch = useDispatch()
  const organization = useAppSelector(selectCurrentOrganization)

  const emptySurvey: Survey = {
    name: t('survey.default-template-title'),
    description: '',
    redirect_url: '',
    is_markdown_enabled: false,
  }

  const history = useHistory()
  const [template, setTemplate] = useState<Survey>(emptySurvey)
  const [json, setJson] = useState<null | JSONValue>(null)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [isLoaderVisible, setIsLoaderVisible] = useState(true)

  const { name, description, is_markdown_enabled, redirect_url } = template

  const { getTemplateByID } = SurveysService()

  const getTemplate = async () => {
    console.log({ templateId })
    try {
      setIsLoaderVisible(true)
      const template: Survey = await getTemplateByID(templateId)
      if (template) {
        setTemplate(template)
        setJson(template.body)
      }
      setIsLoaderVisible(false)
    } catch (error: any) {
      console.log(error)
      setIsLoaderVisible(false)
    }
  }

  const updateTemplates = () => {
    setIsLoaderVisible(true)
    try {
      if (template && template._id) {
        apiNoAuth
          .put(`/templates/${template._id}`, {
            name: name,
            description: description,
            redirectUrl: redirect_url,
            body: json,
            isMarkdownEnabled: is_markdown_enabled,
          })
          .then(
            (response) => {
              setIsLoaderVisible(false)
              toast.success(response.data.message, { theme: 'colored' })
              setTemplate({
                id: template.id,
                name: name,
                description: description,
                body: json,
                is_markdown_enabled: is_markdown_enabled,
              })
              getTemplate()
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const createTemplate = () => {
    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/templates`, {
          name: name,
          description: description,
          redirect_url: redirect_url,
          body: json,
          is_markdown_enabled: is_markdown_enabled,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)
            dispatch(templateCreated())
            history.goBack()
          },
          (error) => {
            setIsLoaderVisible(false)
            console.log(error)
          }
        )
    } catch (e) {
      console.log(`Error creating template: ${e}`)
    }
  }

  useEffect(() => {
    if (pathname.includes('/create')) {
      setIsPreview(false)
    } else if (pathname.includes('/edit')) {
      setIsPreview(false)
    } else {
      setIsPreview(true)
    }
  }, [pathname])

  useEffect(() => {
    if (pathname.includes('/create')) {
      setTemplate(emptySurvey)
      setIsLoaderVisible(false)
    } else {
      getTemplate()
    }
  }, [pathname])

  return (
    <Box className="">
      <Box className="">
        <SurveyForm
          survey={template}
          isPreview={isPreview}
          isLoaderVisible={isLoaderVisible}
          json={json}
          setSurvey={setTemplate}
          setJson={setJson}
          createSurvey={createTemplate}
          updateSurvey={updateTemplates}
        />
      </Box>
    </Box>
  )
}

export default TemplateDetail
