// form
import { useForm } from 'react-hook-form'
// @mui
import { Stack, InputAdornment } from '@mui/material'
import { StyledButton } from 'common/styled-component/new-template/StyledButton'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
// @types
import FormProviderCustom from 'Component/ReactHookFormComponents/FormProviderCustom'
import RHFTextField from 'Component/ReactHookFormComponents/RHFTextField'
//icons
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
//hook
import MeService from 'Service/MeService'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const SOCIAL_LINKS = [
  {
    value: 'facebookLink',
    icon: <FacebookIcon width={24} sx={{ color: 'rgb(24, 119, 242)' }} />,
  },
  {
    value: 'instagramLink',
    icon: <InstagramIcon width={24} sx={{ color: 'rgb(223, 62, 48)' }} />,
  },
  {
    value: 'linkedinLink',
    icon: <LinkedInIcon width={24} sx={{ color: 'rgb(0, 96, 151)' }} />,
  },
  {
    value: 'twitterLink',
    icon: <TwitterIcon width={24} sx={{ color: 'rgb(28, 156, 234)' }} />,
  },
] as const

// ----------------------------------------------------------------------
export type IUserSocialLink = {
  facebookLink: string
  instagramLink: string
  linkedinLink: string
  twitterLink: string
}

type FormValuesProps = IUserSocialLink

type Props = {
  socialLinks: IUserSocialLink
}

export default function AccountSocialLinks({ socialLinks }: Props) {
  const defaultValues = {
    facebookLink: socialLinks.facebookLink,
    instagramLink: socialLinks.instagramLink,
    linkedinLink: socialLinks.linkedinLink,
    twitterLink: socialLinks.twitterLink,
  }

  const { putSocialLinks } = MeService()
  const methods = useForm({
    defaultValues,
  })
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    await putSocialLinks(data)
  }

  return (
    <FormProviderCustom methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <StyledCard sx={{ p: 3 }}>
        <Stack spacing={3} alignItems="flex-end">
          {SOCIAL_LINKS.map((link) => (
            <RHFTextField
              key={link.value}
              name={link.value}
              disabled={isSubmitting}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{link.icon}</InputAdornment>
                ),
              }}
            />
          ))}

          <StyledButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('common.save-changes')}
          </StyledButton>
        </Stack>
      </StyledCard>
    </FormProviderCustom>
  )
}
