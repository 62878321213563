import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
import { Trans, useTranslation } from 'react-i18next'
import { Project } from 'Component/FundedTraining/types'
import { NavLink } from 'react-router-dom'

interface Props {
  project: Project
}

export function ProjectOverview(props: Props) {
  const { project } = props

  const projectStart = '12/03/2024'
  const projectEnd = '12/03/2024'
  const totalParticipants = 20
  const completedHoursRatio = '20/100'
  const remainingHours = 50
  const avgAttendancePct = '30%'
  const avgAttendancePctRemaining = '70%'
  const participantsWithReachedMinAttendancePct = '30%'
  const participantsWithNoAttendancePct = '20%'

  const { t } = useTranslation()

  return (
    <Grid container rowSpacing={4} columnSpacing={3}>
      <Grid item md={6} lg={4}>
        <StyledCard
          sx={{
            pt: 1,
            pb: 2,
            px: 2,
            borderRadius: 1,
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
          }}
        >
          <Typography variant="h6" sx={{ mr: 1, mb: 1, color: '#0d6efdee' }}>
            {'Dettagli progetto'}
          </Typography>

          <Trans i18nKey="funded-training.project-detail.overview.project-start">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ projectStart }}</>
            </strong>
          </Trans>
          <br></br>

          <Trans i18nKey="funded-training.project-detail.overview.project-end">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ projectEnd }}</>
            </strong>
          </Trans>
          <br></br>
          <br></br>

          <Trans i18nKey="funded-training.project-detail.overview.total-participants">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ totalParticipants }}</>
            </strong>
          </Trans>

          <br></br>
          <NavLink to={`/funded-training/projects`} exact>
            <Link>
              {t('funded-training.project-detail.overview.participant-details')}
            </Link>
          </NavLink>
        </StyledCard>
      </Grid>

      <Grid item md={6} lg={5}>
        <StyledCard
          sx={{
            pt: 1,
            pb: 2,
            px: 2,
            borderRadius: 1,
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
          }}
        >
          <Typography variant="h6" sx={{ mr: 1, mb: 1, color: '#0d6efdee' }}>
            {'Completamento'}
          </Typography>

          <Trans i18nKey="funded-training.project-detail.overview.completed-hours">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ completedHoursRatio }}</>
            </strong>
          </Trans>
          <br></br>
          <br></br>

          <Trans i18nKey="funded-training.project-detail.overview.remaining-hours">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ remainingHours }}</>
            </strong>
          </Trans>
          <br></br>
        </StyledCard>
      </Grid>

      <Grid item md={6} lg={6}>
        <StyledCard
          sx={{
            pt: 1,
            pb: 2,
            px: 2,
            borderRadius: 1,
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
          }}
        >
          <Typography variant="h6" sx={{ mr: 1, mb: 1, color: '#0d6efdee' }}>
            {'Presenze'}
          </Typography>

          <Trans i18nKey="funded-training.project-detail.overview.avg-attendance-pct">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ avgAttendancePct }}</>
            </strong>
          </Trans>
          <br></br>

          <Trans i18nKey="funded-training.project-detail.overview.avg-attendance-pct-remaining">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ avgAttendancePctRemaining }}</>
            </strong>
          </Trans>
          <br></br>
          <br></br>

          <Trans i18nKey="funded-training.project-detail.overview.participants-with-reached-min-attendance-pct">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ participantsWithReachedMinAttendancePct }}</>
            </strong>
          </Trans>

          <br></br>

          <Trans i18nKey="funded-training.project-detail.overview.participants-with-no-attendance-pct">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ participantsWithNoAttendancePct }}</>
            </strong>
          </Trans>
        </StyledCard>
      </Grid>
    </Grid>
  )
}
