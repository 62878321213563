import { useState } from 'react';
// @mui
import { Tab, Tabs, Typography, Container, Box } from '@mui/material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import TuneIcon from '@mui/icons-material/Tune';
import ShareIcon from '@mui/icons-material/Share';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CompanyProfile from 'View/Settings/CompanyProfile';
import ManageUser from 'View/Settings/ManageUser';
import Archived from 'View/Settings/Archived';
import { useTranslation } from 'react-i18next';
import ConfigRegistration from './ConfigRegistration/ConfigRegistration';
import CompanySocialLinks from 'View/Settings/CompanySocialLinks';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

const Settings = () => {
  const [currentTab, setCurrentTab] = useState('general');
  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  const TABS = [
    {
      value: 'general',
      label: t('user-account.general'),
      icon: <SettingsApplicationsIcon />,
      component: <CompanyProfile />,
    },
    {
      value: 'social_links',
      label: t('user-account.social-links'),
      icon: <ShareIcon />,
      component: <CompanySocialLinks />,
    },
    {
      value: 'manage-users',
      label: t('settings.manage-users'),
      icon: <GroupsOutlinedIcon />,
      component: <ManageUser />,
    },
    {
      value: 'archived-courses',
      label: t('settings.archived-courses'),
      icon: <ArchiveOutlinedIcon />,
      component: <Archived />,
    },
    {
      value: 'config-registration',
      label: t('settings.config-registration'),
      icon: <TuneIcon />,
      component: <ConfigRegistration />,
    },
  ];

  return (
    <Container
      maxWidth={width >= 1200 ? 'lg' : false}
      sx={{ display: 'flex', marginTop: '100px' }}
    >
      <div className="Settings">
        <Box sx={{ flexGrow: 1, padding: '0px 0px 0px 1rem' }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: '1.5rem',
              fontFamily: 'sans-serif',
              fontWeight: 500,
              lineHeight: '1.5',
              paddingLeft: '1rem',
              marginBottom: '2rem',
            }}
          >
            {t('settings.Organization')}
          </Typography>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            sx={{
              paddingLeft: '16px',
              '&.MuiTabs-root .MuiTabs-indicator': {
                backgroundColor: 'rgb(33, 43, 54)',
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                icon={tab.icon}
                value={tab.value}
                sx={{
                  color: 'rgb(99, 115, 129)',
                  lineHeight: '1.25',
                  fontSize: '0.875rem',
                  fontFamily: 'sans-serif',
                  fontWeight: '400',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  minHeight: '0px',
                  textTransform: 'capitalize',
                  padding: '1rem 0px 0.5rem 0px',
                  marginRight: '2rem',
                  '&.MuiButtonBase-root .MuiSvgIcon-root': {
                    margin: '0px 0.5rem 0px 0px',
                  },
                  '&.MuiButtonBase-root.Mui-selected': {
                    color: 'rgb(33, 43, 54)',
                  },
                }}
              />
            ))}
          </Tabs>

          {TABS.map(
            (tab) =>
              tab.value === currentTab && (
                <Box key={tab.value} sx={{ mt: 5 }}>
                  {tab.component}
                </Box>
              )
          )}
        </Box>
      </div>
    </Container>
  );
};

export default Settings;
