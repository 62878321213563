import { FormHelperText } from '@mui/material'
import CurrencyInput from 'react-currency-input-field'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'

type Props = {
  name: string
  price: string
  currency: string
  onPriceValueChange: () => void
  minimumPrice?: number
  className?: string
  disabled?: boolean
}

const CurrencyInputField = (props: Props) => {
  const { t } = useTranslation()

  const getInputError = () => {
    const price = props.price
    const minimumPrice = props.minimumPrice || 0.5

    if (price && minimumPrice) {
      const priceInCents = parseFloat(price) * 100
      const minimumPriceInCents = minimumPrice * 100

      if (priceInCents < minimumPriceInCents) {
        return (
          <Trans i18nKey={'forms.validation.price.below-min-value'}>
            <strong className="item-span">
              <>{{ minimumPrice }}</>
            </strong>
          </Trans>
        )
      }
    }
    return <br />
  }

  return (
    <div>
      <CurrencyInput
        className={props.className || ''}
        name={props.name}
        placeholder={t('courses.price-input-placeholder')}
        intlConfig={{ locale: 'en-US', currency: props.currency }}
        value={props.price}
        defaultValue={'' + props.minimumPrice || ''}
        decimalsLimit={2}
        step={1}
        disabled={props.disabled}
        disableAbbreviations={true}
        disableGroupSeparators={true}
        onValueChange={props.onPriceValueChange}
      />
      <FormHelperText error id="form-text-field-error">
        {getInputError()}
      </FormHelperText>
    </div>
  )
}

export default CurrencyInputField
