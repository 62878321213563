import React, { useState, useEffect } from 'react';
import InviteUser from './ManageUser/InviteUser';
import OrganizationMemberPopup from './ManageUser/OrganizationMemberPopupMenu';
import Loader from 'Component/Loader';
import { useTranslation } from 'react-i18next';
import useApi from '../../api/UseApi';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import TableUsers from './ManageUser/TableUsers';
import { Grid } from '@mui/material';
import TableViewPagination from 'common/TableViewPagination';
import { StyledCard } from 'common/styled-component/new-template/StyledCard';

const ManageUser = () => {
  const [Auth0User, setAuth0User] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [singleAuth0User, setSingleAuth0User] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredByUserType, setFilteredByUserType] = useState([]);
  const [filteredAuth0Users, setFilteredAuth0Users] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pageNo, setPageNo] = useState(0);
  const [orderDirection, setOrderDirection] = useState();
  const [valueToOrderBy, setValueToOrderBy] = useState();
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState(false);

  const organization = useAppSelector(selectCurrentOrganization);
  const { t } = useTranslation();

  const api = useApi(false);

  const getOrganizationMembers = async () => {
    setIsLoaderVisible(true);
    try {
      api.get(`organizations/${organization?.id}/users`).then(
        (response) => {
          setAuth0User(response.data.users);
          setIsLoaderVisible(false);
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const getAllRoles = () => {
    try {
      api.get(`roles`).then(
        (response) => {
          const roles = response.data.roles;
          const rolesToShow = roles.filter((role) => role.name !== 'Owner');
          setAllRoles(rolesToShow);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleClick = (event) => {
    setIsConfirmationDialogVisible(event.currentTarget);
  };

  const handleClose = () => {
    setIsConfirmationDialogVisible(false);
  };

  const onSetSingleAuth0User = (data) => {
    setSingleAuth0User(data);
  };

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === 'asc';
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  useEffect(() => {
    const cleanInputSearchText = searchText.toLowerCase().trim();

    let searchDataList = [];
    if (cleanInputSearchText) {
      searchDataList = filteredByUserType.filter((user) => {
        const matchesName =
          user.first_name &&
          user.first_name.toLowerCase().includes(cleanInputSearchText);
        const matchesLastName =
          user.last_name &&
          user.last_name.toLowerCase().includes(cleanInputSearchText);

        const matchesEmail =
          user.email && user.email.toLowerCase().includes(cleanInputSearchText);

        return matchesName || matchesLastName || matchesEmail;
      });
    } else {
      searchDataList = filteredByUserType;
    }
    setFilteredAuth0Users(searchDataList);
    setPageNo(0);
  }, [searchText, filteredByUserType]);

  useEffect(() => {
    let filteredList = [];
    if (selectedRole) {
      filteredList = Auth0User.filter((data) => {
        return selectedRole === data.roleType;
      });
    } else {
      filteredList = Auth0User;
    }
    setFilteredByUserType(filteredList);
  }, [selectedRole, Auth0User]);

  useEffect(() => {
    getOrganizationMembers();
    getAllRoles();
  }, []);

  return (
    organization?.userPermissions.includes('read:user-management') && (
      <div className="manage_user">
        <StyledCard
          sx={{
            py: 2,
            px: 4,
            mr: 1,
            textAlign: 'center',
            minWidth: 200,
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={6} style={{ width: '100%' }}>
              <input
                className="form-control inputIcon"
                type="text"
                placeholder={t('courses.Search')}
                aria-label="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Grid>
            <Grid item sm={6} style={{ width: '100%' }}>
              <select
                className="form-select"
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}
                style={{
                  color: selectedRole === '' ? '#157aff73' : 'black',
                  fontSize: 'medium',
                }}
              >
                <option value="" className="pb-2" style={{ color: 'black' }}>
                  {selectedRole
                    ? t('settings.clearFilter')
                    : t('common.select')}
                </option>
                {allRoles.map((r) => {
                  return (
                    <option style={{ color: 'black' }} value={r.name}>
                      {r.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>
          <div>
            <div className="Ontable">
              <span style={{ color: '#808080' }}>
                {t('settings.Showing')} {filteredAuth0Users.length}{' '}
                {t('settings.results')}
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {organization?.userPermissions.includes(
                  'add:user-management'
                ) && <InviteUser onUserCreated={getOrganizationMembers} />}
              </div>
            </div>
            {filteredAuth0Users.length > 0 && (
              <>
                <TableUsers
                  filteredAuth0Users={filteredAuth0Users.map((user) => {
                    return {
                      id: user.id,
                      first_name: user.first_name,
                      last_name: user.last_name,
                      email: user.email,
                      role_type: user.roleType,
                    };
                  })}
                  pageNo={pageNo}
                  rowsPerPage={rowsPerPage}
                  orderDirection={orderDirection}
                  valueToOrderBy={valueToOrderBy}
                  handleClick={handleClick}
                  setSingleAuth0User={onSetSingleAuth0User}
                  onRequestSort={handleRequestSort}
                />
              </>
            )}
            {filteredAuth0Users.length === 0 && !isLoaderVisible && (
              <h1
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgb(208 211 214)',
                }}
              >
                {t('NoRecordFound')}
              </h1>
            )}
            {filteredAuth0Users.length > 0 && (
              <TableViewPagination
                numberOfItems={filteredAuth0Users.length}
                rowsPerPage={rowsPerPage}
                pageNo={pageNo}
                onPageChanged={(event, pageNo) => {
                  setPageNo(pageNo);
                }}
                onRowsPerPageChanged={(event) => {
                  setRowsPerPage(event.target.value);
                  setPageNo(0);
                }}
              />
            )}
          </div>

          <OrganizationMemberPopup
            isConfirmationDialogVisible={isConfirmationDialogVisible}
            user={singleAuth0User}
            onUserDeleted={getOrganizationMembers}
            setIsConfirmationDialogVisible={setIsConfirmationDialogVisible}
            onClosed={handleClose}
          />

          <Loader isVisible={isLoaderVisible} />
        </StyledCard>
      </div>
    )
  );
};

export default ManageUser;
