import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Project } from 'Component/FundedTraining/types'
import { CompanyProjectsTable } from './CompanyProjectsTable'

interface Props {
  companyUuid: string
  items: Project[]
  onItemDeleted: () => void
}

export function CompanyProjects(props: Props) {
  const { companyUuid, items, onItemDeleted } = props

  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5">{t('funded-training.projects')}</Typography>

      <CompanyProjectsTable
        companyUuid={companyUuid}
        items={items}
        onItemDeleted={() => {
          onItemDeleted()
        }}
      />
    </Box>
  )
}
