// @mui
import { TableRow, TableCell } from '@mui/material'
//
import EmptyContent from './EmptyContent'

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean
}

export default function TableNoData({ isNotFound }: Props) {
  return (
    <TableRow sx={{ position: 'relative', height: '25px' }}>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title="No Data"
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0, borderColor: 'transparent' }} />
      )}
    </TableRow>
  )
}
