import { Box, Typography, Tabs, Tab, Link } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { Employee } from 'Component/FundedTraining/types'
import { EmployeeForm } from './EmployeeForm'

import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'
import EmployeeTabPanel from 'Component/FundedTraining/Employees/EmployeeTabPanel'
import {
  selectShouldPreselectActivitiesTab,
  setShouldPreselectActivitiesTab,
} from 'Reducers/FundedTraining/EmployeeReducer'
import { EmployeeActivitiesTable } from './EmployeeActivitiesTable'

type RouteParams = {
  employeeUuid: string
}

type LocationState = {
  employeeName: string
}

type Props = {
  isNew?: boolean
}

function a11yProps(index: number) {
  return {
    id: `employee-tab-${index}`,
    'aria-controls': `employee-tabpanel-${index}`,
  }
}

export function EmployeeDetail(props: Props) {
  const { employeeUuid } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { isNew = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [employeeName, setEmployeeName] = useState<string>('')
  const [currentEmployee, setCurrentEmployee] = useState<null | Employee>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  const shouldPreselectActivitiesTab = useAppSelector(
    selectShouldPreselectActivitiesTab
  )

  useEffect(() => {
    if (shouldPreselectActivitiesTab) {
      setTabIndex(1)
      dispatch(setShouldPreselectActivitiesTab(false))
    }
  }, [shouldPreselectActivitiesTab])

  useEffect(() => {
    if (currentEmployee) {
      if (employeeName === '') {
        setEmployeeName(currentEmployee.name)
      }
    } else if (state) {
      setEmployeeName(state.employeeName)
    }
  }, [state, currentEmployee, employeeName])

  const getEmployee = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`funded_training/employees/${employeeUuid}`).then(
        (response) => {
          setCurrentEmployee(response.data)
          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, employeeUuid])

  useEffect(() => {
    getEmployee()
  }, [getEmployee, employeeUuid])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
        <Trans i18nKey={'funded-training.employee-detail.title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd' }}>
            <>{{ employeeName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>

      <NavLink to={`/funded-training/employees`} exact>
        <Link variant="body1">{t('funded-training.go-to-employee-list')}</Link>
      </NavLink>

      {currentEmployee && (
        <>
          <Tabs
            sx={{ mt: 4 }}
            value={tabIndex}
            onChange={onTabChange}
            aria-label="employee tabs"
          >
            <Tab label={t('common.config')} {...a11yProps(1)} />
            <Tab label={t('funded-training.activities')} {...a11yProps(0)} />
          </Tabs>

          <EmployeeTabPanel value={tabIndex} index={0}>
            <EmployeeForm
              currentEmployee={currentEmployee}
              isNew={isNew}
              onEmployeeUpdated={(employee: Employee) => {
                setEmployeeName(employee.name)
              }}
            />
          </EmployeeTabPanel>
          <EmployeeTabPanel value={tabIndex} index={1}>
            {/*TODO: show curriculum*/}
            <Box sx={{ mt: 4 }}>
              <EmployeeActivitiesTable
                items={currentEmployee.activities || []}
                onItemDeleted={() => {
                  // onItemDeleted()
                }}
              />
            </Box>
          </EmployeeTabPanel>
        </>
      )}
    </Box>
  )
}
