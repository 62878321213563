import microphone from 'assets/icon/microphone.svg'
import { useTranslation } from 'react-i18next'
import { getLocalizedDate2FromUTC } from 'common/DateTimeUtils'
import { useAppSelector } from 'Store'
import { selectTimezone } from 'Reducers/CourseRegistrationReducer'
import { CourseRegistrationModel } from './Models'

type Props = {
  courseRegistration: CourseRegistrationModel
}

const MainInfo = ({ courseRegistration }: Props) => {
  const { t } = useTranslation()
  const timezone = useAppSelector(selectTimezone)

  const dateTimeFrom = courseRegistration.sessions[0]?.dateTimeFrom
  const dateTimeTo =
    courseRegistration.sessions[courseRegistration.sessions.length - 1]
      ?.dateTimeFrom
  const { courseName, companyName, location, speakers } = courseRegistration

  const renderDate = () => {
    if (dateTimeFrom && dateTimeTo) {
      return dateTimeFrom === dateTimeTo
        ? getLocalizedDate2FromUTC(dateTimeFrom, timezone)
        : `${getLocalizedDate2FromUTC(
            dateTimeFrom,
            timezone
          )} - ${getLocalizedDate2FromUTC(dateTimeTo, timezone)}`
    }
  }

  return (
    <>
      <div id="course-main-info">
        <div id="course-main-info-background">
          <div className="course-main-info-detail">
            <div className="course-main-info-detail-text-container">
              <span className="ms-2 course-main-info-detail-title">
                {renderDate() || ''}
              </span>
            </div>
          </div>
          <div>
            <div className="course-main-info-detail">
              <div className="course-main-info-detail-text-container">
                <span className="ms-2 course-main-info-course-name">
                  {courseName || ''}
                </span>
              </div>
            </div>
            <div className="course-main-info-detail">
              <div className="course-main-info-detail-text-container">
                <span className="ms-2 course-main-info-company-name">
                  {companyName || ''}
                </span>
              </div>
            </div>
          </div>
          <div className="course-main-info-detail">
            <div className="course-main-info-detail-text-container">
              <span className="ms-2 course-main-info-detail-title">
                {location || ''}
              </span>
            </div>
          </div>
          <div
            className="course-main-info-detail"
            style={{
              display: 'none',
            }}
          >
            <img src={microphone} alt="Course speakers" />
            <div className="course-main-info-detail-text-container">
              <span className="ms-2 course-main-info-detail-title">
                {t('common.speakers').toUpperCase()}
              </span>
              <span className="ms-2 course-main-info-detail-text">
                {speakers?.length || 0}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainInfo
