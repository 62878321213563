import { TFunction } from 'i18next'

export enum TransactionalTemplateName {
  Welcome = 'wyblo-transactional-template-welcome',
  Reminder24h = 'wyblo-transactional-template-reminder-24h',
  Feedback = 'wyblo-transactional-template-feedback',
  FinalFeedback = 'wyblo-transactional-template-final-feedback',
  LearnerLoginCode = 'wyblo-transactional-template-learner-login-code',
  LearnerEmailVerification = 'wyblo-transactional-template-learner-email-verification',
  LearnerWelcomeToCourse = 'wyblo-transactional-template-learner-welcome-to-course',
  LearnerReminder24h = 'wyblo-transactional-template-learner-reminder-24h',
  LearnerReminder3h = 'wyblo-transactional-template-learner-reminder-3h',
}

export function getLocalizedTemplateName(templateName: string, t: TFunction) {
  if (templateName === TransactionalTemplateName.Welcome) {
    return t('mail.transactional-types.welcome')
  } else if (templateName === TransactionalTemplateName.Reminder24h) {
    return t('mail.transactional-types.reminder-24h')
  } else if (templateName === TransactionalTemplateName.Feedback) {
    return t('mail.transactional-types.feedback-form')
  } else if (templateName === TransactionalTemplateName.FinalFeedback) {
    return t('mail.transactional-types.final-feedback-form')
  } else if (templateName === TransactionalTemplateName.LearnerLoginCode) {
    return `Learner - ${t('mail.transactional-types.learner.login-code')}`
  } else if (
    templateName === TransactionalTemplateName.LearnerWelcomeToCourse
  ) {
    return `Learner - ${t(
      'mail.transactional-types.learner.welcome-to-course'
    )}`
  } else if (templateName === TransactionalTemplateName.LearnerReminder24h) {
    return `Learner - ${t('mail.transactional-types.learner.reminder-24h')}`
  } else if (templateName === TransactionalTemplateName.LearnerReminder3h) {
    return `Learner - ${t('mail.transactional-types.learner.reminder-3h')}`
  } else if (
    templateName === TransactionalTemplateName.LearnerEmailVerification
  ) {
    return `Learner - ${t(
      'mail.transactional-types.learner.email-verification'
    )}`
  }

  return ''
}

export function getLocalizedTemplateDescription(
  templateName: string,
  t: TFunction
) {
  if (templateName === TransactionalTemplateName.Welcome) {
    return t('mail.transactional-types.descriptions.welcome')
  } else if (templateName === TransactionalTemplateName.Reminder24h) {
    return t('mail.transactional-types.descriptions.reminder-24h')
  } else if (templateName === TransactionalTemplateName.Feedback) {
    return t('mail.transactional-types.descriptions.feedback-form')
  }
  if (templateName === TransactionalTemplateName.FinalFeedback) {
    return t('mail.transactional-types.descriptions.final-feedback-form')
  }

  return ''
}
