import { useEffect, useState, useCallback } from 'react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import CancelEditIcon from '@mui/icons-material/RestartAltOutlined';
import { toast } from 'react-toastify';
import Loader from 'Component/Loader';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useApi from '../../../api/UseApi';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from 'Component/ConfirmationDialog';
import TemplateSelect from 'View/course/TemplateSelect';
import { Tooltip } from '@mui/material';
import {
  selectCurrentOrganizationStripeAccount,
  selectCurrentOrganization,
} from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import { courseEdited } from 'Reducers/CourseDetailReducer';
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { StyledSelect } from 'common/styled-component/StyledSelect';
import CurrencyInputField from 'Component/CourseRegistration/RegistrationModal/FormFields/CurrencyInputField';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { orange } from '@mui/material/colors';
import Dropzone from 'Component/Dropzone/Dropzone';
import { getTimezone, getUTCDateTime } from 'common/DateTimeUtils';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import DateAndTimePicker from 'Component/DateAndTimePicker/DateAndTimePicker';
import ShowInOrganizationPage from 'Component/ShowInOrganizationPage';
import CourseRegistrationSettings from 'Component/CourseRegistrationSettings';
import { convertServerResourcesToClientResources } from 'utils/type-conversions/resource';
import {
  convertDateTimePickerModelToDateTimeString,
  convertDateTimeStringToDateTimePickerModel,
} from 'utils/type-conversions/datetime';
import i18next from 'i18next';
import DropzoneMulti from 'Component/DropzoneMulti/DropzoneMulti';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { editorModules } from 'Component/EditorDescrition/ReactQuillEditor';

const CourseGeneralSettings = ({ course, visibleLoader }) => {
  const [courseTitle, setCourseTitle] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [price, setPrice] = useState(1.0);
  const [priceInCents, setPriceInCents] = useState(100);
  const [preassessmentTemplateId, setPreassessmentTemplateId] = useState(null);
  const [feedbackFormTemplateId, setFeedbackFormTemplateId] = useState(null);
  const [registrationLink, setRegistrationLink] = useState(null);
  const [preassessmentLink, setPreassessmentLink] = useState(null);
  const [visibleLoader2, setVisibleLoader2] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isPreassessmentLinkCopied, setIsPreassessmentLinkCopied] =
    useState(false);
  const [isFeedbackFormLinkCopied, setIsFeedbackFormLinkCopied] =
    useState(false);
  const [isPaidCourse, setIsPaidCourse] = useState(false);

  const [feedbackFormLink, setFeedbackFormLink] = useState(null);
  const [shouldSendFeedbackForm, setShouldSendFeedbackForm] = useState(false);
  const [isFeedbackFormSent, setIsFeedbackFormSent] = useState(false);
  const [feedbackFormScheduledDateTime, setFeedbackFormScheduledDateTime] =
    useState(null);
  const [dateAndTime, setDateAndTime] = useState({
    date: null,
    time: new Date().toLocaleTimeString(i18next.language, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: getTimezone(),
    }),
  });
  const [serverTime, setServerTime] = useState('');
  const [isEditingFeedbackFormTemplate, setIsEditingFeedbackFormTemplate] =
    useState(false);

  const [isRegistrationLinkCopied, setIsRegistrationLinkCopied] =
    useState(false);
  const [isPublishConfirmationDialogOpen, setIsPublishConfirmationDialogOpen] =
    useState(false);
  const currencies = ['EUR', 'GBP', 'USD'];
  const [currency, setCurrency] = useState('EUR');

  const initialDelta = { ops: [] };
  const [editorContent, setEditorContent] = useState(initialDelta);

  const handleEditorChange = (_content, _delta, _source, editor) => {
    setEditorContent(editor.getContents());
  };

  const [bannerFile, setBannerFile] = useState([]);
  const [resourceFiles, setResourceFiles] = useState([]);

  const [showInOrganizationPublicPage, setShowInOrganizationPublicPage] =
    useState(false);

  const [isRegistrationPageEnabled, setIsRegistrationPageEnabled] =
    useState(true);

  const dispatch = useDispatch();

  const organization = useAppSelector(selectCurrentOrganization);
  const organizationStripeAccount = useAppSelector(
    selectCurrentOrganizationStripeAccount
  );
  const { t } = useTranslation();

  let history = useHistory();

  const api = useApi(false);

  const onDateChange = (date) => {
    setDateAndTime({ ...dateAndTime, date });
  };

  const onTimeChange = (time) => {
    setDateAndTime({ ...dateAndTime, time });
  };

  useEffect(() => {
    const dateTimePickerModel = convertDateTimeStringToDateTimePickerModel(
      feedbackFormScheduledDateTime
    );
    const { date, time } = dateTimePickerModel;
    if (date && time) {
      setDateAndTime({ date, time });
      setServerTime(time);
    }
  }, [feedbackFormScheduledDateTime]);

  useEffect(() => {
    if (course) {
      setShowInOrganizationPublicPage(course.show_in_catalog);
      setIsRegistrationPageEnabled(course.enable_registration_page);
      setCourseTitle(course.title);
      setCreatedBy(course.created_by);
      setEditorContent(course.description_markup);
      setRegistrationLink(course.course_Link);
      setPreassessmentLink(course.survey_link);

      setIsEditingFeedbackFormTemplate(false);

      if (course.feedback_form_survey_link) {
        setShouldSendFeedbackForm(true);
        setFeedbackFormLink(course.feedback_form_survey_link);
        setFeedbackFormScheduledDateTime(
          course.feedback_form_campaign.scheduledAt
        );
        setIsFeedbackFormSent(course.feedback_form_campaign.status === 'sent');
      } else {
        setShouldSendFeedbackForm(false);
        setFeedbackFormLink(null);
        setFeedbackFormTemplateId(null);
        setFeedbackFormScheduledDateTime(null);
        setIsFeedbackFormSent(false);
      }

      if (course.stripe_price_id) {
        setVisibleLoader2(true);
        api.get(`courses/${course.id}/price`).then(
          (response) => {
            setVisibleLoader2(false);

            setIsPaidCourse(true);

            const amount = response.data.price.amount;
            const priceToShow = (amount / 100).toFixed(2);

            setPrice(priceToShow);
            setPriceInCents(amount);
            setCurrency(response.data.price.currency.toUpperCase());
          },
          (error) => {
            setVisibleLoader2(false);
            console.log(error);
          }
        );
      } else {
        setIsPaidCourse(false);
        setPrice(1.0);
        setCurrency('EUR');
      }
    }
  }, [api, course]);

  const onCurrencySelected = (event) => {
    setCurrency(event.target.value);
  };

  const onPriceValueChange = (value, _, values) => {
    const floatPrice = '' + values.float;
    const splitFloatPrice = floatPrice.split('.');
    const integerPart = splitFloatPrice[0];

    if (Number.isNaN(Number(integerPart))) {
      setPriceInCents(0);
      setPrice('');
      return;
    }

    let newPriceInCents = 0;

    if (splitFloatPrice.length > 1) {
      let decimalPart = splitFloatPrice[1];
      if (decimalPart.length === 1) {
        decimalPart *= 10;
      }

      if (integerPart === '0') {
        newPriceInCents = decimalPart;
      } else {
        newPriceInCents = integerPart + decimalPart;
      }
    } else {
      newPriceInCents = integerPart === '0' ? integerPart : integerPart + '00';
    }

    setPriceInCents(parseInt(newPriceInCents));

    if (value) {
      setPrice(value);
    } else {
      setPrice('');
    }
  };

  const getTemplates = useCallback(() => {
    try {
      api.get(`organizations/${organization?.id}/templates`).then(
        (response) => {
          const orgTemplates = response.data.templates;

          api
            .get('survey-service/templates', {
              params: { wybloTemplatesOnly: true },
            })
            .then(
              (response) => {
                setTemplates(orgTemplates.concat(response.data));
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }, [api, organization?.id]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const getCourseUpdateParams = () => {
    const courseUpdateRequestParams = {
      show_in_catalog: showInOrganizationPublicPage,
      description_markup: editorContent,
      title: courseTitle,
      enable_registration_page: isRegistrationPageEnabled,
      manage_feedback_form: true,
    };

    // Pre-assessment
    if (preassessmentTemplateId) {
      courseUpdateRequestParams['survey_template_id'] = preassessmentTemplateId;
    }

    if (shouldSendFeedbackForm) {
      if (feedbackFormTemplateId) {
        courseUpdateRequestParams['feedback_form_survey_external_template_id'] =
          feedbackFormTemplateId;
      }

      if (dateAndTime) {
        const dateTimeString =
          convertDateTimePickerModelToDateTimeString(dateAndTime);

        courseUpdateRequestParams['feedback_form_scheduled_date'] =
          getUTCDateTime(dateTimeString);
      }
    } else {
      if (feedbackFormLink) {
        courseUpdateRequestParams['unset_feedback_form'] = true;
      }
    }

    // Price and currency
    if (isPaidCourse) {
      courseUpdateRequestParams['price'] = priceInCents;
      courseUpdateRequestParams['currency'] = currency.toLowerCase();
    } else {
      courseUpdateRequestParams['price'] = 0;
    }

    return courseUpdateRequestParams;
  };

  const validateAndUpdate = (publish) => {
    if (shouldSendFeedbackForm && !feedbackFormLink) {
      if (
        !dateAndTime.date ||
        !dateAndTime.time ||
        dateAndTime.date.format().toLowerCase() === 'invalid date' ||
        dateAndTime.time.toLowerCase() === 'invalid date'
      ) {
        toast.error(
          t('courses.course-feedback-form-error-missing-scheduled-date'),
          { theme: 'colored' }
        );
        return;
      }

      if (!feedbackFormTemplateId) {
        toast.error(t('courses.course-feedback-form-error-missing-template'), {
          theme: 'colored',
        });
        return;
      }
    }

    if (publish) {
      publishCourse();
    } else {
      updateCourse();
    }
  };

  const updateResources = (resources) => {
    const resourcesFormData = new FormData();

    if (bannerFile.length > 0) {
      resourcesFormData.append('banner', bannerFile[0]);
    }

    const filtered = resources.filter((resource) => !resource.id);
    if (filtered) {
      for (let i = 0; i < filtered.length; i++) {
        resourcesFormData.append('resources[]', filtered[i]);
      }
    }

    try {
      api.put(`courses/${course.id}/update_resources`, resourcesFormData).then(
        (response) => {
          toast.success(response.data.message, { theme: 'colored' });
          setVisibleLoader2(false);
          dispatch(courseEdited());
        },
        (error) => {
          console.log(error);
          setVisibleLoader2(false);
          toast.error(error.response.data.message, { theme: 'colored' });
        }
      );
    } catch (e) {
      setVisibleLoader2(false);
      console.log(e.message);
    }
  };

  const updateCourse = () => {
    setVisibleLoader2(true);

    const courseUpdateRequestParams = getCourseUpdateParams();

    try {
      api.put(`courses/${course.id}`, courseUpdateRequestParams).then(
        (response) => {
          if (bannerFile.length > 0 || resourceFiles.length > 0) {
            updateResources(resourceFiles);
          } else {
            setVisibleLoader2(false);
            setResourceFiles(response.data.course.resources);

            dispatch(courseEdited());
            toast.success(response.data.message, { theme: 'colored' });
          }
        },
        (error) => {
          console.log(error);
          setVisibleLoader2(false);
          toast.error(error.response.data.message, { theme: 'colored' });
        }
      );
    } catch (e) {
      setVisibleLoader2(false);
      console.log(e.message);
    }
  };

  const publishCourse = () => {
    setVisibleLoader2(true);

    const courseUpdateRequestParams = getCourseUpdateParams();
    try {
      api.post(`courses/${course.id}/publish`, courseUpdateRequestParams).then(
        (response) => {
          if (
            response.data.message === 'Courses published successfully!' ||
            response.data.message === 'Course is already published!'
          ) {
            if (bannerFile.length > 0 || resourceFiles.length > 0) {
              updateResources(resourceFiles);
            } else {
              setVisibleLoader2(false);
              dispatch(courseEdited());
              toast.success(response.data.message, { theme: 'colored' });
            }
          } else {
            toast.warning(response.data.message, { theme: 'colored' });
          }
        },
        (error) => {
          console.log(error.response.data);
          setVisibleLoader2(false);
          toast.error(error.response.data.message, { theme: 'colored' });
        }
      );
    } catch (e) {
      setVisibleLoader2(false);
      console.log(e.message);
    }
  };

  const deleteCourse = () => {
    setVisibleLoader2(true);

    try {
      api.delete(`courses/${course.id}`).then(
        (response) => {
          toast.success(response.data.message, { theme: 'colored' });
          setVisibleLoader2(false);
          history.push('/Courses');
        },
        (error) => {
          console.log(error);
          setVisibleLoader2(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const setBanner = (file) => {
    setBannerFile(file);
  };

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setResourceFiles([
        ...resourceFiles,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
            options: ['new'],
          })
        ),
      ]);
    },
    [resourceFiles]
  );

  const handleRemoveFile = (inputFile) => {
    try {
      if (inputFile.id) {
        api.delete(`courses/${course.id}/resources/${inputFile.id}`).then(
          (response) => {
            toast.success(response.data.message, { theme: 'colored' });
            const resourceFilesNotDeleted = resourceFiles.filter(
              (obj) => obj.id !== inputFile.id
            );

            setResourceFiles(resourceFilesNotDeleted);
            setVisibleLoader2(false);
          },
          (error) => {
            console.log(error);
            setVisibleLoader2(false);
            toast.error(error.response.data.message, { theme: 'colored' });
          }
        );
      } else {
        const resourceFilesCopy = resourceFiles.filter(
          (obj) => obj !== inputFile
        );

        setResourceFiles(resourceFilesCopy);
      }
    } catch (e) {
      setVisibleLoader2(false);
      console.log(e.message);
    }
  };

  useEffect(() => {
    setResourceFiles(convertServerResourcesToClientResources(course.resources));
  }, [course]);

  return (
    <div>
      <ConfirmationDialog
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        i18nKey="courses.course-delete-confirmation"
        entityName={course.title}
        onConfirm={deleteCourse}
      />
      <div className="col-md-8">
        <ShowInOrganizationPage
          isDesign={false}
          checked={showInOrganizationPublicPage}
          onChange={(checked) => {
            setShowInOrganizationPublicPage(checked);
          }}
        />
      </div>

      {organization?.userPermissions.includes('edit:courses') && (
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="courseEditform">
            <label className="form-label">
              {t('courses.CourseName')}
              <span className="text-danger">*</span>
            </label>
            <div className="row m-0">
              <div className="col-md-8 mb-2 p-0 ">
                <input
                  disabled={course.publish_at}
                  type="text"
                  className={`form-control addCoursesInput ${
                    course.publish_at ? 'disabled' : ''
                  }`}
                  placeholder={t('courses.Typethenamehere')}
                  value={courseTitle}
                  onChange={(e) => {
                    setCourseTitle(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          {createdBy && (
            <div className="courseEditform">
              <label className="form-label">
                {t('courses.createdBy')}
                <span className="text-danger">*</span>
              </label>
              <div className="row m-0">
                <div className="col-md-8 mb-2 p-0 ">
                  <input
                    disabled
                    type="text"
                    className={`form-control addCoursesInput disabled`}
                    placeholder={t('courses.Typethenamehere')}
                    value={createdBy}
                  />
                </div>
              </div>
            </div>
          )}

          {(!course.publish_at || (course.publish_at && editorContent)) && (
            <div className="courseEditform">
              <label className="form-label">{t('common.description')}</label>
              <div className="row m-0">
                <div className="col-md-8 mb-2 p-0">
                  <Box
                    sx={{
                      '.ql-toolbar.ql-snow': {
                        borderColor: '#9bc6fe',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        background: '#ffffff',
                      },
                      '.ql-header.ql-picker': {
                        zIndex: 1000,
                      },
                      '.ql-container.ql-snow': {
                        borderColor: '#9bc6fe',
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        background: '#ffffff',
                      },
                      '.ql-editor': {
                        maxHeight: 250,
                        overflowY: 'auto',
                      },
                    }}
                  >
                    <ReactQuill
                      theme="snow"
                      value={editorContent}
                      onChange={handleEditorChange}
                      modules={editorModules}
                    />
                  </Box>
                </div>
              </div>
            </div>
          )}

          {(!course.publish_at || course.publish_at) && (
            <div className="courseEditform col-md-8">
              <label className="form-label">{t('courses.resources')}</label>
              <DropzoneMulti
                files={resourceFiles}
                multiple
                onDrop={handleDropMultiFile}
                onRemove={handleRemoveFile}
              />
            </div>
          )}

          {(!course.publish_at || (course.publish_at && preassessmentLink)) && (
            <div className="courseEditform">
              <div className="row m-0">
                <div className="col-md-8 mb-2 p-0 ">
                  <label className="form-label">
                    {t('courses.Preassessment')}
                  </label>
                  {!preassessmentLink ? (
                    <TemplateSelect
                      templates={templates}
                      value={preassessmentTemplateId}
                      onChange={(value) => {
                        setPreassessmentTemplateId(value);
                      }}
                    />
                  ) : (
                    <div
                      className="flexonCopy"
                      style={{ position: 'relative' }}
                    >
                      <input
                        disabled
                        className="form-control addCoursesInput"
                        list="templates"
                        id="preassesment-input"
                        value={preassessmentLink}
                        style={{
                          background: '#e9ecef',
                        }}
                        autoComplete="off"
                      />
                      <Tooltip
                        placement="bottom"
                        title={
                          isPreassessmentLinkCopied
                            ? t('common.copied-to-clipboard-message')
                            : t('common.copy-to-clipboard')
                        }
                        arrow
                      >
                        <button
                          hidden={preassessmentLink == null}
                          className="btn"
                          type="button"
                          onMouseLeave={() =>
                            setIsPreassessmentLinkCopied(false)
                          }
                          onClick={() => {
                            var copyText =
                              document.getElementById('preassesment-input');
                            copyText.select();
                            navigator.clipboard.writeText(copyText.value);
                            setIsPreassessmentLinkCopied(true);
                            setIsRegistrationLinkCopied(false);
                            setIsFeedbackFormLinkCopied(false);
                          }}
                        >
                          <FileCopyOutlinedIcon />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <CourseRegistrationSettings
            checked={isRegistrationPageEnabled}
            onChange={(checked) => {
              setIsRegistrationPageEnabled(checked);
            }}
          />

          {course.publish_at && (
            <div className="courseEditform">
              <div className="row m-0">
                <div className="col-md-8 p-0 mb-2">
                  <label className="form-label">
                    {t('courses.registration-link')}
                  </label>
                  <div className="flexonCopy" style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className="form-control"
                      value={registrationLink}
                      id="registrationLink"
                      disabled
                      style={{ border: 'none' }}
                    />
                    <Tooltip
                      placement="bottom"
                      title={
                        isRegistrationLinkCopied
                          ? t('common.copied-to-clipboard-message')
                          : t('common.copy-to-clipboard')
                      }
                      arrow
                    >
                      <button
                        className="btn"
                        type="button"
                        onMouseLeave={() => setIsRegistrationLinkCopied(false)}
                        onClick={() => {
                          var copyText =
                            document.getElementById('registrationLink');
                          copyText.select();
                          navigator.clipboard.writeText(copyText.value);
                          setIsRegistrationLinkCopied(true);
                          setIsPreassessmentLinkCopied(false);
                          setIsFeedbackFormLinkCopied(false);
                        }}
                      >
                        <FileCopyOutlinedIcon />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Stack sx={{ mb: 4 }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Box>
                <label className="form-label">
                  {t('courses.course-feedback-form-checkbox-title')}
                </label>
              </Box>
              <Checkbox
                color="primary"
                disabled={isFeedbackFormSent}
                checked={shouldSendFeedbackForm}
                onChange={(e, checked) => {
                  setShouldSendFeedbackForm(checked);
                }}
              />
            </Stack>

            {shouldSendFeedbackForm && (
              <Box
                className="col-md-8"
                sx={{
                  p: 2,
                  pb: 3,
                  mb: 2,
                  backgroundColor: '#e9ecef33',
                  borderRadius: 1,
                }}
              >
                <div className="courseEditform" style={{ paddingBottom: 0 }}>
                  <div className="row m-0">
                    <div className="col-md-12 mb-2 p-0 ">
                      {/*Feedback form scheduled date*/}
                      <div className="mb-3 addCoursesDate">
                        <Stack direction="row" alignItems="center">
                          <label className="form-label">
                            {isFeedbackFormSent
                              ? t('common.sent')
                              : t(
                                  'courses.course-feedback-form-scheduled-time'
                                )}
                          </label>
                          <Box
                            sx={{ ml: 1, mb: 1 }}
                            hidden={!isFeedbackFormSent}
                          >
                            <CheckIcon htmlColor="#218a4d" />
                          </Box>
                        </Stack>

                        <DateAndTimePicker
                          date={dateAndTime?.date}
                          time={dateAndTime?.time}
                          serverTime={serverTime}
                          onDateChange={(date) => onDateChange(date)}
                          onTimeChange={(time) => onTimeChange(time)}
                        />
                      </div>

                      {/*Select feedback form template*/}
                      <label className="form-label mt-4">
                        {t('courses.course-feedback-form-template')}
                      </label>
                      <Stack
                        className="flexonCopy"
                        direction="row"
                        hidden={
                          feedbackFormLink !== null &&
                          feedbackFormLink !== undefined &&
                          !isEditingFeedbackFormTemplate
                        }
                      >
                        <TemplateSelect
                          templates={templates}
                          value={feedbackFormTemplateId}
                          onChange={(value) => {
                            setFeedbackFormTemplateId(value);
                          }}
                        />
                        <Tooltip
                          placement="bottom"
                          title={t('common.cancel')}
                          arrow
                        >
                          <button
                            hidden={!isEditingFeedbackFormTemplate}
                            className="btn"
                            type="button"
                            onClick={() => {
                              setIsEditingFeedbackFormTemplate(false);
                            }}
                          >
                            <CancelEditIcon />
                          </button>
                        </Tooltip>
                      </Stack>

                      {/*Feedback form link*/}
                      <Box
                        hidden={
                          !feedbackFormLink || isEditingFeedbackFormTemplate
                        }
                      >
                        <div
                          className="flexonCopy"
                          style={{ position: 'relative' }}
                        >
                          <input
                            disabled
                            className="form-control addCoursesInput"
                            list="templates"
                            id="feedback-form-input"
                            value={feedbackFormLink}
                            style={{
                              background: '#e9ecef',
                            }}
                            autoComplete="off"
                          />
                          <Tooltip
                            placement="bottom"
                            title={
                              isFeedbackFormLinkCopied
                                ? t('common.copied-to-clipboard-message')
                                : t('common.copy-to-clipboard')
                            }
                            arrow
                          >
                            <button
                              hidden={feedbackFormLink == null}
                              className="btn"
                              type="button"
                              onMouseLeave={() =>
                                setIsFeedbackFormLinkCopied(false)
                              }
                              onClick={() => {
                                var copyText = document.getElementById(
                                  'feedback-form-input'
                                );
                                copyText.select();
                                navigator.clipboard.writeText(copyText.value);
                                setIsFeedbackFormLinkCopied(true);
                                setIsRegistrationLinkCopied(false);
                                setIsPreassessmentLinkCopied(false);
                              }}
                            >
                              <FileCopyOutlinedIcon />
                            </button>
                          </Tooltip>
                          <Tooltip
                            placement="bottom"
                            title={t('common.edit')}
                            arrow
                          >
                            <button
                              hidden={
                                feedbackFormLink == null || isFeedbackFormSent
                              }
                              className="btn"
                              type="button"
                              onClick={() => {
                                setIsEditingFeedbackFormTemplate(true);
                              }}
                            >
                              <EditIcon />
                            </button>
                          </Tooltip>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </Box>
            )}
          </Stack>

          <div className="courseEditform">
            <div className="row m-0">
              <div className="col-md-8 p-0 ">
                <label className="form-label">{t('common.upload-image')}</label>
                <Dropzone
                  course={course}
                  image={bannerFile}
                  setImage={setBanner}
                />
              </div>
            </div>
          </div>

          {organizationStripeAccount && (
            <>
              {(!organizationStripeAccount.charges_enabled ||
                !organizationStripeAccount.payouts_enabled) && (
                <Stack
                  sx={{
                    mb: 4,
                    maxWidth: '600px',
                    p: 2,
                    backgroundColor: '#e9ecef33',
                  }}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  direction="row"
                  justifyContent="center"
                  justifySelf="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Stack justifyContent="center" alignItems="center">
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <WarningAmberIcon
                        sx={{ mb: 1, color: orange[600] }}
                      ></WarningAmberIcon>
                      <Box sx={{ mt: 1, color: orange[600] }}>
                        <Typography variant="body1" style={{ marginBottom: 0 }}>
                          {t(
                            'payments.messages.stripe-account-config-issues-1'
                          )}
                        </Typography>
                      </Box>
                    </Stack>

                    <Button
                      sx={{ mt: 2 }}
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        history.push('/Payments');
                      }}
                    >
                      {t('payments.go-to-payments-section')}
                    </Button>
                  </Stack>
                </Stack>
              )}

              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                hidden={
                  !course.stripe_price_id &&
                  (course.publish_at ||
                    !organizationStripeAccount.charges_enabled ||
                    !organizationStripeAccount.payouts_enabled)
                }
              >
                <Box sx={{ mt: 1, mb: 0 }}>
                  <label className="form-label">
                    {t('courses.paid-course')}
                  </label>
                </Box>
                <Checkbox
                  color="primary"
                  checked={isPaidCourse}
                  disabled={course.publish_at}
                  onChange={(e, checked) => {
                    setIsPaidCourse(checked);
                  }}
                />
              </Stack>
            </>
          )}

          {isPaidCourse && (
            <Box sx={{ p: 2, backgroundColor: '#e9ecef33', borderRadius: 1 }}>
              <label className="form-label">
                {t('courses.price')}
                {' ('}
                {t('courses.vat-excluded')}
                {')'}
              </label>
              <Stack
                direction="row"
                spacing={2}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <FormControl sx={{ width: 90 }}>
                  <StyledSelect
                    id="currency-select"
                    disabled={course.publish_at}
                    value={currency}
                    onChange={onCurrencySelected}
                  >
                    {currencies.map((currency, index) => (
                      <MenuItem value={currency}>{currency}</MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                <div className="col-md-3 mb-2 p-0 ">
                  <CurrencyInputField
                    className="form-control addCoursesInput"
                    disabled={course.publish_at}
                    name="priceInput"
                    price={price}
                    currency={currency}
                    onPriceValueChange={onPriceValueChange}
                  />
                </div>
              </Stack>
            </Box>
          )}

          <div className="submitSection">
            <button
              className="btn text-danger"
              onClick={() => {
                if (course !== undefined) {
                  setIsPopupOpen(true);
                }
              }}
            >
              {t('courses.DeleteCourse')}
            </button>
            {course.status === 'Draft' && (
              <button
                className="btn btn-primary me-5"
                onClick={() => {
                  setIsPublishConfirmationDialogOpen(true);
                }}
              >
                {t('courses.Publish')}
              </button>
            )}
            {course !== undefined && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (course !== undefined) {
                    validateAndUpdate(false);
                  }
                }}
              >
                {t('courses.Update')}
              </button>
            )}
          </div>
        </form>
      )}

      <ConfirmationDialog
        isOpen={isPublishConfirmationDialogOpen}
        onClose={() => {
          setIsPublishConfirmationDialogOpen(false);
        }}
        i18nKey="courses.publish-confirmation-question"
        onConfirm={() => {
          validateAndUpdate(true);
        }}
      />

      <Loader isVisible={visibleLoader2} />

      <Loader isVisible={visibleLoader} />
    </div>
  );
};

export default CourseGeneralSettings;
