// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// @mui
import { IconButton, InputAdornment, Stack } from '@mui/material'
import { StyledButton } from 'common/styled-component/new-template/StyledButton'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
//icon
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import InfoIcon from '@mui/icons-material/Info'
//react-hook-form
import FormProviderCustom from 'Component/ReactHookFormComponents/FormProviderCustom'
import RHFTextField from 'Component/ReactHookFormComponents/RHFTextField'
//hook
import { ChangePasswordValidationSchema } from 'common/form-validation/ChangePasswordValidationSchema'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import MeService from 'Service/MeService'
import { objectIsEmpty } from 'utils/utils'

// ----------------------------------------------------------------------

export type IUserAccountChangePassword = {
  newPassword: string
  confirmNewPassword: string
}

type FormValuesProps = IUserAccountChangePassword

export default function AccountChangePassword() {
  const [showPassword, setShowPassword] = useState(false)

  const { t } = useTranslation()
  const { patchChangePassword } = MeService()

  const defaultValues = {
    newPassword: '',
    confirmNewPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(ChangePasswordValidationSchema(t)),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    await patchChangePassword(data)
    reset()
  }

  return (
    <FormProviderCustom methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <StyledCard>
        <Stack spacing={3} alignItems="flex-end" sx={{ p: 3 }}>
          <RHFTextField
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            label={t('changePassword.NewPassword')}
            disabled={isSubmitting}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={
              <Stack
                component="span"
                direction="row"
                alignItems="center"
                sx={{
                  alignItems: 'start',
                  mt: '0.5rem',
                  color: 'rgb(99, 115, 129)',
                }}
              >
                <InfoIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
                {t('user-account.info-password')}
              </Stack>
            }
          />

          <RHFTextField
            name="confirmNewPassword"
            type={showPassword ? 'text' : 'password'}
            label={t('changePassword.confirmPassword')}
            disabled={isSubmitting}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <StyledButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={isSubmitting || objectIsEmpty(errors)}
          >
            {t('common.save-changes')}
          </StyledButton>
        </Stack>
      </StyledCard>
    </FormProviderCustom>
  )
}
