import { Stack, Typography, InputLabel, Box, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'
import FormTextField from 'Component/form-fields/FormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FunderWithChannels,
  FundingChannel,
  Notice,
} from 'Component/FundedTraining/types'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import {
  NoticeFormData,
  NoticeFormValidation,
} from 'common/form-validation/NoticeFormValidation'
import { orange } from '@mui/material/colors'
import FtFormTextField from 'Component/form-fields/FtFormTextField'
import FunderAutoComplete from '../FunderAutocomplete'
import { current } from '@reduxjs/toolkit'
import FundingChannelAutoComplete from '../FundingChannelAutocomplete'

type Props = {
  currentNotice?: Notice
  isNew?: boolean
  onItemUpdated?(notice: Notice): void
}

export function NoticeForm(props: Props) {
  const { currentNotice = null, isNew = false, onItemUpdated } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [funders, setFunders] = useState<FunderWithChannels[]>([])
  const [fundingChannels, setFundingChannels] = useState<FundingChannel[]>([])
  const [selectedFunder, setSelectedFunder] =
    useState<null | FunderWithChannels>(null)
  const [selectedFundingChannel, setSelectedFundingChannel] =
    useState<null | FundingChannel>(null)

  const formMethods = useForm<NoticeFormData>({
    resolver: yupResolver(NoticeFormValidation(t)),
  })

  const getFunders = useCallback(() => {
    if (organization) {
      setIsLoading(true)
      try {
        api
          .get('funded_training/funders.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              setFunders(response.data)
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
      } catch (e: any) {
        setIsLoading(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  const getFundingChannelsForFunder = useCallback(() => {
    if (organization && selectedFunder) {
      setIsLoading(true)
      try {
        api
          .get(
            `funded_training/funders/${selectedFunder?.uuid}/funding_channels`,
            {
              params: {
                organization_id: organization.id,
              },
            }
          )
          .then(
            (response) => {
              setFundingChannels(response.data)
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
      } catch (e: any) {
        setIsLoading(false)
        console.log(e.message)
      }
    }
  }, [api, organization, selectedFunder])

  useEffect(() => {
    getFunders()
  }, [getFunders, currentNotice])

  useEffect(() => {
    getFundingChannelsForFunder()
  }, [getFundingChannelsForFunder, selectedFunder])

  const updateNotice = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.put(`funded_training/notices/${currentNotice?.uuid}`, data).then(
        (response) => {
          toast.success(response.data.message, {
            theme: 'colored',
          })
          if (onItemUpdated) {
            onItemUpdated(response.data.notice)
          }

          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createNotice = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.post('funded_training/notices', data).then(
        (response) => {
          toast.success(t('funded-training.notice-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.goBack()
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: NoticeFormData) => {
    const params: Record<string, any> = {}

    params['title'] = data.title
    params['notice_code'] = data.notice_code
    if (selectedFundingChannel) {
      params['funding_channel_id'] = selectedFundingChannel.id
    }
    params['valid_course_min_valid_participants'] =
      data.valid_course_min_valid_participants
    params['valid_participant_min_attended_hours_pct'] =
      data.valid_participant_min_attended_hours_pct

    if (organization) {
      params['organization_id'] = organization.id
    }

    if (isNew) {
      createNotice(params)
    } else {
      updateNotice(params)
    }
  }

  useEffect(() => {
    if (currentNotice) {
      if (currentNotice.funding_channel) {
        setSelectedFundingChannel(currentNotice.funding_channel)
        setSelectedFunder(currentNotice.funding_channel?.funder)
      }
      formMethods.setValue('title', currentNotice.title)
      formMethods.setValue('notice_code', currentNotice.notice_code)
      formMethods.setValue(
        'valid_course_min_valid_participants',
        currentNotice.valid_course_min_valid_participants
      )
      formMethods.setValue(
        'valid_participant_min_attended_hours_pct',
        currentNotice.valid_participant_min_attended_hours_pct
      )
    }
  }, [formMethods, currentNotice])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          <Stack
            mt={4}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Stack>
              <FtFormTextField
                name="title"
                type="text"
                label={t('common.title')}
                mandatory={true}
              />

              <FtFormTextField
                name="notice_code"
                type="text"
                label={t('funded-training.notice-code')}
              />

              <InputLabel sx={{ color: '#1976d2' }}>
                <Typography variant="subtitle1">
                  {t('funded-training.funder')}
                </Typography>
              </InputLabel>

              <Box sx={{ mb: 2 }}>
                <FunderAutoComplete
                  allFunders={funders}
                  selectedItem={selectedFunder}
                  onUpdated={(newFunder: null | FunderWithChannels) => {
                    setSelectedFunder(newFunder)
                    setSelectedFundingChannel(null)
                  }}
                ></FunderAutoComplete>
              </Box>

              <InputLabel sx={{ color: '#1976d2' }}>
                <Typography variant="subtitle1">
                  {t('funded-training.funding-channel')}
                </Typography>
              </InputLabel>

              <Box sx={{ mb: 2 }}>
                <FundingChannelAutoComplete
                  allFundingChannels={fundingChannels}
                  funder={selectedFunder}
                  selectedItem={selectedFundingChannel}
                  onUpdated={(newItem: null | FundingChannel) => {
                    setSelectedFundingChannel(newItem)
                  }}
                ></FundingChannelAutoComplete>
              </Box>

              <Typography
                variant="h6"
                sx={{
                  mt: 1,
                  fontFamily: 'nexalight',
                  color: orange[500],
                }}
              >
                {t('funded-training.notice-detail.activity-validation-rules')}
              </Typography>
              <Divider
                variant="fullWidth"
                sx={{ mb: 1, backgroundColor: '#c0c0c0' }}
              />
              <FtFormTextField
                name="valid_course_min_valid_participants"
                type="number"
                label={t(
                  'funded-training.notice-detail.valid-course-min-valid-participants'
                )}
                tooltipDescription={t(
                  'funded-training.notice-detail.valid-course-min-valid-participants-description'
                )}
              />
              <FtFormTextField
                name="valid_participant_min_attended_hours_pct"
                type="number"
                label={t(
                  'funded-training.notice-detail.valid-participant-min-attended-hours-pct'
                )}
                tooltipDescription={t(
                  'funded-training.notice-detail.valid-participant-min-attended-hours-pct-description'
                )}
              />

              <Box sx={{ mt: 2 }} />
            </Stack>
            <StyledButton
              type="submit"
              startIcon={<SaveIcon />}
              hidden={isLoading}
              sx={{
                backgroundColor: '#0d6efd !important',
              }}
            >
              <Typography sx={{ mt: 0.5 }}>
                {isNew ? t('common.create') : t('common.update')}
              </Typography>
            </StyledButton>
            <Loader isVisible={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}
