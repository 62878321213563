import { Box, Checkbox, Link, Stack, Typography } from '@mui/material'
import { StyledButton } from 'common/styled-component/StyledButton'
import { JSONValue } from 'common/types/Json'
import { Survey } from 'Component/CourseRegistration/Models'
import Loader from 'Component/Loader'
import SurveyCreator from 'Component/SurveyCreator'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import SaveIcon from '@mui/icons-material/Save'
import useWindowDimensions from 'common/hooks/useWindowDimensions'

interface SurveyForm {
  isLoaderVisible: boolean
  isPreview: boolean
  survey: Survey
  json: JSONValue | null
  setSurvey: (value: Survey) => void
  setJson: (value: JSONValue) => void
  createSurvey: () => void
  updateSurvey: () => void
}

const SurveyForm = (props: SurveyForm) => {
  const {
    isLoaderVisible,
    isPreview,
    survey,
    json,
    setJson,
    setSurvey,
    createSurvey,
    updateSurvey,
  } = props
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { width } = useWindowDimensions()

  const { name, description, is_markdown_enabled, redirect_url } = survey

  return (
    <>
      {!isLoaderVisible && (
        <div>
          <Container>
            <Stack direction={width >= 1000 ? 'row' : 'column'}>
              <Stack sx={{ width: 700, mr: 2 }}>
                <Typography
                  sx={{ ml: 0.5, color: '#0d6efd' }}
                  fontSize={16}
                  fontFamily="nexa"
                >
                  {t('survey.title')}&nbsp;
                </Typography>
                <input
                  hidden={isPreview && !name}
                  type="text"
                  className="form-control mb-3"
                  placeholder={t('survey.title')}
                  defaultValue={name ?? ''}
                  onBlur={(e) => {
                    setSurvey({ ...survey, name: e.target.value })
                  }}
                  readOnly={isPreview}
                />
              </Stack>

              <Stack sx={{ width: 700 }} hidden={isPreview && !description}>
                <Typography
                  sx={{ ml: 0.5, color: '#0d6efd' }}
                  fontSize={16}
                  fontFamily="nexa"
                >
                  {t('survey.description')}&nbsp;
                </Typography>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder={t('survey.description')}
                  defaultValue={description ?? ''}
                  onBlur={(e) => {
                    setSurvey({ ...survey, description: e.target.value })
                  }}
                  readOnly={isPreview}
                />
              </Stack>
            </Stack>
            <Stack direction={width >= 1000 ? 'row' : 'column'}>
              <Stack
                sx={{ width: 700, mr: 2 }}
                hidden={isPreview && !redirect_url}
              >
                <Typography
                  sx={{ ml: 0.5, color: '#0d6efd' }}
                  fontSize={16}
                  fontFamily="nexa"
                >
                  {t('survey.redirect-url')}&nbsp;
                </Typography>
                <Typography
                  fontSize={16}
                  sx={{ ml: 0.5 }}
                  fontFamily="nexalight"
                >
                  {t('survey.redirect-url-description')}&nbsp;
                </Typography>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder={t('survey.redirect-url')}
                  defaultValue={redirect_url || ''}
                  onBlur={(e) => {
                    setSurvey({ ...survey, redirect_url: e.target.value })
                  }}
                  readOnly={isPreview}
                />
              </Stack>

              {!isPreview && (
                <Stack sx={{ width: 700 }}>
                  <Typography
                    sx={{ ml: 0.5, color: '#0d6efd' }}
                    fontSize={16}
                    fontFamily="nexa"
                  >
                    {t('survey.enable-markdown-title')}&nbsp;
                  </Typography>
                  <Typography fontSize={16} fontFamily="nexalight">
                    {t('survey.enable-markdown-description')}&nbsp;
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Checkbox
                      color="primary"
                      sx={{ pl: 0 }}
                      checked={is_markdown_enabled ?? null}
                      onChange={(e, checked) => {
                        setSurvey({ ...survey, is_markdown_enabled: checked })
                      }}
                    />
                    <Typography sx={{ mt: 1 }} fontSize={16} fontFamily="nexa">
                      {t('survey.enable-markdown')}&nbsp;
                    </Typography>
                    <Link
                      sx={{ fontWeight: 'bold', pl: 2 }}
                      href={'https://www.markdownguide.org/cheat-sheet'}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t('survey.enable-markdown-more-info')}
                    </Link>
                  </Stack>
                </Stack>
              )}
            </Stack>
            {!isLoaderVisible && (
              <StyledButton
                startIcon={<SaveIcon />}
                sx={{
                  backgroundColor: '#0d6efd !important',
                  mb: 2,
                  px: 2,
                  py: 1,
                }}
                onClick={(event) => {
                  if (!json) {
                    toast.warning(t('survey.warning.at-least-one-question'), {
                      theme: 'colored',
                    })
                    return
                  }

                  setTimeout(() => {
                    if (pathname.includes('/create')) {
                      createSurvey()
                    } else {
                      updateSurvey()
                    }
                  }, 200)
                }}
              >
                <Typography sx={{ mt: 0.5 }}>{t('survey.save')}</Typography>
              </StyledButton>
            )}
          </Container>
          <SurveyCreator
            surveyJSON={json}
            onSurveySaved={(json) => {
              setJson(json)
            }}
            isPreview={isPreview}
            isMarkdownEnabled={is_markdown_enabled}
          />
        </div>
      )}
      <Loader isVisible={isLoaderVisible} />
    </>
  )
}

export default SurveyForm
