import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { localizedUTCMoment } from 'common/DateTimeUtils'
import moment from 'moment-timezone'

type Props = {
  courseStartDate: string
}

const CourseCountdown = ({ courseStartDate }: Props) => {
  const [currentMoment, setCurrentMoment] = useState(
    localizedUTCMoment(undefined)
  )

  const courseStartMoment = localizedUTCMoment(courseStartDate)
  const timeDiff = courseStartMoment.diff(currentMoment)
  const timeToCourse = timeDiff > 0 ? timeDiff : 0
  const duration = moment.duration(timeToCourse)
  const { t } = useTranslation()

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentMoment(() => localizedUTCMoment(undefined))
    }, 1000)

    return () => clearInterval(interval)
  }, [currentMoment])

  const days = Math.trunc(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  return (
    <>
      {timeToCourse > 0 ? (
        <div className="countdown-grid">
          <div>
            <div className="countdown-grid-item">{days}</div>
            <div className="countdown-grid-item">
              {t('countdown.day', { count: days }).toUpperCase()}
            </div>
          </div>
          <div>
            <div className="countdown-grid-item">{hours}</div>
            <div className="countdown-grid-item">
              {t('countdown.hour', { count: hours }).toUpperCase()}
            </div>
          </div>
          <div>
            <div className="countdown-grid-item">{minutes}</div>
            <div className="countdown-grid-item">
              {t('countdown.minute', { count: minutes }).toUpperCase()}
            </div>
          </div>
          <div>
            <div className="countdown-grid-item">{seconds}</div>
            <div className="countdown-grid-item">
              {t('countdown.second', { count: seconds }).toUpperCase()}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CourseCountdown
