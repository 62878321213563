import useApi from 'api/UseApi'
import { toast } from 'react-toastify'
import { Session } from '../View/course/AddSession/AddSession'

export type CourseData = {
  title: string
  organization_id: number | null
  survey_template_id: any
  description_markup: any
  sessions_attributes: any
}

const SessionsService = () => {
  const api = useApi(false)

  const postSessions = async (courseId: any, newSessions: any) => {
    try {
      return await api
        .post(`courses/${courseId}/sessions`, {
          sessions: newSessions,
        })
        .then(
          (response) => {
            toast.success(response.data.message, { theme: 'colored' })
            return response.data.sessions
          },
          (error) => {
            toast.error(error.response.data.message, { theme: 'colored' })
          }
        )
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
    }
  }

  const putSession = async (
    singleSession: Partial<Session>,
    newSessions: any
  ) => {
    try {
      return await api
        .put(
          `courses/${singleSession.course_id}/sessions/${singleSession.id}`,
          newSessions[0]
        )
        .then(
          (response) => {
            toast.success(response.data.message, { theme: 'colored' })
            return response.data.session
          },
          (error) => {
            toast.error(error.response.data.message, { theme: 'colored' })
          }
        )
    } catch (e: any) {
      toast.error(e.message, { theme: 'colored' })
    }
  }

  return { postSessions, putSession }
}

export default SessionsService
