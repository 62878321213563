import { useTranslation } from 'react-i18next'
import { PowerBIEmbed } from 'powerbi-client-react'

import '../assets/scss/dashboard.scss'
import { models, Embed, service, Dashboard } from 'powerbi-client'
import { useState, useCallback, useEffect } from 'react'
import useApi from 'api/UseApi'
import Loader from 'Component/Loader'

declare global {
  interface Window {
    dashboard: any
  }
}

const PowerBIDashboard = () => {
  const { t } = useTranslation()

  const [dashboard, setDashboard] = useState<Dashboard>()
  const [isLoaderVisible, setIsLoaderVisible] = useState(true)

  const api = useApi(false)

  const [dashboardConfig, setDashboardConfig] =
    useState<null | models.IDashboardEmbedConfiguration>(null)

  const embedEventHandlers = new Map([
    [
      'loaded',
      function () {
        console.log('Dashboard has loaded')
      },
    ],
    [
      'rendered',
      function () {
        console.log('Dashboard has rendered')
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail)
        }
      },
    ],
  ])

  const getDashboardEmbedData = useCallback(() => {
    setIsLoaderVisible(true)
    api.get('hosting/pbi/dashboard-embed-data').then(
      (response) => {
        setIsLoaderVisible(false)

        setDashboardConfig({
          type: 'dashboard',
          id: 'c07843a6-67df-42cd-886e-2557ee8f5418',
          embedUrl: response.data.embedUrl,
          tokenType: models.TokenType.Embed,
          accessToken: response.data.embedToken,
          settings: undefined,
        })

        dashboard?.reload()
      },
      (error) => {
        setIsLoaderVisible(false)
      }
    )
  }, [api, dashboard])

  useEffect(() => {
    getDashboardEmbedData()
  }, [getDashboardEmbedData])

  return (
    <div className="">
      <div className="">
        <h4> {t('Dashboard')}</h4>
        <Loader isVisible={isLoaderVisible} />

        {dashboardConfig && (
          <PowerBIEmbed
            embedConfig={dashboardConfig}
            eventHandlers={embedEventHandlers}
            cssClassName={'power-bi-dashboard'}
            getEmbeddedComponent={(embedObject: Embed) => {
              window.dashboard = embedObject as Dashboard
              setDashboard(embedObject as Dashboard)
            }}
          />
        )}

        {!dashboardConfig && !isLoaderVisible && (
          <h1
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ebedef',
            }}
          >
            {t('NoRecordFound')}
          </h1>
        )}
      </div>
    </div>
  )
}

export default PowerBIDashboard
