import { Box, Link, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useLocation, useParams, NavLink } from 'react-router-dom'
import useApi from 'api/UseApi'
import Loader from 'Component/Loader'
import { useTranslation } from 'react-i18next'
import { Activity } from 'Component/FundedTraining/types'
import { ActivityForm } from './ActivityForm'
import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'
import {
  selectShouldPreselectParticipantsTab,
  setShouldPreselectParticipantsTab,
} from 'Reducers/FundedTraining/ActivityReducer'
import ActivityTabPanel from './ActivityTabPanel'
import { ActivityParticipants } from './ActivityParticipant/ActivityParticipants'
import { ActivityOverview } from './ActivityOverview'

type RouteParams = {
  projectUuid: string
  activityUuid: string
}

type LocationState = {
  activityTitle: string
}

type Props = {
  isNew?: boolean
}

function a11yProps(index: number) {
  return {
    id: `activity-tab-${index}`,
    'aria-controls': `activity-tabpanel-${index}`,
  }
}

export function ActivityDetail(props: Props) {
  const { isNew = false } = props
  const { t } = useTranslation()

  const { projectUuid, activityUuid } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()

  const api = useApi(false)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentActivity, setCurrentActivity] = useState<null | Activity>(null)
  const [activityName, setActivityName] = useState<string>('')

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  const shouldPreselectParticipantsTab = useAppSelector(
    selectShouldPreselectParticipantsTab
  )

  useEffect(() => {
    if (shouldPreselectParticipantsTab) {
      setTabIndex(2)
      dispatch(setShouldPreselectParticipantsTab(false))
    }
  }, [shouldPreselectParticipantsTab])

  const getActivity = useCallback(() => {
    setIsLoading(true)
    try {
      api
        .get(
          `funded_training/projects/${projectUuid}/activities/${activityUuid}`
        )
        .then(
          (response) => {
            setIsLoading(false)
            setCurrentActivity(response.data)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, activityUuid])

  useEffect(() => {
    getActivity()
  }, [getActivity, activityUuid])

  useEffect(() => {
    if (currentActivity) {
      if (activityName === '') {
        setActivityName(currentActivity.title)
      }
    } else if (state) {
      if (activityName === '') {
        setActivityName(state.activityTitle)
      }
    }
  }, [state, currentActivity])

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h5" sx={{ mb: 1, color: '#000000' }}>
        <Trans i18nKey={'funded-training.activity-detail.title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd', fontSize: '2rem' }}>
            <>{{ activityName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>
      <NavLink to={`/funded-training/projects/${projectUuid}`} exact>
        <Link variant="body1">{t('funded-training.go-to-project')}</Link>
      </NavLink>

      {currentActivity && (
        <>
          {currentActivity.course && (
            <Stack
              direction="row"
              sx={{
                py: 1,
                px: 2,
                mt: 5,
                width: 'fit-content',
                borderRadius: 1,
                backgroundColor: 'rgba(25, 118, 210, 0.08)',
              }}
            >
              <Typography variant="h6" sx={{ mr: 1, color: '#000000' }}>
                {t('funded-training.activity-detail.associated-course')}
              </Typography>
              <strong style={{ color: '#0d6efd', fontSize: '1rem' }}>
                <NavLink to={`/Courses/${currentActivity.course.id}`} exact>
                  <Link variant="h6">{`${currentActivity.course.title}`}</Link>
                </NavLink>
              </strong>
            </Stack>
          )}

          <Tabs
            sx={{ mt: 4 }}
            value={tabIndex}
            onChange={onTabChange}
            aria-label="activity tabs"
          >
            <Tab label={t('common.overview')} {...a11yProps(1)} />
            <Tab label={t('common.config')} {...a11yProps(1)} />
            <Tab label={t('common.participants')} {...a11yProps(0)} />
          </Tabs>

          <ActivityTabPanel value={tabIndex} index={0}>
            <ActivityOverview activity={currentActivity} />
          </ActivityTabPanel>

          <ActivityTabPanel value={tabIndex} index={1}>
            <ActivityForm
              projectUuid={projectUuid}
              currentActivity={currentActivity}
              isNew={isNew}
              onActivityUpdated={(newActivity: Activity) => {
                setActivityName(newActivity.title)
              }}
            />
          </ActivityTabPanel>
          <ActivityTabPanel value={tabIndex} index={2}>
            <ActivityParticipants
              projectUuid={projectUuid}
              activityUuid={currentActivity.uuid}
              onItemDeleted={() => {
                getActivity()
              }}
            />
          </ActivityTabPanel>
        </>
      )}
      <Loader isVisible={isLoading} />
    </Box>
  )
}
