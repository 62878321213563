import { Stack, Typography, InputLabel, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'
import FormTextField from 'Component/form-fields/FormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Employee } from 'Component/FundedTraining/types'
import {
  EmployeeFormData,
  EmployeeFormValidation,
} from 'common/form-validation/EmployeeFormValidation'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'

type Props = {
  currentEmployee?: Employee
  isNew?: boolean
  onEmployeeUpdated?(employee: Employee): void
}

export function EmployeeForm(props: Props) {
  const { currentEmployee = null, isNew = false, onEmployeeUpdated } = props

  const history = useHistory()

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const formMethods = useForm<EmployeeFormData>({
    resolver: yupResolver(EmployeeFormValidation(t)),
  })

  const updateEmployee = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.put(`funded_training/employees/${currentEmployee?.uuid}`, data).then(
        (response) => {
          toast.success(t('funded-training.employee-updated'), {
            theme: 'colored',
          })
          if (onEmployeeUpdated) {
            onEmployeeUpdated(response.data.employee)
          }

          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createEmployee = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.post('funded_training/employees', data).then(
        (response) => {
          toast.success(t('funded-training.employee-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.goBack()
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: EmployeeFormData) => {
    const params: Record<string, any> = {}

    params['name'] = data.name
    params['surname'] = data.surname
    params['fiscal_code'] = data.fiscal_code

    if (organization) {
      params['organization_id'] = organization.id
    }

    if (isNew) {
      createEmployee(params)
    } else {
      updateEmployee(params)
    }
  }

  useEffect(() => {
    if (currentEmployee) {
      formMethods.setValue('name', currentEmployee.name)
      formMethods.setValue('surname', currentEmployee.surname)
      formMethods.setValue('fiscal_code', currentEmployee.fiscal_code)
    }
  }, [formMethods, currentEmployee])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          <Stack
            mt={4}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Stack>
              <FormTextField
                name="name"
                type="text"
                label={t('common.name')}
                mandatory={true}
              />
              <FormTextField
                name="surname"
                type="text"
                label={t('common.surname')}
                mandatory={true}
              />

              <FormTextField
                name="fiscal_code"
                type="text"
                label={t('common.fiscal-code')}
                mandatory={true}
              />

              <Box sx={{ mt: 2 }} />
            </Stack>
            <StyledButton
              type="submit"
              startIcon={<SaveIcon />}
              hidden={isLoading}
              sx={{
                backgroundColor: '#0d6efd !important',
              }}
            >
              <Typography sx={{ mt: 0.5 }}>
                {isNew ? t('common.create') : t('common.update')}
              </Typography>
            </StyledButton>
            <Loader isVisible={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}
