import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { EmployeeForm } from './EmployeeForm'

export function NewEmployee() {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20, mr: 5 }}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('funded-training.new-employee')}
        </Typography>
        <NavLink to={`/funded-training/employees`} exact>
          <Link variant="body1">
            {t('funded-training.go-to-employee-list')}
          </Link>
        </NavLink>
        <EmployeeForm isNew={true} />
      </Stack>
    </Box>
  )
}
