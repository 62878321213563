import { TFunction } from 'i18next'
import { string, object, SchemaOf } from 'yup'
import { urlRegExp } from 'common/RegularExpressions'

export type SessionDesignFormData = {
  name: string
  description: string | null
  address: string | null
  meeting_link: string | null
}

export const SessionDesignFormValidation = (t: TFunction) => {
  const schema: SchemaOf<SessionDesignFormData> = object().shape({
    name: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.title')),
    description: string()
      .nullable()
      .default(null)
      .label(t('common.description')),
    address: string().nullable().default(null).label(t('courses.Address')),
    meeting_link: string()
      .nullable()
      .default(null)
      .test(
        'valid-url',
        t('forms.validation.url.not-valid'),
        (field) => !field || urlRegExp.test(field)
      )
      .label(t('common.meeting-link')),
  })

  return schema
}
