import { Box, Typography } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { Template } from 'Component/Mail/NotificationServiceTypes'
import { TemplateForm } from './TemplateForm'

type RouteParams = {
  templateId: string
}

type LocationState = {
  templateName: string
}

export function TemplateDetail() {
  const { templateId } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const api = useApi(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentTemplate, setCurrentTemplate] = useState<null | Template>(null)
  const [screenTitle, setScreenTitle] = useState<string>('')

  const getTemplate = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`notification-service/templates/${templateId}`).then(
        (response) => {
          setIsLoading(false)
          setCurrentTemplate(response.data.data)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, templateId])

  useEffect(() => {
    getTemplate()
  }, [getTemplate, templateId])

  useEffect(() => {
    if (currentTemplate) {
      if (screenTitle === '') {
        setScreenTitle(currentTemplate.name)
      }
    } else if (state) {
      if (screenTitle === '') {
        setScreenTitle(state.templateName)
      }
    }
  }, [state, currentTemplate, screenTitle])

  return (
    <Box>
      <Box>
        <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
          <Trans i18nKey={'mail.template-detail-title'}>
            {'placeholder '}
            <strong style={{ color: '#0d6efd', fontSize: '2rem' }}>
              <>{{ screenTitle }}</>
            </strong>
            {' placeholder'}
          </Trans>
        </Typography>
        {currentTemplate && (
          <TemplateForm
            currentTemplate={currentTemplate}
            onTemplateNameUpdated={(templateName) => {
              setScreenTitle(templateName)
            }}
          />
        )}
      </Box>
    </Box>
  )
}
