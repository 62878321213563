export const meetingAddressHtml =
  '' +
  '<div class="wyblo-component-meeting-address"><br/>' +
  '<table data-gjs-draggable="false"' +
  '  style="font-family: \'Montserrat\', sans-serif"' +
  '  role="presentation"' +
  '  cellpadding="0"' +
  '  cellspacing="0"' +
  '  width="100%"' +
  '  border="0"' +
  '>' +
  '  <tbody data-gjs-draggable="false">' +
  '    <tr data-gjs-draggable="false">' +
  '      <td data-gjs-draggable="false"' +
  '        style="' +
  '          overflow-wrap: break-word;' +
  '          word-break: break-word;' +
  '          padding: 5px 14px 10px;' +
  "          font-family: 'Montserrat', sans-serif;" +
  '        "' +
  '        align="left"' +
  '      >' +
  '        <div data-gjs-draggable="false"' +
  '          style="line-height: 170%; text-align: center; word-wrap: break-word"' +
  '        >' +
  '          <p  data-gjs-draggable="false" style="font-size: 14px; line-height: 170%; text-align: left">' +
  '            <span data-gjs-draggable="false"' +
  '              style="' +
  '                font-size: 18px;' +
  '                line-height: 30.6px;' +
  '                color: #6f6d6d;' +
  '                font-family: verdana, geneva;' +
  '              "' +
  "              >Ti aspettiamo all'indirizzo:</span" +
  '            >' +
  '          </p>' +
  '          <p  data-gjs-draggable="false" style="font-size: 14px; line-height: 170%; text-align: center">' +
  '            <span  data-gjs-draggable="false" style="color: #000000; font-size: 14px; line-height: 23.8px"' +
  '              ><strong data-gjs-draggable="false"' +
  '                ><span data-gjs-draggable="false"' +
  '                  style="' +
  '                    font-size: 18px;' +
  '                    line-height: 30.6px;' +
  '                    font-family: verdana, geneva;' +
  '                  "' +
  '                  >{{address}}</span' +
  '                ></strong' +
  '              ></span' +
  '            >' +
  '          </p>' +
  '        </div>' +
  '      </td>' +
  '    </tr>' +
  '  </tbody>' +
  '</table>' +
  '</div>' +
  ''
