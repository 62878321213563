import useApi from 'api/UseApi'
import { Survey } from 'Component/CourseRegistration/Models'

const SurveysService = () => {
  const newBackend = useApi(false)

  const getSurveyByID = async (surveyID: string) => {
    try {
      let response = await newBackend.get(`surveys/${surveyID}`)
      return response.data
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const getTemplateByID = async (templateID: string) => {
    try {
      let response = await newBackend.get(`templates/${templateID}`)
      return response.data
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return { getSurveyByID, getTemplateByID }
}

export default SurveysService
