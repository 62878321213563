import { Tooltip } from '@mui/material'
import { Avatar, AvatarGroup } from '@mui/material'

type Props = {
  trainers: any[]
  maxAvatars?: number
}

const TrainerAvatars = ({ trainers, maxAvatars = 3 }: Props) => {
  function stringToColor(name: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.substr(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  return (
    <AvatarGroup max={1000000}>
      {trainers != null &&
        trainers.slice(0, maxAvatars).map((trainer: any, index) => (
          <Tooltip key={trainer.email} title={trainer.email}>
            <Avatar
              sx={{
                marginTop: '0.313rem',
                width: '2rem',
                height: '2rem',
                fontSize: '.75rem',
                backgroundColor: stringToColor(trainer.email),
              }}
            >
              {trainer.short_name}
            </Avatar>
          </Tooltip>
        ))}

      {trainers != null && trainers.length > maxAvatars && (
        <Tooltip
          title={
            <>
              {trainers
                .slice(maxAvatars, trainers.length)
                .map((trainer: any, index) => (
                  <>
                    <span key={trainer.email}>{trainer.email}</span> <br />
                  </>
                ))}
            </>
          }
        >
          <Avatar
            sx={{
              marginTop: '0.313rem',
              width: '2rem',
              height: '2rem',
              fontSize: '.75rem',
            }}
          >{`+${trainers.length - maxAvatars}`}</Avatar>
        </Tooltip>
      )}
    </AvatarGroup>
  )
}

export default TrainerAvatars
