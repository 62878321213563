import {
  Typography,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { Template } from 'Component/Mail/NotificationServiceTypes'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import EmailPlaceholders from '../EmailPlaceholders'
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import { GrapesjsReact } from 'grapesjs-react'
import 'grapesjs-preset-newsletter'
import EditorComponentsDescriptions from 'Component/Mail/Transactional/EditorComponentsDescriptions'
import { preAssessmentHtml } from 'Component/Mail/Transactional/EditorComponents/preassessment'
import { preAssessmentButtonHtml } from 'Component/Mail/Transactional/EditorComponents/preassessment-button'
import { meetingAddressHtml } from 'Component/Mail/Transactional/EditorComponents/meeting-address'
import { meetingAddressOnlyHtml } from 'Component/Mail/Transactional/EditorComponents/meeting-address-only'
import { meetingLinkHtml } from 'Component/Mail/Transactional/EditorComponents/meeting-link'
import { meetingLinkButtonHtml } from 'Component/Mail/Transactional/EditorComponents/meeting-link-button'
import { resourceUrlHtml } from 'Component/Mail/Transactional/EditorComponents/resource-url'
import { resourceUrlButtonHtml } from 'Component/Mail/Transactional/EditorComponents/resource-url-button'
import {
  getLocalizedTemplateDescription,
  TransactionalTemplateName,
} from './Utils'
import InfoIcon from '@mui/icons-material/Info'

type Props = {
  template?: Template
}

export function TransactionalTemplateForm(props: Props) {
  const { template = null } = props

  const [currentTemplate, setCurrentTemplate] = useState<null | Template>(null)

  const { t } = useTranslation()
  const api = useApi(false)

  const [templateEditor, setTemplateEditor] = useState<null | grapesjs.Editor>(
    null
  )
  const [openPlaceholder, setOpenPlaceholder] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [
    isResetContentConfirmationDialogOpen,
    setIsResetContentConfirmationDialogOpen,
  ] = useState(false)

  useEffect(() => {
    setCurrentTemplate(template)
  }, [template])

  useEffect(() => {
    templateEditor?.setComponents(currentTemplate?.content)
  }, [currentTemplate, templateEditor])

  const updateTemplate = (html: string) => {
    setIsLoading(true)

    try {
      api
        .put(`notification-service/templates/${currentTemplate?.uuid}`, {
          name: currentTemplate?.name,
          content: html,
          design: '',
        })
        .then(
          (response) => {
            toast.success(t('mail.template-updated'), {
              theme: 'colored',
            })

            setIsLoading(false)
          },
          (error) => {
            toast.error(error.message, { theme: 'colored' })
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const resetContent = () => {
    setIsLoading(true)

    try {
      api
        .get(
          `notification-service/transactional-templates/default-by-name/${currentTemplate?.name}`
        )
        .then(
          (response) => {
            toast.success(t('mail.content-reset-success'), {
              theme: 'colored',
            })
            if (currentTemplate) {
              setCurrentTemplate({
                uuid: currentTemplate.uuid,
                name: currentTemplate.name,
                content: response.data.data.content,
                design: currentTemplate.design,
                status: currentTemplate.status,
                createdTime: currentTemplate.createdTime,
                updatedTime: currentTemplate.updatedTime,
              })
            }

            setIsLoading(false)
          },
          (error) => {
            toast.error(error.message, { theme: 'colored' })
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const saveDesign = () => {
    if (templateEditor) {
      const docTemplate = document.implementation.createHTMLDocument()
      docTemplate.body.remove()
      let body = docTemplate.createElement('body')
      body.innerHTML = templateEditor.runCommand('gjs-get-inlined-html').trim()
      docTemplate.getElementsByTagName('html')[0].append(body)
      const templateHtml = new XMLSerializer().serializeToString(docTemplate)
      console.log(`templateHtml ${templateHtml}`)
      updateTemplate(templateHtml)
    }
  }

  return (
    <>
      <Typography sx={{ ml: 0.5 }} variant="h6">
        {t('common.description')}
      </Typography>
      <Typography
        sx={{
          mb: 4,
          ml: 0.5,
          color: 'black',
          fontFamily: 'nexalight',
        }}
        variant="body1"
      >
        {getLocalizedTemplateDescription(currentTemplate?.name || '', t)}
      </Typography>

      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        <StyledButton
          startIcon={<SaveIcon />}
          sx={{
            backgroundColor: '#0d6efd !important',
            mr: 3,
          }}
          onClick={(event) => {
            saveDesign()
          }}
        >
          <Typography sx={{ mt: 0.5 }}>{t('common.update')}</Typography>
        </StyledButton>

        <StyledButton
          sx={{
            backgroundColor: '#0d6efd !important',
            fontSize: '18px',
            mr: 3,
          }}
          onClick={() => {
            setIsResetContentConfirmationDialogOpen(true)
          }}
        >
          {t('mail.reset-content')}
        </StyledButton>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography variant="h6">{t('mail.placeholders-title')}</Typography>
          <IconButton onClick={() => setOpenPlaceholder(true)}>
            <InfoIcon />
          </IconButton>
        </Stack>
      </Stack>

      <GrapesjsReact
        id="grapesjs-react"
        plugins={['gjs-preset-newsletter', 'gjs-blocks-basic']}
        storageManager={{ autoload: false }}
        onInit={(editor: grapesjs.Editor) => {
          // Panels
          editor.Panels.addButton('options', {
            id: 'undo',
            className: 'fa fa-undo',
            command: 'undo',
            attributes: { title: 'Undo' },
          })
          editor.Panels.addButton('options', {
            id: 'redo',
            className: 'fa fa-redo',
            command: 'redo',
            attributes: { title: 'Redo' },
          })

          // PRE-ASSESSMENT SECTION
          editor.DomComponents.addType('pre-assessment-section-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' &&
              el.className === 'wyblo-component-preassessment',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: preAssessmentHtml,
              },
            },
          })

          // PRE-ASSESSMENT BUTTON SECTION
          editor.DomComponents.addType('pre-assessment-button-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' &&
              el.className === 'wyblo-component-preassessment',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: preAssessmentButtonHtml,
              },
            },
          })

          // RESOURCE URL COMPONENT
          editor.DomComponents.addType('resource-url-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' &&
              el.className === 'wyblo-component-resource-url',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: resourceUrlHtml,
              },
            },
          })

          // RESOURCE URL BUTTON COMPONENT
          editor.DomComponents.addType('resource-url-button-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' &&
              el.className === 'wyblo-button-resource-url',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: resourceUrlButtonHtml,
              },
            },
          })

          // ADDRESS COMPONENT
          editor.DomComponents.addType('meeting-address-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' &&
              el.className === 'wyblo-component-address',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: meetingAddressHtml,
              },
            },
          })

          // ADDRESS ONLY COMPONENT
          editor.DomComponents.addType('meeting-address-only-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' &&
              el.className === 'wyblo-component-address',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: meetingAddressOnlyHtml,
              },
            },
          })

          // LINK COMPONENT
          editor.DomComponents.addType('meeting-link-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' && el.className === 'wyblo-component-link',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: meetingLinkHtml,
              },
            },
          })

          // LINK BUTTON COMPONENT
          editor.DomComponents.addType('meeting-link-button-component', {
            isComponent: (el) =>
              el.tagName === 'DIV' && el.className === 'wyblo-component-link',

            model: {
              defaults: {
                tagName: 'div',
                editable: false,
                droppable: false,
                draggable: true,
                propagate: ['editable', 'droppable'],
                components: meetingLinkButtonHtml,
              },
            },
          })

          // PRE-ASSESSMENT BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('pre-assessment-block', {
              label: t(
                'mail.transactional-components.optional-components.preassessment'
              ),
              category: t('mail.transactional-components.optional-category'),
              content: { type: 'pre-assessment-section-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
          </svg>`,
            })
          }

          // PRE-ASSESSMENT BUTTON BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('pre-assessment-button-component', {
              label: t(
                'mail.transactional-components.optional-components.preassessment'
              ),
              category: t('mail.transactional-components.optional-button'),
              content: { type: 'pre-assessment-button-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
          </svg>`,
            })
          }

          // RESOURCE URL BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('resource-url-block', {
              label: t(
                'mail.transactional-components.optional-components.resources'
              ),
              category: t('mail.transactional-components.optional-category'),
              content: { type: 'resource-url-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                  </svg>`,
            })
          }

          // RESOURCE URL BUTTON BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('resource-url-button-block', {
              label: t(
                'mail.transactional-components.optional-components.resources'
              ),
              category: t('mail.transactional-components.optional-button'),
              content: { type: 'resource-url-button-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                  </svg>`,
            })
          }

          // ADDRESS BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('meeting-address-block', {
              label: t(
                'mail.transactional-components.optional-components.meeting-address'
              ),
              category: t('mail.transactional-components.optional-category'),
              content: { type: 'meeting-address-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
          </svg>`,
            })
          }

          // ADDRESS ONLY BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('meeting-address-only-block', {
              label: t(
                'mail.transactional-components.optional-components.meeting-address'
              ),
              category: t('mail.transactional-components.optional-button'),
              content: { type: 'meeting-address-only-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
          </svg>`,
            })
          }

          // LINK BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('meeting-link-block', {
              label: t(
                'mail.transactional-components.optional-components.meeting-link'
              ),
              category: t('mail.transactional-components.optional-category'),
              content: { type: 'meeting-link-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
          </svg>`,
            })
          }

          // LINK BLOCK
          if (currentTemplate?.name !== TransactionalTemplateName.Feedback) {
            editor.BlockManager.add('meeting-link-button-block', {
              label: t(
                'mail.transactional-components.optional-components.meeting-link-button'
              ),
              category: t('mail.transactional-components.optional-button'),
              content: { type: 'meeting-link-button-component' },
              media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
          </svg>`,
            })
          }

          editor.setComponents(currentTemplate?.content)

          setTemplateEditor(editor)

          // editor.on('update', () => {
          //   const updatedHtml = editor.getHtml()
          //   console.log(`Updated Html ${updatedHtml}`)
          // })
        }}
      />

      <Dialog onClose={() => setOpenPlaceholder(false)} open={openPlaceholder}>
        <DialogContent>
          <Typography
            sx={{ fontFamily: 'nexalight', marginLeft: 0.5 }}
            variant="subtitle1"
          >
            <EmailPlaceholders
              type="content"
              transactionalTemplateName={currentTemplate?.name || ''}
            />
          </Typography>

          <EditorComponentsDescriptions
            transactionalTemplateName={currentTemplate?.name || ''}
          />
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        isOpen={isResetContentConfirmationDialogOpen}
        onClose={() => {
          setIsResetContentConfirmationDialogOpen(false)
        }}
        i18nKey="mail.reset-content-confirmation"
        onConfirm={() => {
          resetContent()
        }}
      />
    </>
  )
}
