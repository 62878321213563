import { Box, Link, Typography } from '@mui/material'
import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Employee } from 'Component/FundedTraining/types'
import { EmployeesTable } from './EmployeesTable'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import useApi from 'api/UseApi'
import Loader from 'Component/Loader'

interface Props {
  projectUuid: string
}

export function Employees(props: Props) {
  const { t } = useTranslation()

  const api = useApi(false)

  const [employees, setEmployees] = useState<Employee[]>([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const getEmployees = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api
          .get('funded_training/employees.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              setEmployees(response.data)
              setIsLoaderVisible(false)
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        setIsLoaderVisible(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getEmployees()
  }, [getEmployees])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {t('funded-training.employees')}
      </Typography>

      <NavLink to={`/funded-training`} exact>
        <Link variant="body1">
          {t('funded-training.go-to-funded-training-main-menu')}
        </Link>
      </NavLink>

      <Box sx={{ mt: 2 }}>
        <EmployeesTable
          items={employees}
          onItemDeleted={() => {
            getEmployees()
          }}
        />
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </Box>
  )
}
