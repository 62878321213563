import 'assets/scss/main.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Courses from './Component/Courses';
import Trainees from './Component/Trainees';
import Settings from './Component/Settings';
import CourseDetail from './Component/CourseDetail';
import Library from './Component/Library';
import ProtectedRoute from './Component/ProtectedRoute';
import { useAuth0 } from '@auth0/auth0-react';
import Feedback from './Component/Feedback';
import CompleteProfile from './View/CompleteProfile';
import useApi from 'api/UseApi';
import ChangePassword from './Component/ChangePassword';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CrmSection from 'Component/CrmSection';
import SurveySection from 'Component/SurveySection';
import { useAppSelector } from 'Store';
import theme from './theme';
import {
  selectDashboardReportLink,
  selectShouldChangePassword,
  selectShouldCompleteProfile,
  setShouldCompleteProfile,
  setDashboardReportLink,
  setCurrentOrganization,
  setOrganizations,
  setUserId,
  setShouldChangePassword,
  setLanguage,
  setFirstName,
  setLastName,
  setBirthDate,
  setJobPosition,
  setMobile,
  setLinkedin,
  setFacebook,
  setTwitter,
  setInstagram,
  setEmail,
  selectCurrentOrganization,
  setCurrentOrganizationStripeAccount,
  setAvatarUrl,
} from 'Reducers/UserConfigReducer';

import { setTimezone } from 'common/DateTimeUtils';
import en from 'javascript-time-ago/locale/en.json';
import it from 'javascript-time-ago/locale/it.json';
import TimeAgo from 'javascript-time-ago';
import CourseRegistration from 'Component/CourseRegistration/CourseRegistration';
import MarketingRegistration from 'Component/CourseRegistration/MarketingRegistration';
import { SurveySubmission } from 'Component/SurveySubmission';
import { ToastContainer } from 'react-toastify';
import { Box, ThemeProvider } from '@mui/material';
import Sidebar from 'Component/Sidebar/Sidebar';
import { Payments } from 'Component/Payments';
import OrganizationLogin from 'Component/OrganizationLogin/OrganizationLogin';
import {
  SELECTED_ORGANIZATION_ID,
  SHOULD_UPDATE_ACCESS_TOKEN_ORG,
} from 'common/LocalStorageKeys';
import { MailSection } from 'Component/Mail/MailSection';
import { CourseDesignList } from 'Component/Design/CourseDesignList';
import { Campaigns } from 'Component/Mail/Campaigns/Campaigns';
import { Tags } from 'Component/Mail/Tags/Tags';
import { Subscribers } from 'Component/Mail/Subscribers/Subscribers';
import { TagSubscribers } from 'Component/Mail/Tags/TagSubscribers';
import { Templates } from 'Component/Mail/Templates/Templates';
import { CampaignDetail } from 'Component/Mail/Campaigns/CampaignDetail';
import { TemplateDetail } from 'Component/Mail/Templates/TemplateDetail';
import { NewCampaign } from 'Component/Mail/Campaigns/NewCampaign';
import { NewTemplate } from 'Component/Mail/Templates/NewTemplate';
import { localization as surveyCreatorLocalization } from 'survey-creator-core';
import DashboardOld from 'Component/DashboardOld';
import PowerBIDashboard from 'Component/DashboardNew';
import SurveyDetail from 'Component/SurveyDetail/SurveyDetail';
import TemplateSurveyDetail from 'Component/TemplateDetail/TemplateDetail';
import { Transactional } from 'Component/Mail/Transactional/Transactional';
import { TransactionalTemplateDetail } from 'Component/Mail/Transactional/TransactionalTemplateDetail';
import TestCourses from 'Component/TestCourses/TestCourses';
import { NewCourseDesign } from 'Component/Design/NewCourseDesign';
import { CourseDesignDetail } from 'Component/Design/CourseDesignDetail';
import { NewSessionDesign } from 'Component/Design/Session/NewSessionDesign';
import { SessionDesignDetail } from 'Component/Design/Session/SessionDesignDetail';
import { CourseDesignRegistrationPreview } from 'Component/Design/CourseDesignRegistrationPreview';
import Catalog from 'Component/Catalog/catalog-page';
import { EditPDF } from 'Component/Certificate/EditPDF';
import { lazy } from 'react';
import CatalogDetail from 'Component/CatalogDetail/CatalogDetail';
import CoursesSchedule from 'Component/CoursesSchedule/CoursesSchedule';
import NavVertical from 'Component/NavVertical/NavVertical';
import NavMini from 'Component/NavMini/NavMini';
import useResponsive from 'common/hooks/useResponsive';
import Header from 'Component/Header/Header';
import UserAccount from 'Component/UserAccount/UserAccount';
import { setLoader } from 'Reducers/LoaderReducer';
import { SessionAttendance } from 'Component/Attendance/SessionAttendance';
import FileManager from 'Component/FileManager/FileManager';

import DescriptionMigration from 'View/DescriptionMigration';
import SessionDatesList from 'Component/SessionDatesList/SessionDatesList';
import { FundedTraining } from 'Component/FundedTraining/FundedTraining';
import { Companies } from 'Component/FundedTraining/Companies/Companies';
import { Employees } from 'Component/FundedTraining/Employees/Employees';
import { Funders } from 'Component/FundedTraining/Funders/Funders';
import { NewCompany } from 'Component/FundedTraining/Companies/NewProject';
import { NewEmployee } from 'Component/FundedTraining/Employees/NewEmployee';
import { NewFunder } from 'Component/FundedTraining/Funders/NewFunder';
import { CompanyDetail } from 'Component/FundedTraining/Companies/CompanyDetail';
import { FunderDetail } from 'Component/FundedTraining/Funders/FunderDetail';
import { EmployeeDetail } from 'Component/FundedTraining/Employees/EmployeeDetail';
import { ActivityParticipantDetail } from 'Component/FundedTraining/Projects/Activities/ActivityParticipant/ActivityParticipantDetail';
import { NewActivityParticipant } from 'Component/FundedTraining/Projects/Activities/ActivityParticipant/NewActivityParticipant';
import { Notices } from 'Component/FundedTraining/Notices/Notices';
import { NewNotice } from 'Component/FundedTraining/Notices/NewNotice';
import { NoticeDetail } from 'Component/FundedTraining/Notices/NoticeDetail';
import { NewProject } from 'Component/FundedTraining/Projects/NewProject';
import { NewActivity } from 'Component/FundedTraining/Projects/Activities/NewActivity';
import { ActivityDetail } from 'Component/FundedTraining/Projects/Activities/ActivityDetail';
import { Projects } from 'Component/FundedTraining/Projects/Projects';
import { ProjectDetail } from 'Component/FundedTraining/Projects/ProjectDetail';
import { FundingChannelDetail } from 'Component/FundedTraining/Funders/FundingChannelDetail';
import { NewFundingChannel } from 'Component/FundedTraining/Funders/NewFundingChannel';

function App() {
  const api = useApi(false);
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated, logout } = useAuth0();
  const dashboardReportLink = useAppSelector(selectDashboardReportLink);
  const shouldShowChangePassword = useAppSelector(selectShouldChangePassword);
  const shouldCompleteProfile = useAppSelector(selectShouldCompleteProfile);
  const currentOrganization = useAppSelector(selectCurrentOrganization);
  const [open, setOpen] = useState(false);
  const [themeLayout, setThemeLayout] = useState('vertical');

  const SurveyReport = lazy(() => import('Component/SurveyReport.tsx'));

  const [status, setStatus] = useState('INITIAL');

  const isDesktop = useResponsive('up', 'lg');

  const isNavMini = themeLayout === 'mini';

  const updateCurrentOrganization = useCallback(
    (organization) => {
      window.localStorage.setItem(
        SELECTED_ORGANIZATION_ID,
        organization.id.toString()
      );
      dispatch(setCurrentOrganization(organization));
    },
    [dispatch]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onToggleLayout = useCallback(() => {
    const value = themeLayout === 'vertical' ? 'mini' : 'vertical';
    setThemeLayout(value);
  }, [themeLayout]);

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  const logoutOrg = () => {
    logout({ returnTo: window.location.origin });
    // Setting the organization on the global state
    // will make sure other tabs get logged out,
    // since a new call to getOrganization will be made.
    if (currentOrganization)
      dispatch(setCurrentOrganization(currentOrganization));
    window.localStorage.clear();
  };

  const getUserSettings = useCallback(() => {
    dispatch(setLoader(true));
    api.get('users/me').then(
      (response) => {
        console.log('STATE DEBUG --> SETTINGS 2');
        const responseData = response.data;

        const user = responseData.users;
        const userId = user.id;

        const organizations = responseData.organizations.map((org) => {
          let userPermissionsForOrg = org.lstpermissions.map((data) => {
            return data.permission_name.trim();
          });

          let userPermissions = user.permissions.map((data) => {
            return data.permission_name.trim();
          });

          userPermissionsForOrg.push.apply(
            userPermissionsForOrg,
            userPermissions
          );

          return {
            id: org.organization_id,
            auth0OrgId: org.auth0_org_id,
            email: org.email,
            name: org.organization_name,
            userRoleName: org.roleName,
            stripeAccountId: org.stripe_account_id,
            logoUrl: org.logo_url,
            userPermissions: userPermissionsForOrg,
            isHostingEnabled: org.hosting_feature,
            notificationId: org.notification_id,
          };
        });

        const firstOrganization = organizations[0];

        const dashboardReportLink = user.report_link;
        const shouldShowChangePassword = !user.is_password_change;
        const shouldCompleteProfile = responseData.completeProfile;

        if (user.timezone) {
          setTimezone(user.timezone);
        }
        if (user.language) {
          dispatch(setLanguage(user.language));
          i18n.changeLanguage(user.language);
          surveyCreatorLocalization.currentLocale = user.language;
        }

        dispatch(setDashboardReportLink(dashboardReportLink));
        dispatch(setUserId(userId));

        const selectedOrganizationId = window.localStorage.getItem(
          SELECTED_ORGANIZATION_ID
        );

        if (!selectedOrganizationId) {
          window.localStorage.setItem(SHOULD_UPDATE_ACCESS_TOKEN_ORG, 'true');
          updateCurrentOrganization(firstOrganization);
        } else {
          // Set previously selected organization when app refreshes.
          let isStoredOrganizationValid = false;

          organizations.forEach((organization) => {
            if (organization.id === parseInt(selectedOrganizationId)) {
              isStoredOrganizationValid = true;
              updateCurrentOrganization(organization);
              return;
            }
          });

          if (!isStoredOrganizationValid) {
            window.localStorage.setItem(SHOULD_UPDATE_ACCESS_TOKEN_ORG, 'true');
            // The stored organization is not in the list of user's organizations
            updateCurrentOrganization(firstOrganization);
          }
        }

        dispatch(setOrganizations(organizations));
        dispatch(setFirstName(user.first_name));
        dispatch(setLastName(user.last_name));
        dispatch(setBirthDate(user.birthdate));
        dispatch(setJobPosition(user.job_position));
        dispatch(setEmail(user.email));
        dispatch(setMobile(user.mobilenumber));
        dispatch(setAvatarUrl(user.avatar.url));
        dispatch(setFacebook(user.facebook));
        dispatch(setLinkedin(user.linkedin));
        dispatch(setTwitter(user.twitter));
        dispatch(setInstagram(user.instagram));
        dispatch(setShouldChangePassword(shouldShowChangePassword));
        dispatch(setShouldCompleteProfile(shouldCompleteProfile));

        setStatus('LOADED');
        dispatch(setLoader(false));
      },
      (error) => {
        setStatus('ERROR');
        dispatch(setLoader(false));
        console.log(
          `STATE DEBUG --> Error fetching Dashboard report: ${error}`
        );
      }
    );
  }, [api, dispatch, i18n, updateCurrentOrganization]);

  const getOrganization = useCallback(
    // Here we get details about the organization's Stripe account
    (orgId) => {
      api.get(`organizations/${orgId}`).then(
        (response) => {
          let organizationStripeAccount = null;
          if (response.data.organization.stripe_account) {
            organizationStripeAccount =
              response.data.organization.stripe_account;
          }

          dispatch(
            setCurrentOrganizationStripeAccount(organizationStripeAccount)
          );
        },
        (error) => {}
      );
    },
    [api, dispatch]
  );

  useEffect(() => {
    if (currentOrganization) {
      getOrganization(currentOrganization.id);
    }
  }, [getOrganization, currentOrganization]);

  useEffect(() => {
    console.log(
      'STATE DEBUG --> STATUS = ' +
        status +
        ' Path Name = ' +
        history.location.pathname
    );

    if (
      history.location.pathname.startsWith('/CourseRegistration') ||
      history.location.pathname.startsWith('/SubmitSurvey')
    ) {
      return;
    }

    if (status === 'INITIAL') {
      TimeAgo.addDefaultLocale(en);
      TimeAgo.addLocale(it);
      setStatus('LOADING');

      getUserSettings();
    } else if (status === 'LOADED') {
      // Navigation logic
      if (shouldShowChangePassword) {
        history.push('/ChangePassword');
      } else if (
        history.location.pathname === '/Dashboard' &&
        !dashboardReportLink
      ) {
        history.push('/Courses');
      }
    }
  }, [
    history,
    status,
    dashboardReportLink,
    shouldShowChangePassword,
    getUserSettings,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        {isAuthenticated &&
          !(
            history.location.pathname.startsWith('/CourseRegistration') ||
            history.location.pathname.startsWith('/CourseOverview') ||
            history.location.pathname.match(
              '/Design/[a-zA-Z0-9-]*/RegistrationPreview'
            ) ||
            history.location.pathname.startsWith('/SubmitSurvey') ||
            history.location.pathname.startsWith('/seller-onboarding') ||
            history.location.pathname.startsWith('/file-manager') ||
            history.location.pathname.startsWith(
              '/organization/[a-zA-Z0-9-]*/catalog'
            )
          ) && (
            <>
              <CompleteProfile
                open={shouldCompleteProfile}
                onClose={() => {
                  dispatch(setShouldCompleteProfile(false));
                }}
              />
            </>
          )}
        <Switch>
          <Route path="/file-manager/:slug" exact component={FileManager} />
          <Route
            path="/Design/:uuid/Overview"
            exact
            component={CatalogDetail}
          />
          <Route path="/organization/:slug/catalog" exact component={Catalog} />
          <Route path="/CourseOverview/:slug">
            <CourseRegistration overview={true} />
          </Route>
          <Route
            path="/CourseRegistration/:slug"
            component={CourseRegistration}
          />
          <Route
            path="/MarketingRegistration/:slug"
            component={MarketingRegistration}
          />
          <Route path="/login/:slug" component={OrganizationLogin} />
          <Route path="/SubmitSurvey/:survey_id" component={SurveySubmission} />
          <ProtectedRoute path="/test" exact component={TestCourses} />
          <ProtectedRoute
            path="/ChangePassword"
            exact
            component={ChangePassword}
          />
          <ProtectedRoute
            path="/session-dates-list/:courseId"
            exact
            component={SessionDatesList}
          />
          <ProtectedRoute path="/Library" exact component={Library} />
          <ProtectedRoute path="/Feedback" exact component={Feedback} />
          <ProtectedRoute path="/Trainees" exact component={Trainees} />
          <ProtectedRoute path="/Settings" exact component={Settings} />
          <ProtectedRoute
            path="/Settings/account"
            exact
            component={UserAccount}
          />
          <ProtectedRoute
            path="/Settings/:settings"
            exact
            component={Settings}
          />
          <ProtectedRoute
            path="/Template/:templateId/edit"
            exact
            component={TemplateSurveyDetail}
          />
          <ProtectedRoute
            path="/Template/create"
            exact
            component={TemplateSurveyDetail}
          />
          <ProtectedRoute
            path="/Template/:templateId"
            exact
            component={TemplateSurveyDetail}
          />
          <ProtectedRoute
            path="/Survey/:surveyId/edit"
            exact
            component={SurveyDetail}
          />
          <ProtectedRoute
            path="/Survey/create"
            exact
            component={SurveyDetail}
          />
          <ProtectedRoute
            path="/Survey/:surveyId"
            exact
            component={SurveyDetail}
          />
          <ProtectedRoute path="/Survey" exact component={SurveySection} />
          <ProtectedRoute path="/Payments" exact component={Payments} />
          <ProtectedRoute path="/Mail" exact component={MailSection} />
          <ProtectedRoute
            path="/funded-training"
            exact
            component={FundedTraining}
          />
          <ProtectedRoute
            path="/funded-training/projects"
            exact
            component={Projects}
          />
          <ProtectedRoute
            path="/funded-training/companies"
            exact
            component={Companies}
          />
          <ProtectedRoute
            path="/funded-training/companies/new"
            exact
            component={NewCompany}
          />
          <ProtectedRoute
            path="/funded-training/companies/:companyUuid"
            exact
            component={CompanyDetail}
          />

          <ProtectedRoute
            path="/funded-training/employees"
            exact
            component={Employees}
          />
          <ProtectedRoute
            path="/funded-training/employees/new"
            exact
            component={NewEmployee}
          />
          <ProtectedRoute
            path="/funded-training/employees/:employeeUuid"
            exact
            component={EmployeeDetail}
          />
          <ProtectedRoute
            path="/funded-training/funders"
            exact
            component={Funders}
          />
          <ProtectedRoute
            path="/funded-training/funders/new"
            exact
            component={NewFunder}
          />
          <ProtectedRoute
            path="/funded-training/funders/:funderUuid"
            exact
            component={FunderDetail}
          />
          <ProtectedRoute
            path="/funded-training/funders/:funderUuid/funding-channels/new"
            exact
            component={NewFundingChannel}
          />
          <ProtectedRoute
            path="/funded-training/funders/:funderUuid/funding-channels/:fundingChannelUuid"
            exact
            component={FundingChannelDetail}
          />
          <ProtectedRoute
            path="/funded-training/notices"
            exact
            component={Notices}
          />
          <ProtectedRoute
            path="/funded-training/notices/new"
            exact
            component={NewNotice}
          />
          <ProtectedRoute
            path="/funded-training/notices/:noticeUuid"
            exact
            component={NoticeDetail}
          />
          <ProtectedRoute
            path="/funded-training/projects/new"
            exact
            component={NewProject}
          />
          <ProtectedRoute
            path="/funded-training/projects/:projectUuid"
            exact
            component={ProjectDetail}
          />
          <ProtectedRoute
            path="/funded-training/projects/:projectUuid/activities/new"
            exact
            component={NewActivity}
          />
          <ProtectedRoute
            path="/funded-training/projects/:projectUuid/activities/:activityUuid"
            exact
            component={ActivityDetail}
          />
          <ProtectedRoute
            path="/funded-training/projects/:projectUuid/activities/:activityUuid/participants/new"
            exact
            component={NewActivityParticipant}
          />
          <ProtectedRoute
            path="/funded-training/projects/:projectUuid/activities/:activityUuid/participants/:participantUuid"
            exact
            component={ActivityParticipantDetail}
          />
          <ProtectedRoute path="/Mail/Campaigns" exact component={Campaigns} />
          <ProtectedRoute
            path="/Mail/Transactional"
            exact
            component={Transactional}
          />
          <ProtectedRoute
            path="/Mail/Campaigns/New"
            exact
            component={NewCampaign}
          />
          <ProtectedRoute
            path="/Mail/Campaigns/:campaignId"
            exact
            component={CampaignDetail}
          />
          <ProtectedRoute path="/Mail/Templates" exact component={Templates} />
          <ProtectedRoute
            path="/Mail/Templates/New"
            exact
            component={NewTemplate}
          />
          <ProtectedRoute
            path="/Mail/Templates/:templateId"
            exact
            component={TemplateDetail}
          />
          <ProtectedRoute
            path="/Mail/Transactional/Templates/:templateId"
            exact
            component={TransactionalTemplateDetail}
          />
          <ProtectedRoute
            path="/Mail/Subscribers"
            exact
            component={Subscribers}
          />
          <ProtectedRoute path="/Mail/Tags" exact component={Tags} />
          <ProtectedRoute path="/Mail/Tags" exact component={Tags} />
          <ProtectedRoute
            path="/Mail/Tags/:tag_id/Subscribers"
            exact
            component={TagSubscribers}
          />
          <ProtectedRoute
            path="/Survey/Result/:survey_id"
            exact
            component={SurveyReport}
          />
          <ProtectedRoute path="/CRM" exact component={CrmSection} />
          <ProtectedRoute path="/Calendar" exact component={CoursesSchedule} />
          <ProtectedRoute
            path="/Courses/:courseId"
            exact
            component={CourseDetail}
          />
          <ProtectedRoute
            path="/Courses/:courseId/Session/:sessionId/Attendance"
            exact
            component={SessionAttendance}
          />
          <ProtectedRoute path="/Courses" exact component={Courses} />
          <ProtectedRoute path="/Design" exact component={CourseDesignList} />
          <ProtectedRoute
            path="/Design/:designId/RegistrationPreview"
            component={CourseDesignRegistrationPreview}
          />
          <ProtectedRoute
            path="/Design/New"
            exact
            component={NewCourseDesign}
          />
          <ProtectedRoute
            path="/Design/:designId"
            exact
            component={CourseDesignDetail}
          />
          <ProtectedRoute
            path="/Design/:designId/Session/New"
            exact
            component={NewSessionDesign}
          />
          <ProtectedRoute
            path="/Design/:designId/Session/:sessionDesignId"
            exact
            component={SessionDesignDetail}
          />
          <ProtectedRoute path="/EditPDF" exact component={EditPDF} />
          <ProtectedRoute path="/Dashboard" exact component={DashboardOld} />
          <ProtectedRoute
            path="/WIPDashboard"
            exact
            component={PowerBIDashboard}
          />
          <ProtectedRoute
            path="/DescriptionMigration"
            exact
            component={DescriptionMigration}
          />
          <Route path="/">
            <Redirect to="/Courses" />
          </Route>
        </Switch>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;
