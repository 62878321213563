import { Container, Grid } from '@mui/material'
import CourseInfo from 'Component/CourseRegistration/CourseInfo/CourseInfo'
import Footer from 'Component/CourseRegistration/Footer'
import MainInfo from 'Component/CourseRegistration/MainInfo'
import {
  CourseRegistrationModel,
  OrganizationInfoModel,
} from 'Component/CourseRegistration/Models'
import OrganizationInfo from 'Component/CourseRegistration/OrganizationInfo'
import Loader from 'Component/Loader'
import CatalogService from 'Service/CatalogService'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { convertCourseDesignToRegistration } from 'utils/type-conversions/design'

const CatalogDetail = () => {
  const [organizationInfo, setOrganizationInfo] =
    useState<OrganizationInfoModel>()
  const [course, setCourse] = useState<CourseRegistrationModel | null>()
  const [isLoading, setIsLoading] = useState(true)

  let { uuid } = useParams<{ uuid: string }>()

  const { getCourseDesignDetail } = CatalogService()

  const getCourseDetail = async () => {
    try {
      setIsLoading(true)
      const courseDetail = await getCourseDesignDetail(uuid)
      const courseDetailClient = courseDetail
        ? convertCourseDesignToRegistration(courseDetail)
        : null
      setCourse(courseDetailClient)
      setOrganizationInfo(courseDetail?.organization)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCourseDetail()
  }, [])

  return (
    <>
      {course && organizationInfo ? (
        <div id="course-registration">
          <Container maxWidth="md">
            <Grid container sx={{ p: 0, backgroundColor: '#f7f9fb' }}>
              {course?.banner_url && (
                <Grid item md={8} sx={{ p: 0 }}>
                  <img
                    className="img-fluid"
                    src={course?.banner_url}
                    alt="course_image"
                  />
                </Grid>
              )}
              <Grid item md={course?.banner_url ? 4 : 'auto'} sx={{ p: 0 }}>
                <MainInfo courseRegistration={course} />
              </Grid>
            </Grid>
            <CourseInfo sessions={course.sessions} />
            {<OrganizationInfo organizationInfo={organizationInfo} />}
            <Footer />
          </Container>
        </div>
      ) : (
        <Loader isVisible={isLoading} />
      )}
    </>
  )
}

export default CatalogDetail
