const surveyCreated = () => {
  return {
    type: 'SURVEY_CREATED',
  };
};

const surveysFetched = () => {
  return {
    type: 'SURVEYS_FETCHED',
  };
};

const templateCreated = () => {
  return {
    type: 'TEMPLATE_CREATED',
  };
};

const templatesFetched = () => {
  return {
    type: 'TEMPLATES_FETCHED',
  };
};

export { surveyCreated, surveysFetched, templateCreated, templatesFetched };
