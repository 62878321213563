import template from '../../assets/document/template_course_participants.xls'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

interface AddMemberDialogProp {
  isInvitePopupVisible: boolean
  downloadicon: string
  file: any
  firstName: string
  lastName: string
  isLoading: boolean
  closeInvite: () => void
  uploadFile: () => Promise<void>
  setFile: (value: string) => void
  setFileVal: (value: FileList | null) => void
  inviteSingleParticipants: () => Promise<void>
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setEmail: (value: string) => void
}

const AddMemberDialog = ({
  isInvitePopupVisible,
  downloadicon,
  file,
  firstName,
  lastName,
  isLoading,
  closeInvite,
  uploadFile,
  setFile,
  setFileVal,
  inviteSingleParticipants,
  setFirstName,
  setLastName,
  setEmail,
}: AddMemberDialogProp) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={isInvitePopupVisible}
      onClose={closeInvite}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="registrantsInvite"
    >
      <DialogTitle id="alert-dialog-title">
        {t('courses.InviteNewMembers')}
        <CloseIcon onClick={closeInvite} />
      </DialogTitle>
      <DialogContent>
        <div className="flexonmi_head">
          <h6 style={{ color: '#157AFF' }} className="mb-0">
            {t('courses.Importfromalist')}
          </h6>
          <div className="flexsmall">
            <h6 className="me-2 mb-0" style={{ color: '#157AFF' }}>
              {t('courses.Template')}
            </h6>
            <button
              className="btn"
              style={{ background: 'rgba(115, 176, 255, 0.2)' }}
            >
              <a href={template}>
                <img src={downloadicon} alt="uploadicon" className="w-75" />
              </a>
            </button>
          </div>
        </div>
        <DialogContentText id="alert-dialog-description">
          <form
            onSubmit={(event) => {
              event.preventDefault()
              uploadFile()
            }}
          >
            <div className="flexonaddtrinees mb-2">
              <div style={{ width: '65%', position: 'relative' }}>
                <input
                  type="file"
                  placeholder={t('courses.FileName')}
                  className="form-control hiddenUpload"
                  required
                  onChange={(e) => {
                    setFile(e.target.value)
                    setFileVal(e.target.files)
                  }}
                />
                <input
                  type="text"
                  placeholder={t('courses.FileName')}
                  className="form-control addCoursesInput"
                  disabled
                  value={file}
                />
              </div>
              <button
                className="btn"
                style={{
                  fontFamily: 'nexa',
                }}
              >
                {t('courses.Upload')}
              </button>
            </div>
          </form>
          <small
            style={{
              fontFamily: 'nexa',
              color: '#b7b7b7',
              fontSize: '12px',
            }}
          >
            {t('courses.AcceptedFormats')}
          </small>
        </DialogContentText>

        <div className="flexonmi_head mt-5">
          <h6 style={{ color: '#157AFF' }} className="mb-0">
            {t('courses.InvitewithEmail')}
          </h6>
        </div>

        <DialogContentText id="alert-dialog-description">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              inviteSingleParticipants()
            }}
          >
            <div
              className="flexonaddtrinees mb-2"
              style={{ flexDirection: 'column' }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                <input
                  type="text"
                  placeholder="Enter First Name"
                  required
                  className="form-control addCoursesInput mb-2 w-75"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                />
                <span>&nbsp; &nbsp;&nbsp;</span>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  required
                  className="form-control addCoursesInput mb-2 w-75"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                />
              </div>

              <div
                style={{
                  width: '100%',
                  position: 'relative',
                }}
              >
                <input
                  type="email"
                  placeholder={t('courses.EnterEmailaddress')}
                  required
                  className="form-control addCoursesInput mb-2"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </div>

              <div className="text-center">
                <button
                  disabled={isLoading}
                  className="btn text-center"
                  type="submit"
                  style={{
                    fontFamily: 'nexa',
                  }}
                >
                  {t('courses.Invite')}
                </button>
              </div>
            </div>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default AddMemberDialog
