import { FormControl, FormHelperText, SelectChangeEvent } from '@mui/material'
import { getTimezone } from 'common/DateTimeUtils'
import i18next from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SelectTimeProp {
  disable?: boolean
  startDate?: string
  endDate?: string
  timeSelected?: string
  serverTime?: string
  onChangeState: (value: string) => void
}

function SelectTime({
  disable,
  timeSelected,
  serverTime,
  onChangeState,
}: SelectTimeProp) {
  const [times, setTimes] = useState<moment.Moment[]>([])
  const [error, setError] = useState(false)

  const { t } = useTranslation()

  //creating an array of times from 00:00 to 23:45
  function calculateTimes(startTime: moment.Moment, endTime: moment.Moment) {
    let timeStops = []

    while (startTime < endTime) {
      timeStops.push(moment(startTime))
      startTime.add('m', 15)
    }

    return timeStops
  }

  const renderTimeSelected = () => {
    if (!timeSelected || timeSelected === 'Invalid date') {
      return ''
    }
    return timeSelected
  }

  const inputValidation = (value: string) => {
    if (i18next.language === 'it') {
      if (value.length === 5) {
        const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        setError(!regex.test(value))
        return regex.test(value)
      }
      const regex = /[0-9]|(:)|(^$)/
      setError(regex.test(value))
      return regex.test(value)
    } else {
      if (value.length === 8) {
        const regex = /((1[0-2]|0[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
        setError(!regex.test(value))
        return regex.test(value)
      }
      const regex = /[0-9]|(A)|(a)|(P)|(p)|(M)|(m)|(^$)/
      setError(regex.test(value))
      return regex.test(value)
    }
  }

  const onChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    if (inputValidation(value)) {
      onChangeState(value.toUpperCase())
    } else setError(true)
  }

  const onBlur = (e: any) => {
    const value = e.target.value
    const regex =
      i18next.language === 'it'
        ? /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        : /((1[0-2]|0[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
    if (regex.test(value)) {
      setError(false)
    } else {
      setError(false)
      onChangeState(
        serverTime
          ? serverTime
          : new Date().toLocaleTimeString(i18next.language, {
              hour: '2-digit',
              minute: '2-digit',
              timeZone: getTimezone(),
            })
      )
    }
  }

  useEffect(
    () => {
      /* update the range of the times array from the time selected to 23:45 but it's not working
    if (
      startDate &&
      endDate?.split('T')[0] === startDate?.split('T')[0]
    ) {
      const timeSelected = new Date(startDate).toLocaleTimeString('it', {
        timeStyle: 'short',
        hour12: i18next.language === 'it' ? false : true,
      })
      setTimes(
        calculateTimes(
          moment().startOf('day').add(moment.duration(timeSelected), 'hours'),
          moment().endOf('day')
        )
      )
    } else { */
      setTimes(calculateTimes(moment().startOf('day'), moment().endOf('day')))
      //}
    },
    [
      /* startDate, endDate */
    ]
  )

  return (
    <div>
      <FormControl
        sx={{ m: 1, maxHeight: '100px', position: 'relative' }}
        size="small"
        error={error}
      >
        {/* input text with select */}
        <input
          className={error ? 'selectTime--error' : 'selectTime'}
          placeholder="HH:MM"
          list="select"
          name={'select-time'}
          onChange={onChange}
          onBlur={onBlur}
          // The format of "value" field must be "HH:mm"
          value={renderTimeSelected()}
          disabled={disable}
          autoComplete="off"
          maxLength={i18next.language === 'it' ? 5 : 8}
          required
        />
        <datalist id="select">
          {times.map((t) => (
            <option
              key={t.format('LT')}
              value={t.format(i18next.language === 'it' ? 'HH:mm' : 'hh:mm A')}
            >
              {moment(t).format(
                i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
              )}
            </option>
          ))}
        </datalist>
        <FormHelperText
          sx={{
            position: 'absolute',
            transform: 'translateY(100%)',
            bottom: '-0.5rem',
            width: '100%',
          }}
        >
          {error ? t('courses.error-select-time') : ''}
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default SelectTime
