import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import useApi from 'api/UseApi';
import Loader from 'Component/Loader';
import { useTranslation } from 'react-i18next';
import Pagination from 'common/Pagination';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';

function Trainees() {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [getAlltrainees, setgetAlltrainees] = useState([]);
  const [filterTrainees, setfilterTrainees] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [error, seterror] = useState(false);
  const organization = useAppSelector(selectCurrentOrganization);

  const { t } = useTranslation();

  const api = useApi(false);

  const exportCSV = async () => {
    setIsLoaderVisible(true);

    try {
      api
        .get(`organizations/${organization?.id}/people.xlsx`, {
          responseType: 'blob',
        })
        .then(
          (response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);
            link.download = `Attendees-${+new Date()}.xlsx`;
            link.click();

            setIsLoaderVisible(false);
          },
          (error) => {
            console.log(error);
            setIsLoaderVisible(false);
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (organization) {
      setIsLoaderVisible(true);

      const Traineesget = async () => {
        try {
          api.get(`organizations/${organization?.id}/people.json`).then(
            (response) => {
              setgetAlltrainees(response.data.users);
              setfilterTrainees(response.data.users);
              setIsLoaderVisible(false);
            },
            (error) => {
              console.log(error);
              setIsLoaderVisible(false);
              seterror(true);
            }
          );
        } catch (e) {
          console.log(e.message);
        }
      };

      Traineesget();
    }
  }, [api, organization]);

  const SearchOnVal = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setfilterTrainees(getAlltrainees);
    else {
      const filteredData = getAlltrainees.filter((item) => {
        return item.first_name != null
          ? Object.keys(item.first_name).some(
              (key) =>
                item.first_name
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue) ||
                item.last_name
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue) ||
                item.email.toString().toLowerCase().includes(lowercasedValue)
            )
          : null;
      });
      setfilterTrainees(filteredData);
    }
    setPageNo(1);
  };

  return (
    <div className="">
      <div className="trainees">
        <div className="search_addCourse">
          <div className="inputSearch">
            <SearchIcon style={{ color: '#DEDEDE' }} />
            <div className="mx-2 inputWidth">
              <input
                type="email"
                className="form-control"
                placeholder={t('courses.Search')}
                onChange={(e) => {
                  SearchOnVal(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="upTable">
          <div className="tableHeadFlex mb-2">
            <h4 className="mb-0">{t('trainees.Trainees')}</h4>

            <div>
              {organization?.userPermissions.includes('read:participants') && (
                <button className="btn mainbtnColor me-4" onClick={exportCSV}>
                  <svg
                    width="15"
                    height="15"
                    style={{
                      marginRight: '.7rem',
                    }}
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.2 12.6H9V4.5H11.7L8.1 0L4.5 4.5H7.2V12.6Z"
                      fill="#157AFF"
                    />
                    <path
                      d="M1.8 17.9998H14.4C15.3927 17.9998 16.2 17.1925 16.2 16.1998V8.0998C16.2 7.1071 15.3927 6.2998 14.4 6.2998H10.8V8.0998H14.4V16.1998H1.8V8.0998H5.4V6.2998H1.8C0.8073 6.2998 0 7.1071 0 8.0998V16.1998C0 17.1925 0.8073 17.9998 1.8 17.9998Z"
                      fill="#157AFF"
                    />
                  </svg>

                  {t('courses.Export')}
                </button>
              )}
            </div>
          </div>

          <div className="TabStart">
            {organization?.userPermissions.includes('read:participants') && (
              <div className="trainessTable">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t('trainees.Name')}</th>
                      <th scope="col">{t('trainees.surname')}</th>
                      <th scope="col">{t('trainees.Email')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterTrainees
                      .slice(
                        pageNo * rowsPerPage - rowsPerPage,
                        pageNo * rowsPerPage
                      )
                      .map((data, index) => (
                        <tr key={index}>
                          <td>{data.first_name}</td>

                          <td>{data.last_name}</td>
                          <td>{data.email}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {(error ||
                  (filterTrainees.length === 0 && !isLoaderVisible)) && (
                  <h1
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#ebedef',
                    }}
                  >
                    {t('NoRecordFound')}
                  </h1>
                )}
                <Pagination
                  numberOfItems={filterTrainees.length}
                  rowsPerPage={rowsPerPage}
                  pageNo={pageNo}
                  onPageChanged={(pageNo) => {
                    setPageNo(pageNo);
                  }}
                  onRowsPerPageChanged={(rowsPerPage) => {
                    setRowsPerPage(rowsPerPage);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Loader isVisible={isLoaderVisible} />
    </div>
  );
}

export default Trainees;
