import { FormProvider, UseFormReturn } from 'react-hook-form'
import FormInputField, { FormInputFieldType } from './FormFields/FormInputField'
import { useTranslation } from 'react-i18next'
import FormPrivacyPolicyField from './FormFields/FormPrivacyPolicyField'
import { OrganizationInfoModel, UserInfo } from '../Models'
import { Config } from 'Component/ConfigRegistration/ConfigRegistration'

interface Props {
  formMethods: FormMethods
  organizationInfo?: OrganizationInfoModel
  customFields?: Config[]
}

type FormMethods = UseFormReturn<UserInfo, object>

const RegistrationForm = ({ formMethods, customFields }: Props) => {
  const { t, i18n } = useTranslation()

  function getLabel(name: string) {
    return i18n.exists(`course-registration.modal.form.${name}`)
      ? t(`course-registration.modal.form.${name}`)
      : `${name.charAt(0).toUpperCase()}${name.slice(1).replaceAll('_', ' ')}`
  }

  function compare(a: Config, b: Config) {
    if (a.position === b.position) return 0

    if (a.position === '') return 1

    if (b.position === '') return -1

    return a.position < b.position ? -1 : 1
  }

  return (
    <FormProvider {...formMethods}>
      <form className="pt-5 pb-5">
        <div className="row justify-content-start">
          {customFields
            ?.filter((c) => c.id)
            .sort(compare)
            .map((c, i) => (
              <FormInputField
                key={`${c}${i}`}
                type={c.kind as FormInputFieldType}
                className="col-md-6 col-lg-3"
                name={c.name}
                label={getLabel(c.name)}
                required={c.mandatory}
              />
            ))}
          <div className="row justify-content-start mt-5">
            <FormPrivacyPolicyField
              name="privacyPolicy"
              className="col-md-9"
              label={t('common.accept-privacy-policy')}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export type { UserInfo, FormMethods }
export default RegistrationForm
