import { Box, Link, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityParticipant } from 'Component/FundedTraining/types'
import { NavLink } from 'react-router-dom'
import { ActivityParticipantsTable } from './ActivityParticipantsTable'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import useApi from 'api/UseApi'

interface Props {
  projectUuid: string
  activityUuid: string
  onItemDeleted: () => void
}

export function ActivityParticipants(props: Props) {
  const { projectUuid, activityUuid, onItemDeleted } = props
  const { t } = useTranslation()

  const api = useApi(false)

  const [participants, setParticipants] = useState<ActivityParticipant[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const organization = useAppSelector(selectCurrentOrganization)

  const getActivityParticipants = useCallback(() => {
    if (organization) {
      setIsLoading(true)
      try {
        api
          .get(
            `funded_training/projects/${projectUuid}/activities/${activityUuid}/employees`,
            {
              params: {
                organization_id: organization.id,
              },
            }
          )
          .then(
            (response) => {
              setParticipants(response.data)
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
      } catch (e: any) {
        setIsLoading(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getActivityParticipants()
  }, [getActivityParticipants])

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {t('common.participants')}
      </Typography>

      <Box sx={{ mt: 2 }}>
        <ActivityParticipantsTable
          projectUuid={projectUuid}
          activityUuid={activityUuid}
          items={participants}
          onItemDeleted={() => {
            onItemDeleted()
          }}
        />
      </Box>
    </Box>
  )
}
