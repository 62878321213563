import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { Tag } from 'Component/Mail/NotificationServiceTypes'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'

const CustomAutocomplete = styled(Autocomplete)({
  '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
    '&:hover': {
      borderColor: '#73b0ff',
    },
  },

  '.MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: 0.3,
    fontSize: 16,
    '&::placeholder': {
      color: '#157aff66',
      opacity: 1,
    },
  },

  '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
  },

  '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#73b0ff',
  },
})

type Props = {
  campaignTags: Tag[]
  organizationTags: Tag[]
  onTagsUpdated: (newTagIds: string[]) => void
}

export default function TagsAutoComplete(props: Props) {
  const { campaignTags, organizationTags, onTagsUpdated } = props
  const { t } = useTranslation()

  return (
    <>
      <Stack spacing={3} sx={{ maxWidth: '30rem' }}>
        <CustomAutocomplete
          multiple
          id="tags-outlined"
          options={organizationTags.sort((a, b) => {
            // Keep course tags at the bottom of the dropdown
            if (b.name.startsWith('wyblo-transactional-tag-event-')) {
              return -1
            }

            if (a.name.startsWith('wyblo-transactional-tag-event-')) {
              return 1
            }

            // Apply regular sorting for all other tags
            return -b.name.localeCompare(a.name)
          })}
          getOptionLabel={(option: any) => {
            if (option.name.startsWith('wyblo-transactional-tag-event-')) {
              const splitString: string[] = option.name.split('-')
              let courseIDString: string = `${t('common.course')} ${
                splitString[4]
              }`

              return courseIDString
            }
            return option.name
          }}
          isOptionEqualToValue={(tag: any, value: any) =>
            tag.name === value.name
          }
          defaultValue={campaignTags}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ backgroundColor: 'white' }}
              placeholder={t('mail.tag-field-placeholder')}
            />
          )}
          onChange={(event, newTags: any) => {
            const newTagIds: string[] = newTags.map((tag: Tag) => {
              return tag.uuid
            })

            onTagsUpdated(newTagIds)
          }}
        />
      </Stack>
    </>
  )
}
