import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface CourseRegistrationState {
  timezone: string
}

const initialState: CourseRegistrationState = {
  timezone: 'Europe/Rome',
}

export const CourseRegistrationSlice = createSlice({
  name: 'course-registration',
  initialState,
  reducers: {
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload
    },
  },
})

// SELECTORS
export const selectTimezone = (state: RootState) =>
  state.CourseRegistration.timezone

export const { setTimezone } = CourseRegistrationSlice.actions

export default CourseRegistrationSlice.reducer
