import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import { Activity, ActivityParticipant } from 'Component/FundedTraining/types'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

interface Props {
  activityParticipant: ActivityParticipant
}

export function ActivityParticipantOverview(props: Props) {
  const { activityParticipant } = props

  const companyName = 'Ducati'
  const codiceFiscale = 'SPSCRI80A10G812G'
  const completedHoursRatio = '20/30'

  const { t } = useTranslation()

  return (
    <Grid container rowSpacing={4} columnSpacing={3}>
      <Grid item md={6} lg={4}>
        <StyledCard
          sx={{
            pt: 1,
            pb: 2,
            px: 2,
            borderRadius: 1,
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
          }}
        >
          <Typography variant="h6" sx={{ mr: 1, mb: 1, color: '#0d6efdee' }}>
            {'Info principali'}
          </Typography>

          <Trans i18nKey="funded-training.activity-detail.participant-company-name">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ companyName }}</>
            </strong>
          </Trans>

          <br></br>

          <Trans i18nKey="funded-training.activity-detail.participant-fiscal-code">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ codiceFiscale }}</>
            </strong>
          </Trans>

          <br></br>
          <br></br>
          <Trans i18nKey="funded-training.activity-detail.participant-hour-ratio">
            {'placeholder '}
            <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
              <>{{ completedHoursRatio }}</>
            </strong>
          </Trans>

          <br></br>
        </StyledCard>
      </Grid>
    </Grid>
  )
}
