import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import { ActivityForm } from './ActivityForm'

type RouteParams = {
  projectUuid: string
}

export function NewActivity() {
  const { t } = useTranslation()
  const { projectUuid } = useParams<RouteParams>()

  return (
    <Box sx={{ mb: 20, mr: 5 }} className="">
      <Stack className="">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('funded-training.new-activity')}
        </Typography>
        <NavLink to={`/funded-training/${projectUuid}`} exact>
          <Link variant="body1">{t('funded-training.go-to-project')}</Link>
        </NavLink>

        <ActivityForm projectUuid={projectUuid} isNew={true} />
      </Stack>
    </Box>
  )
}
