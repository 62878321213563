import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SessionDesign } from '../Types'
import { SessionDesignsTable } from './SessionDesignsTable'

interface Props {
  courseDesignId: string
  sessionDesigns: SessionDesign[]
  onItemDeleted: () => void
}

export function CourseDesignSessionList(props: Props) {
  const { courseDesignId, sessionDesigns, onItemDeleted } = props

  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5">{t('common.sessions')}</Typography>

      <SessionDesignsTable
        courseDesignId={courseDesignId}
        items={sessionDesigns}
        onItemDeleted={() => {
          onItemDeleted()
        }}
      />
    </Box>
  )
}
