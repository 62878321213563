import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import useApi from '../../api/UseApi';
import EditSession from './EditSession/EditSession';
import { useTranslation } from 'react-i18next';
import {
  getLocalizedDateFromUTC,
  getLocalizedTimeFromUTC,
} from 'common/DateTimeUtils';
import ConfirmationDialog from 'Component/ConfirmationDialog';
import { Tooltip } from '@mui/material';

export default function TemporaryDrawer({
  session,
  serverSession,
  courses: course,
  getAllcourse,
}) {
  const [drawerToggle, setdrawerToggle] = React.useState({
    right: false,
  });
  const { t } = useTranslation();
  const [visibleLoader, setvisibleLoader] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const api = useApi(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setdrawerToggle({ ...drawerToggle, [anchor]: open });
  };

  const deleteSession = async () => {
    setvisibleLoader(true);

    try {
      api.delete(`courses/${course.id}/sessions/${session.id}`).then(
        (response) => {
          if (response.status === 204) {
            getAllcourse();
            setvisibleLoader(false);
            toast.success(t('courses.session-has-been-deleted-successfully'), {
              theme: 'colored',
            });
          }
        },
        (error) => {
          console.log(error);
          toast.error(error.response.message, { theme: 'colored' });
          setvisibleLoader(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
      role="presentation"
    >
      <div className="eventAddressLink">
        <div className="event-head">
          <h4>{session.title}</h4>
          <i
            class="fas fa-times"
            style={{ cursor: 'pointer' }}
            onClick={toggleDrawer(anchor, false)}
          ></i>
        </div>
        <div className="status">
          {session.status === 'Live' ? (
            <span style={{ color: 'red' }}>
              {' '}
              <i
                class="fa fa-circle"
                aria-hidden="true"
                style={{ fontSize: 'large' }}
              ></i>{' '}
              &nbsp; {session.status}
            </span>
          ) : null}
          {session.status === 'Draft' ? (
            <span style={{ color: 'gray' }}>
              {' '}
              <i
                class="fa fa-circle"
                aria-hidden="true"
                style={{ fontSize: 'large' }}
              ></i>{' '}
              &nbsp; {session.status}
            </span>
          ) : null}
          {session.status === 'Completed' ? (
            <span style={{ color: 'green' }}>
              {' '}
              <i
                class="fa fa-circle"
                aria-hidden="true"
                style={{ fontSize: 'large' }}
              ></i>{' '}
              &nbsp; {session.status}
            </span>
          ) : null}
          {session.status === 'Coming Next' ? (
            <span style={{ color: '#157AFF' }}>
              {' '}
              <i
                class="fa fa-circle"
                aria-hidden="true"
                style={{ fontSize: 'large' }}
              ></i>{' '}
              &nbsp; {session.status}
            </span>
          ) : null}
        </div>

        <div className="event-mid">
          <div className="mid-head">
            <h5
              style={{
                color: '#2972ff',
                wordBreak: 'break-word',
              }}
            >
              {session.sessionname}
            </h5>
          </div>
          <div className="info-section">
            <ul className="mb-0 p-0">
              <li>
                {t('courses.Date')}:{' '}
                <span style={{ color: '#157AFF' }}>
                  {getLocalizedDateFromUTC(session.start_date)}
                </span>
              </li>
              <li>
                {t('courses.Time')}:{' '}
                <span style={{ color: '#157AFF' }}>
                  {session.start_date !== null &&
                    getLocalizedTimeFromUTC(session.start_date)}
                  {session.end_date !== null && (
                    <>- {getLocalizedTimeFromUTC(session.end_date)}</>
                  )}
                </span>
              </li>

              <li className="d-flex">
                {t('survey.survey')}:&nbsp; <br />
                <a
                  href={session.survey_link}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {session.survey_link}
                </a>
              </li>

              <li className="d-flex">
                {t('courses.link')}:&nbsp;{' '}
                <a
                  href={session.link}
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {session.link}
                </a>
              </li>

              <li className="d-flex">
                {t('courses.Address')}:&nbsp;{' '}
                <span
                  style={{
                    color: '#157AFF',
                    maxWidth: '200px',
                    overfloWrap: 'break-word',
                    wordWrap: 'break-word',
                  }}
                >
                  {session.inpersonmeetingaddress}
                </span>
              </li>
            </ul>
          </div>

          <div className="info-buttons">
            <button className="btn btn-primary">
              <EditSession
                serverSingleSession={serverSession}
                singleSession={session}
                getAllcourse={getAllcourse}
              />
            </button>
            <button
              className="btn btn-primary"
              onClick={() => setIsPopupOpen(true)}
            >
              {t('courses.Delete')}
            </button>
          </div>
        </div>

        <div
          style={{
            position: 'fixed',
            zIndex: '999',
            top: '50%',
            right: '130px',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader
            type="Oval"
            color="#2477bc"
            height={60}
            width={60}
            visible={visibleLoader}
          />
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <ConfirmationDialog
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        i18nKey="courses.session-delete-confirmation"
        entityName={session.sessionname}
        onConfirm={deleteSession}
      />

      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {session.sessionname && (
            <Tooltip
              style={{
                cursor: 'pointer',
              }}
              placement="bottom-start"
              onClick={toggleDrawer(anchor, true)}
              title={session.sessionname}
              arrow={true}
            >
              <span className="sessionellipse">{session.sessionname}</span>
            </Tooltip>
          )}

          <Drawer
            anchor={anchor}
            open={drawerToggle[anchor]}
            onClose={toggleDrawer('right', false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
