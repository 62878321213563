import { useEffect } from 'react'
// @mui
import { Box, Drawer, Typography } from '@mui/material'
// hooks
import useResponsive from '../../common/hooks/useResponsive'
import { useLocation } from 'react-router-dom'
// components
import Scrollbar from '../Scrollbar/Scrollbar'
import NavSectionVertical from './NavSectionVertical'
//config
import logo from 'assets/icon/wyblo_Pittogramma_color.png'
import packageJson from '../../../package.json'
import useNavConfig from 'common/hooks/useNavConfig'
//component
import NavToggleButton from '../NavToggleButton/NavToggleButton'
import OrganizationSelector from 'Component/OrganizationSelector'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 120,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
}

type Props = {
  openNav: boolean
  themeLayout: string
  onCloseNav: VoidFunction
  onToggleLayout: () => void
}

export default function NavVertical({
  openNav,
  themeLayout,
  onCloseNav,
  onToggleLayout,
}: Props) {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const isDesktop = useResponsive('up', 'lg')
  const navConfig = useNavConfig()

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ padding: '16px 16px 16px 40px' }}>
        <OrganizationSelector />
      </Box>

      <NavSectionVertical data={navConfig} />

      {/* company logo with versioning */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 10,
          left: 40,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img className="sidebar_logo" src={logo} alt="logo" />
        <Typography
          sx={{
            color: '#2477BC',
            fontSize: '13px',
            marginTop: '1rem',
          }}
        >
          {t('version')} {packageJson.version}
        </Typography>
      </Box>
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton
        onToggleLayout={onToggleLayout}
        themeLayout={themeLayout}
      />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 999,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'solid',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              zIndex: 9999,
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}
