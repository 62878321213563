import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import stripe from 'assets/icon/stripe.svg'
import { ReactComponent as PaymentsIcon } from 'assets/icon/payments.svg'
import { StyledButtonGreen } from 'common/styled-component/StyledButton'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useAppSelector } from 'Store'
import {
  selectCurrentOrganization,
  selectCurrentOrganizationStripeAccount,
  setCurrentOrganizationStripeAccount,
} from 'Reducers/UserConfigReducer'
import { orange } from '@mui/material/colors'
import useApi from 'api/UseApi'
import Loader from 'Component/Loader'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { DialogTitle } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { StripeDocs } from './StripeDocs'
import { PaymentsMailto } from './PaymentsMailto'
import { GeneralUserSettingsValidationSchema } from 'common/form-validation/GeneralUserSettingsValidation'

export function Payments() {
  const { t } = useTranslation()
  const organization = useAppSelector(selectCurrentOrganization)
  const organizationStripeAccount = useAppSelector(
    selectCurrentOrganizationStripeAccount
  )
  const api = useApi(false)
  const [isLoaderVisible, setIsLoaderVisible] = useState(true)
  const [isConnectingToStripe, setIsConnectingToStripe] = useState(false)

  const dispatch = useDispatch()

  const getOrganization = useCallback(
    (orgId: number) => {
      setIsLoaderVisible(true)

      api.get(`organizations/${organization?.id}`).then(
        (response) => {
          setIsLoaderVisible(false)

          let organizationStripeAccount = null
          if (response.data.organization.stripe_account) {
            organizationStripeAccount =
              response.data.organization.stripe_account
          }

          dispatch(
            setCurrentOrganizationStripeAccount(organizationStripeAccount)
          )
        },
        (error) => {
          setIsLoaderVisible(false)
        }
      )
    },
    [api, dispatch, organization?.id]
  )

  useEffect(() => {
    if (organization) {
      getOrganization(organization.id)
    }
  }, [getOrganization, organization])

  const getDisabledReasonMessages = (reason: string, t: TFunction) => {
    let message = ''
    let additionalMessage = ''
    if (reason === 'requirements.past_due') {
      message = t('payments.messages.stripe-account-update-config')
    } else if (reason === 'under_review') {
      message = t('payments.messages.stripe-account-under-review')
    } else if (reason === 'requirements.pending_verification') {
      message = t('payments.messages.stripe-account-pending-verification')
    } else if (reason === 'platform_paused') {
      message = t('payments.messages.stripe-account-paused')
    } else if (
      reason.includes('rejected') ||
      reason === 'other' ||
      reason === 'listed'
    ) {
      message = t('payments.messages.stripe-account-disabled')
      additionalMessage = t(
        'payments.messages.stripe-account-disabled-log-into-dashboard'
      )
    }
    return { message, additionalMessage }
  }

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        {organization?.userPermissions &&
          organization?.userPermissions.length > 0 && (
            <Box>
              <Stack direction="row" spacing={1}>
                <Typography variant="h5">{t('common.payments')}</Typography>
                <img src={stripe} alt="Powered by Stripe" width="120" />
              </Stack>

              {organization?.userPermissions.includes(
                'view:payments-section'
              ) || organization?.userPermissions.includes('manage:payments') ? (
                <>
                  <Loader isVisible={isLoaderVisible} />
                  {!isLoaderVisible && (
                    <>
                      {/* If user is Admin and there's no account show mailto */}
                      {!organizationStripeAccount &&
                        organization?.userPermissions.includes(
                          'view:payments-section'
                        ) && <PaymentsMailto />}

                      {organization?.userPermissions.includes(
                        'manage:payments'
                      ) && (
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              maxWidth: 1100,
                              padding: 0,
                              mt: 2,
                              mb: 2,
                            }}
                          >
                            <Grid item xs={8}>
                              {/* Second grid for welcome message + image */}
                              <Grid
                                container
                                sx={{
                                  padding: 4,
                                  background: '#d5f4e7',
                                  borderRadius: 3,
                                  height: '100%',
                                  flexWrap: 'nowrap',
                                }}
                              >
                                <Grid item>
                                  {organizationStripeAccount?.charges_enabled &&
                                  organizationStripeAccount?.payouts_enabled ? (
                                    <>
                                      <Typography
                                        variant="h5"
                                        sx={{
                                          color: '#004b50',
                                        }}
                                      >
                                        {/* TODO */}
                                        {t(
                                          'payments.messages.stripe-account-configured-title'
                                        )}
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        spacing={4}
                                        alignItems="center"
                                      >
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            color: '#407e7c',
                                            fontFamily: 'nexalight',
                                            fontSize: 16,
                                            mt: 2,
                                            mb: 2,
                                          }}
                                        >
                                          {t(
                                            'payments.messages.stripe-account-configured'
                                          )}{' '}
                                          {t(
                                            'payments.messages.stripe-account-configured-2'
                                          )}
                                        </Typography>

                                        <Box sx={{ width: 150 }}>
                                          <PaymentsIcon
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                            }}
                                          />
                                        </Box>
                                      </Stack>
                                    </>
                                  ) : (
                                    <>
                                      <Typography
                                        variant="body1"
                                        color={orange[600]}
                                      >
                                        {t(
                                          'payments.messages.stripe-account-config-issues-1'
                                        )}
                                      </Typography>

                                      {organizationStripeAccount?.requirements
                                        ?.disabled_reason && (
                                        <Typography
                                          variant="body1"
                                          color={orange[600]}
                                          style={{ marginTop: 2 }}
                                        >
                                          {
                                            getDisabledReasonMessages(
                                              organizationStripeAccount
                                                .requirements.disabled_reason,
                                              t
                                            ).message
                                          }
                                          <br />
                                          {
                                            getDisabledReasonMessages(
                                              organizationStripeAccount
                                                .requirements.disabled_reason,
                                              t
                                            ).additionalMessage
                                          }
                                        </Typography>
                                      )}
                                    </>
                                  )}

                                  {/* Only show configure button if the user can manage payments */}
                                  <StyledButtonGreen
                                    sx={{ mt: 3 }}
                                    hidden={
                                      !organization?.userPermissions.includes(
                                        'manage:payments'
                                      )
                                    }
                                    onClick={() => {
                                      setIsConnectingToStripe(true)

                                      try {
                                        api
                                          .get(
                                            `organizations/${organization?.id}/stripe_account_link`
                                          )
                                          .then(
                                            (response) => {
                                              const stripeAccountLink =
                                                response.data.link
                                              if (stripeAccountLink) {
                                                window.location.replace(
                                                  stripeAccountLink
                                                )
                                              }
                                            },
                                            (error) => {
                                              setIsConnectingToStripe(false)
                                            }
                                          )
                                      } catch (error) {
                                        setIsConnectingToStripe(false)
                                      }
                                    }}
                                  >
                                    {t('payments.configure')}
                                  </StyledButtonGreen>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4} sx={{ padding: 0 }}>
                              {/* Dashboard */}
                              <Stack
                                height="100%"
                                direction="column"
                                sx={{
                                  pt: 6,
                                  pb: 4,
                                  pr: 4,
                                  pl: 4,
                                  background: '#d5f4e7',
                                  borderRadius: 3,
                                  height: '100%',
                                }}
                              >
                                <Typography variant="h6" color="#004b50">
                                  {t('payments.docs.stripe-dashboard.title')}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mt: 1,
                                    mb: 1,
                                    fontFamily: 'nexalight',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: '#407e7c',
                                      fontFamily: 'nexalight',
                                      fontSize: 16,
                                    }}
                                  >
                                    {t('payments.docs.stripe-dashboard.text1')}

                                    <Link
                                      href={'https://dashboard.stripe.com'}
                                      rel="noreferrer"
                                      target="_blank"
                                      sx={{ fontWeight: 'bold' }}
                                    >
                                      {t('payments.stripe-dashboard')}
                                    </Link>
                                    {'.'}
                                  </Typography>
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {(organization?.userPermissions.includes(
                        'manage:payments'
                      ) ||
                        (organizationStripeAccount &&
                          organization?.userPermissions.includes(
                            'view:payments-section'
                          ))) && <StripeDocs />}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </Box>
          )}
        <Dialog open={isConnectingToStripe}>
          <DialogContent>
            <Stack
              spacing={2}
              sx={{
                color: '#0d6efd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {' '}
              <DialogTitle>{t('settings.stripe-connecting')}</DialogTitle>
              <CircularProgress
                style={{
                  color: '#0d6efd',
                  marginBottom: '2rem',
                  height: '2rem',
                  width: '2rem',
                }}
              />
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  )
}
