import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Store from './Store'
import Auth0ProviderWithHistory from './Component/AuthProviderWithHistory'
import EnvIdentifier from './Component/EnvIdentifier'
import { Suspense } from 'react'
import './i18n'
import Loader, { PaddingLoader } from 'Component/Loader'

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <EnvIdentifier />
          <Suspense
            fallback={<Loader isVisible={true} padding={PaddingLoader.NONE} />}
          >
            <App />
          </Suspense>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals()
