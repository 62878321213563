const DownloadFile = (data) => {
  return {
    type: 'DOWNLOAD_SINGLE_FILE',
    payload: data,
  };
};

const clearDownloadFile = () => {
  return {
    type: 'CLEAR_DOWNLOAD_SINGLE_FILE',
  };
};

export { DownloadFile, clearDownloadFile };
