import { InputBase } from '@mui/material'
import { styled } from '@mui/system'

export const FormTextFieldInputBase = styled(InputBase)({
  maxWidth: '30rem',
  backgroundColor: 'white',
  fontFamily: 'nexa',
  borderRadius: '4px',
  border: '1px solid #73b0ff',
  width: '100%',
  '&:focus, &.Mui-focused': {
    backgroundColor: 'white',
    boxShadow: '0 0 0 2px rgba(21, 122, 255, 0.4)',
  },
  fontSize: 16,
  paddingLeft: '0.75rem',
  lineHeight: '1.5',
})
