import { TFunction } from 'i18next'
import { string, object, SchemaOf } from 'yup'

export type ActivityFormData = {
  title: string
  description: string | null
  funder_activity_id: string | null
}

export const ActivityFormValidation = (t: TFunction) => {
  const schema: SchemaOf<ActivityFormData> = object().shape({
    title: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.title')),
    description: string()
      .nullable()
      .default(null)
      .label(t('common.description')),
    funder_activity_id: string()
      .nullable()
      .default(null)
      .label(t('funded-training.activity-id')),
  })

  return schema
}
