import { Box, Typography } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { CourseDesign } from './Types'
import Loader from 'Component/Loader'
import CourseDesignTabPanel from './CourseDesignTabPanel'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { CourseDesignSessionList } from './Session/CourseDesignSessionList'
import { useTranslation } from 'react-i18next'
import { CourseDesignForm } from './CourseDesignForm'
import { useAppSelector } from 'Store'
import {
  selectShouldPreselectSessionListTab,
  setShouldPreselectSessionListTab,
} from 'Reducers/CourseDesignReducer'
import { useDispatch } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  StyledButton,
  StyledButtonGreen,
} from 'common/styled-component/StyledButton'
import { toast } from 'react-toastify'

type RouteParams = {
  designId: string
}

type LocationState = {
  designName: string
}

type Props = {
  isNewDesign?: boolean
}

function a11yProps(index: number) {
  return {
    id: `course-design-tab-${index}`,
    'aria-controls': `course-design-tabpanel-${index}`,
  }
}

export function CourseDesignDetail(props: Props) {
  const { isNewDesign = false } = props

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { designId } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  const api = useApi(false)
  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentDesign, setCurrentDesign] = useState<null | CourseDesign>(null)
  const [screenTitle, setScreenTitle] = useState<string>('')

  const shouldPreselectSessionListTab = useAppSelector(
    selectShouldPreselectSessionListTab
  )

  const getCourseDesign = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`course_designs/${designId}`).then(
        (response) => {
          setIsLoading(false)
          setCurrentDesign(response.data)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, designId])

  useEffect(() => {
    getCourseDesign()
  }, [getCourseDesign, designId])

  const createCourseFromDesign = () => {
    setIsLoading(true)

    try {
      api.post(`courses/from-design/${designId}`).then(
        (response) => {
          toast.success(t('course-design.design-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.push(`/session-dates-list/${response.data.id}`)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    if (shouldPreselectSessionListTab) {
      setTabIndex(1)
      dispatch(setShouldPreselectSessionListTab(false))
    }
  }, [shouldPreselectSessionListTab])

  useEffect(() => {
    if (currentDesign) {
      if (screenTitle === '') {
        setScreenTitle(currentDesign.name)
      }
    } else if (state) {
      if (screenTitle === '') {
        setScreenTitle(state.designName)
      }
    }
  }, [state, currentDesign, screenTitle])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
          <Trans i18nKey={'course-design.design-detail-title'}>
            {'placeholder '}
            <strong style={{ color: '#0d6efd', fontSize: '2rem' }}>
              <>{{ screenTitle }}</>
            </strong>
            {' placeholder'}
          </Trans>
        </Typography>

        <StyledButtonGreen
          hidden={isNewDesign}
          sx={{
            mb: 4,
          }}
          endIcon={<AddIcon />}
          onClick={(event) => {
            createCourseFromDesign()
          }}
        >
          <Typography sx={{ mt: 0.5 }}>
            {t('course-design.instantiate-course')}
          </Typography>
        </StyledButtonGreen>

        <StyledButton
          hidden={isNewDesign}
          sx={{
            mb: 4,
            ml: 4,
            backgroundColor: '#0d6efd !important',
          }}
          endIcon={<VisibilityIcon />}
          onClick={(event) => {
            window.open(`/Design/${designId}/RegistrationPreview`)
          }}
        >
          <Typography sx={{ mt: 0.5 }}>
            {t('course-design.registration-preview')}
          </Typography>
        </StyledButton>
        {currentDesign && (
          <>
            <Tabs
              value={tabIndex}
              onChange={onTabChange}
              aria-label="course design tabs"
            >
              <Tab label={t('common.config')} {...a11yProps(1)} />
              <Tab label={t('common.sessions')} {...a11yProps(0)} />
            </Tabs>

            <CourseDesignTabPanel value={tabIndex} index={0}>
              <CourseDesignForm
                currentDesign={currentDesign}
                isNewDesign={isNewDesign}
                onDesignUpdated={(newDesign: CourseDesign) => {
                  setScreenTitle(newDesign.name)
                }}
              />
            </CourseDesignTabPanel>
            <CourseDesignTabPanel value={tabIndex} index={1}>
              <CourseDesignSessionList
                courseDesignId={currentDesign.uuid}
                sessionDesigns={currentDesign.session_designs}
                onItemDeleted={() => {
                  getCourseDesign()
                }}
              />
            </CourseDesignTabPanel>
          </>
        )}
      </Box>
      <Loader isVisible={isLoading} />
    </Box>
  )
}
