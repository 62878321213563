import { useState, useEffect, useCallback, memo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useApi from '../../api/UseApi';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

const UpdateSessionTrainersPopup = ({
  sessionTrainers,
  sessionId,
  courseId,
  onTrainersUpdated,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [organizationTrainersEmails, setOrganizationTrainersEmails] = useState(
    []
  );
  const [sessionTrainersEmails, setSessionTrainersEmails] = useState([]);
  const [emailsToAdd, setEmailsToAdd] = useState([]);
  const [emailsToRemove, setEmailsToRemove] = useState([]);

  const organization = useAppSelector(selectCurrentOrganization);

  const api = useApi(false);

  useEffect(() => {
    const emails = sessionTrainers.map((trainer) => {
      return trainer.email;
    });
    setSessionTrainersEmails(emails);
  }, [sessionTrainers]);

  const getTrainers = useCallback(() => {
    setIsLoading(true);
    try {
      api.get(`/organizations/${organization?.id}/hosts.json`).then(
        (response) => {
          const trainerEmails = response.data.customers.map((trainer) => {
            return trainer.email;
          });
          setOrganizationTrainersEmails(trainerEmails);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    } catch (e) {
      console.log(e.message);
      setIsLoading(false);
    }
  }, [api, organization?.id]);

  const addTrainerToSession = () => {
    setIsLoading(true);

    try {
      api
        .put(`/courses/${courseId}/sessions/${sessionId}/trainers`, {
          emails_to_add: emailsToAdd,
          emails_to_remove: emailsToRemove,
        })
        .then(
          (response) => {
            toast.success(response.data.message, { theme: 'colored' });
            onPopupClose();
            setIsLoading(false);
            onTrainersUpdated();
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const onPlusButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    getTrainers();
  };

  const onPopupClose = () => {
    setAnchorEl(null);
    setEmailsToAdd([]);
    setEmailsToRemove([]);
  };

  return (
    <div>
      <i
        aria-describedby={'trainer-popper'}
        variant="contained"
        className="fas fa-plus-circle"
        style={{
          color: '#73B0FF',
          fontSize: '1.2rem',
          cursor: 'pointer',
        }}
        onClick={onPlusButtonClick}
      ></i>

      <Popper
        id={'trainer-popper'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onPopupClose}
      >
        <ClickAwayListener onClickAway={onPopupClose}>
          <Stack
            direction="row"
            spacing={3}
            sx={{
              marginBottom: 4,
              backgroundColor: 'white',
              p: 2,
              borderRadius: 1,
              boxShadow: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: 300,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Autocomplete
                sx={{ width: 300 }}
                id="trainer-email-autocomplete"
                multiple
                hidden={isLoading}
                disablePortal
                defaultValue={sessionTrainersEmails}
                onChange={(event, newEmails) => {
                  const toAdd = newEmails.filter(
                    (x) => !sessionTrainersEmails.includes(x)
                  );
                  setEmailsToAdd(toAdd);

                  const toRemove = sessionTrainersEmails.filter(
                    (x) => !newEmails.includes(x)
                  );
                  setEmailsToRemove(toRemove);
                }}
                options={organizationTrainersEmails}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label={t('courses.TrainerEmail')} />
                )}
              />

              <CircularProgress
                hidden={!isLoading}
                style={{ height: '2rem', width: '2rem' }}
              />
            </Box>

            <Button
              variant="contained"
              disableElevation
              disabled={emailsToAdd.length === 0 && emailsToRemove.length === 0}
              sx={{ px: 3 }}
              onClick={() => {
                addTrainerToSession();
              }}
            >
              {t('common.update')}
            </Button>
          </Stack>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default memo(UpdateSessionTrainersPopup);
