import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import i18next from 'i18next'
import moment, { Moment } from 'moment-timezone'
import { TIMEZONE_KEY } from 'common/LocalStorageKeys'
import 'moment/min/locales'

export const setTimezone = (timezone: string): void => {
  window.localStorage.setItem(TIMEZONE_KEY, timezone)
}

export const getTimezone = (): string => {
  return window.localStorage.getItem(TIMEZONE_KEY) || 'Europe/Rome'
}

export const getTimezoneLabel = (timezoneName: string): string => {
  if (timezoneName) {
    return `${timezoneName} (${getTimezoneAbbreviation(timezoneName)})`
  }
  return ''
}

export const getTimezoneAbbreviation = (timezoneName: string): string => {
  if (timezoneName) {
    return moment.tz(timezoneName).zoneAbbr()
  }
  return ''
}

export const getTimezoneOptions = () => {
  return moment.tz.names().map((timezoneName) => {
    return {
      id: timezoneName,
      label: getTimezoneLabel(timezoneName),
    }
  })
}

export const getUTCDateTime = (dateString: string) => {
  // Setting user timezone to fix input.
  // This prevents incorrect timezone data returned by date pickers.
  let momentWithUserTimezone = moment(dateString).tz(getTimezone(), true)

  const utcMoment = moment.utc(momentWithUserTimezone)

  // Shifting time to UTC+0 before formatting
  return utcMoment.utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss')
}

export const getPickerDate = (dateString: string) => {
  let momentWithUserTimezone = moment(dateString)

  // Shifting time to UTC+0 before formatting
  return momentWithUserTimezone.format('YYYY-MM-DD')
}

// -------------------------Localization------------------------------

type LocalizableDate = string | Moment | MaterialUiPickersDate

export const localizedMoment = (
  date?: LocalizableDate,
  timezone?: string
): Moment => {
  return moment(date)
    .locale(i18next.language)
    .tz(timezone || getTimezone())
}

export const localizedUTCMoment = (
  date?: LocalizableDate,
  timezone?: string
): Moment => {
  return moment
    .utc(date)
    .locale(i18next.language)
    .tz(timezone || getTimezone())
}

export const getUTCMoment = (
  date?: LocalizableDate,
  timezone?: string
): Moment => {
  return moment.utc(date).tz(timezone || getTimezone())
}

/**
 *  Localized date string with format "10/29/2021"
 */
export const getLocalizedDate = (date: LocalizableDate, timezone?: string) => {
  return localizedMoment(date, timezone).format('L')
}

/**
 *  Localized date string with format "10/29/2021"
 *
 *  To be used for dates returned from Backend.
 */
export const getLocalizedDateFromUTC = (
  date: LocalizableDate,
  timezone?: string
) => {
  return localizedUTCMoment(date, timezone).format('L')
}

/**
 *  Localized date string with format "2021/10/29"
 *
 *  To be used for dates returned from Backend.
 */
export const getInputDateFromUTC = (
  date: LocalizableDate,
  timezone?: string
) => {
  return localizedUTCMoment(date, timezone).format('YYYY-MM-DD')
}

/**
 *  Localized date string with format "Oct 29, 2021"
 */
export const getLocalizedDate2 = (
  date: LocalizableDate,
  timezone?: string
): string => {
  return localizedMoment(date, timezone).format('ll')
}

/**
 *  Localized date string with format "Oct 29, 2021"
 *
 *  To be used for dates returned from Backend.
 */
export const getLocalizedDate2FromUTC = (
  date: LocalizableDate,
  timezone?: string
): string => {
  return localizedUTCMoment(date, timezone).format('ll')
}

/**
 *  Localized time string with format "6:22 PM"
 */
export const getLocalizedTime = (date: LocalizableDate, timezone?: string) => {
  return localizedMoment(date, timezone).format('LT')
}

/**
 *  Localized time string with format "6:22 PM"
 *
 *  To be used for dates returned from Backend.
 */
export const getLocalizedTimeFromUTC = (
  date: LocalizableDate,
  timezone?: string
) => {
  return localizedUTCMoment(date, timezone).format('LT')
}

/**
 *  Localized date/time string with format "Oct 29, 2021 6:23 PM"
 */
export const getLocalizedDateTime = (
  date: LocalizableDate,
  timezone?: string
) => {
  return localizedMoment(date, timezone).format('lll')
}

/**
 * Localized date/time string with format "Oct 29, 2021 6:23 PM"
 *
 * To be used for dates returned from Backend.
 */
export const getLocalizedDateTimeFromUTC = (
  date: LocalizableDate,
  forPicker?: boolean,
  timezone?: string
) => {
  if (forPicker) {
    // Pickers will do internal conversions of the date.
    // The value passed to the picker needs to be in the default locale ('en'),
    // in order to avoid "Invalid date" errors.
    return getUTCMoment(date, timezone).format('lll')
  } else {
    return localizedUTCMoment(date, timezone).format('lll')
  }
}

export const formatLocalizedTime = () => {}
