// @mui
import { TableRow, TableCell, TextField } from '@mui/material'
// utils
import {
  getInputDateFromUTC,
  getTimezone,
  getUTCDateTime,
} from 'common/DateTimeUtils'
// @types
import { Meeting, MeetingDateTime } from 'Component/CourseRegistration/Models'
import DateAndTimePicker from 'Component/DateAndTimePicker/DateAndTimePicker'
import i18next from 'i18next'
import { ChangeEventHandler, SetStateAction, useEffect, useState } from 'react'
import { convertDateTimeStringToDateTimePickerModel } from 'utils/type-conversions/datetime'
// components

// ----------------------------------------------------------------------

type Props = {
  row: MeetingDateTime
  selected: boolean
  index: number
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
  setTableData: (value: SetStateAction<MeetingDateTime[]>) => void
}

export default function SessionDatesListRow({
  row,
  index,
  setTableData,
}: Props) {
  const { sessionname, start_date_time, end_date_time } = row
  const [serverStartTime, setServerStartTime] = useState<string>()

  const onChangeStartDate = (date: string) => {
    setTableData((state) => {
      const newState = [...state]
      newState[index].start_date_time.date = date
      return newState
    })
  }

  const onChangeEndDate = (date: string) => {
    setTableData((state) => {
      const newState = [...state]
      newState[index].end_date_time.date = date
      return newState
    })
  }

  const onChangeStartTime = (time: string) => {
    setTableData((state) => {
      const newState = [...state]
      newState[index].start_date_time.time = time
      return newState
    })
  }

  const onChangeEndTime = (time: string) => {
    setTableData((state) => {
      const newState = [...state]
      newState[index].end_date_time.time = time
      return newState
    })
  }

  return (
    <>
      <TableRow
        sx={{
          borderRadius: 1,
          position: 'relative',
          '& .MuiTableCell-root': {
            bgcolor: 'background.default',
          },
          '&:hover': {
            backgroundColor: 'rgb(255, 255, 255)',
            boxShadow: 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px',
            transition:
              'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            zIndex: 1,
          },
        }}
      >
        <TableCell
          sx={{
            lineHeight: '1.57143',
            fontSize: '0.875rem',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            display: 'table-cell',
            verticalAlign: 'inherit',
            textAlign: 'left',
            padding: '16px',
            color: 'rgb(33, 43, 54)',
            borderTop: '1px solid rgba(145, 158, 171, 0.16)',
            borderBottom: '1px solid rgb(255 255 255)',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          }}
        >
          {sessionname.length > 8
            ? sessionname.substring(0, 40) + '...'
            : sessionname}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            color: 'text.secondary',
            whiteSpace: 'nowrap',
            lineHeight: '1.57143',
            fontSize: '0.875rem',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            display: 'table-cell',
            verticalAlign: 'inherit',
            textAlign: 'left',
            padding: '16px',
            borderTop: '1px solid rgba(145, 158, 171, 0.16)',
            borderBottom: '1px solid rgb(255 255 255)',
          }}
        >
          <DateAndTimePicker
            normalSpace
            date={start_date_time?.date}
            time={start_date_time?.time}
            serverTime={start_date_time?.time}
            onDateChange={(date) => onChangeStartDate(date)}
            onTimeChange={(time) => onChangeStartTime(time)}
          />
        </TableCell>

        <TableCell
          align="center"
          sx={{
            color: 'text.secondary',
            whiteSpace: 'nowrap',
            lineHeight: '1.57143',
            fontSize: '0.875rem',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            display: 'table-cell',
            verticalAlign: 'inherit',
            textAlign: 'left',
            padding: '16px',
            borderTop: '1px solid rgba(145, 158, 171, 0.16)',
            borderBottom: '1px solid rgb(255 255 255)',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <DateAndTimePicker
            normalSpace
            date={end_date_time?.date}
            time={end_date_time?.time}
            serverTime={end_date_time?.time}
            onDateChange={(date) => onChangeEndDate(date)}
            onTimeChange={(time) => onChangeEndTime(time)}
          />
        </TableCell>
      </TableRow>
    </>
  )
}
