import { Overview } from '../Models'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

type Props = {
  overview: Overview
}

const OverviewTab = ({ overview }: Props) => {
  const extraInfoList = overview.extraInfos.map((extraInfo) => (
    <li className="overview-extra-info-span">
      {extraInfo.highlightedText && (
        <span className="highlighted">{extraInfo.highlightedText}&nbsp;</span>
      )}
      {extraInfo.text}
    </li>
  ))
  return (
    <div id="overview-tab">
      <ReactQuill theme="bubble" readOnly={true} value={overview.text} />
      <ul>{extraInfoList}</ul>
    </div>
  )
}

export default OverviewTab
