import React, { useState, useEffect } from 'react';
import TimeAgo from 'Component/TimeAgo';
import Loader from 'Component/Loader';
import { Accordion } from 'react-bootstrap';
import useApi from '../../api/UseApi';
import {
  getLocalizedDate2FromUTC,
  getLocalizedTimeFromUTC,
} from 'common/DateTimeUtils';

const Upcomming = () => {
  const [getAllData, setgetAllData] = useState([]);
  const [getAllData2, setgetAllData2] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const api = useApi();

  const completedFeedback = async () => {
    try {
      api
        .post(`Feedback/GetFeedback`, {
          Status: 'Upcoming',
        })
        .then(
          (response) => {
            response.data.courses.forEach((element, index) => {
              element.uniqId = Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(0, 5);
              element.show = false;
            });
            response.data.courses.forEach((element, index) => {
              response.data.courses[0].toggle = 'show';
              response.data.courses[0].show = true;
            });
            setgetAllData(response.data.courses);
            setgetAllData2(response.data.courses);
            setIsLoaderVisible(false);
          },
          (error) => {
            console.log(error);
            setIsLoaderVisible(false);
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    completedFeedback();
  }, []);

  const changeData = async (index) => {
    getAllData[index].show = !getAllData[index].show;

    setgetAllData2(() => {
      return [...getAllData];
    });
  };

  return (
    <div>
      <Accordion defaultActiveKey={0}>
        {getAllData2.map((data, index) => (
          <Accordion.Item eventKey={index} key={index} className="mb-4">
            <Accordion.Header>
              <span>
                {data.title}

                <span className="dateoncomp">
                  {' '}
                  {getLocalizedDate2FromUTC(data.course_begin_date)}
                </span>
                <span className="dateoncomp">
                  {' '}
                  {getLocalizedDate2FromUTC(data.course_end_date)}
                </span>
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <div className="card-body pt-0">
                <div className="smallStepper">
                  <ul className="steps pt-0">
                    <li className="step">
                      <div className="dates">
                        <span
                          className="time"
                          style={{
                            visibility: 'hidden',
                          }}
                        ></span>
                      </div>
                      <div className="step-context">
                        <span className="step-heading">&nbsp;</span>
                      </div>
                    </li>
                    {data.meetings.map((item, index) => (
                      <li className="step" key={index}>
                        <div className="dates">
                          <span className="time time3"></span>
                        </div>
                        <div className="step-context">
                          <span className="step-heading">
                            {item.sessionname} <br />
                            <small style={{ color: '#D9D8D8' }}>
                              <span
                                className="dateoncomp"
                                style={{ margin: 0 }}
                              >
                                {' '}
                                {getLocalizedDate2FromUTC(item.start_date)}
                              </span>{' '}
                              - {getLocalizedTimeFromUTC(item.start_date)}
                            </small>
                          </span>
                          <span className="step-heading2">
                            <TimeAgo
                              startDate={item.start_date}
                              endDate={item.end_date}
                            />
                          </span>
                          {/* <a href={item.form_link} target="_blank" style={{
                                                        color: "#157AFF", textDecoration: "underline",
                                                    }}>{t('Feedbackpage.View Answers')}</a> */}
                          <span className="step-heading2"></span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <Loader isVisible={isLoaderVisible} />
    </div>
  );
};

export default Upcomming;
