const initialstate = {
  download: false,
  folderpath: '',
  filename: '',
  bucketname: '',
};
const DownloadSingleFile = (state = initialstate, action) => {
  switch (action.type) {
    case 'DOWNLOAD_SINGLE_FILE':
      return (state = action.payload);
    case 'CLEAR_DOWNLOAD_SINGLE_FILE':
      return (state = {
        download: false,
        folderpath: '',
        filename: '',
        bucketname: '',
      });
    default:
      return state;
  }
};

export default DownloadSingleFile;
