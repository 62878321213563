import useApi from 'api/UseApi'

const TemplatesService = () => {
  const api = useApi(false)

  const getTemplates = async (organizationID: number | null) => {
    try {
      let response = await api.get(`organizations/${organizationID}/templates`)
      const templates: any[] = response.data.templates
      response = await api.get('survey-service/templates', {
        params: { wybloTemplatesOnly: true },
      })
      return templates.concat(response.data)
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return { getTemplates }
}

export default TemplatesService
