import { Checkbox, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  isDesign: boolean
  checked: boolean
  onChange(value: boolean): void
}

const ShowInOrganizationPage = ({
  isDesign = false,
  checked = false,
  onChange,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Stack sx={{ pt: 1, pb: 2, mb: 3, backgroundColor: '#f4f4f4' }}>
      <Stack direction="row" justifyContent="start" alignItems="center">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={(_, checked) => {
            onChange(checked)
          }}
        />
        <Typography sx={{ mt: 1 }} fontSize={16} fontFamily="nexa">
          {t('course-design.show-in-organization-page')}&nbsp;
        </Typography>
      </Stack>
      <Stack sx={{ px: 2, mt: 0.5 }} direction="row">
        <Typography fontSize={16} fontFamily="nexalight">
          {isDesign
            ? t('course-design.show-in-organization-page-design-description')
            : t('course-design.show-in-organization-page-course-description')}
          &nbsp;
        </Typography>
      </Stack>
    </Stack>
  )
}

export default ShowInOrganizationPage
