import {
  DraftSession,
  Session as SessionModel,
} from 'View/course/AddSession/AddSession'
import { getTimezone, localizedUTCMoment } from 'common/DateTimeUtils'
import moment from 'moment'
import i18next from 'i18next'

//Session into DraftSession
export const convertSessionModelToDraftSession = (
  session: SessionModel
): DraftSession => {
  const localizedStartTime = localizedUTCMoment(session.start_date).format(
    i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
  )
  const localizedEndTime = localizedUTCMoment(session.end_date).format(
    i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
  )
  const start_date = moment(session.start_date)
    .tz(getTimezone())
    .format('YYYY-MM-DD')
  const end_date = moment(session.end_date)
    .tz(getTimezone())
    .format('YYYY-MM-DD')

  return {
    ...session,
    start_date: start_date,
    end_date: end_date,
    start_time: localizedStartTime,
    end_time: localizedEndTime,
    is_feedback_form_checked: null,
  }
}

export const convertSessionModelsToDraftSessions = (
  sessions: SessionModel[]
): DraftSession[] => sessions.map(convertSessionModelToDraftSession)

//DraftSession into Session
const time24h = (time?: string): string => {
  if (i18next.language !== 'it') {
    const timeFormatted = moment(time, 'HH:mm A').format('HH:mm')
    return timeFormatted
  } else return time || ''
}

export const convertDraftSessionToSessionModel = (
  draftSession: DraftSession
): SessionModel => {
  const session: DraftSession = {
    ...draftSession,
    start_date: `${
      draftSession.start_date?.format('YYYY-MM-DD').split('T')[0]
    }T${time24h(draftSession.start_time)}:00`,
    end_date: `${
      draftSession.end_date?.format('YYYY-MM-DD').split('T')[0]
    }T${time24h(draftSession.end_time)}:00`,
  }

  if (session.is_feedback_form_checked === false) {
    if (session.survey_template_id) {
      delete session.survey_template_id
    }

    if (session.survey_link) {
      session.unset_feedback_form = true
    }
  }

  delete session.start_time
  delete session.end_time
  delete session.is_feedback_form_checked

  return session as SessionModel
}

export const convertDraftSessionsToSessionModels = (
  draftSessions: DraftSession[]
): SessionModel[] => draftSessions.map(convertDraftSessionToSessionModel)
