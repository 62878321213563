import {
  styled,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '200px',
  marginTop: '20px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
    marginTop: '50px',
    width: '100%',
    padding: '0px',
  },
  padding: '1rem',
  backgroundColor: 'transparent',
  width: '85%',
}))

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: 'white',
    height: '72px',
    fontFamily: 'Helvetica',
    fontSize: '16px',
    fontWeight: 700,
    fonstStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    borderBottom: 0,
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
    '&:first-child': {
      borderBottomLeftRadius: '5px !important',
      borderTopLeftRadius: '5px !important',
    },
    '&:last-child': {
      borderBottomRightRadius: '5px !important',
      borderTopRightRadius: '5px !important',
    },
  },
  '.MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon, .MuiTableSortLabel-root:hover':
    {
      color: 'white',
    },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  boxShadow: '0 5px 5px #999',
  backgroundColor: 'white',
}))

export const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
  boxShadow: '0 2px 5px #999',
}))

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#3682C0 !important',
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 0,
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}))

export const StyledTableTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
  color: '#000',
}))
