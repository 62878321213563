import Box from '@mui/material/Box'
import { Session } from 'Component/CourseRegistration/Models'
import SessionInfoTabPanel from './SessionInfoTabPanel'
import React from 'react'
import {
  SessionInfoTabs,
  SessionInfoTab,
  SessionInfoTabLabel,
} from './SessionInfoTabs'
import SessionDetail from './SessionDetail'
import { getLocalizedDate2FromUTC } from 'common/DateTimeUtils'
import { useAppSelector } from 'Store'
import { selectTimezone } from 'Reducers/CourseRegistrationReducer'

type Props = {
  sessions: Session[]
}

function a11yProps(index: number) {
  return {
    id: `session-info-tab-${index}`,
    'aria-controls': `session-info-tabpanel-${index}`,
  }
}

const SessionInfo = ({ sessions }: Props) => {
  const [value, setValue] = React.useState(0)
  const timezone = useAppSelector(selectTimezone)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const sessionInfoTabs = sessions.map((session, index) => (
    <SessionInfoTab
      key={`${session}${index}`}
      label={
        <SessionInfoTabLabel
          title={`Session ${index + 1}`}
          subTitle={getLocalizedDate2FromUTC(
            session.dateTimeFrom || '',
            timezone
          )}
        />
      }
      {...a11yProps(index)}
    />
  ))

  const sessionInfoTabPanels = sessions.map((session, index) => (
    <SessionInfoTabPanel key={`${session}${index}`} value={value} index={index}>
      <SessionDetail session={session} />
    </SessionInfoTabPanel>
  ))

  return (
    <Box sx={{ width: '100%' }}>
      <Box id="session-info-tab-box">
        <SessionInfoTabs
          value={value}
          onChange={handleChange}
          aria-label="session info scrollable tabs"
        >
          {sessionInfoTabs}
        </SessionInfoTabs>
      </Box>
      {sessionInfoTabPanels}
    </Box>
  )
}

export default SessionInfo
