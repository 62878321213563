import { useState, useEffect } from 'react'
import CourseInfo from './CourseInfo/CourseInfo'
import MainInfo from './MainInfo'
import Footer from './Footer'
import {
  CourseRegistrationModel,
  getCourseLocation,
  OrganizationInfoModel,
  RouteParams,
} from './Models'
import { useTranslation } from 'react-i18next'
import useApi from 'api/UseApi'
import { useParams } from 'react-router-dom'
import useQuery from 'common/hooks/UseQueryHook'
import { AxiosResponse } from 'axios'
import { Session } from 'Component/CourseRegistration/Models'
import { TFunction } from 'i18next'
import Loader, { PaddingLoader } from 'Component/Loader'
import { useDispatch } from 'react-redux'
import { setTimezone } from 'Reducers/CourseRegistrationReducer'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DialogTitle } from '@mui/material'
import {
  Box,
  Button,
  Container,
  DialogActions,
  Grid,
  Stack,
} from '@mui/material'
import SuccessAnimation from 'assets/icon/success.gif'
import { useHistory } from 'react-router-dom'
import CourseCountdown from './Header/CourseCountdown'
import RegistrationModal from './RegistrationModal/RegistrationModal'
import OrganizationInfo from './OrganizationInfo'
import { StyledRegistrationButton } from 'common/styled-component/StyledButton'
import { SessionDesign } from 'Component/Design/Types'
import { localizedUTCMoment } from 'common/DateTimeUtils'
import { toast } from 'react-toastify'

interface CourseRegistrationProps {
  designId: string | null
  overview: boolean
}

enum ButtonStatus {
  COMPLETED = 'Completed',
  LIVE = 'Live',
  PUBLISHED = 'Published',
  COMING_NEXT = 'Coming Next',
}

const CourseRegistration = (props: CourseRegistrationProps) => {
  const { designId = null, overview = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const api = useApi(false, true)
  let history = useHistory()
  let { slug } = useParams<RouteParams>()
  let query = useQuery()
  const [error, setError] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentCancelled, setPaymentCancelled] = useState(false)
  const [organizationInfo, setOrganizationInfo] =
    useState<OrganizationInfoModel>()
  const [courseRegistration, setCourseRegistration] =
    useState<CourseRegistrationModel | null>(null)
  const [isRegistrationModalShown, setIsRegistrationModalShown] =
    useState(false)
  const showRegistrationModal = () => setIsRegistrationModalShown(true)
  const hideRegistrationModal = () => setIsRegistrationModalShown(false)

  const getCourseRegistration = async () => {
    api.get(`courses/${slug}/registration`).then(
      (response) => {
        if (!response.data.hasOwnProperty('course')) {
          setError(true)
          setCourseRegistration(null)
          return
        }
        setError(false)

        const course = response.data.course
        const organization = response.data.organization

        if (course.timezone) {
          dispatch(setTimezone(course.timezone))
        }

        setCourseRegistration(parseCourseRegistration(response, t))
        setOrganizationInfo(organization)
      },
      (error) => {
        setError(true)
        toast.error(error.response.data.message, { theme: 'colored' })
      }
    )
  }

  const getCourseDesign = async () => {
    api.get(`course_designs/${designId}/registration`).then(
      (response) => {
        setError(false)

        setCourseRegistration(parseCourseDesign(response, t))
        setOrganizationInfo(response.data.organization)
      },
      (error) => {
        setError(true)
        console.log(`Error while getting Course with Slug "${slug}" : ${error}`)
      }
    )
  }

  const buttonStatus = () => {
    return ButtonStatus.COMPLETED === courseRegistration?.status
  }

  const clearUrlQuery = () => {
    history.push(`${slug}`)
  }

  useEffect(() => {
    const success: boolean = query.get('success') === 'true'
    const cancelled: boolean = query.get('cancelled') === 'true'

    setPaymentSuccess(success)
    setPaymentCancelled(cancelled)
  }, [query])

  useEffect(() => {
    const success: boolean = query.get('success') === 'true'
    const cancelled: boolean = query.get('cancelled') === 'true'

    setPaymentSuccess(success)
    setPaymentCancelled(cancelled)
  }, [query])

  useEffect(() => {
    if (designId) {
      getCourseDesign()
    } else {
      getCourseRegistration()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const layout = courseRegistration ? (
    successLayout(
      slug,
      courseRegistration,
      isRegistrationModalShown,
      organizationInfo,
      overview,
      designId ? true : false,
      t,
      showRegistrationModal,
      hideRegistrationModal,
      buttonStatus
    )
  ) : (
    <></>
  )
  return (
    <div id="course-registration">
      <Dialog
        open={courseRegistration != null && paymentSuccess}
        onClose={() => {
          clearUrlQuery()
        }}
      >
        <DialogContent>
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Box sx={{ color: '#2477BC' }}>
              <DialogTitle>
                {t('course-registration.modal.title-success')}
              </DialogTitle>
            </Box>
            <img
              src={SuccessAnimation}
              alt="Registration success icon"
              width="100"
            />
            <Box sx={{ mt: 3, fontSize: 16 }}>
              <span>{t('course-registration.modal.success-message-1')}</span>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            mt: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              clearUrlQuery()
            }}
            className="yes-button"
          >
            {t('common.close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={courseRegistration != null && paymentCancelled}
        onClose={() => {
          clearUrlQuery()
        }}
      >
        <DialogContent>
          <Stack
            sx={{
              color: '#2477BC',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <DialogTitle>
              {t('payments.messages.payment-cancelled')}
            </DialogTitle>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            mt: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              clearUrlQuery()
            }}
            className="yes-button"
          >
            {t('common.close')}
          </Button>
        </DialogActions>
      </Dialog>
      {layout}
      {error ?? errorLayout(t('NoRecordFound'))}
      <Loader
        isVisible={!courseRegistration && !error}
        padding={PaddingLoader.NONE}
      />
    </div>
  )
}

function parseCourseRegistration(
  courseResponse: AxiosResponse,
  t: TFunction
): CourseRegistrationModel {
  const course = courseResponse.data.course
  const organization = courseResponse.data.organization
  const sessions: Session[] = course.meetings.map((meeting: any) => {
    return {
      id: meeting.id,
      title: meeting.sessionname,
      descriptionMarkup: meeting.description_markup,
      dateTimeFrom: meeting.start_date,
      dateTimeTo: meeting.end_date,
      isOnline: meeting.link,
      locationAddress: meeting.inpersonmeetingaddress,
      link: meeting.link,
    }
  })

  let courseRegistration: CourseRegistrationModel = {
    companyName: organization.name,
    companyLogo: organization.logo_url,
    courseName: course.title,
    overview: { text: course.description_markup, extraInfos: [] },
    location: getCourseLocation(sessions, t),
    sessions: sessions,
    status: course.status,
    banner_url: course.banner.url,
    stripePriceId: course.stripe_price_id,
  }

  const price = courseResponse.data.course.price
  if (price) {
    courseRegistration.price = {
      amount: (price.amount / 100).toFixed(2),
      currency: price.currency.toUpperCase(),
    }
  }

  return courseRegistration
}

function parseCourseDesign(
  designResponse: AxiosResponse,
  t: TFunction
): CourseRegistrationModel {
  const design = designResponse.data

  const sessions: Session[] = design.session_designs.map(
    (sessionDesign: SessionDesign, index: number) => {
      const now = localizedUTCMoment(undefined)
      const dateTimeFrom = now
        .add(10 + index, 'days')
        .add(index, 'hours')
        .format('lll')
      const dateTimeTo = now
        .add(10 + index, 'days')
        .add(2 + index, 'hours')
        .format('lll')

      return {
        id: sessionDesign.uuid,
        title: sessionDesign.name,
        descriptionMarkup: sessionDesign.description_markup,
        dateTimeFrom: dateTimeFrom,
        dateTimeTo: dateTimeTo,
        isOnline: sessionDesign.meeting_link ? true : false,
        locationAddress: sessionDesign.address,
        link: sessionDesign.meeting_link,
        speakers: [],
      }
    }
  )

  let courseRegistration: CourseRegistrationModel = {
    companyName: design.organization.name,
    companyLogo: design.organization.logo_url,
    courseName: design.name,
    overview: { text: design.description_markup, extraInfos: [] },
    location: getCourseLocation(sessions, t),
    sessions: sessions,
    status: 'Live',
    banner_url: '',
    stripePriceId: null,
  }

  return courseRegistration
}

function successLayout(
  slug: string,
  courseRegistration: CourseRegistrationModel,
  isRegistrationModalShown: boolean,
  organizationInfo: OrganizationInfoModel | undefined,
  overview: boolean,
  isDesignPreview: boolean,
  t: TFunction,
  showRegistrationModal: () => void,
  hideRegistrationModal: () => void,
  buttonStatus: () => boolean
) {
  return (
    <>
      <Container maxWidth="md">
        <Grid container sx={{ p: 0, backgroundColor: '#f7f9fb' }}>
          {courseRegistration?.banner_url && (
            <Grid item md={8} sx={{ p: 0 }}>
              <img
                className="img-fluid"
                src={courseRegistration?.banner_url}
                alt="course_image"
              />
            </Grid>
          )}
          <Grid
            item
            md={courseRegistration?.banner_url ? 4 : 'auto'}
            sx={{ p: 0 }}
          >
            <MainInfo courseRegistration={courseRegistration} />
          </Grid>
        </Grid>
        <div className="courseCountdown__container">
          {courseRegistration.sessions.length > 0 &&
            courseRegistration.sessions[0].dateTimeFrom && (
              <CourseCountdown
                courseStartDate={courseRegistration.sessions[0].dateTimeFrom}
              />
            )}
        </div>
        <div className="course-registration-bar">
          <Box
            hidden={!courseRegistration.stripePriceId}
            className="course-registration-bar__price"
          >
            {courseRegistration.price?.amount}{' '}
            {courseRegistration.price?.currency}
            {courseRegistration.price
              ? ' (' + t('courses.vat-excluded') + ')'
              : ''}
          </Box>

          <div>
            {!overview && (
              <>
                {' '}
                <StyledRegistrationButton
                  className="blue-button"
                  onClick={() => {
                    if (isDesignPreview) {
                      return
                    }
                    showRegistrationModal()
                  }}
                  disabled={buttonStatus()}
                >
                  {t('course-registration.register-button')}
                </StyledRegistrationButton>
                <RegistrationModal
                  isMarketing={false}
                  courseSlug={slug}
                  isShown={isRegistrationModalShown}
                  handleClose={hideRegistrationModal}
                  isPaidCourse={!!courseRegistration.stripePriceId}
                  organizationInfo={organizationInfo}
                />
              </>
            )}
          </div>
        </div>
        <CourseInfo
          overview={courseRegistration.overview}
          sessions={courseRegistration.sessions}
        />
        {organizationInfo && (
          <OrganizationInfo organizationInfo={organizationInfo} />
        )}
        <Footer />
      </Container>
    </>
  )
}

function errorLayout(text?: string) {
  return (
    <h1
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ebedef',
      }}
    >
      {text}
    </h1>
  )
}

export default CourseRegistration
