import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FundingChannel } from 'Component/FundedTraining/types'
import { FunderFundingChannelsTable } from './FunderFundingChannelsTable'

interface Props {
  funderUuid: string
  items: FundingChannel[]
  onItemDeleted: () => void
}

export function FunderFundingChannels(props: Props) {
  const { funderUuid, items, onItemDeleted } = props

  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5">
        {t('funded-training.funding-channels')}
      </Typography>

      <FunderFundingChannelsTable
        funderUuid={funderUuid}
        items={items}
        onItemDeleted={() => {
          onItemDeleted()
        }}
      />
    </Box>
  )
}
