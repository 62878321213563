import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface CourseDetailState {
  shouldUpdateCourse: boolean
  courseDescription?: any
}

const initialState: CourseDetailState = {
  shouldUpdateCourse: true,
}

export const CourseDetailSlice = createSlice({
  name: 'course-detail',
  initialState,
  reducers: {
    courseFetched: (state) => {
      state.shouldUpdateCourse = false
    },
    courseEdited: (state) => {
      state.shouldUpdateCourse = true
    },
    setCourseDescription: (state, action: PayloadAction<any>) => {
      state.courseDescription = action.payload
    },
  },
})

// SELECTORS
export const selectShouldUpdateCourse = (state: RootState) =>
  state.CourseDetail.shouldUpdateCourse

export const { courseFetched, courseEdited, setCourseDescription } =
  CourseDetailSlice.actions

export default CourseDetailSlice.reducer
