import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface LoaderState {
  isLoading: boolean
}

const initialState: LoaderState = {
  isLoading: false,
}

export const LoaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

// SELECTORS
export const selectLoader = (state: RootState) => state.Loader.isLoading

export const { setLoader } = LoaderSlice.actions

export default LoaderSlice.reducer
