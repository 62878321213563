import { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import Loader from 'Component/Loader';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useApi from '../../api/UseApi';
import { getLocalizedDateFromUTC } from 'common/DateTimeUtils';
import Pagination from 'common/Pagination';
import ConfirmationDialog from 'Component/ConfirmationDialog';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import { useAppSelector } from 'Store';
import { StyledCard } from 'common/styled-component/new-template/StyledCard';

const Archived = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [allCourses, setallCourses] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [courseData, setCourseData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const { t } = useTranslation();
  const organization = useAppSelector(selectCurrentOrganization);

  const api = useApi(false);

  const GetAllarchiveCourse = async () => {
    setIsLoaderVisible(true);

    try {
      api.get(`organizations/${organization?.id}/courses/archived`).then(
        (response) => {
          response.data.courses.forEach((element) => {
            element.tablecollapse = false;
          });

          setallCourses(response.data.courses);

          setIsLoaderVisible(false);
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    GetAllarchiveCourse();
  }, []);

  const DeleteCourse = async () => {
    setIsLoaderVisible(true);
    try {
      api.delete(`courses/${courseData.id}`).then(
        (response) => {
          setIsLoaderVisible(false);

          toast.success('course has been deleted successfully', {
            theme: 'colored',
          });
          GetAllarchiveCourse();
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const restore = (getCourseId) => {
    setIsLoaderVisible(true);
    try {
      api.post(`courses/${getCourseId}/restore`).then(
        (response) => {
          setIsLoaderVisible(false);
          toast.success(response.data.message, { theme: 'colored' });
          GetAllarchiveCourse();
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="archived">
      <StyledCard
        sx={{
          py: 2,
          px: 4,
          mr: 1,
          textAlign: 'center',
          minWidth: 200,
        }}
      >
        <ConfirmationDialog
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          i18nKey="courses.course-delete-confirmation"
          entityName={courseData.title}
          onConfirm={DeleteCourse}
        />

        {organization?.userPermissions.includes('edit:courses') && (
          <Table responsive="sm">
            <thead>
              <tr>
                <th>{t('settings.CourseName')}</th>
                <th>{t('settings.Sessions')}</th>
                <th>{t('settings.EndDate')}</th>
                <th>{t('registrants')}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allCourses
                .slice(pageNo * rowsPerPage - rowsPerPage, pageNo * rowsPerPage)
                .map((data, index) => (
                  <tr key={index}>
                    <td>{data.title}</td>
                    <td>{data.meetings.length}</td>
                    <td>
                      {data.course_end_date != null &&
                        getLocalizedDateFromUTC(data.course_end_date)}
                    </td>
                    <td>{data.course_total_registrants}</td>

                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => restore(data.id)}
                      >
                        {t('settings.Restore')}
                      </button>
                    </td>

                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setCourseData(data);
                          setIsPopupOpen(true);
                        }}
                      >
                        {t('settings.Delete')}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        {allCourses.length > 0 && (
          <Pagination
            numberOfItems={allCourses.length}
            rowsPerPage={rowsPerPage}
            pageNo={pageNo}
            onPageChanged={(pageNo) => {
              setPageNo(pageNo);
            }}
            onRowsPerPageChanged={(rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
            }}
          />
        )}
        <Loader isVisible={isLoaderVisible} />
      </StyledCard>
    </div>
  );
};

export default Archived;
