import { styled } from '@mui/system'
import { Box, Button, TableBody, TableCell } from '@mui/material'
import { getLocalizedDateFromUTC } from 'common/DateTimeUtils'
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableTypography,
} from 'common/styled-component/StyledTable'
import { Course } from 'Component/CourseRegistration/Models'
import CourseStatus from 'Component/CourseStatus/CourseStatus'
import TrainerAvatars from 'Component/TrainerAvatars'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useHistory } from 'react-router-dom'
import ExpandableTableRow from './ExpandableTableRow'
import { useEffect, useState } from 'react'
import useWindowDimensions from 'common/hooks/useWindowDimensions'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import DropdownTableCourses from 'Component/DropdownTableCourses/DropdownTableCourses'
import { Breakpoints } from './TableCourses'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useTranslation } from 'react-i18next'
import CourseService from 'Service/CourseService'

interface TableCoursesBodyProps {
  course: Course
  organization: any
  getAllCourses: () => void
  setIsLoaderVisible: (value: boolean) => void
}

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '&::before': {
    content: '"-"',
    display: 'block',
    lineHeight: '1em',
    color: 'transparent',
  },
}))

const TableCoursesBody = ({
  course,
  organization,
  getAllCourses,
  setIsLoaderVisible,
}: TableCoursesBodyProps) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteTag, setDeleteTag] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPublishConfirmationDialogOpen, setIsPublishConfirmationDialogOpen] =
    useState(false)
  const [singleCourse, setSingleCourse] = useState<Course>()

  const history = useHistory()
  const { width } = useWindowDimensions()
  const api = useApi(false)
  const { t } = useTranslation()
  const { publishCourse, deleteCourse, archiveCourse } = CourseService()

  const publish = async () => {
    setIsLoaderVisible(true)
    try {
      if (singleCourse) {
        await publishCourse(singleCourse)
        setIsLoaderVisible(false)
        getAllCourses()
      }
    } catch (e: any) {
      console.log(e.message)
      setIsLoaderVisible(false)
    }
  }

  const removeCourse = async () => {
    setIsLoaderVisible(true)

    try {
      if (singleCourse) {
        await deleteCourse(singleCourse)
        setIsLoaderVisible(false)
        getAllCourses()
      }
    } catch (e: any) {
      console.log(e.message)
      setIsLoaderVisible(false)
    }
    handleClose()
  }

  const archive = async () => {
    setIsLoaderVisible(true)
    try {
      if (singleCourse) {
        await archiveCourse(singleCourse)
        setIsLoaderVisible(false)
        getAllCourses()
      }
    } catch (e: any) {
      console.log(e.message)
      setIsLoaderVisible(false)
    }
  }

  const navigateCourseDetail = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    course: Course
  ) => {
    e.stopPropagation()
    history.push(
      organization?.userPermissions.includes('edit:session')
        ? `/Courses/${course.id}`
        : '/Courses'
    )
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (width >= Breakpoints.LG) {
      setOpen(false)
    }
  }, [width])

  return (
    <>
      <StyledTableBody>
        <StyledTableRow
          className={
            open
              ? 'style_table_row_active'
              : width <= Breakpoints.LG
              ? 'style_table_row cursorPointer'
              : 'style_table_row'
          }
          role="checkbox"
          tabIndex={-1}
          onClick={() => {
            width <= Breakpoints.LG && setOpen(!open)
          }}
        >
          {Breakpoints.XS <= width && (
            <StyledTableCell>
              <StyledTableTypography>{course.title}</StyledTableTypography>
            </StyledTableCell>
          )}
          {Breakpoints.XS <= width && (
            <StyledTableCell>
              <CourseStatus course={course} />
            </StyledTableCell>
          )}
          {Breakpoints.SM <= width && (
            <StyledTableCell>
              <div style={{ display: 'inline-flex' }}>
                <TrainerAvatars trainers={course.trainer} />
              </div>
            </StyledTableCell>
          )}
          {Breakpoints.SM <= width && (
            <StyledTableCell>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <StyledTableTypography>
                  {course.course_begin_date != null
                    ? getLocalizedDateFromUTC(course.course_begin_date)
                    : null}
                </StyledTableTypography>
                <StyledTableTypography>
                  {course.course_end_date != null
                    ? getLocalizedDateFromUTC(course.course_end_date)
                    : null}
                </StyledTableTypography>
              </Box>
            </StyledTableCell>
          )}
          {Breakpoints.XS <= width && (
            <StyledTableCell>
              <StyledTableTypography>
                {course.meetings.length}
              </StyledTableTypography>
            </StyledTableCell>
          )}
          {Breakpoints.LG <= width && (
            <StyledTableCell>
              <StyledTableTypography>
                {course.course_total_registrants}
              </StyledTableTypography>
            </StyledTableCell>
          )}
          <StyledTableCell>
            <Box sx={{ display: 'flex' }}>
              <MoreVertIcon
                className="cursorPointer"
                onClick={(e) => {
                  e.stopPropagation()
                  handleClick(e)
                  setSingleCourse(course)
                }}
              />
              <Button
                sx={{ color: 'gray', padding: 0, minWidth: '0px' }}
                onClick={(e) => {
                  navigateCourseDetail(e, course)
                }}
              >
                <VisibilityIcon />
              </Button>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <ExpandableTableRow open={open} course={course} />
      </StyledTableBody>
      <DropdownTableCourses
        anchorEl={anchorEl}
        course={course}
        organization={organization}
        handleClose={handleClose}
        setDeleteTag={setDeleteTag}
        setIsPopupOpen={setIsPopupOpen}
        setIsPublishConfirmationDialogOpen={setIsPublishConfirmationDialogOpen}
        archiveCourse={archive}
      />
      <ConfirmationDialog
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false)
        }}
        i18nKey={
          deleteTag === 'course'
            ? 'courses.course-delete-confirmation'
            : 'courses.session-delete-confirmation'
        }
        entityName={
          deleteTag === 'course' ? course?.title : course?.sessionname
        }
        onConfirm={removeCourse}
      />
      <ConfirmationDialog
        isOpen={isPublishConfirmationDialogOpen}
        onClose={() => {
          setIsPublishConfirmationDialogOpen(false)
        }}
        i18nKey="courses.course-publish-confirmation"
        entityName={course?.title}
        onConfirm={() => {
          publish()
        }}
      />
    </>
  )
}

export default TableCoursesBody
