import Box from '@mui/material/Box'
import { Overview, Session } from 'Component/CourseRegistration/Models'
import OverviewTab from './OverviewTab'
import SessionInfo from './SessionInfo/SessionInfo'
import { useTranslation } from 'react-i18next'

type Props = {
  overview?: Overview
  sessions: Session[]
}

const CourseInfo = ({ overview, sessions }: Props) => {
  const { t } = useTranslation()

  return (
    <Box className="course-info-container">
      {overview && (
        <Box hidden={!overview.text} className="course-info-section">
          <span className="course-info-section-title">
            {' '}
            {t('course-registration.overview')}{' '}
          </span>
          <Box className="course-info-tab-panel">
            <OverviewTab overview={overview} />
          </Box>
        </Box>
      )}

      <Box className="course-info-section">
        <span className="course-info-section-title">
          {' '}
          {t('common.sessions')}{' '}
        </span>
        <Box className="course-info-tab-panel">
          <SessionInfo sessions={sessions} />
        </Box>
      </Box>
    </Box>
  )
}

export default CourseInfo
