import { useState } from 'react';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import filtericon from '../../../assets/icon/global/filtericon.svg';
import '../courseFilter/CourseFilter.scss';
import Loader from 'Component/Loader';
import DatePicker from 'Component/DatePicker';

export default function CourseFilter({ onFiltersSelected }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  const [sessionCount, setsessionCount] = useState('-');
  const [registrantsFrom, setregistrantsFrom] = useState('-');
  const [registrantsTo, setregistrantsTo] = useState('-');
  const [status, setStatus] = useState(null);
  const [certificate, setCertificate] = useState('');
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilter = () => {
    setsessionCount('-');
    setCertificate('');
    setregistrantsFrom('-');
    setregistrantsTo('-');
    setstartDate(null);
    setendDate(null);
    setStatus(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const applyFilters = () => {
    setAnchorEl(null);

    onFiltersSelected({
      sessionCount: sessionCount === '-' ? null : Number(sessionCount),
      status: status,
      startDate: startDate,
      endDate: endDate,
      registrantsFrom: registrantsFrom === '-' ? null : Number(registrantsFrom),
      registrantsTo: registrantsTo === '-' ? null : Number(registrantsTo),
    });
  };

  return (
    <div>
      <button className="btn graytxt" onClick={handleClick}>
        <img src={filtericon} alt="filtericon" className="filtericon" />
        {t('courses.Filter')}
      </button>

      <Popover
        className="courseFilter"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            applyFilters();
          }}
          onReset={(e) => {
            clearFilter();
          }}
        >
          <div className="filter-begin">
            <div className="">
              <label className="form-label">
                {t('course-filter.min-sessions')}
              </label>
              <div className="number-input">
                <input
                  type="text"
                  className="form-control addCoursesInput mb-4 me-2"
                  value={sessionCount}
                />
                <div className="up-down-icon">
                  <i
                    class="fas fa-caret-up"
                    onClick={() => {
                      if (sessionCount === '-') {
                        setsessionCount('0');
                      } else {
                        setsessionCount(`${Number(sessionCount) + 1}`);
                      }
                    }}
                  ></i>
                  <i
                    class="fas fa-caret-down"
                    onClick={() => {
                      if (sessionCount === '-') {
                        setsessionCount('-');
                      } else if (sessionCount === '0') {
                        setsessionCount('-');
                      } else {
                        setsessionCount(`${Number(sessionCount) - 1}`);
                      }
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="">
              <label className="form-label">{t('course-filter.status')}</label>
              <div className="number-input">
                <select
                  className="form-select addCoursesInput mb-4"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status}
                >
                  <option value="">{t('common.select')}</option>
                  <option value="Live">{t('common.live')}</option>
                  <option value="Draft">{t('common.draft')}</option>
                  <option value="Completed">{t('common.completed')}</option>
                  <option value="Coming Next">{t('common.coming-next')}</option>
                </select>
              </div>
            </div>

            <div className="addCoursesDate mb-4">
              <label className="form-label">
                {t('course-filter.begin-date')}
              </label>
              <div>
                <DatePicker
                  value={startDate}
                  onDateChange={(newDate) => {
                    setstartDate(newDate);
                  }}
                />
              </div>
            </div>

            <div className="addCoursesDate mb-4">
              <label className="form-label">
                {t('course-filter.end-date')}
              </label>
              <div>
                <DatePicker
                  value={endDate}
                  onDateChange={(newDate) => {
                    setendDate(newDate);
                  }}
                />
              </div>
            </div>

            <label className="form-label">
              {t('course-filter.min-participants')}
            </label>
            <div className="number-input">
              <input
                type="text"
                className="form-control addCoursesInput mb-4 me-2"
                value={registrantsFrom}
              />
              <div className="up-down-icon">
                <i
                  class="fas fa-caret-up"
                  onClick={() => {
                    if (registrantsFrom === '-') {
                      setregistrantsFrom('0');
                    } else {
                      setregistrantsFrom(`${Number(registrantsFrom) + 1}`);
                    }
                  }}
                ></i>
                <i
                  class="fas fa-caret-down"
                  onClick={() => {
                    if (registrantsFrom === '-') {
                      setregistrantsFrom('-');
                    } else if (registrantsFrom === '0') {
                      setregistrantsFrom('-');
                    } else {
                      setregistrantsFrom(`${Number(registrantsFrom) - 1}`);
                    }
                  }}
                  style={{
                    cursor: `${
                      registrantsFrom === '-' ? 'not-allowed' : 'pointer'
                    }`,
                  }}
                ></i>
              </div>
            </div>
            <label className="form-label">
              {' '}
              {t('course-filter.max-participants')}{' '}
            </label>
            <div className="number-input">
              <input
                type="text"
                className="form-control addCoursesInput mb-4 me-2"
                value={registrantsTo}
              />
              <div className="up-down-icon">
                <i
                  class="fas fa-caret-up"
                  onClick={() => {
                    if (registrantsTo === '-') {
                      setregistrantsTo('0');
                    } else {
                      setregistrantsTo(`${Number(registrantsTo) + 1}`);
                    }
                  }}
                ></i>
                <i
                  class="fas fa-caret-down"
                  onClick={() => {
                    if (registrantsTo === '-') {
                      setregistrantsTo('-');
                    } else if (registrantsTo === '0') {
                      setregistrantsTo('-');
                    } else {
                      setregistrantsTo(`${Number(registrantsTo) - 1}`);
                    }
                  }}
                  style={{
                    cursor: `${
                      registrantsTo === '-' ? 'not-allowed' : 'pointer'
                    }`,
                  }}
                ></i>
              </div>
            </div>
          </div>
          <div className="submit d-flex justify-content-around">
            <button className="btn btn-primary" type="reset">
              {t('course-filter.clearFilter')}
            </button>
            <button className="btn btn-primary" type="submit">
              {t('course-filter.apply')}
            </button>
          </div>
        </form>
        <Loader isVisible={isLoaderVisible} />
      </Popover>
    </div>
  );
}
