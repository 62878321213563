import { Table } from '@mui/material'
import { useState } from 'react'
import { Course, Survey } from 'Component/CourseRegistration/Models'
import { StyledTableContainer } from 'common/styled-component/StyledTable'
import TableCoursesHeader from './TableCoursesHeader'
import TableCoursesBody from './TableCoursesBody'

interface TableCoursesProp {
  courses: Course[]
  organization: any
  page?: number
  rowsPerPage?: number
  getAllCourses: () => void
  setIsLoaderVisible: (value: boolean) => void
}

export type Order = 'asc' | 'desc'

export enum Breakpoints {
  XS = 0,
  SM = 600,
  MD = 900,
  LG = 1200,
  XL = 1536,
}

const TableCourses = ({
  courses,
  organization,
  page = 1,
  rowsPerPage = 5,
  getAllCourses,
  setIsLoaderVisible,
}: TableCoursesProp) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('')

  function comparator(a: any, b: any, orderBy: string) {
    switch (orderBy) {
      case 'course_begin_date':
        return new Date(b) < new Date(a)
          ? -1
          : new Date(b) > new Date(a)
          ? 1
          : 0
      case 'meetings':
        return b.length < a.length ? -1 : b.length > a.length ? 1 : 0
      default:
        switch (typeof a) {
          case 'string':
            return b?.toLowerCase() < a?.toLowerCase()
              ? -1
              : b?.toLowerCase() > a?.toLowerCase()
              ? 1
              : 0
          case 'number':
            return b < a ? -1 : b > a ? 1 : 0
          default:
            return 0
        }
    }
  }

  function getComparator(order: any, orderBy: string) {
    return order === 'desc'
      ? (a: any, b: any) => comparator(a[orderBy], b[orderBy], orderBy)
      : (a: any, b: any) => -comparator(a[orderBy], b[orderBy], orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler =
    (cellId: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, cellId)
    }

  return (
    <>
      <StyledTableContainer>
        <Table sx={{ width: '80%', marginBottom: '1rem' }}>
          <TableCoursesHeader
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
          {stableSort(courses, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((course) => {
              return (
                <TableCoursesBody
                  key={course.id}
                  course={course}
                  organization={organization}
                  getAllCourses={getAllCourses}
                  setIsLoaderVisible={setIsLoaderVisible}
                />
              )
            })}
        </Table>
      </StyledTableContainer>
    </>
  )
}

export default TableCourses
