import { useState, useEffect } from 'react'
import MainInfo from './MainInfo'
import Footer from './Footer'
import { OrganizationInfoModel, RouteParams } from './Models'
import { useTranslation } from 'react-i18next'
import useApi from 'api/UseApi'
import { useParams } from 'react-router-dom'
import { Session } from 'Component/CourseRegistration/Models'
import { TFunction } from 'i18next'
import Loader, { PaddingLoader } from 'Component/Loader'
import { Box, Container } from '@mui/material'
import RegistrationModal from './RegistrationModal/RegistrationModal'
import { StyledRegistrationButton } from 'common/styled-component/StyledButton'
import { toast } from 'react-toastify'
import { Stack } from '@mui/system'

const MarketingRegistration = () => {
  const { t } = useTranslation()
  const api = useApi(false, true)
  let { slug } = useParams<RouteParams>()
  const [error, setError] = useState(false)
  const [organizationInfo, setOrganizationInfo] =
    useState<OrganizationInfoModel>()
  const [courseName, setCourseName] = useState('')
  const [isRegistrationModalShown, setIsRegistrationModalShown] =
    useState(false)
  const showRegistrationModal = () => setIsRegistrationModalShown(true)
  const hideRegistrationModal = () => setIsRegistrationModalShown(false)
  const [isLoading, setIsLoading] = useState(false)

  const getCourseRegistration = async () => {
    setIsLoading(true)
    api.get(`courses/${slug}/registration`).then(
      (response) => {
        if (!response.data.hasOwnProperty('course')) {
          setError(true)
          setIsLoading(false)
          return
        }
        setError(false)
        const organization = response.data.organization
        setCourseName(response.data.course.title)

        setOrganizationInfo(organization)
        setIsLoading(false)
      },
      (error) => {
        setError(true)
        toast.error(error.response.data.message, { theme: 'colored' })
        setIsLoading(false)
      }
    )
  }

  useEffect(() => {
    getCourseRegistration()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const layout = successLayout(
    slug,
    isRegistrationModalShown,
    organizationInfo,
    courseName,
    t,
    showRegistrationModal,
    hideRegistrationModal
  )

  return (
    <div id="course-registration">
      {!isLoading ? layout : <></>}
      <Loader isVisible={isLoading && !error} padding={PaddingLoader.NONE} />
    </div>
  )
}

function successLayout(
  slug: string,
  isRegistrationModalShown: boolean,
  organizationInfo: OrganizationInfoModel | undefined,
  courseName: string | undefined,
  t: TFunction,
  showRegistrationModal: () => void,
  hideRegistrationModal: () => void
) {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 10 }}>
        <Stack direction="column" sx={{ alignItems: 'center' }}>
          <Stack direction="column" sx={{ p: 0, alignItems: 'center' }}>
            <h2>{courseName || ''}</h2>
            <p style={{ color: '#637381' }}>
              {t('course-registration.marketing-register')}
            </p>
          </Stack>
          <div>
            <StyledRegistrationButton
              className="blue-button"
              sx={{ mt: 5 }}
              onClick={() => {
                showRegistrationModal()
              }}
            >
              {t('course-registration.register-button')}
            </StyledRegistrationButton>
            <RegistrationModal
              isMarketing={true}
              courseSlug={slug}
              isShown={isRegistrationModalShown}
              handleClose={hideRegistrationModal}
              isPaidCourse={false}
              organizationInfo={organizationInfo}
            />
          </div>
        </Stack>
        <Footer />
      </Container>
    </>
  )
}

export default MarketingRegistration
