import { Box, IconButton, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import Loader from 'Component/Loader'
import { SessionAttendanceTable } from './SessionAttendanceTable'
import {
  getLocalizedDateTimeFromUTC,
  localizedUTCMoment,
} from 'common/DateTimeUtils'
import i18next from 'i18next'
import { AttendanceRequestItem, AttendanceResult } from './types'
import { toast } from 'react-toastify'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

type RouteParams = {
  courseId: string
  sessionId: string
}
export type AttendanceSession = {
  course_title: string
  session_title: string
  start_date: string
  end_date: string
}

export function SessionAttendance() {
  const { courseId, sessionId } = useParams<RouteParams>()
  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)
  const [session, setSession] = useState<AttendanceSession | null>(null)
  const [sessionStartTime, setSessionStartTime] = useState<string | null>(null)
  const [sessionEndTime, setSessionEndTime] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [attendanceResults, setAttendanceResults] = useState<
    AttendanceResult[]
  >([])

  const history = useHistory()

  const backNavigation = () => {
    history.push(`/Courses/${courseId}`)
  }

  const getSessionAttendance = useCallback(() => {
    if (organization) {
      setIsLoading(true)
      try {
        api.get(`courses/${courseId}/sessions/${sessionId}/people`).then(
          (response) => {
            saveAttendanceResults(response.data)
            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
      } catch (e: any) {
        setIsLoading(false)
        console.log(e.message)
      }
    }
  }, [api, courseId, organization, sessionId])

  useEffect(() => {
    getSessionAttendance()
  }, [courseId, getSessionAttendance, sessionId])

  function updateAttendance(attendanceRequestItems: AttendanceRequestItem[]) {
    setIsLoading(true)
    try {
      api
        .post(`courses/${courseId}/sessions/${sessionId}/attendance`, {
          attendances_attributes: attendanceRequestItems,
        })
        .then(
          (response) => {
            toast.success(response.data.message || t('common.success'), {
              theme: 'colored',
            })

            saveAttendanceResults(response.data)
            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            toast.error(error.response.data.message, { theme: 'colored' })
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      setIsLoading(false)
      console.log(e.message)
    }
  }

  useEffect(() => {
    if (session) {
      if (session.start_date && session.end_date) {
        const start_time = localizedUTCMoment(session.start_date).format(
          i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
        )

        const end_time = localizedUTCMoment(session.end_date).format(
          i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
        )

        setSessionStartTime(start_time)
        setSessionEndTime(end_time)
      }
    }
  }, [session])

  function saveAttendanceResults(attendanceResponse: any) {
    if (attendanceResponse.session) {
      // We check whether the session object is there,
      // since it's only returned for the GET, not the PUT
      setSession({
        course_title: attendanceResponse.session.course_name,
        session_title: attendanceResponse.session.name,
        start_date: attendanceResponse.session.start_date,
        end_date: attendanceResponse.session.end_date,
      })
    }

    const convertedUsers: AttendanceResult[] = []
    attendanceResponse.users.map((user: any) => {
      let converted_entrance_time = user.entrance_time
      let converted_exit_time = user.exit_time

      // Convert entrance/exit datetime objects to time-only
      if (converted_entrance_time) {
        converted_entrance_time = localizedUTCMoment(user.entrance_time).format(
          i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
        )
      }

      if (converted_exit_time) {
        converted_exit_time = localizedUTCMoment(user.exit_time).format(
          i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
        )
      }
      convertedUsers.push({
        id: user.id,
        name: user.first_name + ' ' + user.last_name,
        user_id: user.user_id,
        email: user.email,
        entrance_time: converted_entrance_time,
        exit_time: converted_exit_time,
      })
    })
    setAttendanceResults(convertedUsers)
  }

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box sx={{ display: 'flex', alignItems: 'start' }}>
        <IconButton onClick={backNavigation}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Box sx={{ p: '0.5rem 0 0 0' }}>
          <Typography variant="h5">{t('courses.manage-attendance')}</Typography>

          <Typography variant="body1" sx={{ color: '#5e5e5e' }}>
            <Trans i18nKey={'attendance.session-info.title'}>
              {'placeholder '}
              <strong style={{ color: '#0d6efd', fontSize: '1.2rem' }}>
                <>{{ title: session ? session.session_title : '' }}</>
              </strong>
              {' placeholder'}
            </Trans>
          </Typography>

          <Typography variant="body1" sx={{ color: '#5e5e5e' }}>
            <Trans i18nKey={'attendance.session-info.start-date'}>
              {'placeholder '}
              <strong style={{ color: 'black', fontSize: '1.1rem' }}>
                <>
                  {{
                    startDate: session
                      ? getLocalizedDateTimeFromUTC(session.start_date)
                      : '',
                  }}
                </>
              </strong>
              {' placeholder'}
            </Trans>
          </Typography>

          <Typography variant="body1" sx={{ color: '#5e5e5e' }}>
            <Trans i18nKey={'attendance.session-info.end-date'}>
              {'placeholder '}
              <strong style={{ color: 'black', fontSize: '1.1rem' }}>
                <>
                  {{
                    endDate: session
                      ? getLocalizedDateTimeFromUTC(session.end_date)
                      : '',
                  }}
                </>
              </strong>
              {' placeholder'}
            </Trans>
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ mt: 2, mb: 1, color: '#5e5e5e' }}
          >
            <Trans i18nKey={'attendance.session-info.course-title'}>
              {'placeholder '}
              <strong style={{ color: '#0d6efd' }}>
                <>{{ courseTitle: session ? session.course_title : '' }}</>
              </strong>
              {' placeholder'}
            </Trans>
          </Typography>
        </Box>
      </Box>

      {session && sessionStartTime && sessionEndTime && (
        <SessionAttendanceTable
          session={session}
          sessionStartTime={sessionStartTime}
          sessionEndTime={sessionEndTime}
          attendanceResults={attendanceResults}
          onAttendanceResultUpdated={(
            attendanceRequestItems: AttendanceRequestItem[]
          ) => {
            updateAttendance(attendanceRequestItems)
          }}
        />
      )}
      <Loader isVisible={isLoading || !sessionStartTime || !sessionEndTime} />
    </Box>
  )
}
