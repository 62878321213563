import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
  SelectChangeEvent,
  IconButton,
  Dialog,
  DialogContent,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import EmailEditor from 'react-email-editor'
import { useEffect, useRef, useState, useCallback } from 'react'
import sampleTemplateJson from 'Component/Mail/Templates/sample-template.json'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { Template } from 'Component/Mail/NotificationServiceTypes'
import { MenuItem } from '@mui/material'
import { FormTextFieldInputBase } from 'common/styled-component/FormTextFieldInputBase'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useHistory } from 'react-router-dom'
import EmailPlaceholders from '../EmailPlaceholders'
import InfoIcon from '@mui/icons-material/Info'

type Props = {
  currentTemplate?: Template
  isNewTemplate?: boolean
  onTemplateNameUpdated?(name: string): void
}

export function TemplateForm(props: Props) {
  const {
    currentTemplate = null,
    isNewTemplate = false,
    onTemplateNameUpdated,
  } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)

  const organization = useAppSelector(selectCurrentOrganization)

  const emailEditorRef = useRef<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDesignLoaded, setIsDesignLoaded] = useState<boolean>(false)
  const [wasOnLoadCalled, setWasOnLoadCalled] = useState<boolean>(false)

  const [templateName, setTemplateName] = useState<string>('')
  const [selectedTemplateId, setSelectedTemplateId] = useState<
    undefined | string
  >(undefined)

  const [organizationTemplates, setOrganizationTemplates] = useState<
    Template[]
  >([])
  const [templatesMap, setTemplatesMap] = useState<{ [key: string]: Template }>(
    {}
  )
  const [templateChangeEvent, setTemplateChangeEvent] =
    useState<null | SelectChangeEvent>(null)

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false)
  const [openPlaceholder, setOpenPlaceholder] = useState(false)

  useEffect(() => {
    if (currentTemplate) {
      setTemplateName(currentTemplate.name)
    }
  }, [currentTemplate])

  useEffect(() => {
    if (
      (currentTemplate || isNewTemplate) &&
      wasOnLoadCalled &&
      !isDesignLoaded &&
      emailEditorRef?.current !== null &&
      emailEditorRef?.current !== undefined
    ) {
      if (isNewTemplate) {
        emailEditorRef.current.addEventListener('design:loaded', onDesignLoad)
        emailEditorRef.current.loadDesign(sampleTemplateJson)
      } else if (currentTemplate) {
        emailEditorRef.current.addEventListener('design:loaded', onDesignLoad)
        emailEditorRef.current.loadDesign(JSON.parse(currentTemplate.design))
      }
    }
  }, [
    emailEditorRef,
    wasOnLoadCalled,
    currentTemplate,
    isDesignLoaded,
    isNewTemplate,
  ])

  const getOrganizationTemplates = useCallback(() => {
    if (organization) {
      setIsLoading(true)
      try {
        api
          .get(`notification-service/templates`, {
            params: {
              organizationUuid: organization.notificationId,
            },
          })
          .then(
            (response) => {
              setOrganizationTemplates(response.data.data)
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
      } catch (e: any) {
        console.log(e.message)
        setIsLoading(false)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getOrganizationTemplates()
  }, [getOrganizationTemplates])

  useEffect(() => {
    let newTemplatesMap: { [key: string]: Template } = {}

    organizationTemplates.forEach((template: Template) => {
      newTemplatesMap[template.uuid] = template
    })

    setTemplatesMap(newTemplatesMap)
  }, [organizationTemplates])

  const updateTemplate = (html: string, design: any) => {
    setIsLoading(true)

    try {
      api
        .put(`notification-service/templates/${currentTemplate?.uuid}`, {
          name: templateName,
          content: html,
          design: JSON.stringify(design),
        })
        .then(
          (response) => {
            toast.success(t('mail.template-updated'), {
              theme: 'colored',
            })
            if (onTemplateNameUpdated) {
              onTemplateNameUpdated(templateName)
            }

            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createTemplate = (html: string, design: any) => {
    setIsLoading(true)

    try {
      api
        .post('notification-service/templates', {
          organizationUuid: organization?.notificationId,
          name: templateName,
          content: html,
          design: JSON.stringify(design),
        })
        .then(
          (response) => {
            toast.success(t('mail.template-created'), {
              theme: 'colored',
            })
            setIsLoading(false)
            history.goBack()
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const saveDesign = () => {
    if (emailEditorRef?.current) {
      exportHtml()

      emailEditorRef.current.exportHtml((data: any) => {
        const { design, html } = data

        if (isNewTemplate) {
          createTemplate(html, design)
        } else {
          updateTemplate(html, design)
        }
      })
    }
  }

  const exportHtml = () => {
    if (emailEditorRef?.current) {
      emailEditorRef.current.exportHtml((data: any) => {
        const { design, html, json } = data
      })
    }
  }

  const onDesignLoad = (data: object) => {
    setIsDesignLoaded(true)
  }

  const onLoad = () => {
    setWasOnLoadCalled(true)
  }

  const onReady = () => {
    setIsLoading(false)
  }

  const onTemplateSelected = (event: SelectChangeEvent) => {
    setTemplateChangeEvent(event)
    setIsConfirmationDialogOpen(true)
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={3}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Stack>
          <TextField
            value={templateName}
            label={t('mail.template-name')}
            onChange={(e) => setTemplateName(e.target.value)}
            autoFocus
            inputProps={{
              sx: {
                p: 1.5,
                backgroundColor: 'white',
              },
            }}
          />
          {!templateName ? (
            <FormHelperText
              sx={{ pl: 0.1 }}
              error
              id="template-name-validation-error"
            >
              {t('mail.template-name-empty-warning')}
            </FormHelperText>
          ) : (
            <br />
          )}
        </Stack>
        <StyledButton
          disabled={!templateName}
          startIcon={<SaveIcon />}
          hidden={isLoading}
          sx={{
            backgroundColor: '#0d6efd !important',
          }}
          onClick={(event) => {
            saveDesign()
          }}
        >
          <Typography sx={{ mt: 0.5 }}>
            {isNewTemplate ? t('common.create') : t('common.update')}
          </Typography>
        </StyledButton>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography variant="h6">{t('mail.placeholders-title')}</Typography>
          <IconButton onClick={() => setOpenPlaceholder(true)}>
            <InfoIcon />
          </IconButton>
        </Stack>
      </Stack>

      <InputLabel sx={{ ml: 0.5, color: '#1976d2' }}>
        <Typography sx={{ fontFamily: 'nexa' }} variant="subtitle1">
          {t('mail.template-select-description')}
        </Typography>
      </InputLabel>
      <FormControl sx={{ ml: 0.5, width: 400 }}>
        <Select
          labelId="template-select-label"
          id="template-select"
          value={selectedTemplateId || ''}
          label={null}
          onChange={onTemplateSelected}
          input={<FormTextFieldInputBase placeholder="template" />}
        >
          {organizationTemplates.map((template, index) => (
            <MenuItem key={index} value={template.uuid}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: 2, p: 0.2, height: '65vh', backgroundColor: 'white' }}>
        <EmailEditor
          style={{ height: '65vh' }}
          ref={emailEditorRef}
          onLoad={onLoad}
          onReady={onReady}
        />
      </Box>

      <Dialog onClose={() => setOpenPlaceholder(false)} open={openPlaceholder}>
        <DialogContent>
          <InputLabel sx={{ marginLeft: 0.5, color: 'black' }}>
            <Typography sx={{ fontFamily: 'nexalight' }} variant="subtitle1">
              <EmailPlaceholders type="content" />
            </Typography>
          </InputLabel>
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => {
          setIsConfirmationDialogOpen(false)
        }}
        i18nKey="mail.reset-content-confirmation"
        onConfirm={() => {
          if (templateChangeEvent) {
            const selectedTemplateId: string = templateChangeEvent.target
              .value as string
            const selectedTemplate = templatesMap[selectedTemplateId]

            emailEditorRef.current.loadDesign(
              JSON.parse(selectedTemplate.design)
            )
            setTemplateChangeEvent(null)
            setSelectedTemplateId(selectedTemplateId)
          }
        }}
      />
    </>
  )
}
