import { useState, useEffect, useCallback, memo } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import useApi from 'api/UseApi'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { CircularProgress } from '@mui/material'
import { Box, IconButton } from '@mui/material'
import { Tag } from 'Component/Mail/NotificationServiceTypes'
import TagIcon from '@mui/icons-material/LocalOffer'

type Props = {
  subscriberId: string
  subscriberTags: Tag[]
  onTagsUpdated: () => void
}

const SubscriberTagsPopup = (props: Props) => {
  const { subscriberId, subscriberTags, onTagsUpdated } = props

  const { t } = useTranslation()
  const api = useApi(false)

  const organization = useAppSelector(selectCurrentOrganization)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)

  const [subscriberTagIds, setSubscriberTagsIds] = useState<string[]>([])
  const [organizationTags, setOrganizationTags] = useState<Tag[]>([])
  const [newTagIds, setNewTagIds] = useState<string[]>([])
  const [tagIdsToAdd, setTagsIdsToAdd] = useState<string[]>([])
  const [tagIdsToRemove, setTagsIdsToRemove] = useState<string[]>([])

  useEffect(() => {
    const tagIds = subscriberTags.map((tag) => {
      return tag.uuid
    })
    setSubscriberTagsIds(tagIds)
  }, [subscriberTags])

  const getOrganizationTags = useCallback(() => {
    if (organization) {
      setIsLoading(true)
      try {
        api
          .get(`tags`, {
            params: {
              organizationUuid: organization.notificationId,
            },
          })
          .then(
            (response) => {
              setOrganizationTags(response.data.data)
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
      } catch (e: any) {
        console.log(e.message)
        setIsLoading(false)
      }
    }
  }, [api, organization])

  const updateTags = () => {
    setIsLoading(true)

    try {
      api
        .put(`subscribers/${subscriberId}/tags`, {
          tags: newTagIds,
        })
        .then(
          (response) => {
            toast.success('Tags Updated', { theme: 'colored' })
            onPopupClose()
            setIsLoading(false)
            onTagsUpdated()
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onPlusButtonClick = (currentTarget: any) => {
    setAnchorEl(currentTarget)
    getOrganizationTags()
  }

  const onPopupClose = () => {
    setAnchorEl(null)
    setNewTagIds([])
  }

  return (
    <div style={{ marginTop: '1rem', marginRight: '1rem' }}>
      <IconButton
        aria-describedby={'subscriber-tags-popper'}
        sx={{ color: '#73B0FF' }}
        onClick={(event) => {
          onPlusButtonClick(event.currentTarget)
        }}
      >
        <TagIcon />
      </IconButton>

      <Popper
        id={'subscriber-tags-popper'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <ClickAwayListener onClickAway={onPopupClose}>
          <Stack
            direction="row"
            spacing={3}
            sx={{
              marginBottom: 4,
              backgroundColor: 'white',
              p: 2,
              borderRadius: 1,
              boxShadow: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: 300,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Autocomplete
                sx={{ width: 300 }}
                id="subscriber-tags-autocomplete"
                options={organizationTags}
                getOptionLabel={(tag) => tag.name}
                isOptionEqualToValue={(tag, value) => tag.name === value.name}
                defaultValue={subscriberTags}
                multiple
                hidden={isLoading}
                disablePortal
                onChange={(event, newTags) => {
                  const newTagIds = newTags.map((tag) => {
                    return tag.uuid
                  })

                  const toAdd = newTagIds.filter(
                    (tagId) => !subscriberTagIds.includes(tagId)
                  )

                  setTagsIdsToAdd(toAdd)

                  const toRemove = subscriberTagIds.filter(
                    (tagId) => !newTagIds.includes(tagId)
                  )
                  setTagsIdsToRemove(toRemove)

                  setNewTagIds(newTagIds)
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('mail.subscriber-tags')}
                    placeholder={t('mail.subscriber-tags-popup.placeholder')}
                  />
                )}
              />

              <CircularProgress
                hidden={!isLoading}
                style={{ height: '2rem', width: '2rem' }}
              />
            </Box>

            <Button
              variant="contained"
              disableElevation
              disabled={tagIdsToRemove.length === 0 && tagIdsToAdd.length === 0}
              sx={{ px: 3 }}
              onClick={() => {
                updateTags()
              }}
            >
              {t('common.update')}
            </Button>
          </Stack>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default memo(SubscriberTagsPopup)
