import { TFunction } from 'i18next'
import { string, object, SchemaOf, number } from 'yup'

export type FunderFormData = {
  name: string
}

export const FunderFormValidation = (t: TFunction) => {
  const schema: SchemaOf<FunderFormData> = object().shape({
    name: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.name')),
  })

  return schema
}
