import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CampaignForm } from 'Component/Mail/Campaigns/CampaignForm'

export function NewCampaign() {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5" sx={{ mt: 5 }}>
          {t('mail.new-campaign')}
        </Typography>
        <CampaignForm isNewCampaign={true} />
      </Box>
    </Box>
  )
}
