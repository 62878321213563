import { Box, Typography, Tabs, Tab, Link } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { Company } from 'Component/FundedTraining/types'
import { CompanyForm } from './CompanyForm'
import {
  selectShouldPreselectProjectsTab,
  setShouldPreselectProjectsTab,
} from 'Reducers/FundedTraining/CompanyReducer'
import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'
import CompanyTabPanel from './CompanyTabPanel'
import { CompanyProjects } from './CompanyProjects'

type RouteParams = {
  companyUuid: string
}

type LocationState = {
  companyName: string
}

type Props = {
  isNew?: boolean
}

function a11yProps(index: number) {
  return {
    id: `company-tab-${index}`,
    'aria-controls': `company-tabpanel-${index}`,
  }
}

export function CompanyDetail(props: Props) {
  const { companyUuid } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { isNew = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [companyName, setCompanyName] = useState<string>('')
  const [currentCompany, setCurrentCompany] = useState<null | Company>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  const shouldPreselectProjectsTab = useAppSelector(
    selectShouldPreselectProjectsTab
  )

  useEffect(() => {
    if (shouldPreselectProjectsTab) {
      setTabIndex(1)
      dispatch(setShouldPreselectProjectsTab(false))
    }
  }, [shouldPreselectProjectsTab])

  useEffect(() => {
    if (currentCompany) {
      if (companyName === '') {
        setCompanyName(currentCompany.name)
      }
    } else if (state) {
      setCompanyName(state.companyName)
    }
  }, [state, currentCompany, companyName])

  const getCompany = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`funded_training/companies/${companyUuid}`).then(
        (response) => {
          setCurrentCompany(response.data)
          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, companyUuid])

  useEffect(() => {
    getCompany()
  }, [getCompany, companyUuid])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
        <Trans i18nKey={'funded-training.company-detail.title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd' }}>
            <>{{ companyName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>

      <NavLink to={`/funded-training/companies`} exact>
        <Link variant="body1">{t('funded-training.go-to-company-list')}</Link>
      </NavLink>

      {currentCompany && (
        <>
          <Tabs
            sx={{ mt: 4 }}
            value={tabIndex}
            onChange={onTabChange}
            aria-label="company tabs"
          >
            <Tab label={t('common.config')} {...a11yProps(1)} />
            <Tab label={t('funded-training.projects')} {...a11yProps(0)} />
          </Tabs>

          <CompanyTabPanel value={tabIndex} index={0}>
            <CompanyForm
              currentCompany={currentCompany}
              isNew={isNew}
              onCompanyUpdated={(company: Company) => {
                setCompanyName(company.name)
              }}
            />
          </CompanyTabPanel>
          <CompanyTabPanel value={tabIndex} index={1}>
            <CompanyProjects
              companyUuid={companyUuid}
              items={currentCompany.projects || []}
              onItemDeleted={() => {}}
            />
          </CompanyTabPanel>
        </>
      )}
    </Box>
  )
}
