import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Template } from 'Component/Mail/NotificationServiceTypes'
import { useEffect, useState, useCallback } from 'react'
import { TemplatesTable } from 'Component/Mail/Templates/TemplatesTable'
import Loader from 'Component/Loader'
import useApi from 'api/UseApi'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'

export function Templates() {
  const { t } = useTranslation()
  const api = useApi(false)
  const [templates, setTemplates] = useState<Template[]>([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const getTemplates = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api
          .get('notification-service/templates', {
            params: {
              organizationUuid: organization.notificationId,
            },
          })
          .then(
            (response) => {
              setTemplates(response.data.data)
              setIsLoaderVisible(false)
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        setIsLoaderVisible(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getTemplates()
  }, [getTemplates])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('mail.templates')}
        </Typography>
        <TemplatesTable
          templates={templates}
          onTemplateDeleted={() => {
            getTemplates()
          }}
        />
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </Box>
  )
}
