import { AppointmentModel } from '@devexpress/dx-react-scheduler'
import { Meeting } from 'Component/CourseRegistration/Models'

//Appointment and Meeting
export const convertMeetingToAppointment = (
  appointment: Meeting
): AppointmentModel => ({
  title: appointment.sessionname,
  startDate: new Date(appointment.start_date),
  endDate: new Date(appointment.end_date),
})

export const convertMeetingsToAppointments = (
  appointments: Meeting[]
): AppointmentModel[] => appointments.map(convertMeetingToAppointment)
