import { CourseDesign } from 'Component/Design/Types'
import { ServerCourse, ServerCourseDesign } from 'models/course'

type CoursesResponse = {
  courses: ServerCourse[]
}

type CoursesDesignResponse = {
  courses: ServerCourseDesign[]
}

const baseURL = process.env.REACT_APP_API_BASE_URL || ''

const CatalogService = () => {
  const getCourseCatalog = async (slugOrganization: string) => {
    try {
      const res: Response = await fetch(
        `${baseURL}/organizations/${slugOrganization}/courses`
      )
      const response: CoursesResponse = await res.json()
      return response.courses
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const getDesignCatalog = async (slugOrganization: string) => {
    try {
      const res: Response = await fetch(
        `${baseURL}/organizations/${slugOrganization}/catalogue`
      )
      const response: CoursesDesignResponse = await res.json()
      return response.courses
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const getCourseDesignDetail = async (uuid: string) => {
    try {
      const res: Response = await fetch(
        `${baseURL}/course_designs/${uuid}/registration`
      )
      const response: CourseDesign = await res.json()
      return response
      //return mockCourseDesign
    } catch (error: any) {
      console.log(error)
    }
  }

  return { getDesignCatalog, getCourseCatalog, getCourseDesignDetail }
}
export default CatalogService
