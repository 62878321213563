import { CourseDesign, SessionDesign } from 'Component/Design/Types'
import {
  CourseRegistrationModel,
  Session,
} from 'Component/CourseRegistration/Models'

//Session and SessionDesign
export const convertSessionDesignToSession = (
  session: SessionDesign
): Session => ({
  id: session.uuid,
  descriptionMarkup: session.description_markup,
  locationAddress: session.address,
  link: session.meeting_link,
  title: session.name,
  wyblo_hosting: session.wyblo_hosting,
  wyblo_link: session.wyblo_link,
})

export const convertSessionDesignsToSessions = (
  sessions: SessionDesign[]
): Session[] => sessions.map(convertSessionDesignToSession)

//CourseDesign and CourseRegistration
export const convertCourseDesignToRegistration = (
  course: CourseDesign
): CourseRegistrationModel => ({
  courseName: course.name,
  sessions: convertSessionDesignsToSessions(course.session_designs),
  timezone: course.timezone || '',
  companyName: course.organization.name,
  location: '',
  banner_url: null,
  stripePriceId: null,
})

export const convertCourseDesignsToRegistrations = (
  courses: CourseDesign[]
): CourseRegistrationModel[] => courses.map(convertCourseDesignToRegistration)
