import numeral from 'numeral'

//check if object is empty
export const objectIsEmpty = (obj: object): boolean => {
  return Object.keys(obj).length !== 0
}

// ----------------------------------------------------------------------

export function fileTypeByUrl(fileUrl = '') {
  return (fileUrl && fileUrl.split('.').pop()) || ''
}

export function fileNameByUrl(fileUrl: string) {
  return fileUrl.split('/').pop()
}

// ----------------------------------------------------------------------

export interface CustomFile extends File {
  id?: number
  path?: string
  preview?: string
  lastModifiedDate?: Date
  isServer?: boolean
}

type ServerFile = {
  id?: number
  name?: string
  size?: number
  url?: string
}

export type ServerFileCustom = ServerFile & CustomFile

// Define more types here
const FORMAT_PDF = ['pdf']
const FORMAT_TEXT = ['txt']
const FORMAT_PHOTOSHOP = ['psd']
const FORMAT_WORD = ['doc', 'docx']
const FORMAT_EXCEL = ['xls', 'xlsx']
const FORMAT_ZIP = ['zip', 'rar', 'iso']
const FORMAT_ILLUSTRATOR = ['ai', 'esp']
const FORMAT_POWERPOINT = ['ppt', 'pptx']
const FORMAT_AUDIO = ['wav', 'aif', 'mp3', 'aac']
const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg']
const FORMAT_VIDEO = ['m4v', 'avi', 'mpg', 'mp4', 'webm']

const iconUrl = (icon: string) => `/assets/icons/files/${icon}.svg`

// ----------------------------------------------------------------------

export function fileFormat(fileUrl: string | undefined) {
  let format

  switch (fileUrl?.includes(fileTypeByUrl(fileUrl))) {
    case FORMAT_TEXT.includes(fileTypeByUrl(fileUrl)):
      format = 'txt'
      break
    case FORMAT_ZIP.includes(fileTypeByUrl(fileUrl)):
      format = 'zip'
      break
    case FORMAT_AUDIO.includes(fileTypeByUrl(fileUrl)):
      format = 'audio'
      break
    case FORMAT_IMG.includes(fileTypeByUrl(fileUrl)):
      format = 'image'
      break
    case FORMAT_VIDEO.includes(fileTypeByUrl(fileUrl)):
      format = 'video'
      break
    case FORMAT_WORD.includes(fileTypeByUrl(fileUrl)):
      format = 'word'
      break
    case FORMAT_EXCEL.includes(fileTypeByUrl(fileUrl)):
      format = 'excel'
      break
    case FORMAT_POWERPOINT.includes(fileTypeByUrl(fileUrl)):
      format = 'powerpoint'
      break
    case FORMAT_PDF.includes(fileTypeByUrl(fileUrl)):
      format = 'pdf'
      break
    case FORMAT_PHOTOSHOP.includes(fileTypeByUrl(fileUrl)):
      format = 'photoshop'
      break
    case FORMAT_ILLUSTRATOR.includes(fileTypeByUrl(fileUrl)):
      format = 'illustrator'
      break
    default:
      format = fileTypeByUrl(fileUrl)
  }

  return format
}

// ----------------------------------------------------------------------

export function fileThumb(fileUrl: string) {
  let thumb

  switch (fileFormat(fileUrl)) {
    case 'folder':
      thumb = iconUrl('ic_folder')
      break
    case 'txt':
      thumb = iconUrl('ic_txt')
      break
    case 'zip':
      thumb = iconUrl('ic_zip')
      break
    case 'audio':
      thumb = iconUrl('ic_audio')
      break
    case 'video':
      thumb = iconUrl('ic_video')
      break
    case 'word':
      thumb = iconUrl('ic_word')
      break
    case 'excel':
      thumb = iconUrl('ic_excel')
      break
    case 'powerpoint':
      thumb = iconUrl('ic_power_point')
      break
    case 'pdf':
      thumb = iconUrl('ic_pdf')
      break
    case 'photoshop':
      thumb = iconUrl('ic_pts')
      break
    case 'illustrator':
      thumb = iconUrl('ic_ai')
      break
    case 'image':
      thumb = iconUrl('ic_img')
      break
    default:
      thumb = iconUrl('ic_file')
  }
  return thumb
}

export function fileData(file: CustomFile | ServerFileCustom | string) {
  // Url
  if (typeof file === 'string') {
    return {
      key: file,
      preview: file,
      name: fileNameByUrl(file),
      type: fileTypeByUrl(file),
    }
  }

  if ('url' in file) {
    return {
      key: file.name,
      preview: file.url,
      id: file.id,
      name: file.name,
      size: file.size,
    }
  }

  // File
  return {
    key: file.preview,
    id: file.id,
    name: file.name,
    size: file.size,
    path: file.path,
    type: file.type,
    preview: file.preview,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
  }
}

// ----------------------------------------------------------------------

//table empty rows
export function emptyRows(
  page: number,
  rowsPerPage: number,
  arrayLength: number
) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// ----------------------------------------------------------------------

type InputValue = string | number | null

//format number

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : ''

  return result(format, '.0')
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key)

  return isInteger ? format.replace(key, '') : format
}
