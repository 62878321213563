import { Box, Typography, Tabs, Tab, Link } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { FunderWithChannels } from 'Component/FundedTraining/types'
import { FunderForm } from './FunderForm'
import {
  selectShouldPreselectFundingChannelsTab,
  setShouldPreselectFundingChannelsTab,
} from 'Reducers/FundedTraining/FunderReducer'
import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'
import FunderTabPanel from 'Component/FundedTraining/Funders/FunderTabPanel'
import { FunderFundingChannels } from './FunderFundingChannels'

type RouteParams = {
  funderUuid: string
}

type LocationState = {
  funderName: string
}

type Props = {
  isNew?: boolean
}

function a11yProps(index: number) {
  return {
    id: `funder-tab-${index}`,
    'aria-controls': `funder-tabpanel-${index}`,
  }
}

export function FunderDetail(props: Props) {
  const { funderUuid } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { isNew = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [funderName, setFunderName] = useState<string>('')
  const [currentFunder, setCurrentFunder] = useState<null | FunderWithChannels>(
    null
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  const shouldPreselectFundingChannelsTab = useAppSelector(
    selectShouldPreselectFundingChannelsTab
  )

  useEffect(() => {
    if (shouldPreselectFundingChannelsTab) {
      setTabIndex(1)
      dispatch(setShouldPreselectFundingChannelsTab(false))
    }
  }, [shouldPreselectFundingChannelsTab, dispatch])

  useEffect(() => {
    if (currentFunder) {
      if (funderName === '') {
        setFunderName(currentFunder.name)
      }
    } else if (state) {
      setFunderName(state.funderName)
    }
  }, [state, currentFunder, funderName])

  const getFunder = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`funded_training/funders/${funderUuid}`).then(
        (response) => {
          setCurrentFunder(response.data)
          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, funderUuid])

  useEffect(() => {
    getFunder()
  }, [getFunder, funderUuid])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
        <Trans i18nKey={'funded-training.funder-detail.title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd' }}>
            <>{{ funderName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>

      <NavLink to={`/funded-training/funders`} exact>
        <Link variant="body1">{t('funded-training.go-to-funder-list')}</Link>
      </NavLink>

      {currentFunder && (
        <>
          <Tabs
            sx={{ mt: 4 }}
            value={tabIndex}
            onChange={onTabChange}
            aria-label="funder tabs"
          >
            <Tab label={t('common.config')} {...a11yProps(1)} />
            <Tab
              label={t('funded-training.funding-channels')}
              {...a11yProps(0)}
            />
          </Tabs>

          <FunderTabPanel value={tabIndex} index={0}>
            <FunderForm
              currentFunder={currentFunder}
              isNew={isNew}
              onFunderUpdated={(funder: FunderWithChannels) => {
                setFunderName(funder.name)
              }}
            />
          </FunderTabPanel>
          <FunderTabPanel value={tabIndex} index={1}>
            <FunderFundingChannels
              funderUuid={funderUuid}
              items={currentFunder.funding_channels}
              onItemDeleted={() => {
                getFunder()
              }}
            />
          </FunderTabPanel>
        </>
      )}
    </Box>
  )
}
