import { useParams } from 'react-router-dom'
import CourseRegistration from 'Component/CourseRegistration/CourseRegistration'

type RouteParams = {
  designId: string
}

export function CourseDesignRegistrationPreview() {
  const { designId } = useParams<RouteParams>()

  return <CourseRegistration designId={designId} overview={false} />
}
