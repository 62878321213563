import SearchIcon from '@mui/icons-material/Search'
import { ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

interface SearchBarProp {
  setQuery: (value: string) => void
}

const SearchBar = ({ setQuery }: SearchBarProp) => {
  const { t } = useTranslation()

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value)
  }

  return (
    <div className="search_addCourse">
      <div className="inputSearch">
        <SearchIcon style={{ color: '#DEDEDE' }} />
        <div className="mx-2 inputWidth">
          <input
            type="text"
            className="form-control"
            placeholder={t('courses.Search')}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchBar
