import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearDownloadFile } from '../../../Actions/Downloadfile';
import useApi from '../../../api/UseApi';

function RenameFile({ onHide, show, data, setvisibleLoader, setrefresh }) {
  const props = { onHide, show };
  const [takefilename, settakefilename] = useState('');
  const [oldfilename, setoldfilename] = useState('');
  const dispatch = useDispatch();

  const api = useApi();

  useEffect(() => {
    return () => {
      setoldfilename(data.filename);
    };
  }, [data]);

  const renameFile = async () => {
    setvisibleLoader(true);

    try {
      api
        .post(`S3/RenameFile`, {
          folderPath:
            data.folderpath !== null
              ? data.folderpath !== ''
                ? `${data.folderpath}`
                : ''
              : '',
          fileName: data.filename,
          bucketName: data.bucketname,
          newFileName: takefilename !== '' ? takefilename : data.filename,
        })
        .then(
          (response) => {
            setvisibleLoader(false);
            toast.success(response.data.message, { theme: 'colored' });
            dispatch(clearDownloadFile());
            settakefilename('');
            setrefresh(true);
            onHide();
          },
          (error) => {
            console.log(error);
            setvisibleLoader(false);
            toast.error(error.response.data.message, { theme: 'colored' });
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="New file name"
            value={takefilename}
            onChange={(e) => {
              settakefilename(e.target.value);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={renameFile}
          style={{
            padding: '4px 30px',
          }}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RenameFile;
