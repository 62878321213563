import { OrganizationInfoModel } from './Models'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Link } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

interface OrganizationInfoProps {
  organizationInfo: OrganizationInfoModel
}

const OrganizationInfo = (props: OrganizationInfoProps) => {
  const { organizationInfo } = props

  return (
    <>
      {organizationInfo ? (
        <div className="organizationInfo__container">
          {organizationInfo.logo_url && (
            <div>
              <img
                className="organizationInfo__image"
                src={organizationInfo.logo_url}
                alt="Session time icon"
                width={80}
                height={80}
              />
            </div>
          )}
          {organizationInfo.name && (
            <div className="organizationInfo__name">
              <span>{organizationInfo.name}</span>
            </div>
          )}
          {organizationInfo.description_markup && (
            <ReactQuill
              theme="bubble"
              readOnly={true}
              value={organizationInfo.description_markup}
            />
          )}
          <div className="organizationInfo__social">
            {organizationInfo.linkedin && (
              <Link target="_blank" href={organizationInfo.linkedin}>
                <LinkedInIcon />
              </Link>
            )}
            {organizationInfo.facebook && (
              <Link target="_blank" href={organizationInfo.facebook}>
                <FacebookIcon />
              </Link>
            )}
            {organizationInfo.twitter && (
              <Link target="_blank" href={organizationInfo.twitter}>
                <TwitterIcon />
              </Link>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default OrganizationInfo
