export const resourceUrlButtonHtml =
  '' +
  '<div class="wyblo-component-resource-url"><br/>' +
  '<table data-gjs-draggable="false"' +
  '  style="font-family: \'Montserrat\', sans-serif"' +
  '  role="presentation"' +
  '  cellpadding="0"' +
  '  cellspacing="0"' +
  '  width="100%"' +
  '  border="0"' +
  '>' +
  '  <tbody data-gjs-draggable="false">' +
  '    <tr data-gjs-draggable="false">' +
  '      <td data-gjs-draggable="false"' +
  '        style="' +
  '          overflow-wrap: break-word;' +
  '          word-break: break-word;' +
  '          padding: 10px 10px 25px;' +
  "          font-family: 'Montserrat', sans-serif;" +
  '        "' +
  '        align="left"' +
  '      >' +
  '        <!--[if mso' +
  '          ]><style>' +
  '            .v-button {' +
  '              background: transparent !important;' +
  '            }' +
  '          </style><!' +
  '        [endif]-->' +
  '        <div data-gjs-draggable="false" align="center">' +
  '          <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="{{resourceUrl}}" style="height:43px; v-text-anchor:middle; width:129px;" arcsize="9.5%"  stroke="f" fillcolor="#44a96d"><w:anchorlock/><center style="color:#FFFFFF;font-family:\'Montserrat\',sans-serif;"><![endif]-->' +
  '          <a data-gjs-draggable="false"' +
  '            href="{{resourceUrl}}"' +
  '            target="_blank"' +
  '            class="v-button"' +
  '            style="' +
  '              box-sizing: border-box;' +
  '              display: inline-block;' +
  "              font-family: 'Montserrat', sans-serif;" +
  '              text-decoration: none;' +
  '              -webkit-text-size-adjust: none;' +
  '              text-align: center;' +
  '              color: #ffffff;' +
  '              background-color: #44a96d;' +
  '              border-radius: 4px;' +
  '              -webkit-border-radius: 4px;' +
  '              -moz-border-radius: 4px;' +
  '              width: auto;' +
  '              max-width: 100%;' +
  '              overflow-wrap: break-word;' +
  '              word-break: break-word;' +
  '              word-wrap: break-word;' +
  '              mso-border-alt: none;' +
  '            "' +
  '          >' +
  '            <span data-gjs-draggable="false"' +
  '              style="display: block; padding: 10px 20px 11px; line-height: 120%"' +
  '              ><span data-gjs-draggable="false"' +
  '                style="' +
  '                  font-size: 18px;' +
  '                  line-height: 21.6px;' +
  '                  font-family: verdana, geneva;' +
  '                "' +
  '                ><strong data-gjs-draggable="false"' +
  '                  ><span data-gjs-draggable="false" style="line-height: 21.6px; font-size: 18px"' +
  '                    >Materiali</span' +
  '                  ></strong' +
  '                ></span' +
  '              ></span' +
  '            >' +
  '          </a>' +
  '          <!--[if mso]></center></v:roundrect><![endif]-->' +
  '        </div>' +
  '      </td>' +
  '    </tr>' +
  '  </tbody>' +
  '</table>' +
  '</div>' +
  ''
