import { Route } from 'react-router-dom';
import AuthenticatedComponent from './AuthenticatedComponent/AuthenticatedComponent';

const ProtectedRoute = ({ component, path, ...args }) => {
  const authenticatedComponent = (
    <AuthenticatedComponent component={component} />
  );
  return <Route {...args}>{authenticatedComponent}</Route>;
};

export default ProtectedRoute;
