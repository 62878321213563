import {
  ProtectedRoute,
  SettingsIconLink,
  AccountPopover,
  OrganizationSelector,
} from 'design-system'
import packageJsonVersion from '../../../package.json'
import { ComponentType } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loader from 'Component/Loader'
import useNavConfig from 'common/hooks/useNavConfig'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import company_logo from 'assets/icon/company_logo.png'
import logo from 'assets/icon/wyblo_Pittogramma_color.png'
import { useAppSelector } from 'Store'
import {
  selectCurrentOrganization,
  selectEmail,
  selectFirstName,
  selectLastName,
  selectOrganizations,
} from 'Reducers/UserConfigReducer'

type Props = {
  component: ComponentType
}

const AuthenticatedComponent = ({ component }: Props) => {
  const currentOrganization = useAppSelector(selectCurrentOrganization)
  const organizations = useAppSelector(selectOrganizations)
  const first_name = useAppSelector(selectFirstName)
  const email = useAppSelector(selectEmail)
  const last_name = useAppSelector(selectLastName)

  const navConfig = useNavConfig()
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()

  const auth = () => {
    return withAuthenticationRequired(component, {
      onRedirecting: () => <Loader isVisible={true} />,
    })
  }

  return (
    <ProtectedRoute
      logo={logo}
      packageJsonVersion={packageJsonVersion.version}
      withAuthenticationRequired={auth}
      userMail={email || ''}
      userName={first_name || ''}
      protectedRouteText={{
        versionText: t('version'),
      }}
      navPermission={{
        showDashboard: true,
        showNewsletter: true,
        showPaymentWarning: false,
      }}
      userPermission={currentOrganization?.userPermissions || []}
      headerComponents={[
        {
          component: <SettingsIconLink path="/Settings" />,
        },
        {
          component: (
            <AccountPopover
              logoutText={t('common.logout')}
              options={[{ label: 'Account', linkTo: '/Settings/account' }]}
              userMail={email || ''}
              userName={last_name || ''}
              logout={() => logout({ returnTo: window.location.origin })}
            ></AccountPopover>
          ),
        },
      ]}
      organizationSelector={
        <OrganizationSelector
          company_logo={company_logo}
          currentOrganization={currentOrganization}
          organizationSelectorText={{
            myOrganization: t('organization-selector.my-organizations'),
            placeholder: t('organization-selector.search-input-placeholder'),
          }}
          organizations={organizations}
          loginWithRedirect={loginWithRedirect}
        />
      }
      logout={() => logout({ returnTo: window.location.origin })}
      component={component}
      navConfig={navConfig}
    />
  )
}

export default AuthenticatedComponent
