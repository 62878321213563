import { Box, Divider, Grid, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import info from 'assets/icon/info.svg'

export function StripeDocs() {
  const { t } = useTranslation()

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 1,
          mb: 1,
          mr: 1,
          maxWidth: 1100,
          padding: 0,
        }}
      >
        <Grid item xs={6} sx={{ padding: 0 }}>
          <Box
            height="100%"
            sx={{
              padding: 2,
              backgroundColor: '#ffffff',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">
              {t('payments.docs.language-settings.title')}
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 1, mb: 1, fontFamily: 'nexalight' }}
            >
              <Typography
                variant="body1"
                sx={{ fontFamily: 'nexalight', fontSize: 15 }}
              >
                {t('payments.docs.language-settings.text1')}

                <Link
                  href={'https://dashboard.stripe.com/settings/user'}
                  rel="noreferrer"
                  target="_blank"
                  sx={{ fontWeight: 'bold' }}
                >
                  {t('payments.docs.language-settings.text2')}
                </Link>
                {'.'}
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ padding: 0 }}>
          <Box
            height="100%"
            sx={{
              padding: 2,
              backgroundColor: '#ffffff',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">
              {t('payments.docs.email-settings.title')}
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 1, mb: 1, fontFamily: 'nexalight' }}
            >
              <Typography
                variant="body1"
                sx={{ fontFamily: 'nexalight', fontSize: 15 }}
              >
                {t('payments.docs.email-settings.text1')}

                <Link
                  href={'https://dashboard.stripe.com/settings/emails'}
                  rel="noreferrer"
                  target="_blank"
                  sx={{ fontWeight: 'bold' }}
                >
                  {t('payments.docs.email-settings.text2')}
                </Link>
                {'.'}
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Divider
        variant="middle"
        sx={{
          mt: 5,
          mb: 5,
          maxWidth: 1050,
          backgroundColor: '#a2a4a7',
        }}
      />

      {/* About Stripe */}
      <Box sx={{ mr: 1, color: '#0d6efd' }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            margin: 2,
          }}
        >
          <InfoOutlinedIcon width="25"></InfoOutlinedIcon>
          <Typography variant="h5" sx={{ ml: 1, mt: 8 }}>
            {t('payments.docs.title')}
          </Typography>
        </Stack>
      </Box>

      <Box
        sx={{
          py: 1,
          px: 2,
          mt: 2,
          maxWidth: 1085,
          backgroundColor: '#ffffff',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          borderRadius: 2,
        }}
      >
        <Typography
          variant="body1"
          sx={{ mt: 1, fontFamily: 'nexalight', fontSize: 15 }}
        >
          {t('payments.docs.intro.text1')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1, fontFamily: 'nexalight', fontSize: 15 }}
        >
          {t('payments.docs.intro.text2')}
        </Typography>
        <Stack direction="row" sx={{ p: 1, mt: 3, backgroundColor: '#f3f3f3' }}>
          <TipsAndUpdatesIcon sx={{ mr: 1 }}></TipsAndUpdatesIcon>{' '}
          <Typography
            variant="body1"
            sx={{ fontFamily: 'nexalight', fontSize: 15 }}
          >
            {t('payments.docs.intro.tip-localization')}
          </Typography>
        </Stack>
        <br />
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          mt: 1,
          mb: 1,
          mr: 1,
          maxWidth: 1100,
          padding: 0,
        }}
      >
        <Grid item xs={6} sx={{ padding: 0 }}>
          <Box
            height="100%"
            sx={{
              backgroundColor: '#ffffff',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: 2,
              padding: 2,
            }}
          >
            <Typography variant="h6">
              {t('payments.docs.payouts.title')}
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 1, fontFamily: 'nexalight', fontSize: 14 }}
            >
              {t('payments.docs.payouts.text1')}
            </Typography>
            <br />
            <Typography
              variant="body1"
              sx={{ mb: 1, fontFamily: 'nexalight', fontSize: 14 }}
            >
              {t('payments.docs.payouts.text2')}
            </Typography>
            <ul style={{ margin: 0 }}>
              <li>
                <Typography variant="body1" sx={{ fontSize: 14 }}>
                  <Link
                    href={'https://stripe.com/docs/payouts'}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('payments.docs.payouts.payouts-link')}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ fontSize: 14 }}>
                  <Link
                    href={'https://stripe.com/docs/payouts#payout-schedule'}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('payments.docs.payouts.payout-schedule-link')}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ fontSize: 14 }}>
                  <Link
                    href={'https://stripe.com/docs/payouts#payout-speed'}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('payments.docs.payouts.payout-speed-link')}
                  </Link>
                </Typography>
              </li>
            </ul>
            <br />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ padding: 0 }}>
          <Box
            height="100%"
            sx={{
              backgroundColor: '#ffffff',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: 2,
              padding: 2,
            }}
          >
            <Typography variant="h6">
              {t('payments.docs.processing-fees.title')}
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 1, fontFamily: 'nexalight', fontSize: 14 }}
            >
              {t('payments.docs.processing-fees.text1')}
            </Typography>
            <br />
            <Typography
              variant="body1"
              sx={{ mb: 1, fontFamily: 'nexalight', fontSize: 14 }}
            >
              {t('payments.docs.processing-fees.text2')}
            </Typography>
            <ul style={{ margin: 0 }}>
              <li>
                <Typography variant="body1" sx={{ fontSize: 14 }}>
                  <Link
                    href={'https://stripe.com/en-it/pricing'}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('payments.docs.processing-fees.eu-link')}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ fontSize: 14 }}>
                  <Link
                    href={'https://stripe.com/us/pricing'}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('payments.docs.processing-fees.us-link')}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ fontSize: 14 }}>
                  <Link
                    href={'https://stripe.com/gb/pricing'}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('payments.docs.processing-fees.uk-link')}
                  </Link>
                </Typography>
              </li>
            </ul>
            <br />

            <Typography
              variant="body1"
              sx={{ mb: 1, fontFamily: 'nexalight', fontSize: 14 }}
            >
              {t('payments.docs.processing-fees.text3')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
