import { FormControl, FormHelperText, SelectChangeEvent } from '@mui/material'
import i18next from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SelectTimeProp {
  isEnabled?: boolean
  startDate?: string
  timeSelected?: string
  serverTime?: string
  onChangeState: (value: string) => void
}

/**
 *  - Shows validation errors only when out of focus.
 *  - Shows validation errors on the right side
 *  - Slightly different styling
 */
function AttendanceSelectTime({
  isEnabled,
  timeSelected,
  serverTime,
  onChangeState,
}: SelectTimeProp) {
  const [times, setTimes] = useState<moment.Moment[]>([])
  const [error, setError] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const { t } = useTranslation()

  // Creating an array of times from 00:00 to 23:45
  function getTimeSuggestions(
    startTime: moment.Moment,
    endTime: moment.Moment
  ) {
    let timeSuggestions = []

    while (startTime < endTime) {
      timeSuggestions.push(moment(startTime))
      startTime.add('m', 15)
    }

    return timeSuggestions
  }

  const inputValidation = (value: string) => {
    if (i18next.language === 'it') {
      if (value.length === 5) {
        const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        setError(!regex.test(value))
        return regex.test(value)
      }
      const regex = /[0-9]|(:)|(^$)/
      setError(regex.test(value))
      return regex.test(value)
    } else {
      if (value.length === 8) {
        const regex = /((1[0-2]|0[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
        setError(!regex.test(value))
        return regex.test(value)
      }
      const regex = /[0-9]|(A)|(a)|(P)|(p)|(M)|(m)|(^$)/
      setError(regex.test(value))
      return regex.test(value)
    }
  }

  const onChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    if (inputValidation(value)) {
      setError(false)
      onChangeState(value.toUpperCase())
    } else setError(true)
  }

  const onBlur = (e: any) => {
    setIsFocused(false)
    const value = e.target.value
    const regex =
      i18next.language === 'it'
        ? /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        : /((1[0-2]|0[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/

    setError(!regex.test(value))
  }

  useEffect(() => {
    setTimes(getTimeSuggestions(moment().startOf('day'), moment().endOf('day')))
  }, [])

  useEffect(() => {
    setError(false)
  }, [serverTime])

  return (
    <div>
      <FormControl
        sx={{ maxHeight: '100px', position: 'relative' }}
        size="small"
        error={error}
      >
        {/* input text with select */}
        <input
          className={error ? 'selectTime--error' : 'selectTime'}
          style={{
            backgroundColor: 'rgba(21, 122, 255, 0.1)',
            border: '1px solid #adb8c8',
            borderRadius: '4px',
          }}
          list="select"
          name={'select-time'}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={() => {
            setIsFocused(true)
          }}
          // The format of "value" field must be "HH:mm"
          value={!isEnabled ? '-' : timeSelected}
          disabled={!isEnabled}
          autoComplete="off"
          maxLength={i18next.language === 'it' ? 5 : 8}
          required
        />
        <datalist id="select">
          {times.map((t) => (
            <option
              key={t.format('LT')}
              value={t.format(i18next.language === 'it' ? 'HH:mm' : 'hh:mm A')}
            >
              {moment(t).format(
                i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
              )}
            </option>
          ))}
        </datalist>
        <FormHelperText
          sx={{
            position: 'absolute',
            transform: 'translateX(100%)',
            bottom: '-0.5rem',
            width: '100%',
          }}
        >
          {error && !isFocused && isEnabled
            ? t('courses.error-select-time')
            : ''}
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default AttendanceSelectTime
