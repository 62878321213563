import MailIcon from '@mui/icons-material/MailOutline'
import SubscribersIcon from '@mui/icons-material/Group'
import TagIcon from '@mui/icons-material/LocalOfferOutlined'
import TemplateIcon from '@mui/icons-material/ColorLens'
import TransactionalMailIcon from '@mui/icons-material/Star'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export function MailSection() {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5">{t('common.mail')}</Typography>

        <Stack spacing={3} sx={{ mt: 4, mb: 3, maxWidth: 500 }}>
          <Button
            variant="outlined"
            startIcon={<TransactionalMailIcon />}
            onClick={() => {
              history.push('Mail/Transactional')
            }}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t('mail.transactional')}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            startIcon={<MailIcon />}
            onClick={() => {
              history.push('Mail/Campaigns')
            }}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t('mail.campaigns')}
            </Typography>
          </Button>

          <Button
            variant="outlined"
            startIcon={<TemplateIcon />}
            onClick={() => {
              history.push('Mail/Templates')
            }}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t('mail.templates')}
            </Typography>
          </Button>
          <Button
            hidden={process.env.REACT_APP_ENVIRONMENT !== 'development'}
            variant="outlined"
            startIcon={<SubscribersIcon />}
            onClick={() => {
              history.push('Mail/Subscribers')
            }}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t('mail.subscribers')}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            startIcon={<TagIcon />}
            onClick={() => {
              history.push('Mail/Tags')
            }}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t('mail.tags')}
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
