// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField, TextFieldProps } from '@mui/material'

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string
}

export default function RHFTextField({ name, helperText, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          autoComplete="off"
          sx={{
            '&.MuiFormControl-root .MuiInputBase-root': {
              lineHeight: '1.57143em',
              fontSize: '0.875rem',
              fontFamily: 'sans-serif',
              fontWeight: '400',
              borderRadius: '0.5rem',
              color: 'rgb(33, 43, 54)',
              backgroundColor: 'white',
            },
            '&.MuiFormControl-root .MuiInputBase-root fieldset': {
              borderColor: 'rgba(145, 158, 171, 0.2)',
            },
            '&.MuiFormControl-root .MuiInputBase-root.Mui-focused fieldset': {
              borderColor: 'rgb(33, 43, 54)',
            },
            '&.MuiFormControl-root .MuiFormLabel-root': {
              lineHeight: '1.57143',
              fontSize: '0.9rem',
              fontFamily: 'sans-serif',
              fontWeight: '400',
              borderRadius: '0.5rem',
              color: 'rgb(145, 158, 171)',
            },
            '&.MuiFormControl-root .MuiInputLabel-shrink': {
              lineHeight: '1.5',
              fontSize: '0.875rem',
              fontWeight: '500',
            },
            '&.MuiFormControl-root .MuiInputLabel-shrink.Mui-focused': {
              color: 'rgb(33, 43, 54)',
            },
          }}
        />
      )}
    />
  )
}
