//icons from icons-material
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DesignIcon from '@mui/icons-material/DesignServices'
import PaymentsIcon from '@mui/icons-material/CreditCard'
import MailIcon from '@mui/icons-material/MailOutline'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import PeopleIcon from '@mui/icons-material/People'
import PollIcon from '@mui/icons-material/Poll'
import GroupsIcon from '@mui/icons-material/Groups'
import FeedbackIcon from '@mui/icons-material/Feedback'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
// import StarIcon from '@mui/icons-material/Star'
import StarIcon from '@mui/icons-material/Stars'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { NavListProps } from 'Component/NavVertical/NavSectionVertical'
import { useTranslation } from 'react-i18next'
import { yellow } from '@mui/material/colors'

const useNavConfig = (): NavListProps[] => {
  const { t } = useTranslation()

  const navConfig: NavListProps[] = [
    {
      id: 'dashboard',
      title: t('sidebar.dashboard'),
      path: '/Dashboard',
      icon: <DashboardIcon className="navIconMui" />,
      permissions: ['read:dashboard'],
    },
    {
      id: 'calendar',
      title: t('sidebar.calendar'),
      path: '/Calendar',
      icon: <CalendarMonthIcon className="navIconMui" />,
      permissions: [
        'read:courses',
        'read:invited-session',
        'list:participant_courses',
      ],
    },
    {
      id: 'courses',
      title: t('sidebar.courses'),
      path: '/Courses',
      icon: <ImportContactsIcon className="navIconMui" />,
      permissions: [
        'read:courses',
        'read:invited-session',
        'list:participant_courses',
      ],
    },
    {
      id: 'funded_training',
      title: t('common.funded-training'),
      path: '/funded-training',
      icon: <StarIcon sx={{ color: yellow[800] }} />,
      permissions: ['edit:courses'],
    },
    {
      id: 'design',
      title: t('sidebar.design'),
      path: '/Design',
      icon: <DesignIcon className="navIconMui" />,
      permissions: ['edit:courses'],
    },
    {
      id: 'crm',
      title: t('sidebar.crm'),
      path: '/CRM',
      icon: <PeopleIcon className="navIconMui" />,
      permissions: ['read:participants'],
    },
    {
      id: 'survey',
      title: t('sidebar.survey'),
      path: '/Survey',
      icon: <PollIcon className="navIconMui" />,
      permissions: ['manage:survey'],
    },
    {
      id: 'payments',
      title: t('sidebar.payments'),
      path: '/Payments',
      icon: <PaymentsIcon className="navIconMui" />,
      permissions: ['manage:payments', 'view:payments-section'],
    },
    {
      id: 'mail',
      title: t('sidebar.mail'),
      path: '/Mail',
      icon: <MailIcon className="navIconMui" />,
      permissions: ['view:newsletter'],
    },
  ]

  return navConfig
}

export default useNavConfig
