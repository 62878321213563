import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface ActivityState {
  shouldPreselectParticipantsTab: boolean
}

const initialState: ActivityState = {
  shouldPreselectParticipantsTab: false,
}

export const ActivitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setShouldPreselectParticipantsTab: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shouldPreselectParticipantsTab = action.payload
    },
    participantAdded: (state) => {
      state.shouldPreselectParticipantsTab = true
    },
    participantEdited: (state) => {
      state.shouldPreselectParticipantsTab = true
    },
  },
})

// SELECTORS

export const selectShouldPreselectParticipantsTab = (state: RootState) =>
  state.Activity.shouldPreselectParticipantsTab

export const {
  setShouldPreselectParticipantsTab,
  participantAdded,
  participantEdited,
} = ActivitySlice.actions

export default ActivitySlice.reducer
