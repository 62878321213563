// @mui
import { Table, TableBody, TableContainer, Box } from '@mui/material'
// components
import { TableProps } from 'Component/Table/type'
import TableNoData from 'Component/Table/TableNoData'
import TableEmptyRows from 'Component/Table/TableEmptyRows'
import TableHeadCustom from 'Component/Table/TableHeadCustom'
import TableSelectedAction from 'Component/Table/TableSelectedAction'
import TablePaginationCustom from 'Component/Table/TablePaginationCustom'
import FileTableRow from 'Component/FileTableRow/FileTableRow'
//utils
import { emptyRows } from 'utils/utils'
import { ResourceClient } from 'utils/type-conversions/resource'
import { Meeting, MeetingDateTime } from 'Component/CourseRegistration/Models'
import SessionDatesListRow from 'Component/SessionDatesListRow/SessionDatesListRow'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

type Props = {
  table: TableProps
  tableData: MeetingDateTime[]
  isNotFound: boolean
  dataFiltered: MeetingDateTime[]
  onDeleteRow: (id: string) => void
  setTableData: (value: SetStateAction<MeetingDateTime[]>) => void
}

export default function SessionDatesListTable({
  table,
  tableData,
  isNotFound,
  dataFiltered,
  onDeleteRow,
  setTableData,
}: Props) {
  const {
    dense,
    page,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = table
  const { t } = useTranslation()

  const denseHeight = dense ? 52 : 72

  const TABLE_HEAD = [
    { id: 'name', label: t('common.name'), align: 'left' },
    {
      id: 'start_date',
      label: t('common.start-date'),
      align: 'left',
      width: 120,
    },
    { id: 'end_date', label: t('common.end-date'), align: 'left', width: 120 },
  ]

  return (
    <>
      <Box
        sx={{
          px: 1,
          position: 'relative',
          borderRadius: 1.5,
          bgcolor: 'background.neutral',
        }}
      >
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={tableData.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData.map((row) => row.sessionname)
            )
          }
          sx={{
            pl: 1,
            pr: 2,
            top: 8,
            left: 8,
            right: 8,
            width: 'auto',
            borderRadius: 1,
          }}
        />

        <TableContainer>
          <Table
            size={dense ? 'small' : 'medium'}
            sx={{
              minWidth: 960,
              borderCollapse: 'separate',
              borderSpacing: '0 8px',
              '& .MuiTableCell-head': {
                boxShadow: 'none !important',
              },
            }}
          >
            <TableHeadCustom
              headLabel={TABLE_HEAD}
              sx={{
                '& .MuiTableCell-head': {
                  bgcolor: 'transparent',
                },
              }}
            />

            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <SessionDatesListRow
                    key={row.sessionname}
                    row={row}
                    index={index}
                    selected={selected.includes(row.sessionname)}
                    setTableData={setTableData}
                    onSelectRow={() => onSelectRow(row.sessionname)}
                    onDeleteRow={() => onDeleteRow(row.sessionname)}
                  />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
              />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TablePaginationCustom
        count={dataFiltered.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
        sx={{
          '& .MuiTablePagination-root': { borderTop: 'none' },
          '& .MuiFormControlLabel-root': { px: 0 },
        }}
      />
    </>
  )
}
