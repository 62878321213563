import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useTranslation } from 'react-i18next'

interface DropzoneProp {
  image?: File[]
  course: any
  setImage: (file: File[] | undefined) => void
}

const Dropzone = (prop: DropzoneProp) => {
  const { image, course, setImage } = prop

  const [courseCopy, setCourseCopy] = useState<any>({
    banner: {
      filename: '',
      bytesize: '',
    },
  })

  const { t } = useTranslation()

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: false,
    disabled: !!image?.length || !!courseCopy.banner.filename,
  })

  const acceptedFileItems = () => {
    if (image) {
      const items = image.map((file) => (
        <span key={file.name}>
          {file.name} - {(file.size / 1048576).toFixed(2)} MB
        </span>
      ))
      return items
    }
  }

  useEffect(() => {
    setImage(acceptedFiles)
  }, [acceptedFiles])

  useEffect(() => {
    setCourseCopy(course)
  }, [])

  return (
    <section>
      <div
        {...getRootProps(
          !!image?.length || !!courseCopy.banner.filename
            ? { className: 'dropzone disabled' }
            : { className: 'dropzone' }
        )}
      >
        <input {...getInputProps()} />
        <p>{t('dropzone.drag-or-click')}</p>
        <em>{t('dropzone.format')}</em>
      </div>
      {((image && image?.length > 0) ||
        (courseCopy.banner.filename && courseCopy.banner.bytesize)) && (
        <aside>
          <label className="mt-3 form-label">
            {t('dropzone.image-uploaded')}
          </label>
          <div className="d-flex align-items-end form-control border-0 m-0">
            <div className="d-flex align-items-center m-0">
              {courseCopy.banner.filename && courseCopy.banner.bytesize ? (
                <span key={courseCopy.banner.filename}>
                  {courseCopy.banner.filename} -{' '}
                  {(courseCopy.banner.bytesize / 1048576).toFixed(2)} MB
                </span>
              ) : (
                acceptedFileItems()
              )}
            </div>
            <button
              className="border-0 m-0 ms-5 bg-body text-danger"
              onClick={() => {
                setImage([])
                setCourseCopy({ banner: { filename: '', bytesize: '' } })
              }}
            >
              <DeleteOutlinedIcon />
            </button>
          </div>
        </aside>
      )}
    </section>
  )
}

export default Dropzone
