import { urlRegExp, vatNumberRegExp } from 'common/RegularExpressions'
import { TFunction } from 'i18next'
import { CustomFile } from 'utils/utils'
import { string, object, SchemaOf } from 'yup'

export type CompanyProfileData = {
  logo: CustomFile
  name: string
  description: string | null
  email: string
  address: string | null
  vatNumber: string | null
  supportEmail: string | null
}

export type CompanyProfileSocialData = {
  facebook: string | null
  twitter: string | null
  linkedin: string | null
}

export const CompanyProfileValidationSchema = (t: TFunction) => {
  return object().shape({
    name: string()
      .required(t('forms.validation.required-field'))
      .label(t('settings.FirstName')),
    description: string()
      .nullable()
      .default(null)
      .label(t('settings.Description')),
    email: string()
      .email(t('forms.validation.email.not-valid'))
      .required(t('forms.validation.required-field'))
      .label(t('settings.Emailid')),
    supportEmail: string()
      .email(t('forms.validation.email.not-valid'))
      .required(t('forms.validation.required-field'))
      .label(t('settings.Emailid')),
    address: string().nullable().default(null).label(t('settings.Address')),
    vatNumber: string()
      .nullable()
      .default(null)
      .test(
        'valid-vat-number',
        t('forms.validation.vat-number.not-valid'),
        (field) => !field || vatNumberRegExp.test(field)
      )
      .label(t('settings.vat-number')),
  })
}

export const CompanyProfileSocialValidationSchema = (t: TFunction) => {
  const schema: SchemaOf<CompanyProfileSocialData> = object().shape({
    facebook: string()
      .nullable()
      .default(null)
      .test(
        'valid-url',
        t('forms.validation.url.not-valid'),
        (field) => !field || urlRegExp.test(field)
      )
      .label(t('settings.Facebook')),
    twitter: string()
      .nullable()
      .default(null)
      .test(
        'valid-url',
        t('forms.validation.url.not-valid'),
        (field) => !field || urlRegExp.test(field)
      )
      .label(t('settings.Twitter')),
    linkedin: string()
      .nullable()
      .default(null)
      .test(
        'valid-url',
        t('forms.validation.url.not-valid'),
        (field) => !field || urlRegExp.test(field)
      )
      .label(t('settings.Linkedin')),
  })

  return schema
}
