import { Box, Button, SxProps, Theme } from '@mui/material'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { CustomFile, ServerFileCustom, fileData } from 'utils/utils'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useTranslation } from 'react-i18next'

export interface UploadProps extends DropzoneOptions {
  error?: boolean
  sx?: SxProps<Theme>
  thumbnail?: boolean
  placeholder?: React.ReactNode
  helperText?: React.ReactNode
  disableMultiple?: boolean
  //
  file?: CustomFile | ServerFileCustom | string | null
  onDelete?: VoidFunction
  //
  files: (CustomFile | ServerFileCustom | string)[]
  onUpload?: VoidFunction
  onRemove?: (file: CustomFile | ServerFileCustom | string) => void
  onRemoveAll?: VoidFunction
}

export default function DropzoneMulti({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      multiple,
      disabled,
      ...other,
    })
  const { t } = useTranslation()

  const hasFiles = files && multiple && files.length > 0

  const isError = isDragReject || !!error

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
        }}
      >
        <input {...getInputProps()} />
        <p>{t('dropzone.drag-and-drop')}</p>
      </Box>

      {helperText && helperText}

      <>
        <Box sx={{ my: 3 }}>
          {files.map((file, i) => {
            const { id, name, size } = fileData(file)
            return (
              name &&
              size && (
                <ul>
                  <li key={id}>
                    <Box sx={{ display: 'flex' }}>
                      {name} - {(size / 1048576).toFixed(2)} MB
                      <Button
                        className="border-0 m-0 ms-5 bg-body text-danger"
                        onClick={() => {
                          onRemove?.(file)
                        }}
                        sx={{ alignItems: 'baseline', paddingTop: 0 }}
                      >
                        <DeleteOutlinedIcon />
                      </Button>
                    </Box>
                  </li>
                </ul>
              )
            )
          })}
          {/* <MultiFilePreview files={files} thumbnail={thumbnail} onRemove={onRemove} /> */}
        </Box>

        {/* <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                      {onRemoveAll && (
                          <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>
                              Remove all
                          </Button>
                      )}

                      {onUpload && (
                          <Button size="small" variant="contained" onClick={onUpload}>
                              Upload files
                          </Button>
                      )}
                  </Stack> */}
      </>
    </Box>
  )
}
