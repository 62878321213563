import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'
import FormTextField from 'Component/form-fields/FormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Activity } from 'Component/FundedTraining/types'
import {
  ActivityFormData,
  ActivityFormValidation,
} from 'common/form-validation/ActivityFormValidation'

type Props = {
  projectUuid: string
  currentActivity?: Activity
  isNew?: boolean
  onActivityUpdated?(activity: Activity): void
}

export function ActivityForm(props: Props) {
  const {
    projectUuid,
    currentActivity = null,
    isNew = false,
    onActivityUpdated,
  } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formMethods = useForm<ActivityFormData>({
    resolver: yupResolver(ActivityFormValidation(t)),
  })

  const updateActivity = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api
        .put(
          `funded_training/projects/${projectUuid}/activities/${currentActivity?.uuid}`,
          data
        )
        .then(
          (response) => {
            toast.success(t('funded-training.activity-updated'), {
              theme: 'colored',
            })
            if (onActivityUpdated) {
              onActivityUpdated(response.data.activity)
            }

            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createActivity = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.post(`funded_training/projects/${projectUuid}/activities`, data).then(
        (response) => {
          toast.success(t('funded-training.activity-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.goBack()
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: ActivityFormData) => {
    const params: Record<string, any> = {}

    params['title'] = data.title
    if (data.description) {
      params['description'] = data.description
    }

    if (data.funder_activity_id) {
      params['funder_activity_id'] = data.funder_activity_id
    }

    if (isNew) {
      createActivity(params)
    } else {
      updateActivity(params)
    }
  }

  useEffect(() => {
    if (currentActivity) {
      formMethods.setValue('title', currentActivity.title)
      formMethods.setValue('description', currentActivity.description)
      formMethods.setValue(
        'funder_activity_id',
        currentActivity.funder_activity_id
      )
    }
  }, [formMethods, currentActivity])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          <Stack
            mt={4}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Stack>
              <FormTextField
                name="title"
                type="text"
                label={t('common.title')}
                mandatory={true}
              />
              <FormTextField
                name="description"
                type="text"
                label={t('common.description')}
              />

              <FormTextField
                name="funder_activity_id"
                type="text"
                label={t('funded-training.activity-id')}
                tooltipDescription={t(
                  'funded-training.activity-id-description'
                )}
              />
            </Stack>
            <StyledButton
              type="submit"
              startIcon={<SaveIcon />}
              hidden={isLoading}
              sx={{
                backgroundColor: '#0d6efd !important',
              }}
            >
              <Typography sx={{ mt: 0.5 }}>
                {isNew ? t('common.create') : t('common.update')}
              </Typography>
            </StyledButton>
            <Loader isVisible={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}
