import wyblo from 'assets/icon/wyblo.svg'
import { useTranslation } from 'react-i18next'
import { localizedMoment } from 'common/DateTimeUtils'
import { useAppSelector } from 'Store'
import { selectTimezone } from 'Reducers/CourseRegistrationReducer'

const Footer = (props: any) => {
  const { t } = useTranslation()
  const timezone = useAppSelector(selectTimezone)

  const year: number = localizedMoment(undefined, timezone).year()
  return (
    <>
      <div id="footer__slogan">
        <span>{t('common.slogan')}</span>
        <img src={wyblo} alt="Session time icon" />
      </div>
      <div id="footer__all-rights-reserved">
        <p>{t('common.all-rights-reserved', { year: year })}</p>
      </div>
    </>
  )
}

export default Footer
