import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: 'nexa',
    body1: {
      fontSize: 18,
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
  },
  //Disable elevation
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          padding: '16px 16px 0px 16px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#b9b9b9',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          backgroundColor: '#0d6efd',
          color: 'white',
          padding: '7px 37px',
          borderRadius: '4px',
          ':disabled': {
            backgroundColor: '#e5e5e5',
            color: 'grey',
          },
        },

        text: {
          color: '#0d6efd',
        },
      },
    },
  },
})

export default theme
