import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SurveysService from 'Service/SurveyService'
import { useLocation, useParams } from 'react-router-dom'
import { Survey } from 'Component/CourseRegistration/Models'
import useApi from 'api/UseApi'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { templateCreated } from 'Actions/Survey'
import { useDispatch } from 'react-redux'
import { JSONValue } from 'common/types/Json'
import SurveyForm from 'Component/SurveyForm/SurveyForm'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'

const SurveyDetail = () => {
  const { t } = useTranslation()
  const { surveyId } = useParams<{ surveyId: string }>()
  const { pathname } = useLocation()
  const apiNoAuth = useApi(false, true)
  const api = useApi(false)
  const dispatch = useDispatch()
  const organization = useAppSelector(selectCurrentOrganization)
  const history = useHistory()

  const emptySurvey: Survey = {
    name: t('survey.default-survey-title'),
    description: '',
    redirect_url: '',
    is_markdown_enabled: false,
    body: null,
  }

  const [survey, setSurvey] = useState<Survey>(emptySurvey)
  const [json, setJson] = useState<null | JSONValue>(null)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [isLoaderVisible, setIsLoaderVisible] = useState(true)

  const { name, description, is_markdown_enabled, redirect_url } = survey

  const { getSurveyByID, getTemplateByID } = SurveysService()

  const getSurvey = async () => {
    console.log({ surveyId })
    try {
      setIsLoaderVisible(true)
      if (pathname.includes('Survey')) {
        const survey: Survey = await getSurveyByID(surveyId)
        if (survey) {
          setSurvey(survey)
          setJson(survey.body)
        }
      } else {
        const template = await getTemplateByID(surveyId)
        if (template) setSurvey(template)
      }
      setIsLoaderVisible(false)
    } catch (error: any) {
      console.log(error)
      setIsLoaderVisible(false)
    }
  }

  const updateSurvey = () => {
    setIsLoaderVisible(true)
    try {
      if (survey && survey._id) {
        apiNoAuth
          .put(`/surveys/${survey._id}`, {
            name: name,
            description: description,
            redirectUrl: redirect_url,
            body: json,
            isMarkdownEnabled: is_markdown_enabled,
          })
          .then(
            (response) => {
              setIsLoaderVisible(false)
              toast.success(response.data.message, { theme: 'colored' })
              setSurvey({
                id: survey.id,
                name: name,
                description: description,
                body: json,
                is_markdown_enabled: is_markdown_enabled,
              })
              getSurvey()
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const createSurvey = () => {
    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/surveys`, {
          name: name,
          description: description,
          redirect_url: redirect_url,
          body: json,
          is_markdown_enabled: is_markdown_enabled,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)
            dispatch(templateCreated())
            history.goBack()
          },
          (error) => {
            setIsLoaderVisible(false)
            console.log(error)
          }
        )
    } catch (e) {
      console.log(`Error creating survey: ${e}`)
    }
  }

  useEffect(() => {
    if (pathname.includes('/create')) {
      setIsPreview(false)
    } else if (pathname.includes('/edit')) {
      setIsPreview(false)
    } else {
      setIsPreview(true)
    }
  }, [pathname])

  useEffect(() => {
    if (pathname.includes('/create')) {
      setSurvey(emptySurvey)
      setIsLoaderVisible(false)
    } else {
      getSurvey()
    }
  }, [pathname])

  return (
    <Box className="">
      <Box className="">
        <SurveyForm
          survey={survey}
          isPreview={isPreview}
          isLoaderVisible={isLoaderVisible}
          json={json}
          setSurvey={setSurvey}
          setJson={setJson}
          createSurvey={createSurvey}
          updateSurvey={updateSurvey}
        />
      </Box>
    </Box>
  )
}

export default SurveyDetail
