import success from 'assets/icon/success.gif'
import { useTranslation } from 'react-i18next'

const RegistrationSuccess = (props: any) => {
  const { t } = useTranslation()

  return (
    <div className="registration-modal-success">
      <img src={success} alt="Registration success icon" width="250" />
      <span>{t('course-registration.modal.success-message-1')}</span>
    </div>
  )
}

export default RegistrationSuccess
