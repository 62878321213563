import template from 'assets/document/template_course_participants.xls'
import downloadIcon from 'assets/icon/global/download_icon.png'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PlusOneIcon from '@mui/icons-material/PlusOne'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useDispatch } from 'react-redux'

import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

interface AddParticipantDialogProp {
  isInvitePopupVisible: boolean
  file: any
  isLoading: boolean
  closeInvite: () => void
  uploadFile: () => Promise<void>
  setFile: (value: string) => void
  setFileVal: (value: FileList | null) => void
  onAddSingle: () => void
}

const AddParticipantDialog = ({
  isInvitePopupVisible,
  file,
  isLoading,
  closeInvite,
  uploadFile,
  setFile,
  setFileVal,
  onAddSingle,
}: AddParticipantDialogProp) => {
  const { t } = useTranslation()

  return (
    <Dialog open={isInvitePopupVisible} onClose={closeInvite}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 0.5 }} variant="h6">
            {t('common.add-participants')}
          </Typography>
          <CloseIcon onClick={closeInvite} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <Stack sx={{ pl: 0, mt: 2 }} spacing={3}>
          <Button
            startIcon={<PersonAddIcon />}
            sx={{
              py: 1,
              width: '100%',
            }}
            variant="outlined"
            onClick={() => {
              onAddSingle()
            }}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t(
                'funded-training.activity-detail.participants.add-single-participant'
              )}
            </Typography>
          </Button>
        </Stack>

        <Stack sx={{ pl: 0, mt: 2 }} spacing={3}>
          <Button
            startIcon={<UploadFileIcon />}
            sx={{
              py: 1,
              width: '100%',
            }}
            variant="outlined"
            onClick={() => {}}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t('funded-training.activity-detail.participants.add-from-file')}
            </Typography>
          </Button>
        </Stack>

        <Stack sx={{ pl: 0, mt: 2 }} spacing={3}>
          <Button
            startIcon={<FileCopyIcon />}
            sx={{
              py: 1,
              width: '100%',
            }}
            variant="outlined"
            onClick={() => {}}
          >
            <Typography sx={{ mt: 0.5 }} variant="h6">
              {t(
                'funded-training.activity-detail.participants.copy-from-activity'
              )}
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
    // <Dialog
    //   open={isInvitePopupVisible}
    //   onClose={closeInvite}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   className="registrantsInvite"
    // >
    //   <DialogTitle id="alert-dialog-title">
    //     {t('common.add-participants')}
    //     <CloseIcon onClick={closeInvite} />
    //   </DialogTitle>
    //   <DialogContent>
    //     <div className="flexonmi_head">
    //       <h6 style={{ color: '#157AFF' }} className="mb-0">
    //         {t(
    //           'funded-training.activity-detail.participants.add-single-participant'
    //         )}
    //       </h6>
    //     </div>

    //     <div className="flexonmi_head mt-5">
    //       <h6 style={{ color: '#157AFF' }} className="mb-0">
    //         {t(
    //           'funded-training.activity-detail.participants.copy-from-activity'
    //         )}
    //       </h6>
    //     </div>

    //     <div className="flexonmi_head mt-5">
    //       <h6 style={{ color: '#157AFF' }} className="mb-0">
    //         {t('funded-training.activity-detail.participants.add-from-file')}
    //       </h6>
    //     </div>
    //     <DialogContentText id="alert-dialog-description">
    //       <form
    //         onSubmit={(event) => {
    //           event.preventDefault()
    //           uploadFile()
    //         }}
    //       >
    //         <div className="flexonaddtrinees mb-2">
    //           <div style={{ width: '65%', position: 'relative' }}>
    //             <input
    //               type="file"
    //               className="form-control hiddenUpload"
    //               required
    //               onChange={(e) => {
    //                 setFile(e.target.value)
    //                 setFileVal(e.target.files)
    //               }}
    //             />
    //             <input
    //               type="text"
    //               placeholder="-"
    //               className="form-control addCoursesInput"
    //               disabled
    //               value={file}
    //             />
    //           </div>
    //           <button
    //             className="btn"
    //             style={{
    //               fontFamily: 'nexa',
    //             }}
    //           >
    //             {t('common.upload')}
    //           </button>
    //         </div>
    //       </form>
    //       <small
    //         style={{
    //           fontFamily: 'nexa',
    //           color: '#b7b7b7',
    //           fontSize: '12px',
    //         }}
    //       >
    //         {t(
    //           'funded-training.activity-detail.participants.import-file-accepted-format'
    //         )}
    //       </small>
    //     </DialogContentText>

    //     <div className="flexsmall">
    //       <h6 className="me-2 mb-0" style={{ color: '#157AFF' }}>
    //         {t(
    //           'funded-training.activity-detail.participants.download-import-file'
    //         )}
    //       </h6>
    //       <button
    //         className="btn"
    //         style={{ background: 'rgba(115, 176, 255, 0.2)' }}
    //       >
    //         <a href={template}>
    //           <img src={downloadIcon} alt="uploadicon" className="w-75" />
    //         </a>
    //       </button>
    //     </div>
    //   </DialogContent>
    //   <DialogActions></DialogActions>
    // </Dialog>
  )
}

export default AddParticipantDialog
