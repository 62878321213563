import { Box, Typography, Tabs, Tab, Link } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { Project } from 'Component/FundedTraining/types'
import { ProjectForm } from './ProjectForm'

import { useAppSelector } from 'Store'
import { useDispatch } from 'react-redux'
import ProjectTabPanel from './ProjectTabPanel'
import { Activities } from './Activities/Activities'
import { ProjectOverview } from './ProjectOverview'
import {
  selectShouldPreselectActivitiesTab,
  setShouldPreselectActivitiesTab,
} from 'Reducers/FundedTraining/ProjectReducer'

type RouteParams = {
  projectUuid: string
}

type LocationState = {
  projectTitle: string
}

type Props = {
  isNewProject?: boolean
}

function a11yProps(index: number) {
  return {
    id: `project-tab-${index}`,
    'aria-controls': `project-tabpanel-${index}`,
  }
}

export function ProjectDetail(props: Props) {
  const { projectUuid } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { isNewProject = false } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [projectTitle, setProjectTitle] = useState<string>('')
  const [currentProject, setCurrentProject] = useState<null | Project>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  const shouldPreselectActivitiesTab = useAppSelector(
    selectShouldPreselectActivitiesTab
  )

  useEffect(() => {
    if (shouldPreselectActivitiesTab) {
      setTabIndex(2)
      dispatch(setShouldPreselectActivitiesTab(false))
    }
  }, [shouldPreselectActivitiesTab])

  useEffect(() => {
    if (currentProject) {
      if (projectTitle === '') {
        setProjectTitle(currentProject.title)
      }
    } else if (state) {
      setProjectTitle(state.projectTitle)
    }
  }, [state, currentProject])

  const getProject = useCallback(() => {
    setIsLoading(true)
    try {
      api.get(`funded_training/projects/${projectUuid}`).then(
        (response) => {
          setCurrentProject(response.data)
          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, projectUuid])

  useEffect(() => {
    getProject()
  }, [getProject, projectUuid])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
          <Trans i18nKey={'funded-training.project-detail.title'}>
            {'placeholder '}
            <strong style={{ color: '#0d6efd' }}>
              <>{{ projectTitle }}</>
            </strong>
            {' placeholder'}
          </Trans>
        </Typography>
        <NavLink to={`/funded-training/projects`} exact>
          <Link variant="body1">{t('funded-training.go-to-project-list')}</Link>
        </NavLink>

        {currentProject && (
          <>
            <Tabs
              sx={{ mt: 4 }}
              value={tabIndex}
              onChange={onTabChange}
              aria-label="project tabs"
            >
              <Tab label={t('common.overview')} {...a11yProps(1)} />
              <Tab label={t('common.config')} {...a11yProps(1)} />
              <Tab label={t('funded-training.activities')} {...a11yProps(0)} />
            </Tabs>

            <ProjectTabPanel value={tabIndex} index={0}>
              <ProjectOverview project={currentProject} />
            </ProjectTabPanel>

            <ProjectTabPanel value={tabIndex} index={1}>
              <ProjectForm
                currentProject={currentProject}
                isNewProject={isNewProject}
                onProjectUpdated={(project: Project) => {
                  setProjectTitle(project.title)
                }}
              />
            </ProjectTabPanel>
            <ProjectTabPanel value={tabIndex} index={2}>
              <Activities
                projectUuid={currentProject.uuid}
                activities={currentProject.activities}
                onItemDeleted={() => {
                  getProject()
                }}
              />
            </ProjectTabPanel>
          </>
        )}
      </Box>
    </Box>
  )
}
