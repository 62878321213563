import { useState, useEffect, useCallback, useMemo } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import RegistrationForm, {
  UserInfo,
  FormMethods as RegistrationFormMethods,
} from './RegistrationForm'
import { useTranslation } from 'react-i18next'
import RegistrationSuccess from './RegistrationSuccess'
import useApi from 'api/UseApi'
import { CircularProgress } from '@mui/material'
import { OrganizationInfoModel } from '../Models'
import { string, object, BaseSchema, bool, date } from 'yup'
import { Config } from 'Component/ConfigRegistration/ConfigRegistration'
import OrganizationsService from 'Service/OrganizationsService'
import { response } from 'express'

interface Props {
  isMarketing: boolean
  courseSlug: string
  isShown: boolean
  isPaidCourse: boolean
  organizationInfo?: OrganizationInfoModel
  handleClose(): void
}

export enum Screen {
  UserInfo = 'UserInfo',
  Payment = 'Payment',
  Success = 'Success',
  Loading = 'Loading',
}

type StartingUserInfo = {
  email: any
  customer_attributes: any
}

const getModalBody = (
  screen: Screen,
  formMethods: RegistrationFormMethods,
  customFields?: Config[],
  organizationInfo?: OrganizationInfoModel
) => {
  switch (screen) {
    case Screen.UserInfo:
      return (
        <RegistrationForm
          customFields={customFields}
          organizationInfo={organizationInfo}
          formMethods={formMethods}
        />
      )
    case Screen.Payment:
      break
    case Screen.Success:
      return <RegistrationSuccess />
    case Screen.Loading:
      return (
        <div
          style={{
            height: '40vh',
            position: 'relative',
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <CircularProgress style={{ height: '10vh', width: '10vh' }} />
        </div>
      )
  }
}

const RegistrationModal = (props: Props) => {
  const {
    isMarketing,
    organizationInfo,
    courseSlug,
    handleClose,
    isPaidCourse,
    isShown,
  } = props
  const { t } = useTranslation()
  const api = useApi(false, true)
  const { getCustomFields } = OrganizationsService()

  const [screen, setScreen] = useState(Screen.UserInfo)
  const [customFields, setCustomFields] = useState<Config[]>()

  const screenTexts = {
    [Screen.UserInfo]: {
      title: t('course-registration.modal.title-user-info'),
      button: isPaidCourse
        ? t('course-registration.modal.button-payment')
        : t('course-registration.modal.button-user-info'),
    },
    [Screen.Payment]: {
      title: t('course-registration.modal.title-payment'),
      button: t('course-registration.modal.button-payment'),
    },
    [Screen.Success]: {
      title: t('course-registration.modal.title-success'),
      button: t('course-registration.modal.button-success'),
    },
  }
  const [buttonText, setButtonText] = useState(
    screenTexts[Screen.UserInfo].button
  )
  const [title, setTitle] = useState(screenTexts[Screen.UserInfo].title)

  //Dynamic validation schema for react hook form
  const validationSchema = useMemo(() => {
    if (!customFields?.length) {
      return object()
    }

    const startingShape = {
      privacyPolicy: bool()
        .required(t('forms.validation.required-field'))
        .label(t('common.accept-privacy-policy')),
    }

    let array = [2, 3]
    array[1] = 1
    array = [1, 3, 2, 3]
    array[1] = 1
    array = [...array, 2]
    const shape = customFields
      .filter((c) => c.id)
      .reduce((prev, curr) => {
        switch (curr.kind) {
          case 'text': {
            prev[curr.name] = string().min(
              2,
              t('forms.validation.name.min-characters')
            )
            if (curr.mandatory)
              prev[curr.name] = prev[curr.name].required(
                t('forms.validation.required-field')
              )

            break
          }
          case 'email': {
            prev[curr.name] = string().email(
              t('forms.validation.email.not-valid')
            )
            if (curr.mandatory)
              prev[curr.name] = prev[curr.name].required(
                t('forms.validation.required-field')
              )

            break
          }
          case 'number': {
            prev[curr.name] = string()
            //.min(2,t('forms.validation.number.min-characters')) 2 numbers minimum
            if (curr.mandatory)
              prev[curr.name] = prev[curr.name].required(
                t('forms.validation.required-field')
              )

            break
          }
          case 'date': {
            prev[curr.name] = date()
            if (curr.mandatory)
              prev[curr.name] = prev[curr.name].required(
                t('forms.validation.required-field')
              )

            break
          }
        }

        return prev
      }, startingShape as Record<Config['name'], BaseSchema<any, any, any>>)

    return object().shape(shape)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields])

  const getConfig = async (organizationId: number) => {
    const configServer = await getCustomFields(organizationId)
    setCustomFields(configServer)
    console.log({ configServer })
  }

  useEffect(() => {
    if (organizationInfo?.id) {
      getConfig(organizationInfo.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationInfo])

  useEffect(() => {
    if (screen === Screen.Loading) {
      return
    }

    setTitle(screenTexts[screen].title)
    setButtonText(screenTexts[screen].button)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen])

  const registrationFormSubmitHandler: SubmitHandler<UserInfo> = (
    userInfo: UserInfo
  ) => {
    console.log({ userInfo })

    setScreen(Screen.Loading)
    //adding field value to custom fields
    const userInfoValues = Object.values(userInfo)
    console.log({ userInfoValues })

    const customFieldsWithValue = customFields?.map((c, i) => {
      return { ...c, value: userInfoValues[i] }
    })
    //creating an array of user info with default value
    const userInfoArr = Object.entries(userInfo).map(([key, value]) => ({
      value,
      name: key,
      default:
        customFieldsWithValue?.find((c) => c.name === key)?.default ?? false,
    }))

    const startingUserInfo: StartingUserInfo = {
      email: '',
      customer_attributes: {
        custom_data: {},
      },
    }

    //parsing data for api register user
    if (customFieldsWithValue) {
      const finalUserInfo = userInfoArr.reduce((prev, curr) => {
        if (curr.default) {
          prev.customer_attributes[curr.name] = curr.value
          if (curr.name === 'email') {
            prev.email = curr.value
          }
        } else if (curr.name === 'privacyPolicy') {
          return prev
        } else {
          if (curr.name === 'email') {
            prev.email = curr.value
          } else {
            prev.customer_attributes.custom_data[curr.name] = curr.value
          }
        }
        return prev
      }, startingUserInfo)

      if (!isPaidCourse) {
        registerUser(finalUserInfo)
      } else {
        // The user info will be used to register the user to the course
        // inside a 'payment succeeded' webhook on the backend
        redirectToStripeCheckout(finalUserInfo)
      }
    }
  }

  const redirectToStripeCheckout = useCallback(
    (userInfo: StartingUserInfo) => {
      api
        .get(`courses/${courseSlug}/stripe_checkout_link`, {
          params: userInfo,
        })
        .then(
          (response) => {
            window.open(response.data.link, '_self')
          },
          (error) => {
            toast.error(error.response.data.message, { theme: 'colored' })
            setScreen(Screen.UserInfo)
          }
        )
    },
    [api, courseSlug]
  )

  const registerUser = useCallback(
    (userinfo: StartingUserInfo) => {
      let url = `courses/${courseSlug}/register`
      let handler = (res?: any) => {
        setScreen(Screen.Success)
      }

      if (isMarketing) {
        url = `courses/${courseSlug}/jit/register`
        handler = (response) => {
          window.open(response.data.link, '_self')
        }
      }

      api.post(url, userinfo).then(
        (response) => {
          handler(response)
        },
        (error) => {
          console.log(
            `Error while registering participant to Course with Slug "${courseSlug}" : ${error}`
          )
          toast.error(error.response.data.message, { theme: 'colored' })
          setScreen(Screen.UserInfo)
        }
      )
    },
    [api, courseSlug]
  )

  const registrationFormMethods: RegistrationFormMethods = useForm<UserInfo>({
    resolver: yupResolver(validationSchema),
  })

  return (
    <>
      <Modal
        className="registration-modal-container"
        size="xl"
        show={isShown}
        onHide={() => {
          handleClose()
          setTimeout(() => {
            setScreen(Screen.UserInfo)
          }, 500)
        }}
      >
        <Modal.Header
          closeButton
          onHide={() => {
            setTimeout(() => {
              setScreen(Screen.UserInfo)
            }, 500)
          }}
        >
          <Modal.Title>
            {screen === Screen.Success ? (
              <span style={{ color: 'rgba(90, 178, 13, 1)' }}>{title}</span>
            ) : (
              <span>{title}</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getModalBody(
            screen,
            registrationFormMethods,
            customFields,
            organizationInfo
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            hidden={screen === Screen.Loading}
            variant="primary"
            onClick={(event) => {
              switch (screen) {
                case Screen.UserInfo:
                  registrationFormMethods.handleSubmit(
                    registrationFormSubmitHandler
                  )()
                  break
                case Screen.Success:
                  handleClose()
                  setTimeout(() => {
                    setScreen(Screen.UserInfo)
                  }, 500)
                  break
              }
            }}
          >
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RegistrationModal
