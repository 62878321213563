import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from './Loader'
import { toast } from 'react-toastify'
import useApi from '../api/UseApi'
import { useDispatch } from 'react-redux'
import { surveysFetched, surveyCreated } from 'Actions/Survey'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { AnchorEl } from 'common/types/MaterialUI'
import { useAppSelector } from 'Store'
import { JSONValue } from 'common/types/Json'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { TablePagination } from '@mui/material'
import TableSurvey from './TableSurvey/TableSurvey'
import DropdownSurvey from './DropdownSurvey/DropdownSurvey'
import SearchBar from './SearchBar/SearchBar'

type Props = {
  courseId?: number
}

const Surveys = ({ courseId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<AnchorEl>(null)
  const [surveys, setSurveys] = useState([])
  const [allSurveys, setAllSurveys] = useState([])
  const [selectedSurvey, setSelectedSurvey] = useState<any>(null)
  const [isLoaderVisible, setIsLoaderVisible] = useState(true)
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const apiNoAuth = useApi(false, true)
  const api = useApi(false)
  const surveySelector = useAppSelector((state) => state.Survey)
  const organization = useAppSelector(selectCurrentOrganization)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (anchor: AnchorEl) => {
    setAnchorEl(anchor)
  }

  const getAllSurveys = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api.get(`organizations/${organization.id}/surveys`).then(
          (response) => {
            setSurveys(response.data.surveys)
            setAllSurveys(response.data.surveys)
            setIsLoaderVisible(false)
          },
          (error) => {
            console.log(error)
            setIsLoaderVisible(false)
          }
        )
      } catch (e) {
        if (e instanceof Error) {
          console.log(e.message)
        }
        setIsLoaderVisible(false)
      }
    }
  }, [api, organization])

  const getSurveysForCourseId = useCallback(
    (courseId: number) => {
      if (organization) {
        setIsLoaderVisible(true)
        try {
          api.get(`courses/${courseId}/surveys`).then(
            (response) => {
              setSurveys(response.data.surveys)
              setIsLoaderVisible(false)
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
        } catch (e) {
          if (e instanceof Error) {
            console.log(e.message)
          }
          setIsLoaderVisible(false)
        }
      }
    },
    [api, organization]
  )

  const getSurveys = useCallback(() => {
    if (courseId) {
      getSurveysForCourseId(courseId)
    } else {
      getAllSurveys()
    }
  }, [courseId, getAllSurveys, getSurveysForCourseId])

  const updateSurvey = (
    title: string,
    description: string,
    redirectUrl: string | null,
    body: JSONValue,
    isMarkdownEnabled: boolean
  ) => {
    setIsLoaderVisible(true)
    try {
      apiNoAuth
        .put(`surveys/${selectedSurvey.external_id}`, {
          name: title,
          description: description,
          redirectUrl: redirectUrl,
          body: body,
          isMarkdownEnabled: isMarkdownEnabled,
        })
        .then(
          (response) => {
            setIsLoaderVisible(false)
            toast.success(response.data.message, { theme: 'colored' })
            setSelectedSurvey({
              id: selectedSurvey.id,
              name: title,
              description: description,
              body: body,
              isMarkdownEnabled: isMarkdownEnabled,
            })
            getSurveys()
          },
          (error) => {
            console.log(error)
            setIsLoaderVisible(false)
          }
        )
    } catch (e) {
      console.log(e)
    }
  }

  const cloneSurvey = () => {
    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/surveys/clone`, {
          survey_id: selectedSurvey.id,
          name: `${selectedSurvey.name} ${t('survey.clone_title_suffix')}`,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)

            dispatch(surveyCreated())
          },
          (error) => {
            toast.error(error.message, { theme: 'colored' })
            setIsLoaderVisible(false)
            console.log(error)
          }
        )
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message, { theme: 'colored' })
      }
      setIsLoaderVisible(false)
    }
  }

  const createTemplateFromSurvey = () => {
    setIsLoaderVisible(true)
    try {
      api
        .post(`organizations/${organization?.id}/templates/from_survey`, {
          survey_id: selectedSurvey.id,
        })
        .then(
          (response) => {
            toast.success(response.data.message, {
              theme: 'colored',
            })
            setIsLoaderVisible(false)
          },
          (error) => {
            toast.error(error.message, { theme: 'colored' })
            setIsLoaderVisible(false)
            console.log(error)
          }
        )
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message, { theme: 'colored' })
      }
      setIsLoaderVisible(false)
    }
  }

  const deleteSurvey = () => {
    setIsLoaderVisible(true)

    if (!selectedSurvey) {
      return
    }

    try {
      api.delete(`surveys/${selectedSurvey.id}`).then(
        (response) => {
          toast.success(response.data.message, { theme: 'colored' })
          getSurveys()
        },
        (error) => {
          toast.error(error.message, { theme: 'colored' })
          console.log(error)
          setIsLoaderVisible(false)
        }
      )
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message, { theme: 'colored' })
      }
      setIsLoaderVisible(false)
      console.log(e)
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const SearchOnVal = (value: string) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === '') setSurveys(allSurveys)
    else {
      const filteredData = allSurveys.filter((item: any) => {
        return item.name != null
          ? Object.keys(item.name).some(
              (key) =>
                item.name.toString().toLowerCase().includes(lowercasedValue) ||
                item.description
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue)
            )
          : null
      })
      setSurveys(filteredData)
    }
  }

  useEffect(() => {
    if (query != null) {
      SearchOnVal(query)
    }
  }, [query])

  useEffect(() => {
    getSurveys()
  }, [getSurveys])

  useEffect(() => {
    if (surveySelector.shouldUpdateSurveys) {
      dispatch(surveysFetched())
      getSurveys()
    }
  }, [dispatch, getSurveys, surveySelector.shouldUpdateSurveys])

  return (
    <>
      <div className="viewCourse mb-4">
        <SearchBar setQuery={setQuery} />
      </div>

      <TableSurvey
        surveys={surveys}
        page={page}
        rowsPerPage={rowsPerPage}
        handleClick={handleClick}
        setSelectedSurvey={setSelectedSurvey}
      />
      <TablePagination
        sx={{ color: '#5e5e5e' }}
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={surveys.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DropdownSurvey
        anchorEl={anchorEl}
        courseId={courseId}
        selectedSurvey={selectedSurvey}
        cloneSurvey={cloneSurvey}
        createTemplateFromSurvey={createTemplateFromSurvey}
        handleMenuClose={handleMenuClose}
        setIsDeleteConfirmationDialogOpen={setIsDeleteConfirmationDialogOpen}
      />

      <ConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => {
          setIsDeleteConfirmationDialogOpen(false)
        }}
        i18nKey="survey.survey-delete-confirmation"
        entityName={selectedSurvey?.name}
        onConfirm={() => {
          deleteSurvey()
        }}
      />
      <Loader isVisible={isLoaderVisible} />
    </>
  )
}

export default Surveys
