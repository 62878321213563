import { useEffect, useState } from 'react'
import useApi from 'api/UseApi'
import Loader from 'Component/Loader'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import TableCourseMember from 'Component/TableCourseMember/TableCourseMember'
import MemberDetailDialog from 'Component/MemberDetailDialog/MemberDetailDialog'
import { Customer } from 'Component/CourseRegistration/Models'
import Pagination from 'common/Pagination'

interface CrmTraineesTableProp {
  query?: string
  isLoaderVisible: boolean
  setIsLoaderVisible: (value: boolean) => void
}

const CrmTraineesTable = ({
  query,
  isLoaderVisible,
  setIsLoaderVisible,
}: CrmTraineesTableProp) => {
  const [traineesList, setTraineesList] = useState<Customer[]>([])
  const [alltrainees, setAlltrainees] = useState([])
  const [openMemberDialog, setOpenMemberDialog] = useState(false)
  const [detailIndex, setDetailIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageNo, setPageNo] = useState(1)

  const api = useApi()
  const newBackend = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const renderMemberDetail = (open: boolean, index: number) => {
    setOpenMemberDialog(open)
    setDetailIndex(index)
  }

  const SearchOnVal = (value: string) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === '') setTraineesList(alltrainees)
    else {
      const filteredData = alltrainees.filter((item: any) => {
        return item.first_name != null
          ? Object.keys(item.first_name).some(
              (key) =>
                item.first_name
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue) ||
                item.last_name
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue) ||
                item.email.toString().toLowerCase().includes(lowercasedValue)
            )
          : null
      })
      setTraineesList(filteredData)
    }
  }

  useEffect(() => {
    if (query != null) {
      SearchOnVal(query)
    }
  }, [query])

  useEffect(() => {
    if (organization) {
      setIsLoaderVisible(true)

      const Traineesget = async () => {
        try {
          newBackend.get(`organizations/${organization?.id}/people.json`).then(
            (response) => {
              setTraineesList(response.data.users)
              setAlltrainees(response.data.users)
              setIsLoaderVisible(false)
            },
            (error: any) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
        } catch (e: any) {
          console.log(e.message)
        }
      }

      Traineesget()
    }
  }, [api, newBackend, organization])

  return (
    <>
      <TableCourseMember
        filteredRegistrants={traineesList}
        isLoading={isLoaderVisible}
        renderMemberDetail={renderMemberDetail}
        rowsPerPage={rowsPerPage}
        pageNo={pageNo}
      />

      <Pagination
        numberOfItems={traineesList?.length}
        rowsPerPage={rowsPerPage}
        pageNo={pageNo}
        onPageChanged={(pageNo) => {
          setPageNo(pageNo)
        }}
        onRowsPerPageChanged={(rowsPerPage) => {
          setRowsPerPage(rowsPerPage)
        }}
      />

      <MemberDetailDialog
        open={openMemberDialog}
        setOpen={setOpenMemberDialog}
        registrant={traineesList[detailIndex]}
      />

      <Loader isVisible={isLoaderVisible} />
    </>
  )
}

export default CrmTraineesTable
