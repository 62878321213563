import {
  Stack,
  Typography,
  InputLabel,
  Box,
  Grid,
  Divider,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'
import FtFormTextField from 'Component/form-fields/FtFormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Company,
  Notice,
  Project,
  ProjectStatus,
} from 'Component/FundedTraining/types'
import {
  ProjectFormData,
  ProjectFormValidation,
} from 'common/form-validation/ProjectFormValidation'
import CompanyMultiAutoComplete from './CompanyMultiAutocomplete'
import { orange } from '@mui/material/colors'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import ProjectStatusAutoComplete from './ProjectStatusAutocomplete'
import NoticeAutoComplete from '../ NoticeAutocomplete'

type Props = {
  currentProject?: Project
  isNewProject?: boolean
  onProjectUpdated?(project: Project): void
}

export function ProjectForm(props: Props) {
  const {
    currentProject = null,
    isNewProject = false,
    onProjectUpdated,
  } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedStatus, setSelectedStatus] = useState<null | ProjectStatus>(
    null
  )

  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([])

  const [notices, setNotices] = useState<Notice[]>([])
  const [selectedNotice, setSelectedNotice] = useState<null | Notice>(null)

  const formMethods = useForm<ProjectFormData>({
    resolver: yupResolver(ProjectFormValidation(t)),
  })

  const getNotices = useCallback(() => {
    if (organization) {
      setIsLoading(true)
      try {
        api
          .get('funded_training/notices.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              setNotices(response.data)
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
      } catch (e: any) {
        setIsLoading(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getNotices()
  }, [getNotices, currentProject])

  const getCompanies = useCallback(() => {
    if (organization) {
      setIsLoading(true)
      try {
        api
          .get('funded_training/companies.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              setCompanies(response.data)
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
      } catch (e: any) {
        setIsLoading(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getCompanies()
  }, [getCompanies])

  const updateProject = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.put(`funded_training/projects/${currentProject?.uuid}`, data).then(
        (response) => {
          toast.success(t('funded-training.project-updated'), {
            theme: 'colored',
          })
          if (onProjectUpdated) {
            onProjectUpdated(response.data.project)
          }

          setIsLoading(false)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createProject = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.post('funded_training/projects', data).then(
        (response) => {
          toast.success(t('funded-training.project-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.goBack()
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: ProjectFormData) => {
    const params: Record<string, any> = {}

    params['title'] = data.title
    if (selectedNotice) {
      params['notice_id'] = selectedNotice.id
    }
    params['status'] = selectedStatus
    params['funder_project_id'] = data.funder_project_id
    params['cup'] = data.cup
    params['duration_hours'] = data.duration_hours
    params['start_date'] = data.start_date
    params['end_date'] = data.end_date

    const companyIds: number[] = selectedCompanies.map((company: Company) => {
      return company.id
    })
    params['company_ids'] = companyIds

    if (organization) {
      params['organization_id'] = organization.id
    }

    if (data.description) {
      params['description'] = data.description
    }

    if (isNewProject) {
      createProject(params)
    } else {
      updateProject(params)
    }
  }

  useEffect(() => {
    if (currentProject) {
      if (currentProject.notice) {
        setSelectedNotice(currentProject.notice)
      }
      if (currentProject.status) {
        setSelectedStatus(currentProject.status)
      }
      setSelectedCompanies(currentProject.companies)
      formMethods.setValue('title', currentProject.title)
      formMethods.setValue('description', currentProject.description)
      formMethods.setValue('duration_hours', currentProject.duration_hours)
      formMethods.setValue('start_date', currentProject.start_date)
      formMethods.setValue('end_date', currentProject.end_date)
      formMethods.setValue('cup', currentProject.cup)
      formMethods.setValue(
        'funder_project_id',
        currentProject.funder_project_id
      )
      formMethods.setValue(
        'min_attendance_pct',
        currentProject.min_attendance_pct
      )
      formMethods.setValue(
        'min_participants_pct_with_min_attendance',
        currentProject.min_participants_pct_with_min_attendance
      )
    }
  }, [formMethods, currentProject])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          {(currentProject || isNewProject) && (
            <Stack alignItems="flex-start" justifyContent="flex-start">
              <Grid
                container
                columns={8}
                rowSpacing={1}
                columnSpacing={{ xs: 1 }}
              >
                <Grid item sm={8} lg={8}>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      fontFamily: 'nexalight',
                      color: orange[500],
                    }}
                  >
                    {t('common.main-info')}
                  </Typography>
                </Grid>

                <Grid item sm={8} lg={6}>
                  <Divider
                    variant="fullWidth"
                    sx={{ backgroundColor: '#c0c0c0' }}
                  />
                </Grid>

                <Grid item sm={0} lg={2}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>
                <Grid item sm={8} lg={8}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>

                <Grid item xs={8}>
                  <FtFormTextField
                    name="title"
                    type="text"
                    label={t('common.title')}
                    mandatory={true}
                  />
                </Grid>

                <Grid item xs={4} lg={1}>
                  <FtFormTextField
                    name="duration_hours"
                    type="number"
                    label={t('common.duration-hours')}
                  />
                </Grid>

                <Grid item xs={8}>
                  <InputLabel sx={{ color: '#1976d2' }}>
                    <Typography variant="subtitle1">
                      {t('common.status')}
                    </Typography>
                  </InputLabel>

                  <Box sx={{ mb: 2 }}>
                    <ProjectStatusAutoComplete
                      selectedItem={
                        currentProject ? currentProject.status : null
                      }
                      onUpdated={(newStatus: null | ProjectStatus) => {
                        setSelectedStatus(newStatus)
                      }}
                    ></ProjectStatusAutoComplete>
                  </Box>
                </Grid>

                <Grid item xs={8}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>

                <Grid item xs={8}>
                  <Box sx={{ mb: 2 }}>
                    <InputLabel sx={{ color: '#1976d2' }}>
                      <Typography variant="subtitle1">
                        {t('funded-training.notice')}
                      </Typography>
                    </InputLabel>
                    <NoticeAutoComplete
                      allNotices={notices}
                      selectedItem={selectedNotice}
                      onUpdated={(newNotice: null | Notice) => {
                        setSelectedNotice(newNotice)
                      }}
                    ></NoticeAutoComplete>
                  </Box>
                </Grid>

                <Grid item xs={8}>
                  <InputLabel sx={{ color: '#1976d2' }}>
                    <Typography variant="subtitle1">
                      {t('funded-training.companies')}
                    </Typography>
                  </InputLabel>

                  <Box sx={{ mb: 2 }}>
                    <CompanyMultiAutoComplete
                      selectedCompanies={selectedCompanies}
                      allCompanies={companies}
                      onCompaniesUpdated={(newCompanies: Company[]) => {
                        setSelectedCompanies(newCompanies)
                      }}
                    ></CompanyMultiAutoComplete>
                  </Box>
                </Grid>

                <Grid item xs={8}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>

                <Grid item sm={8} lg={8}>
                  <Typography
                    variant="h6"
                    sx={{ mt: 1, fontFamily: 'nexalight', color: orange[500] }}
                  >
                    {t('common.details')}
                  </Typography>
                </Grid>
                <Grid item sm={8} lg={6}>
                  <Divider
                    variant="fullWidth"
                    sx={{ backgroundColor: '#c0c0c0' }}
                  />
                </Grid>

                <Grid item sm={0} lg={2}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>

                <Grid item sm={8} lg={8}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>
                {/* <Grid item xs={8} sm={4} lg={3}>
                <FtFormTextField
                  name="description"
                  type="text"
                  label={t('common.description')}
                />
              </Grid> */}

                <Grid item xs={8} sm={4} lg={2}>
                  <FtFormTextField
                    name="funder_project_id"
                    type="text"
                    label={t('funded-training.project-id')}
                    tooltipDescription={t(
                      'funded-training.project-id-description'
                    )}
                  />
                </Grid>

                <Grid item xs={8} sm={4} lg={2}>
                  <FtFormTextField
                    name="cup"
                    type="text"
                    label={t('funded-training.cup')}
                    tooltipDescription={t('funded-training.cup-description')}
                  />
                </Grid>

                <Grid item xs={8} sm={4} lg={2}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>

                <Grid item xs={8} sm={4} lg={2}>
                  <Box component="button" sx={{ display: 'none' }} />
                </Grid>

                <Grid item xs={8} sm={4} lg={2}>
                  {' '}
                  <FtFormTextField
                    name="start_date"
                    type="date"
                    label={t('common.start-date')}
                  />
                </Grid>
                <Grid item xs={8} sm={4} lg={2}>
                  <FtFormTextField
                    name="end_date"
                    type="date"
                    label={t('common.end-date')}
                  />
                </Grid>
              </Grid>

              <StyledButton
                type="submit"
                startIcon={<SaveIcon />}
                hidden={isLoading}
                sx={{
                  mt: 3,
                  ml: 2,
                  backgroundColor: '#0d6efd !important',
                }}
              >
                <Typography sx={{ mt: 0.5 }}>
                  {isNewProject ? t('common.create') : t('common.update')}
                </Typography>
              </StyledButton>
              <Loader isVisible={isLoading} />
            </Stack>
          )}
        </form>
      </FormProvider>
    </>
  )
}
