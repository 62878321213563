import React, { Fragment, useState, useEffect, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CourseFilter from '../View/course/courseFilter/CourseFilter';
import dropdownicon from '../assets/icon/global/dropdownicon.svg';
import errorTriangle from '../assets/icon/global/errorTriangle.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'Component/Loader';
import { Tooltip, Menu, MenuItem, Fade } from '@mui/material';

import { useHistory } from 'react-router-dom';
import AddCourse from '../View/course/AddCourse/AddCourse';
import AddSession from '../View/course/AddSession/AddSession';
import { useDispatch } from 'react-redux';
import EditSettings from '../Actions/EditSettings';
import EditSession from '../View/course/EditSession/EditSession';
import PublishIcon from '@mui/icons-material/Publish';
import ArchiveIcon from '@mui/icons-material/Archive';
import UpdateSessionTrainersPopup from '../View/course/UpdateSessionTrainersPopup';
import { useTranslation } from 'react-i18next';
import useApi from 'api/UseApi';
import ViewSession from '../View/course/ViewSession';
import {
  getLocalizedDateFromUTC,
  getLocalizedTimeFromUTC,
} from 'common/DateTimeUtils';
import Pagination from 'common/Pagination';
import ConfirmationDialog from './ConfirmationDialog';
import TrainerAvatars from './TrainerAvatars';
import { useAppSelector } from 'Store';
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer';
import moment from 'moment';
import { Grid, Link } from '@mui/material';
import { emptySession } from '../View/course/AddSession/AddSession';
import VideoCallIcon from '@mui/icons-material/VideoCall';

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    sessionCount: null,
    status: null,
    startDate: null,
    endDate: null,
    registrantsFrom: null,
    registrantsTo: null,
  });
  const [sessionOpt, setsessionOpt] = useState(true);
  const [getIndexVal, setgetIndexVal] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [singleCourse, setSingleCourse] = useState({});
  let history = useHistory();
  const [getSessionid, setgetSessionid] = useState('');
  const [courseInf, setcourseInf] = useState('');
  const dispatch = useDispatch();
  const [singleSession, setSingleSession] = React.useState({
    ...emptySession(),
  });
  const [serverSingleSession, setServerSingleSession] = React.useState({
    ...emptySession(),
  });
  const [pageNo, setPageNo] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [apiError, setApiError] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [deleteTag, setDeleteTag] = useState('');
  const [searchText, setSearchValue] = useState('');
  const { t } = useTranslation();
  const [toggleIcon, settoggleIcon] = useState([
    { filter: false },
    { filter: false },
    { filter: false },
    { filter: false },
    { filter: false },
    { filter: false },
    { filter: false },
    { filter: false },
  ]);
  const [isPublishConfirmationDialogOpen, setIsPublishConfirmationDialogOpen] =
    useState(false);
  const api = useApi(false);
  const organization = useAppSelector(selectCurrentOrganization);

  const changeIcon = React.useCallback(() => {
    settoggleIcon(() => {
      return [...toggleIcon];
    });
  }, [toggleIcon]);

  const getAllCourses = useCallback(() => {
    setIsLoaderVisible(true);
    if (organization) {
      try {
        api
          .get('courses.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              response.data.courses.forEach((element) => {
                element.tablecollapse = false;
              });
              setCourses(response.data.courses);
              setFilteredCourses(response.data.courses);
              setIsLoaderVisible(false);

              dispatch(EditSettings(false));
            },
            (error) => {
              console.log(error);
              setApiError(true);
              setIsLoaderVisible(false);
            }
          );
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [organization, api, dispatch]);

  useEffect(() => {
    getAllCourses();
  }, [getAllCourses]);

  const onFiltersSelected = (filters) => {
    setSelectedFilters(filters);
  };

  useEffect(() => {
    const lowerCaseSearchText = searchText.toLowerCase().trim();

    const newFilteredCourses = courses.filter((course) => {
      const containsSearchText =
        !lowerCaseSearchText ||
        course.title.toString().toLowerCase().includes(lowerCaseSearchText);

      const matchesSessionCount =
        selectedFilters.sessionCount === null ||
        course.meetings.length >= Number(selectedFilters.sessionCount);

      const matchesStatus =
        !selectedFilters.status || course.status === selectedFilters.status;

      const matchesStartDate =
        !selectedFilters.startDate ||
        moment(course.course_begin_date).isSameOrAfter(
          selectedFilters.startDate,
          'day'
        );

      const matchesEndDate =
        !selectedFilters.endDate ||
        moment(course.course_end_date).isSameOrBefore(
          selectedFilters.endDate,
          'day'
        );

      let matchesRegistrants = true;
      if (
        selectedFilters.registrantsFrom &&
        selectedFilters.registrantsTo &&
        selectedFilters.registrantsFrom === selectedFilters.registrantsTo
      ) {
        matchesRegistrants =
          course.course_total_registrants === selectedFilters.registrantsFrom;
      } else {
        const matchesRegistrantsFrom =
          selectedFilters.registrantsFrom == null ||
          course.course_total_registrants >= selectedFilters.registrantsFrom;

        const matchesRegistrantsTo =
          selectedFilters.registrantsTo == null ||
          course.course_total_registrants <= selectedFilters.registrantsTo;

        matchesRegistrants = matchesRegistrantsFrom && matchesRegistrantsTo;
      }

      const matchesFilters =
        containsSearchText &&
        matchesSessionCount &&
        matchesStatus &&
        matchesStartDate &&
        matchesEndDate &&
        matchesRegistrants;

      return matchesFilters;
    });

    setFilteredCourses(newFilteredCourses);
    setPageNo(1);
  }, [
    courses,
    searchText,
    selectedFilters.sessionCount,
    selectedFilters.status,
    selectedFilters.startDate,
    selectedFilters.endDate,
    selectedFilters.registrantsFrom,
    selectedFilters.registrantsTo,
  ]);

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteTag('');
  };

  const moreHideShow = (course, indexVal) => {
    setSingleCourse(course);
    setgetIndexVal(indexVal);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const sessionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleSessions = (index) => {
    const newdata = filteredCourses;
    newdata[index].tablecollapse = !newdata[index].tablecollapse;
    setFilteredCourses(() => {
      return [...newdata];
    });
  };

  const publishCourse = async () => {
    setIsLoaderVisible(true);

    try {
      api.post(`courses/${singleCourse.id}/publish`).then(
        (response) => {
          setIsLoaderVisible(false);
          setSingleCourse({});
          if (
            response.data.message === 'Courses published successfully!' ||
            response.data.message === 'Course is already published!'
          ) {
            toast.success(response.data.message, { theme: 'colored' });
          } else {
            toast.warning(response.data.message, { theme: 'colored' });
          }
          getAllCourses();
        },
        (error) => {
          console.log(error.response.data);
          toast.error(error.response.data.message, { theme: 'colored' });
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const DeleteCourse = async () => {
    setIsLoaderVisible(true);

    try {
      api.delete(`courses/${singleCourse.id}`).then(
        (response) => {
          setIsLoaderVisible(false);
          setSingleCourse({});
          toast.success(t('courses.course-has-been-deleted-successfully'), {
            theme: 'colored',
          });
          getAllCourses();
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
    handleClose();
  };

  const archiveCourse = async () => {
    setIsLoaderVisible(true);
    try {
      api.post(`courses/${singleCourse.id}/archives`).then(
        (response) => {
          setIsLoaderVisible(false);
          toast.success(response.data.message, { theme: 'colored' });
          setSingleCourse({});
          getAllCourses();
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const exportCSV = async () => {
    setIsLoaderVisible(true);

    try {
      api.get(`courses.xlsx`).then(
        (response) => {
          const link = document.createElement('a');
          var binaryData = [];
          binaryData.push(response.data);
          link.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: 'application/xlsx' })
          );
          link.download = `Courses-${+new Date()}.xlsx`;
          link.click();
          setIsLoaderVisible(false);
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteSession = async () => {
    setIsLoaderVisible(true);

    try {
      api.delete(`courses/${singleCourse.id}/sessions/${getSessionid}`).then(
        (response) => {
          if (response.status === 204) {
            getAllCourses();
            setIsLoaderVisible(false);
            toast.success(t('courses.session-has-been-deleted-successfully'), {
              theme: 'colored',
            });
          }
        },
        (error) => {
          console.log(error);
          toast.error(t('common.something-went-wrong'), {
            theme: 'colored',
          });
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
    setIsPopupOpen(false);
    handleClose();
  };

  const sortByName = (index) => {
    if (toggleIcon[index].filter === true) {
      filteredCourses.sort((a, b) => {
        let courseNameOne = a.title.toLowerCase(),
          courseNameTwo = b.title.toLowerCase();

        if (courseNameOne < courseNameTwo) {
          return 1;
        }
        if (courseNameOne > courseNameTwo) {
          return -1;
        }
        return 0;
      });
    } else if (toggleIcon[index].filter === false) {
      filteredCourses.sort((a, b) => {
        let courseNameOne = a.title.toLowerCase(),
          courseNameTwo = b.title.toLowerCase();

        if (courseNameOne < courseNameTwo) {
          return -1;
        }
        if (courseNameOne > courseNameTwo) {
          return 1;
        }
        return 0;
      });
    }
  };

  const sortBeginDate = (index) => {
    if (toggleIcon[index].filter === true) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let date_One = new Date(a.course_begin_date),
          date_Two = new Date(b.course_begin_date);
        return date_One - date_Two;
      });
      setFilteredCourses(sortedCourses);
    } else if (toggleIcon[index].filter === false) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let date_One = new Date(a.course_begin_date),
          date_Two = new Date(b.course_begin_date);
        return date_Two - date_One;
      });
      setFilteredCourses(sortedCourses);
    }
  };

  const sortEndDate = (index) => {
    if (toggleIcon[index].filter === true) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let date_One = new Date(a.course_end_date),
          date_Two = new Date(b.course_end_date);
        return date_One - date_Two;
      });
      setFilteredCourses(sortedCourses);
    } else if (toggleIcon[index].filter === false) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let date_One = new Date(a.course_end_date),
          date_Two = new Date(b.course_end_date);
        return date_Two - date_One;
      });
      setFilteredCourses(sortedCourses);
    }
  };

  const sortByStatus = (index) => {
    if (toggleIcon[index].filter === true) {
      filteredCourses.reverse();
    } else if (toggleIcon[index].filter === false) {
      filteredCourses.sort((a, b) => {
        let courseStatusOne = a.status.toLowerCase(),
          courseStatusTwo = b.status.toLowerCase();

        if (courseStatusOne < courseStatusTwo) {
          return -1;
        }
        if (courseStatusOne > courseStatusTwo) {
          return 1;
        }
        return 0;
      });
    }
  };

  const sortByRegistrants = (index) => {
    if (toggleIcon[index].filter === true) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let registrants_One = a.course_total_registrants,
          registrants_Two = b.course_total_registrants;
        return registrants_One - registrants_Two;
      });
      setFilteredCourses(sortedCourses);
    } else if (toggleIcon[index].filter === false) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let registrants_One = a.course_total_registrants,
          registrants_Two = b.course_total_registrants;
        return registrants_Two - registrants_One;
      });
      setFilteredCourses(sortedCourses);
    }
  };

  const sortBySession = (index) => {
    if (toggleIcon[index].filter === true) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let session_One = a.meetings.length,
          session_Two = b.meetings.length;
        return session_One - session_Two;
      });
      setFilteredCourses(sortedCourses);
    } else if (toggleIcon[index].filter === false) {
      let sortedCourses = filteredCourses.sort((a, b) => {
        let session_One = a.meetings.length,
          session_Two = b.meetings.length;
        return session_Two - session_One;
      });
      setFilteredCourses(sortedCourses);
    }
  };

  const addCourse = (course) => {
    const newCourse = [course];
    const updatedCourses = newCourse.concat(filteredCourses);
    setCourses(updatedCourses);
  };

  return (
    <div className="">
      <ConfirmationDialog
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        i18nKey={
          deleteTag === 'course'
            ? 'courses.course-delete-confirmation'
            : 'courses.session-delete-confirmation'
        }
        entityName={
          deleteTag === 'course'
            ? singleCourse.title
            : singleSession.sessionname
        }
        onConfirm={deleteTag === 'course' ? DeleteCourse : deleteSession}
      />

      <ConfirmationDialog
        isOpen={isPublishConfirmationDialogOpen}
        onClose={() => {
          setIsPublishConfirmationDialogOpen(false);
        }}
        i18nKey="courses.course-publish-confirmation"
        entityName={singleCourse?.title}
        onConfirm={() => {
          publishCourse();
        }}
      />
      <div className="courses">
        <div className="">
          <Grid container spacing={2} className="search_addCourse">
            <Grid>
              <div className="inputSearch">
                <SearchIcon style={{ color: '#DEDEDE' }} />
                <div className="mx-2 inputWidth">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('courses.Search')}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid>
              {organization?.userPermissions.includes('add:course') && (
                <AddCourse onCourseCreated={addCourse} />
              )}
            </Grid>
          </Grid>
        </div>

        <div className="upTable">
          <div className="">
            <Grid container spacing={2} className="mt-2 mb-2">
              <Grid>
                <h4 className="mb-0">{t('courses.Courses')}</h4>
              </Grid>
              <Grid>
                <div className="d-flex">
                  {organization?.userPermissions.includes('read:courses') && (
                    <button
                      className="btn mainbtnColor me-4"
                      onClick={exportCSV}
                    >
                      <svg
                        width="15"
                        height="15"
                        style={{
                          marginRight: '.7rem',
                        }}
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.2 12.6H9V4.5H11.7L8.1 0L4.5 4.5H7.2V12.6Z"
                          fill="#157AFF"
                        />
                        <path
                          d="M1.8 17.9998H14.4C15.3927 17.9998 16.2 17.1925 16.2 16.1998V8.0998C16.2 7.1071 15.3927 6.2998 14.4 6.2998H10.8V8.0998H14.4V16.1998H1.8V8.0998H5.4V6.2998H1.8C0.8073 6.2998 0 7.1071 0 8.0998V16.1998C0 17.1925 0.8073 17.9998 1.8 17.9998Z"
                          fill="#157AFF"
                        />
                      </svg>

                      {t('courses.Export')}
                    </button>
                  )}

                  <CourseFilter onFiltersSelected={onFiltersSelected} />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="Tablestart">
            {organization?.userPermissions.includes('read:courses') ||
            organization?.userPermissions.includes('read:invited-session') ||
            organization?.userPermissions.includes(
              'list:participant_courses'
            ) ? (
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      onClick={() => {
                        toggleIcon[0].filter = !toggleIcon[0].filter;
                        changeIcon(0);
                        sortBySession(0);
                      }}
                    >
                      <span
                        className="courseTh"
                        style={{
                          paddingLeft: '1.5rem',
                        }}
                      >
                        {t('courses.Sessions')}
                        &nbsp;
                        {toggleIcon[0].filter === false && (
                          <i className="fas fa-long-arrow-alt-down"></i>
                        )}
                        {toggleIcon[0].filter === true && (
                          <i className="fas fa-long-arrow-alt-up"></i>
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        toggleIcon[1].filter = !toggleIcon[1].filter;
                        changeIcon(1);
                        sortByStatus(1);
                      }}
                    >
                      <span className="courseTh">
                        {t('courses.Status')}
                        &nbsp;
                        {toggleIcon[1].filter === false && (
                          <i className="fas fa-long-arrow-alt-down"></i>
                        )}
                        {toggleIcon[1].filter === true && (
                          <i className="fas fa-long-arrow-alt-up"></i>
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        toggleIcon[2].filter = !toggleIcon[2].filter;
                        changeIcon(2);
                        sortByName(2);
                      }}
                    >
                      <span className="courseTh">
                        {t('courses.CourseName')}
                        &nbsp;
                        {toggleIcon[2].filter === false && (
                          <i className="fas fa-long-arrow-alt-down"></i>
                        )}
                        {toggleIcon[2].filter === true && (
                          <i className="fas fa-long-arrow-alt-up"></i>
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        toggleIcon[3].filter = !toggleIcon[3].filter;
                        changeIcon();
                        sortBeginDate(3);
                      }}
                    >
                      <span className="courseTh">
                        {t('courses.BeginDate')}
                        &nbsp;
                        {toggleIcon[3].filter === false && (
                          <i className="fas fa-long-arrow-alt-down"></i>
                        )}
                        {toggleIcon[3].filter === true && (
                          <i className="fas fa-long-arrow-alt-up"></i>
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        toggleIcon[4].filter = !toggleIcon[4].filter;
                        changeIcon();
                        sortEndDate(4);
                      }}
                    >
                      <span className="courseTh">
                        {t('courses.EndDate')}
                        &nbsp;
                        {toggleIcon[4].filter === false && (
                          <i className="fas fa-long-arrow-alt-down"></i>
                        )}
                        {toggleIcon[4].filter === true && (
                          <i className="fas fa-long-arrow-alt-up"></i>
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        toggleIcon[5].filter = !toggleIcon[5].filter;
                        changeIcon();
                        sortByRegistrants(5);
                      }}
                    >
                      <span className="courseTh">
                        {t('registrants')}
                        &nbsp;
                        {toggleIcon[5].filter === false && (
                          <i className="fas fa-long-arrow-alt-down"></i>
                        )}
                        {toggleIcon[5].filter === true && (
                          <i className="fas fa-long-arrow-alt-up"></i>
                        )}
                      </span>
                    </th>
                    <th scope="col">
                      <span className="courseTh" style={{ cursor: 'default' }}>
                        {t('courses.Trainers')}
                      </span>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {filteredCourses
                  .slice(
                    pageNo * rowsPerPage - rowsPerPage,
                    pageNo * rowsPerPage
                  )
                  .map((course, index) => (
                    <Fragment key={index}>
                      <tbody className="courseTbody">
                        <tr>
                          <td
                            style={{
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                              borderBottomLeftRadius:
                                course.tablecollapse === true ? '0px' : '25px',
                            }}
                          >
                            <button
                              className="btn d-flex"
                              style={{
                                color: '#0d6efd',
                              }}
                              onClick={() => {
                                toggleSessions(
                                  pageNo * rowsPerPage - rowsPerPage + index
                                );
                              }}
                            >
                              <img src={dropdownicon} alt="dropdownIcon" />
                              &nbsp; &nbsp;
                              {course.meetings != null
                                ? course.meetings.length
                                : null}
                            </button>
                          </td>
                          <td
                            style={{
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                            }}
                          >
                            {course.status === 'Live' ? (
                              <span style={{ color: 'red' }}>
                                <i
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                  style={{ fontSize: 'large' }}
                                ></i>
                                &nbsp; {course.status}
                              </span>
                            ) : null}
                            {course.status === 'Draft' ? (
                              <span style={{ color: 'gray' }}>
                                <i
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                  style={{ fontSize: 'large' }}
                                ></i>{' '}
                                &nbsp; {course.status}
                              </span>
                            ) : null}
                            {course.status === 'Completed' ? (
                              <span style={{ color: 'green' }}>
                                <i
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                  style={{ fontSize: 'large' }}
                                ></i>{' '}
                                &nbsp; {course.status}
                              </span>
                            ) : null}
                            {course.status === 'Coming Next' ? (
                              <span style={{ color: '#157AFF' }}>
                                <i
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                  style={{ fontSize: 'large' }}
                                ></i>{' '}
                                &nbsp; {course.status}
                              </span>
                            ) : null}
                          </td>
                          <td
                            style={{
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                              minWidth: '180px',
                            }}
                          >
                            <Tooltip title={course.title} arrow>
                              <NavLink
                                className="shortName"
                                to={
                                  organization?.userPermissions.includes(
                                    'edit:session'
                                  )
                                    ? `/Courses/${course.id}`
                                    : '/Courses'
                                }
                                exact
                              >
                                {course.title}
                              </NavLink>
                            </Tooltip>
                          </td>

                          <td
                            style={{
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                            }}
                          >
                            {course.course_begin_date != null
                              ? getLocalizedDateFromUTC(
                                  course.course_begin_date
                                )
                              : null}
                          </td>
                          <td
                            style={{
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                            }}
                          >
                            {course.course_end_date != null
                              ? getLocalizedDateFromUTC(course.course_end_date)
                              : null}
                          </td>
                          <td
                            style={{
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                            }}
                          >
                            {course.course_total_registrants}
                          </td>
                          <td
                            style={{
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                            }}
                          >
                            <div style={{ display: 'inline-flex' }}>
                              <TrainerAvatars trainers={course.trainer} />
                            </div>
                          </td>

                          <td
                            style={{
                              borderBottomRightRadius:
                                course.tablecollapse === true ? '0px' : '25px',
                              background:
                                course.tablecollapse === true
                                  ? '#f7f7fa'
                                  : 'white',
                            }}
                          >
                            {course.warning === true && (
                              <Tooltip title={course.warningDetails} arrow>
                                <img src={errorTriangle} alt="errorTriangle" />
                              </Tooltip>
                            )}
                            <MoreVertIcon
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                handleClick(e);
                                moreHideShow(course, index);
                                setsessionOpt(true);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>

                      {course.meetings !== null &&
                        course.tablecollapse === true && (
                          <tbody className="sessionTbody">
                            <tr>
                              <th colSpan={2} style={{ paddingLeft: '30px' }}>
                                {t('courses.SessionName')}
                              </th>
                              <th>{t('courses.Date')}</th>
                              <th>{t('courses.Time')}</th>
                              <th>{t('courses.Trainer')}</th>
                              <th>{t('courses.meeting')}</th>
                              <th></th>
                              <th></th>
                            </tr>

                            {course.meetings.map((session, Sindex) => (
                              <tr key={Sindex}>
                                <td colSpan={2} style={{ paddingLeft: '30px' }}>
                                  {organization?.userPermissions.includes(
                                    'edit:courses'
                                  ) ? (
                                    <ViewSession
                                      serverSession={serverSingleSession}
                                      session={session}
                                      getAllcourse={getAllCourses}
                                      courses={course}
                                    />
                                  ) : (
                                    <span className="sessionellipse">
                                      {session.sessionname}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {session.start_date != null
                                    ? getLocalizedDateFromUTC(
                                        session.start_date
                                      )
                                    : null}
                                </td>
                                <td>
                                  {session.start_date != null
                                    ? getLocalizedTimeFromUTC(
                                        session.start_date
                                      )
                                    : null}
                                </td>

                                <td>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'left',
                                    }}
                                  >
                                    {organization?.userPermissions.includes(
                                      'edit:courses'
                                    ) && (
                                      <UpdateSessionTrainersPopup
                                        courseId={course.id}
                                        sessionId={session.id}
                                        sessionTrainers={
                                          session.session_trainer
                                        }
                                        onTrainersUpdated={() => {
                                          getAllCourses();
                                        }}
                                      />
                                    )}
                                    <TrainerAvatars
                                      trainers={session.session_trainer}
                                    />
                                  </div>
                                </td>
                                <td>
                                  {session.hosting_url && (
                                    <Link
                                      href={session.hosting_url}
                                      target="_blank"
                                    >
                                      <VideoCallIcon className="cursorPointer" />
                                    </Link>
                                  )}
                                </td>
                                <td></td>

                                <td>
                                  {session.meetings_warning === true ? (
                                    <Tooltip
                                      title={session.meetings_warningDetails}
                                      arrow
                                    >
                                      <img
                                        src={errorTriangle}
                                        alt="errorTriangle"
                                      />
                                    </Tooltip>
                                  ) : null}

                                  <MoreVertIcon
                                    className="cursorPointer"
                                    onClick={(e) => {
                                      sessionMenu(e);
                                      setsessionOpt(false);
                                      moreHideShow(course, index);
                                      setgetSessionid(session.id);
                                      setcourseInf({
                                        courseid: course.id,
                                        meetingsid: course.id,
                                      });
                                      setSingleSession(session);
                                      setServerSingleSession(session);
                                    }}
                                    aria-controls="fade-menu"
                                    aria-haspopup="true"
                                  />
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td style={{ paddingTop: '0' }} colSpan={2}>
                                {organization?.userPermissions.includes(
                                  'add:session'
                                ) && (
                                  <AddSession
                                    getAllcourse={getAllCourses}
                                    courseId={course.id}
                                    className="ps-1"
                                  />
                                )}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                    </Fragment>
                  ))}
              </table>
            ) : null}

            {(apiError || filteredCourses.length === 0) && !isLoaderVisible && (
              <h1
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgb(208 211 214)',
                }}
              >
                {t('NoRecordFound')}
              </h1>
            )}
            <Pagination
              numberOfItems={filteredCourses.length}
              rowsPerPage={rowsPerPage}
              pageNo={pageNo}
              onPageChanged={(pageNo) => {
                setPageNo(pageNo);
              }}
              onRowsPerPageChanged={(rowsPerPage) => {
                setRowsPerPage(rowsPerPage);
              }}
            />
          </div>
        </div>
      </div>

      <Menu
        className="viewSessionMenu"
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {sessionOpt === true ? (
          <div>
            {organization?.userPermissions.includes('edit:courses') &&
              !singleCourse.publish_at && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setIsPublishConfirmationDialogOpen(true);
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PublishIcon className="me-1" style={{ color: '#157AFF' }} />
                  <span className="mt-1">{t('courses.Publish')}</span>
                </MenuItem>
              )}

            {organization?.userPermissions.includes('edit:courses') &&
              !singleCourse.publish_at && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setTimeout(() => {
                      history.push(`/Courses/${singleCourse.id}`);
                      dispatch(EditSettings(true));
                    }, 200);
                  }}
                >
                  <i
                    className="far fa-edit ms-1"
                    style={{ color: '#157AFF' }}
                  ></i>
                  {t('courses.Edit')}
                </MenuItem>
              )}

            {organization?.userPermissions.includes('edit:courses') && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  archiveCourse();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ArchiveIcon className="me-1" style={{ color: '#157AFF' }} />
                <span className="mt-1">{t('courses.Archive')}</span>
              </MenuItem>
            )}

            {organization?.userPermissions.includes('delete:course') && (
              <MenuItem
                onClick={() => {
                  setIsPopupOpen(true);
                  handleClose();
                  setDeleteTag('course');
                }}
              >
                <i
                  className="fas fa-trash-alt ms-1"
                  style={{ color: '#157AFF' }}
                ></i>
                {t('courses.Delete')}
              </MenuItem>
            )}

            {singleCourse.publish_at && (
              <MenuItem
                onClick={() => {
                  navigator.clipboard.writeText(singleCourse.course_Link);
                  toast.success(t('common.copied-to-clipboard-message'), {
                    theme: 'colored',
                  });
                  handleClose();
                }}
              >
                <i className="fa fa-copy ms-1" style={{ color: '#157AFF' }}></i>
                {t('courses.copy-registration-link')}
              </MenuItem>
            )}
          </div>
        ) : (
          <div>
            {organization?.userPermissions.includes('edit:session') && (
              <MenuItem
                className="position-relative"
                onClick={() => {
                  handleClose();
                }}
              >
                <EditSession
                  singleSession={singleSession}
                  serverSingleSession={serverSingleSession}
                  getAllcourse={getAllCourses}
                />
              </MenuItem>
            )}

            {organization?.userPermissions.includes('delete:session') && (
              <MenuItem
                onClick={() => {
                  setIsPopupOpen(true);
                  handleClose();
                  setDeleteTag('session');
                }}
              >
                <i
                  className="fas fa-trash-alt"
                  style={{ color: '#157AFF' }}
                ></i>
                {t('courses.Delete')}
              </MenuItem>
            )}
          </div>
        )}
      </Menu>
      <Loader isVisible={isLoaderVisible} />
    </div>
  );
};

export default Courses;
