import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TemplateSelect from 'View/course/TemplateSelect'
import { Organization } from 'Reducers/UserConfigReducer'
import { DraftSession } from '../../View/course/AddSession/AddSession'
import DateAndTimePicker from 'Component/DateAndTimePicker/DateAndTimePicker'
import { Box, Checkbox, Stack, ToggleButton, Tooltip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CancelEditIcon from '@mui/icons-material/RestartAltOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Delta } from 'quill'
import { editorModules } from 'Component/EditorDescrition/ReactQuillEditor'
export interface AddSessionFormProp {
  sessions: DraftSession[]
  serverSessions?: DraftSession[]
  templates?: any[]
  organization: Organization
  removeSession: (index: number) => void
  onSessionFieldChanged: <T extends keyof DraftSession>(
    name: T,
    value: DraftSession[T],
    index: number
  ) => void
}

const AddSessionForm = (prop: AddSessionFormProp) => {
  const {
    sessions,
    serverSessions,
    templates,
    organization,
    removeSession,
    onSessionFieldChanged,
  } = prop

  const { t } = useTranslation()

  const [isEditingFeedbackFormTemplate, setIsEditingFeedbackFormTemplate] =
    useState(false)
  const [isFeedbackFormLinkCopied, setIsFeedbackFormLinkCopied] =
    useState(false)
  const [hosting, setHosting] = useState(false)

  useEffect(() => {
    // Set checkboxes for the first time
    sessions.forEach((session, index) => {
      if (
        (session.is_feedback_form_checked === null ||
          session.is_feedback_form_checked === undefined) &&
        session.survey_link
      ) {
        // session.is_feedback_form_checked = true
        onSessionFieldChanged('is_feedback_form_checked', true, index)
      }
    })
  }, [sessions])

  return (
    <div className="addSession">
      {sessions.map((session, index) => {
        return (
          <div key={index}>
            <div className="coursehead">
              <h1 id={(index + 1).toString()}>
                {t('courses.Session')} {index + 1}
              </h1>
              <CloseIcon
                onClick={() => {
                  removeSession(index)
                }}
              />
            </div>

            <div className="mb-3 mt-5">
              <label className="form-label">
                {t('courses.SessionName')}{' '}
                <span style={{ color: '#FF5050' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control addCoursesInput"
                placeholder={t('courses.Typethenamehere')}
                required
                value={session.sessionname}
                name="sessionname"
                onChange={(e) => {
                  const { value } = e.target
                  onSessionFieldChanged('sessionname', value, index)
                }}
              />
            </div>

            <div className="mb-5">
              <label className="form-label">{t('common.description')}</label>
              <Box
                sx={{
                  '.ql-toolbar.ql-snow': {
                    borderColor: '#9bc6fe',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    background: '#ffffff',
                  },
                  '.ql-header.ql-picker': {
                    zIndex: 1000,
                  },
                  '.ql-container.ql-snow': {
                    borderColor: '#9bc6fe',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    background: '#ffffff',
                  },
                  '.ql-editor': {
                    maxHeight: 250,
                    overflowY: 'auto',
                  },
                  '.ql-tooltip.ql-editing': {
                    ml: 15,
                  },
                }}
              >
                <ReactQuill
                  theme="snow"
                  value={session.description_markup}
                  onChange={(
                    _content: string,
                    _delta: Delta,
                    _source: string,
                    editor: UnprivilegedEditor
                  ) => {
                    onSessionFieldChanged(
                      'description_markup',
                      editor.getContents(),
                      index
                    )
                  }}
                  modules={editorModules}
                />
              </Box>
            </div>

            <div className="mb-3 mt-5 addCoursesDate">
              <label className="form-label">
                {t('common.start')} <span style={{ color: '#FF5050' }}></span>
              </label>
              <DateAndTimePicker
                date={session.start_date}
                time={session.start_time}
                serverTime={serverSessions?.[index]?.start_time}
                onDateChange={(date) =>
                  onSessionFieldChanged('start_date', date, index)
                }
                onTimeChange={(time) =>
                  onSessionFieldChanged('start_time', time, index)
                }
              />
            </div>

            <div className="mb-3 mt-5 addCoursesDate">
              <label className="form-label">
                {t('common.end')} <span style={{ color: '#FF5050' }}></span>
              </label>
              <DateAndTimePicker
                date={session.end_date}
                time={session.end_time}
                serverTime={serverSessions?.[index]?.end_time}
                minDate={session.start_date}
                disabled={!session.start_date || !session.start_time}
                onDateChange={(date) =>
                  onSessionFieldChanged('end_date', date, index)
                }
                onTimeChange={(time) =>
                  onSessionFieldChanged('end_time', time, index)
                }
              />
            </div>

            {templates && (
              <div className="mb-3 mt-5">
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Box sx={{ mt: 1 }}>
                    <label>{t('courses.FeedbackForm')}</label>
                  </Box>
                  <Checkbox
                    color="primary"
                    checked={session.is_feedback_form_checked || false}
                    onChange={(e, checked) => {
                      onSessionFieldChanged(
                        'is_feedback_form_checked',
                        checked,
                        index
                      )
                    }}
                  />
                </Stack>

                {(session.is_feedback_form_checked || false) && (
                  <Box>
                    {/*Select feedback form template*/}
                    <Stack
                      className="flexonCopy"
                      direction="row"
                      hidden={
                        session.survey_link !== null &&
                        session.survey_link !== undefined &&
                        session.survey_link !== '' &&
                        !isEditingFeedbackFormTemplate
                      }
                    >
                      <TemplateSelect
                        templates={templates}
                        formID={session.survey_template_id}
                        onChange={(value) => {
                          onSessionFieldChanged(
                            'survey_template_id',
                            value,
                            index
                          )
                        }}
                      />
                      <Tooltip
                        placement="bottom"
                        title={t('common.cancel')}
                        arrow
                      >
                        <button
                          hidden={!isEditingFeedbackFormTemplate}
                          className="btn"
                          type="button"
                          onClick={() => {
                            setIsEditingFeedbackFormTemplate(false)
                          }}
                        >
                          <CancelEditIcon />
                        </button>
                      </Tooltip>
                    </Stack>

                    {/*Feedback form link*/}
                    <Box
                      hidden={
                        !session.survey_link || isEditingFeedbackFormTemplate
                      }
                    >
                      <div
                        className="flexonCopy"
                        style={{ position: 'relative' }}
                      >
                        <input
                          disabled
                          className="form-control addCoursesInput"
                          list="templates"
                          id="feedback-form-input"
                          value={session.survey_link}
                          style={{
                            background: '#e9ecef',
                          }}
                          autoComplete="off"
                        />
                        <Tooltip
                          placement="bottom"
                          title={
                            isFeedbackFormLinkCopied
                              ? t('common.copied-to-clipboard-message')
                              : t('common.copy-to-clipboard')
                          }
                          arrow
                        >
                          <button
                            hidden={session.survey_link == null}
                            className="btn"
                            type="button"
                            onMouseLeave={() =>
                              setIsFeedbackFormLinkCopied(false)
                            }
                            onClick={() => {
                              var copyText = document.getElementById(
                                'feedback-form-input'
                              ) as HTMLInputElement
                              copyText.select()
                              navigator.clipboard.writeText(copyText.value)
                              setIsFeedbackFormLinkCopied(true)
                            }}
                          >
                            <FileCopyOutlinedIcon />
                          </button>
                        </Tooltip>
                        <Tooltip
                          placement="bottom"
                          title={t('common.edit')}
                          arrow
                        >
                          <button
                            hidden={session.survey_link == null}
                            className="btn"
                            type="button"
                            onClick={() => {
                              setIsEditingFeedbackFormTemplate(true)
                            }}
                          >
                            <EditIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </Box>
                  </Box>
                )}
              </div>
            )}

            <div className="mb-3 mt-4">
              <label className="form-label">
                {t('courses.Online')} <span style={{ color: '#FF5050' }}></span>
              </label>

              <input
                type="text"
                className="form-control addCoursesInput"
                placeholder={t('courses.link')}
                disabled={session.wyblo_hosting}
                id={`url${index}`}
                name="link"
                value={session.link}
                onChange={(e) => {
                  const { value } = e.target
                  onSessionFieldChanged('link', value, index)
                }}
              />
            </div>

            <div
              className={`${
                organization.isHostingEnabled ? 'mb-3' : 'mb-5'
              } mt-4`}
            >
              <label className="form-label">
                {t('courses.Inperson')}{' '}
                <span style={{ color: '#FF5050' }}></span>
              </label>
              <div className="flexonCheck" style={{ justifyContent: 'start' }}>
                <textarea
                  style={{ color: 'black' }}
                  className="form-control"
                  rows={2}
                  placeholder={t('courses.Address')}
                  id={`address${index}`}
                  name="inpersonmeetingaddress"
                  value={session.inpersonmeetingaddress}
                  onChange={(e) => {
                    const { value } = e.target
                    onSessionFieldChanged(
                      'inpersonmeetingaddress',
                      value,
                      index
                    )
                  }}
                />
              </div>
            </div>

            {organization.isHostingEnabled && (
              <>
                <div className="mb-5 mt-4">
                  <div
                    className="flexonCheck"
                    style={{ justifyContent: 'start' }}
                  >
                    <label className="checkbox-button cursorPointer">
                      {t('courses.Hosting')}
                      <ToggleButton
                        value="check"
                        selected={session.wyblo_hosting}
                        onChange={() => {
                          onSessionFieldChanged(
                            'wyblo_hosting',
                            !session.wyblo_hosting,
                            index
                          )
                          if (session.wyblo_hosting)
                            onSessionFieldChanged(
                              'link',
                              session.wyblo_link || '',
                              index
                            )
                        }}
                        sx={{
                          ml: 2,
                          width: 30,
                          height: 30,
                          '& .MuiSvgIcon-root': {
                            display: 'none',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#00A76F14',
                            borderColor: '#00A76F',
                            '& .MuiSvgIcon-root': {
                              fill: '#00A76F',
                              display: 'block',
                            },
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: '#22C55E14',
                            borderColor: '#22C55E',
                            '& .MuiSvgIcon-root': {
                              fill: '#00A76F',
                              display: 'block',
                            },
                          },
                        }}
                      >
                        <CheckIcon />
                      </ToggleButton>
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default AddSessionForm
