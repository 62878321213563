const initialstate = {
  redirect: false,
  participated_id: '',
  attendess_id: '',
};

const FilterRegistrants = (state = initialstate, action) => {
  switch (action.type) {
    case 'GET_FILTER_REGISTRANTS':
      return (state = action.payload);

    case 'CLEAR_FILTER_REGISTRANTS':
      return (state = action.payload);

    default:
      return state;
  }
};

export default FilterRegistrants;
