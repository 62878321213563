import { memo, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  Box,
  ClickAwayListener,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material'
import { FormTextFieldInputBase } from 'common/styled-component/FormTextFieldInputBase'
import { Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { blue } from '@mui/material/colors'
import theme from 'theme'
import { getPickerDate } from 'common/DateTimeUtils'
import { styled } from '@mui/system'

type FieldType = 'text' | 'email' | 'number' | 'date'

type Props = {
  name: string
  label: string
  type: FieldType
  subLabel?: string | null
  placeholder?: string
  mandatory?: boolean
  tooltipDescription?: string | null
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: 15,
    border: '1px solid #dadde9',
  },
}))

/**
 * React-hook-form wrapped component to be included in a FormProvider.
 *
 * Used to represent text or email input.
 */
const FtFormTextField = memo(
  ({
    name,
    label,
    type,
    mandatory = false,
    placeholder = '',
    subLabel = null,
    tooltipDescription = null,
  }: Props) => {
    const {
      control,
      formState: { errors },
    } = useFormContext()

    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleTooltipClose = () => {
      setTooltipOpen(false)
    }

    const handleTooltipOpen = () => {
      setTooltipOpen(true)
    }

    return (
      <>
        <InputLabel>
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{
                color: '#1976d2',
                fontSize: '1rem',
                [theme.breakpoints.down('xl')]: {
                  fontSize: '0.9rem',
                },
              }}
            >
              <span style={{ fontFamily: 'nexa' }}>{label}</span>
              <span hidden={!mandatory} style={{ color: '#FF5050' }}>
                *
              </span>
            </Typography>
            <CustomTooltip
              placement="right"
              onClose={handleTooltipClose}
              open={tooltipOpen}
              title={tooltipDescription}
            >
              <IconButton
                sx={{
                  px: 0,
                  pt: 0,
                  pb: 1,
                  visibility:
                    tooltipDescription !== null ? 'visible' : 'hidden',
                }}
                disableFocusRipple={true}
                disableRipple={true}
                onClick={handleTooltipOpen}
              >
                <HelpOutlineIcon
                  sx={{
                    width: '0.8em',
                    mt: -0.1,
                    color: blue[400],
                    ':hover': {
                      color: blue[600],
                    },
                  }}
                />
              </IconButton>
            </CustomTooltip>
          </Stack>
        </InputLabel>
        {subLabel && (
          <InputLabel sx={{ color: 'black' }}>
            <Typography sx={{ fontFamily: 'nexalight' }} variant="subtitle1">
              {subLabel}
            </Typography>
          </InputLabel>
        )}

        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <Box
                sx={{
                  width: '80%',
                }}
              >
                {type === 'date' ? (
                  <FormTextFieldInputBase
                    value={
                      field.value ? getPickerDate(field.value) : field.value
                    }
                    onChange={(e) => {
                      field.onChange(e.target.value)
                    }}
                    type={type}
                    placeholder={placeholder}
                  ></FormTextFieldInputBase>
                ) : (
                  <FormTextFieldInputBase
                    {...field}
                    type={type}
                    placeholder={placeholder}
                  ></FormTextFieldInputBase>
                )}
              </Box>
              <FormHelperText error id="form-text-field-error">
                {(errors[name]?.message as string) || <br />}
              </FormHelperText>
            </>
          )}
        />
      </>
    )
  }
)

export default FtFormTextField
