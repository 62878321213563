import { TFunction } from 'i18next'
import { string, object, SchemaOf } from 'yup'
import * as Yup from 'yup'

export type ChangePasswordsData = {
  newPassword: string
}

export const ChangePasswordValidationSchema = (t: TFunction) => {
  const schema: SchemaOf<ChangePasswordsData> = object().shape({
    newPassword: string()
      .required(t('forms.validation.required-field'))
      .min(8, t('forms.validation.password.min-characters'))
      .matches(/[a-z]+/, t('forms.validation.password.lowercase'))
      .matches(/[A-Z]+/, t('forms.validation.password.uppercase'))
      .matches(/\d+/, t('forms.validation.password.number')),
    confirmNewPassword: string()
      .required(t('forms.validation.required-field'))
      .min(8, t('forms.validation.password.min-characters'))
      .matches(/[a-z]+/, t('forms.validation.password.lowercase'))
      .matches(/[A-Z]+/, t('forms.validation.password.uppercase'))
      .matches(/\d+/, t('forms.validation.password.number'))
      .oneOf(
        [Yup.ref('newPassword')],
        t('forms.validation.confirm-password.match')
      ),
  })

  return schema
}
