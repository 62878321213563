import { useTranslation } from 'react-i18next'
import { selectDashboardReportLink } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'

import '../assets/scss/dashboard.scss'

const DashboardOld = () => {
  const { t } = useTranslation()
  const dashboardReportLink = useAppSelector(selectDashboardReportLink)

  return (
    <div className="">
      <div className="">
        <h4> {t('Dashboard')}</h4>
        {dashboardReportLink ? (
          <iframe
            title="Report"
            src={dashboardReportLink}
            width="100%"
            style={{ height: '85vh' }}
          />
        ) : (
          <h1
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ebedef',
            }}
          >
            {t('NoRecordFound')}
          </h1>
        )}
        {/*TODO:  Negotiation is temporary added and comment below code because its hard coded now */}
        {/* {isArrowClick ? (
          <div style={{ display: 'inline-flex' }}>
            <i
              style={{
                color: '#595959',
                marginLeft: '1rem',
                cursor: 'pointer',
              }}
              className="fa fa-angle-left"
              aria-hidden="true"
              onClick={() => setIsArrowClick(false)}
            ></i>
            <h5 style={{ color: '#595959', marginLeft: '1rem' }}>
              {' '}
              Negotiation
            </h5>
          </div>
        ) : (
          <h4> {t('Dashboard')}</h4>
        )} */}
        {/* <div className="dashboard-content-container">
          {!isArrowClick ? (
            <div style={{ display: 'inline-flex', marginTop: '1.5rem' }}>
              <div className="dashboard-box">
                <div className="dashboard-detail-box">
                  <div className="dashboard-align-items">
                    <div>
                      <h6> Negotiation</h6>
                      <p>( 29/05/2021)</p>
                    </div>
                    <div className="dashboard-arrow-img">
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsArrowClick(true)}
                        src={arrow}
                      />
                    </div>
                  </div>
                </div>
                <div className="dashboard-total-response">
                  {t('dashboard.totalResponses')}
                  <span> : 30</span>
                </div>
              </div>
              <div className="dashboard-box">
                <div className="dashboard-detail-box">
                  <div className="dashboard-align-items">
                    <div>
                      <h6> Interaction</h6>
                      <p>( 29/05/2021)</p>
                    </div>
                    <div className="dashboard-arrow-img">
                      <img style={{ cursor: 'pointer' }} src={arrow} />
                    </div>
                  </div>
                </div>
                <div className="dashboard-total-response">
                  {t('dashboard.totalResponses')}
                  <span> : 30</span>
                </div>
              </div>
            </div>
          ) : (
            <DashboardDetail />
          )}
        </div> */}
      </div>
    </div>
  )
}

export default DashboardOld
