import { useState, useEffect, useCallback, memo } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import useApi from '../../../api/UseApi'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { CircularProgress, FormHelperText, Popover } from '@mui/material'
import { Box, Typography } from '@mui/material'
import { Tag } from 'Component/Mail/NotificationServiceTypes'
import { StyledButton } from 'common/styled-component/StyledButton'
import AddIcon from '@mui/icons-material/Add'
import { tagRegExp } from 'common/RegularExpressions'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'

type Props = {
  onTagCreated: () => void
}

const CreateTagPopup = (props: Props) => {
  const { onTagCreated } = props

  const { t } = useTranslation()
  const api = useApi(false)

  const [tagName, setTagName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)

  const organization = useAppSelector(selectCurrentOrganization)

  const createTag = () => {
    setIsLoading(true)

    try {
      api
        .post(`tags`, {
          organizationUuid: organization?.notificationId,
          name: tagName,
          subscribers: [],
        })
        .then(
          (response) => {
            toast.success(t('mail.tag-created'), { theme: 'colored' })
            onPopupClose()
            setIsLoading(false)
            onTagCreated()
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
            toast.error(error.response.data.message, { theme: 'colored' })
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onCreateButtonClick = (currentTarget: any) => {
    setAnchorEl(currentTarget)
  }

  const onPopupClose = () => {
    setAnchorEl(null)
    setTagName('')
  }

  return (
    <Box>
      <StyledButton
        aria-describedby="create-tag-popper"
        sx={{
          backgroundColor: '#0d6efd !important',
        }}
        endIcon={<AddIcon />}
        onClick={(event) => {
          onCreateButtonClick(event.currentTarget)
        }}
      >
        <Typography sx={{ mt: 0.5 }}>{t('mail.new-tag')}</Typography>
      </StyledButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={onPopupClose}>
          <Stack
            direction="row"
            spacing={3}
            sx={{
              backgroundColor: 'white',
              pt: 3,
              px: 2,
              pb: 0.5,
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Stack>
                <TextField
                  value={tagName}
                  onChange={(e) => setTagName(e.target.value)}
                  autoFocus
                  inputProps={{
                    sx: {
                      p: 1,
                    },
                  }}
                />
                {tagName && !tagRegExp.test(tagName) ? (
                  <FormHelperText
                    sx={{ pl: 0.1 }}
                    error
                    id="tag-validation-error"
                  >
                    {t('mail.invalid-tag')}
                  </FormHelperText>
                ) : (
                  <br />
                )}
              </Stack>
            </Box>

            <CircularProgress
              hidden={!isLoading}
              style={{ height: '2rem', width: '2rem' }}
            />

            <Button
              variant="contained"
              hidden={isLoading}
              disableElevation
              disabled={!tagName || !tagRegExp.test(tagName)}
              sx={{ height: 42, px: 3 }}
              onClick={() => {
                createTag()
              }}
            >
              {t('common.create')}
            </Button>
          </Stack>
        </ClickAwayListener>
      </Popover>
    </Box>
  )
}

export default memo(CreateTagPopup)
