import ReactTimeAgo from 'react-time-ago'
import i18next from 'i18next'
import { localizedUTCMoment } from 'common/DateTimeUtils'
import { useEffect, useState } from 'react'

type Props = {
  startDate: null | string
  endDate: null | string
}

const TimeAgo = ({ startDate, endDate }: Props) => {
  const [timeAgoDateString, setTimeAgoDateString] = useState<null | string>(
    null
  )

  useEffect(() => {
    let localizedDateString = null
    if (startDate) {
      const now = localizedUTCMoment(undefined)
      const utcStartDate = localizedUTCMoment(startDate)
      const utcEndDate = localizedUTCMoment(endDate)

      if (endDate && now > utcEndDate) {
        localizedDateString = utcEndDate.format('YYYY-MM-DD[T]HH:mm:ssZ')
      } else if (!endDate || now < utcStartDate) {
        localizedDateString = utcStartDate.format('YYYY-MM-DD[T]HH:mm:ssZ')
      }
    }

    setTimeAgoDateString(localizedDateString)
  }, [startDate, endDate])

  return timeAgoDateString ? (
    <ReactTimeAgo
      tooltip={false}
      // @ts-ignore: Type Check expects a date, but the string actually works fine
      date={timeAgoDateString}
      locale={i18next.language}
    />
  ) : (
    <></>
  )
}

export default TimeAgo
