import { Box, Typography } from '@mui/material'
import useApi from 'api/UseApi'
import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { CourseDesignsTable } from './CourseDesignsTable'
import { CourseDesign } from './Types'
import {
  designFetched,
  selectShouldFetchDesign,
} from 'Reducers/CourseDesignReducer'
import { useDispatch } from 'react-redux'

export function CourseDesignList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const api = useApi(false)
  const [courseDesigns, setCourseDesigns] = useState<CourseDesign[]>([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const shouldFetchDesigns = useAppSelector(selectShouldFetchDesign)

  const organization = useAppSelector(selectCurrentOrganization)

  const getCourseDesigns = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api
          .get('course_designs.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              setCourseDesigns(response.data)
              setIsLoaderVisible(false)
              dispatch(designFetched())
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        setIsLoaderVisible(false)
        console.log(e.message)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getCourseDesigns()
  }, [getCourseDesigns, shouldFetchDesigns])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        <Typography variant="h5">{t('common.design')}</Typography>

        <CourseDesignsTable
          items={courseDesigns}
          onItemDeleted={() => {
            getCourseDesigns()
          }}
        />
      </Box>
    </Box>
  )
}
