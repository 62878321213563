import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Survey } from 'Component/CourseRegistration/Models'
import { useHistory } from 'react-router-dom'

interface TableSurveyProp {
  surveys: Survey[]
  page: number
  rowsPerPage: number
  handleClick: (value: any) => void
  setSelectedSurvey: (value: Survey) => void
}

interface HeadCell {
  id: string
  label: string
}

type Order = 'asc' | 'desc'

const TableSurvey = ({
  surveys,
  page,
  rowsPerPage,
  handleClick,
  setSelectedSurvey,
}: TableSurveyProp) => {
  const [headCells, setHeadCells] = useState<HeadCell[]>([])
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')

  const { t } = useTranslation()
  const history = useHistory()

  function stringComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => stringComparator(a, b, orderBy)
      : (a: any, b: any) => -stringComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler =
    (cellId: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, cellId)
    }

  useEffect(() => {
    setHeadCells([
      {
        id: 'name',
        label: t('settings.Name'),
      },
      {
        id: 'description',
        label: t('common.description'),
      },
    ])
  }, [t])

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 800, tableLayout: 'auto' }}
          aria-label="Campaigns table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{ fontSize: '18px' }}
                  key={headCell.id}
                  padding={'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(surveys, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((survey, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index}>
                    <TableCell sx={{ minWidth: 200 }}>
                      <Link
                        sx={{ cursor: 'pointer' }}
                        underline="none"
                        onClick={() => {
                          history.push(`/Survey/Result/${survey.external_id}`, {
                            surveyTitle: survey.name,
                          })
                        }}
                      >
                        {survey.name}
                      </Link>{' '}
                    </TableCell>
                    <TableCell sx={{ color: '#5e5e5e' }}>
                      {survey.description}
                    </TableCell>
                    <TableCell sx={{ color: '#5e5e5e' }} align="right">
                      <MoreVertIcon
                        className="cursorPointer"
                        onClick={(e) => {
                          handleClick(e.currentTarget)
                          setSelectedSurvey(survey)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TableSurvey
