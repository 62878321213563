import { phoneRegExp, urlRegExp } from 'common/RegularExpressions'
import { TFunction } from 'i18next'
import { string, object, SchemaOf } from 'yup'

export type CampaignFormData = {
  title: string
  subject: string
}

export const CampaignFormValidationSchema = (t: TFunction) => {
  const schema: SchemaOf<CampaignFormData> = object().shape({
    title: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.title')),
    subject: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.subject')),
  })

  return schema
}
