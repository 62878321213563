// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// @mui
import { Box, IconButton, InputAdornment, Stack } from '@mui/material'
import { StyledButton } from 'common/styled-component/new-template/StyledButton'
import { StyledCard } from 'common/styled-component/new-template/StyledCard'
//icon
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import InfoIcon from '@mui/icons-material/Info'
//react-hook-form
import FormProviderCustom from 'Component/ReactHookFormComponents/FormProviderCustom'
import RHFTextField from 'Component/ReactHookFormComponents/RHFTextField'
//hook
import { ChangePasswordValidationSchema } from 'common/form-validation/ChangePasswordValidationSchema'
import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'
import { objectIsEmpty } from 'utils/utils'
import { useAppSelector } from 'Store'

import useApi from 'api/UseApi'
import { useHistory } from 'react-router-dom'
import Loader from 'Component/Loader'

import {
  selectDashboardReportLink,
  selectFirstName,
  selectCurrentOrganization,
} from 'Reducers/UserConfigReducer'
import { toast } from 'react-toastify'

export type FormValuesProps = {
  newPassword: string
  confirmNewPassword: string
}

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  const organization = useAppSelector(selectCurrentOrganization)
  const userFirstName = useAppSelector(selectFirstName)
  const dashboardReportLink = useAppSelector(selectDashboardReportLink)

  const { t } = useTranslation()

  const characters = t('changePassword.characters')
  const smallLetters = t('changePassword.small-letters')
  const capitalLetters = t('changePassword.capital-letters')
  const number = t('changePassword.number')

  const api = useApi(false)
  let history = useHistory()

  const defaultValues = {
    newPassword: '',
    confirmNewPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(ChangePasswordValidationSchema(t)),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    setIsLoaderVisible(true)
    try {
      api
        .patch('users/me/change_password', {
          password: data.newPassword,
        })
        .then(
          (response) => {
            setIsLoaderVisible(false)
            toast.success(response.data.message, { theme: 'colored' })
            setTimeout(() => {
              if (dashboardReportLink) {
                history.push('/Dashboard')
              } else {
                history.push('/Courses')
              }
            }, 1000)
          },
          (error) => {
            console.log(error)
            setIsLoaderVisible(false)
            toast.error(error.response.data.message, { theme: 'colored' })
          }
        )
    } catch (error) {
      console.log(error)
      setIsLoaderVisible(false)
    }
  }
  return (
    <Box
      className="ChangePassword"
      style={{
        position: 'absolute',
        zIndex: '9999',
        width: '100%',
        background: '#f7f9fb',
      }}
    >
      <Box className="bg-white mainContainer view_port">
        <Box className="mb-2">
          <h4 className="mb-0 mt-1" style={{ color: '#808080' }}>
            {t('changePassword.Welcome')},
            <span style={{ color: '#157AFF' }}>
              {' '}
              {userFirstName}
              {userFirstName && '!'}
            </span>
          </h4>
        </Box>

        <hr />
        <br />

        <Box className="info">
          <p>
            {t('changePassword.invitationPart1')} {organization?.name}
            .&nbsp;
          </p>
          <p>
            <br />
            {t('changePassword.invitationPart2')}{' '}
            <span style={{ color: '#157AFF' }}>
              {t('changePassword.ChangeyourPassword')}!
            </span>
          </p>
          <p>
            <br />
            <Trans i18nKey="changePassword.invitationPart3">
              {'placeholder '}
              <strong className="item-span">
                <>{{ characters, smallLetters, capitalLetters, number }}</>
              </strong>

              {' placeholder'}
            </Trans>
          </p>
        </Box>
        <FormProviderCustom methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <StyledCard>
            <Stack spacing={3} alignItems="flex-end" sx={{ p: 3 }}>
              <RHFTextField
                name="newPassword"
                type={showPassword ? 'text' : 'password'}
                label={t('changePassword.NewPassword')}
                disabled={isSubmitting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <Stack
                    component="span"
                    direction="row"
                    alignItems="center"
                    sx={{
                      alignItems: 'start',
                      mt: '0.5rem',
                      color: 'rgb(99, 115, 129)',
                    }}
                  >
                    <InfoIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
                    {t('user-account.info-password')}
                  </Stack>
                }
              />

              <RHFTextField
                name="confirmNewPassword"
                type={showPassword ? 'text' : 'password'}
                label={t('changePassword.confirmPassword')}
                disabled={isSubmitting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <StyledButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting || objectIsEmpty(errors)}
              >
                {t('common.save-changes')}
              </StyledButton>
            </Stack>
          </StyledCard>
        </FormProviderCustom>
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </Box>
  )
}

export default ChangePassword
