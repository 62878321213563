import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

export type Organization = {
  id: number
  auth0OrgId: string
  email: string
  name: string
  logoUrl: string
  userRoleID: string
  userRoleName: string
  userPermissions: any[]
  isHostingEnabled: boolean
  stripeAccountId: string
  notificationId: null | string
  lstpermissions: {
    permission_name: string
    resource_server_identifier: string
  }[]
}

interface UserConfigState {
  currentOrganization?: null | Organization
  // Org ID => Organization
  organizations: { [key: number]: Organization }
  currentOrganizationStripeAccount: null | { [key: string]: any }

  userId: number | null
  firstName: string
  lastName: string
  email: string
  mobile: string
  jobPosition: string
  facebook: string | null
  linkedin: string | null
  twitter: string | null
  instagram: string | null
  language: string
  timezone: string
  avatarUrl: string
  birthDate: string
  dashboardReportLink: string | null
  shouldChangePassword: boolean
  shouldCompleteProfile: boolean
}

const initialState: UserConfigState = {
  currentOrganization: null,
  currentOrganizationStripeAccount: null,
  organizations: {},
  userId: null,
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  jobPosition: '',
  facebook: null,
  linkedin: null,
  twitter: null,
  instagram: null,
  language: 'en',
  timezone: '',
  avatarUrl: '',
  birthDate: '',
  dashboardReportLink: null,
  shouldChangePassword: false,
  shouldCompleteProfile: false,
}

export const UserConfigSlice = createSlice({
  name: 'user-config',
  initialState,
  reducers: {
    setCurrentOrganization: (state, action: PayloadAction<Organization>) => {
      state.currentOrganization = action.payload
    },
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      action.payload.forEach((organization) => {
        state.organizations[organization.id] = organization
      })
    },
    setCurrentOrganizationStripeAccount: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.currentOrganizationStripeAccount = action.payload
    },
    onCompanyLogoUpdated: (state, action: PayloadAction<string>) => {
      const previousOrganization = state.currentOrganization
      if (previousOrganization) {
        state.currentOrganization = {
          ...previousOrganization,
          logoUrl: action.payload,
        }
      }
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setMobile: (state, action: PayloadAction<string>) => {
      state.mobile = action.payload
    },
    setJobPosition: (state, action: PayloadAction<string>) => {
      state.jobPosition = action.payload
    },
    setFacebook: (state, action: PayloadAction<string | null>) => {
      state.facebook = action.payload
    },
    setLinkedin: (state, action: PayloadAction<string | null>) => {
      state.linkedin = action.payload
    },
    setTwitter: (state, action: PayloadAction<string | null>) => {
      state.twitter = action.payload
    },
    setInstagram: (state, action: PayloadAction<string | null>) => {
      state.instagram = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload
    },
    setAvatarUrl: (state, action: PayloadAction<string>) => {
      state.avatarUrl = action.payload
    },
    setBirthDate: (state, action: PayloadAction<string>) => {
      state.birthDate = action.payload
    },
    setDashboardReportLink: (state, action: PayloadAction<string>) => {
      state.dashboardReportLink = action.payload
    },
    setShouldChangePassword: (state, action: PayloadAction<boolean>) => {
      state.shouldChangePassword = action.payload
    },
    setShouldCompleteProfile: (state, action: PayloadAction<boolean>) => {
      state.shouldCompleteProfile = action.payload
    },
  },
})

// SELECTORS (GET)
export const selectCurrentOrganization = (state: RootState) =>
  state.UserConfig.currentOrganization
export const selectOrganizations = (state: RootState) =>
  state.UserConfig.organizations
export const selectCurrentOrganizationStripeAccount = (state: RootState) =>
  state.UserConfig.currentOrganizationStripeAccount
export const selectUserId = (state: RootState) => state.UserConfig.userId
export const selectFirstName = (state: RootState) => state.UserConfig.firstName
export const selectLastName = (state: RootState) => state.UserConfig.lastName
export const selectBirthDate = (state: RootState) => state.UserConfig.birthDate
export const selectEmail = (state: RootState) => state.UserConfig.email
export const selectFacebook = (state: RootState) => state.UserConfig.facebook
export const selectLinkedin = (state: RootState) => state.UserConfig.linkedin
export const selectTwitter = (state: RootState) => state.UserConfig.twitter
export const selectInstagram = (state: RootState) => state.UserConfig.instagram
export const selectMobile = (state: RootState) => state.UserConfig.mobile

export const selectShouldChangePassword = (state: RootState) =>
  state.UserConfig.shouldChangePassword
export const selectShouldCompleteProfile = (state: RootState) =>
  state.UserConfig.shouldCompleteProfile
export const selectDashboardReportLink = (state: RootState) =>
  state.UserConfig.dashboardReportLink
export const selectLanguage = (state: RootState) => state.UserConfig.language
export const selectTimezone = (state: RootState) => state.UserConfig.timezone
export const selectAvatarUrl = (state: RootState) => state.UserConfig.avatarUrl
export const selectJobPosition = (state: RootState) =>
  state.UserConfig.jobPosition

export const {
  setOrganizations,
  setCurrentOrganization,
  setUserId,
  setFirstName,
  setLastName,
  setLanguage,
  setTimezone,
  setAvatarUrl,
  setEmail,
  setMobile,
  setFacebook,
  setTwitter,
  setLinkedin,
  setInstagram,
  setJobPosition,
  setCurrentOrganizationStripeAccount,
  setBirthDate,
  setDashboardReportLink,
  setShouldChangePassword,
  setShouldCompleteProfile,
  onCompanyLogoUpdated,
} = UserConfigSlice.actions

export default UserConfigSlice.reducer
