import { useAuth0 } from '@auth0/auth0-react'
import { RouteParams } from 'Component/CourseRegistration/Models'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import OrganizationsService from 'Service/OrganizationsService'

const OrganizationLogin = () => {
  const { slug } = useParams<RouteParams>() /* 'org_AdQ9Z6SiPHyNtEnP' */

  const { loginWithRedirect } = useAuth0()

  const { getOrganizationId } = OrganizationsService()

  const getCustomLogin = async () => {
    if (slug) {
      const orgID = await getOrganizationId(slug)
      loginWithRedirect({ organization: orgID })
    }
  }

  useEffect(() => {
    getCustomLogin()
  }, [slug])

  return null
}

export default OrganizationLogin
