import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useApi from 'api/UseApi'

import { useEffect, useState, useCallback } from 'react'
import { SubscribersTable } from 'Component/Mail/Subscribers/SubscribersTable'
import { Subscriber } from 'Component/Mail/NotificationServiceTypes'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import Loader from 'Component/Loader'

export function Subscribers() {
  const { t } = useTranslation()
  const api = useApi(false)
  const [subscribers, setSubscribers] = useState<Subscriber[]>([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const getSubscribers = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api
          .get(`subscribers`, {
            params: {
              organizationUuid: organization.notificationId,
            },
          })
          .then(
            (response) => {
              setSubscribers(response.data.data)
              setIsLoaderVisible(false)
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        console.log(e.message)
        setIsLoaderVisible(false)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getSubscribers()
  }, [getSubscribers])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        {' '}
        <Typography variant="h5" sx={{ mt: 5, mb: 3 }}>
          {t('mail.subscribers')}
        </Typography>
        <SubscribersTable
          onTagsUpdated={() => {
            getSubscribers()
          }}
          subscribers={subscribers}
        />
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </Box>
  )
}
