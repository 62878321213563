import { getTimezone, localizedUTCMoment } from 'common/DateTimeUtils'
import moment from 'moment'
import i18next from 'i18next'

/**
 * Turn date/time string into picker's date and time
 * date YYYY-MM-DD
 * time HH:mm
 */
export const convertDateTimeStringToDateTimePickerModel = (
  dateUTC: string | null
): { date: string | undefined; time: string | undefined } => {
  const localizedTime = dateUTC
    ? localizedUTCMoment(dateUTC).format(
        i18next.language === 'it' ? 'HH:mm' : 'hh:mm A'
      )
    : undefined
  const stringDate = dateUTC
    ? moment(dateUTC).tz(getTimezone()).format('YYYY-MM-DD')
    : undefined
  return {
    date: stringDate,
    time: localizedTime,
  }
}

/**
 * Turn picker's date and time into date/time string
 */
export const convertDateTimePickerModelToDateTimeString = (dateAndTime: {
  date: string
  time: string
}): string => {
  const { date, time } = dateAndTime
  const timeFormatted = moment(time, 'HH:mm A').format('HH:mm')
  return `${new Date(date)?.toISOString().split('T')[0]}T${timeFormatted}:00`
}

/**
 * Turn picker's date and time into date/time string
 * if date and time are optional
 */
export const convertDateTimePickerModelToDateTimeStringOptional =
  (dateAndTime: {
    date: string | undefined
    time: string | undefined
  }): string => {
    const { date, time } = dateAndTime
    const timeFormatted = moment(time, 'HH:mm A').format('HH:mm')
    return date
      ? `${new Date(date)?.toISOString().split('T')[0]}T${timeFormatted}:00`
      : ''
  }
