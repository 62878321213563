import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CourseDesignForm } from './CourseDesignForm'

export function NewCourseDesign() {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20, mr: 5 }} className="">
      <Stack className="">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('course-design.new')}
        </Typography>

        <CourseDesignForm isNewDesign={true} />
      </Stack>
    </Box>
  )
}
