import { JSONValue } from 'common/types/Json'
import { useEffect } from 'react'
import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'
import { SurveyCreator as SurveyJSCreator } from 'survey-creator-react'
import i18next from 'i18next'
import showdown from 'showdown'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import 'survey-creator-core/survey-creator-core.i18n'
import { localization as surveyCreatorLocalization } from 'survey-creator-core'
import 'survey-core/survey.i18n'
import { surveyLocalization } from 'survey-core'
import { Box } from '@mui/material'

// Restrict languages shown in editor dropdowns
surveyLocalization.supportedLocales = ['en', 'it']

type Props = {
  onSurveySaved(surveyBody: JSONValue): void
  surveyJSON?: JSONValue
  isPreview: boolean
  isMarkdownEnabled: boolean
}

const SurveyCreator = ({
  onSurveySaved,
  surveyJSON: preloadedValue,
  isPreview,
  isMarkdownEnabled,
}: Props) => {
  const organization = useAppSelector(selectCurrentOrganization)

  useEffect(() => {
    let options = {
      questionTypes: [
        'boolean',
        'checkbox',
        'comment',
        'dropdown',
        'expression',
        'file',
        'html',
        'image',
        'imagepicker',
        'matrix',
        'matrixdropdown',
        //'matrixdynamic',
        'multipletext',
        // 'panel',
        //'paneldynamic',
        'radiogroup',
        'rating',
        'ranking',
        // 'signaturepad',
        'text',
      ],
      haveCommercialLicense: true,
      showJSONEditorTab:
        organization?.userPermissions.includes('view:survey-json'),
      showDesignerTab: !isPreview,
      showTranslationTab: !isPreview,
    }

    const surveyCreator = new SurveyJSCreator(options)
    surveyCreator.onShowingProperty.add(function (sender, options) {
      if (options.obj.getType() === 'survey') {
        // 1) Hide "redirect on complete" option
        // It's risky since it doesn't wait for submission to be sent
        // 2) Don't show 'name' property for fields in editor
        // Editing this property can lead to various bugs, e.g. incorrect translations
        options.canShow =
          options.property.name !== 'navigateToUrl' &&
          options.property.name !== 'name'
      }
    })

    surveyCreator.JSON = preloadedValue
      ? preloadedValue
      : {
          locale: i18next.language,
          completedHtml: {
            default: '<h3>Thank you for completing the survey!</h3>',
            it: '<h3>Grazie per aver completato il survey!</h3>',
          },
        }

    // Markdown
    // Create showdown markdown converter
    var converter = new showdown.Converter()
    function doMarkdown(survey: any, options: any) {
      if (isMarkdownEnabled) {
        // convert the markdown text to html
        var str = converter.makeHtml(options.text)
        if (str.indexOf('<p>') === 0) {
          // remove root paragraphs <p></p>
          str = str.substring(3)
          str = str.substring(0, str.length - 4)
        }
        // set html
        options.html = str
      }
    }
    surveyCreator.survey.onTextMarkdown.add(doMarkdown)
    surveyCreator.onDesignerSurveyCreated.add(function (editor, options) {
      options.survey.onTextMarkdown.add(doMarkdown)
    })
    surveyCreator.onTestSurveyCreated.add(function (editor, options) {
      options.survey.onTextMarkdown.add(doMarkdown)
    })

    // Change active tab if it's a preview
    if (isPreview) {
      surveyCreator.makeNewViewActive('test')
    }

    //Automatically save survey definition on changing.
    surveyCreator.isAutoSave = true
    surveyCreator.autoSaveDelay = 0

    surveyCreatorLocalization.currentLocale = i18next.language

    surveyCreator.saveSurveyFunc = function (saveNo: any, callback: any) {
      onSurveySaved(surveyCreator.JSON)
      callback(saveNo, true)
    }
    surveyCreator.render('surveyCreatorContainer')
  }, [isMarkdownEnabled])

  return (
    <div>
      <Box sx={{ height: 1000 }} id="surveyCreatorContainer" />
    </div>
  )
}

export default SurveyCreator
