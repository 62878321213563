import {
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback } from 'react'
import { StyledButton } from 'common/styled-component/StyledButton'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import useApi from 'api/UseApi'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useHistory } from 'react-router-dom'
import { SessionDesign } from '../Types'
import Loader from 'Component/Loader'
import FormTextField from 'Component/form-fields/FormTextField'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  SessionDesignFormData,
  SessionDesignFormValidation,
} from 'common/form-validation/SessionDesignFormValidation'
import TemplatesService from 'Service/TemplatesService'
import { FormTextFieldInputBase } from 'common/styled-component/FormTextFieldInputBase'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Delta } from 'quill'
import { editorModules } from 'Component/EditorDescrition/ReactQuillEditor'

type Props = {
  courseDesignId: string
  currentDesign?: SessionDesign
  isNewDesign?: boolean
  onDesignUpdated?(design: SessionDesign): void
}

export function SessionDesignForm(props: Props) {
  const {
    courseDesignId,
    currentDesign = null,
    isNewDesign = false,
    onDesignUpdated,
  } = props

  const history = useHistory()

  const { t } = useTranslation()
  const api = useApi(false)

  const organization = useAppSelector(selectCurrentOrganization)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formMethods = useForm<SessionDesignFormData>({
    resolver: yupResolver(SessionDesignFormValidation(t)),
  })
  const [surveyTemplates, setTemplates] = useState<any>([])

  const [feedbackFormTemplateId, setFeedbackFormTemplateId] = useState<
    string | null
  >(null)

  const { getTemplates } = TemplatesService()

  const initialDelta: any = { ops: [] }
  const [editorContent, setEditorContent] = useState<Delta>(initialDelta)

  const handleEditorChange = (
    _content: string,
    _delta: Delta,
    _source: string,
    editor: UnprivilegedEditor
  ) => {
    setEditorContent(editor.getContents())
  }

  const getSurveyTemplates = useCallback(async (organizationId: number) => {
    try {
      setIsLoading(true)
      const templates = await getTemplates(organizationId)
      setIsLoading(false)

      if (templates) setTemplates(templates)
    } catch (e: any) {
      console.log(e.message)
    }
  }, [])

  useEffect(() => {
    if (organization) {
      getSurveyTemplates(organization.id)
    }
  }, [getSurveyTemplates, organization])

  const updateDesign = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api
        .put(
          `course_designs/${courseDesignId}/session_designs/${currentDesign?.uuid}`,
          data
        )
        .then(
          (response) => {
            toast.success(t('course-design.session-design-updated'), {
              theme: 'colored',
            })
            if (onDesignUpdated) {
              onDesignUpdated(response.data.session)
            }

            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
    }
  }
  const createDesign = (data: Record<string, any>) => {
    setIsLoading(true)

    try {
      api.post(`course_designs/${courseDesignId}/session_designs`, data).then(
        (response) => {
          toast.success(t('course-design.session-design-created'), {
            theme: 'colored',
          })
          setIsLoading(false)
          history.goBack()
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
        }
      )
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const onSubmit = (data: SessionDesignFormData) => {
    const params: Record<string, any> = {}

    params['name'] = data.name
    if (data.description) {
      params['description'] = data.description
    }

    if (data.address) {
      params['address'] = data.address
    }

    if (data.meeting_link) {
      params['meeting_link'] = data.meeting_link
    }

    params['description_markup'] = editorContent

    if (feedbackFormTemplateId) {
      params['feedback_form_template_external_id'] = feedbackFormTemplateId
    }

    if (isNewDesign) {
      createDesign(params)
    } else {
      updateDesign(params)
    }
  }

  useEffect(() => {
    if (currentDesign) {
      setEditorContent(currentDesign.description_markup)
      setFeedbackFormTemplateId(
        currentDesign.feedback_form_template_external_id
      )
      formMethods.setValue('name', currentDesign.name)
      formMethods.setValue('description', currentDesign.description)
      formMethods.setValue('address', currentDesign.address)
      formMethods.setValue('meeting_link', currentDesign.meeting_link)
    }
  }, [formMethods, currentDesign])

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            formMethods.handleSubmit(onSubmit)(e)
          }}
        >
          <Stack
            mt={4}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Stack>
              <FormTextField
                name="name"
                type="text"
                label={t('common.title')}
                mandatory={true}
              />
              <FormTextField
                name="description"
                type="text"
                label={t('common.description')}
              />
              <InputLabel sx={{ ml: 0.5, color: '#1976d2' }}>
                <Typography sx={{ fontFamily: 'nexa' }} variant="h6">
                  {t('course-design.markup-description')}{' '}
                </Typography>
              </InputLabel>
              <Box
                sx={{
                  height: '100%',
                  maxHeight: 300,
                  '.ql-toolbar.ql-snow': {
                    borderColor: '#9bc6fe',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    background: '#ffffff',
                  },
                  '.ql-header.ql-picker': {
                    zIndex: 1000,
                  },
                  '.ql-container.ql-snow': {
                    borderColor: '#9bc6fe',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    background: '#ffffff',
                  },
                  '.ql-editor': {
                    maxHeight: 250,
                    overflowY: 'auto',
                  },
                }}
              >
                <ReactQuill
                  theme="snow"
                  value={editorContent}
                  onChange={handleEditorChange}
                  modules={editorModules}
                />
              </Box>
              <Box sx={{ mt: 2 }} />
              <FormTextField
                name="address"
                type="text"
                label={t('courses.Address')}
              />
              <Box sx={{ mt: 2 }} />
              <FormTextField
                name="meeting_link"
                type="text"
                label={t('common.meeting-link')}
              />
              <InputLabel sx={{ mt: 2, ml: 0.5, color: '#1976d2' }}>
                <Typography sx={{ fontFamily: 'nexa' }} variant="h6">
                  {t('courses.FeedbackForm')}{' '}
                </Typography>
              </InputLabel>
              <FormControl sx={{ ml: 0.5, width: 400 }}>
                <Select
                  labelId="template-select-label"
                  id="template-select"
                  value={feedbackFormTemplateId || ''}
                  label={null}
                  onChange={(e) => {
                    setFeedbackFormTemplateId(e.target.value)
                  }}
                  input={<FormTextFieldInputBase placeholder="template" />}
                >
                  {surveyTemplates.map((template: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={template.external_id || template._id}
                    >
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <StyledButton
              type="submit"
              startIcon={<SaveIcon />}
              hidden={isLoading}
              sx={{
                backgroundColor: '#0d6efd !important',
              }}
            >
              <Typography sx={{ mt: 0.5 }}>
                {isNewDesign ? t('common.create') : t('common.update')}
              </Typography>
            </StyledButton>
            <Loader isVisible={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}
