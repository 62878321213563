import DescriptionMarkupEditor from 'Component/EditorDescrition/EditorDescription'
import { editorModules } from 'Component/EditorDescrition/ReactQuillEditor'
import { courseEdited } from 'Reducers/CourseDetailReducer'
import useApi from 'api/UseApi'
import { EditorState, convertFromRaw } from 'draft-js'
import { Delta } from 'quill'
import { ChangeEvent, useCallback, useState } from 'react'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { DraftSession } from './course/AddSession/AddSession'
import SessionsService from 'Service/SessionsService'
import {
  convertDraftSessionsToSessionModels,
  convertSessionModelsToDraftSessions,
} from 'utils/type-conversions/session'
import { getUTCDateTime } from 'common/DateTimeUtils'
import { useTranslation } from 'react-i18next'

type ApiData = {
  id?: number
  auth0_org_id?: string
}

const DescriptionMigration = () => {
  const { t } = useTranslation()
  const [apiData, setApiData] = useState<ApiData>({})
  const [isLoading, setIsLoading] = useState(false)
  const api = useApi(false)
  const dispatch = useDispatch()

  const [selectedType, setSelectedType] = useState('course')
  const [inputValue, setInputValue] = useState('')
  const [courseInputValue, setCourseInputValue] = useState('')

  const [descriptionMarkupEditorState, setDescriptionMarkupEditorState] =
    useState(() => EditorState.createEmpty())

  const setEditorState = (editorState: EditorState) => {
    setDescriptionMarkupEditorState(editorState)
  }

  const initialDelta: any = { ops: [] }
  const [editorContent, setEditorContent] = useState<Delta>(initialDelta)

  const [sessions, setSessions] = useState<DraftSession[]>([])
  const [session, setSession] = useState()
  const { putSession } = SessionsService()

  const [organization, setOrganization] = useState<any>()

  const handleEditorChange = (
    _content: string,
    _delta: Delta,
    _source: string,
    editor: UnprivilegedEditor
  ) => {
    setEditorContent(editor.getContents())
  }

  const handleTypeChange = (selectedValue: string) => {
    setSelectedType(selectedValue)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }
  const handleCourseInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCourseInputValue(event.target.value)
  }

  const getApiData = useCallback(async () => {
    switch (selectedType) {
      case 'course':
        setIsLoading(true)
        try {
          api.get(`courses/${inputValue}`).then(
            (response) => {
              setDescriptionMarkupEditorState(
                EditorState.createWithContent(
                  convertFromRaw(response.data.courses[0].description_markup)
                )
              )
            },
            (error) => {
              console.log(error)
            }
          )
          setIsLoading(false)
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
        break
      case 'session':
        setIsLoading(true)
        try {
          api.get(`courses/${courseInputValue}`).then(
            (response) => {
              const course = response.data.courses[0]
              course.meetings.map((session: any) => {
                if (session.id == inputValue) {
                  setSessions(
                    convertSessionModelsToDraftSessions([{ ...session }])
                  )
                  setSession(session)
                  setDescriptionMarkupEditorState(
                    EditorState.createWithContent(
                      convertFromRaw(session.description_markup)
                    )
                  )
                }
              })
            },
            (error) => {
              console.log(error)
            }
          )
          setIsLoading(false)
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
        break
      case 'courseDesign':
        setIsLoading(true)
        try {
          api.get(`course_designs/${inputValue}`).then(
            (response) => {
              setIsLoading(false)
              setDescriptionMarkupEditorState(
                EditorState.createWithContent(
                  convertFromRaw(response.data.description_markup)
                )
              )
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
        break
      case 'sessionDesign':
        setIsLoading(true)
        try {
          api
            .get(
              `course_designs/${courseInputValue}/session_designs/${inputValue}`
            )
            .then(
              (response) => {
                setDescriptionMarkupEditorState(
                  EditorState.createWithContent(
                    convertFromRaw(response.data.description_markup)
                  )
                )
                setIsLoading(false)
              },
              (error) => {
                console.log(error)
                setIsLoading(false)
              }
            )
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
        break
      case 'organization':
        setIsLoading(true)
        try {
          api.get(`organizations/${inputValue}`).then(
            (response) => {
              setOrganization(response.data.organization)
              setDescriptionMarkupEditorState(
                EditorState.createWithContent(
                  convertFromRaw(response.data.organization.description_markup)
                )
              )
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
    }
  }, [api, inputValue])

  const updateApiData = async () => {
    switch (selectedType) {
      case 'course':
        const courseUpdateRequestParams = { description_markup: editorContent }
        try {
          api.put(`courses/${inputValue}`, courseUpdateRequestParams).then(
            (response) => {
              dispatch(courseEdited())
              toast.success(response.data.message, { theme: 'colored' })
            },
            (error) => {
              console.log(error)
              toast.error(error.response.data.message, { theme: 'colored' })
            }
          )
        } catch (e: any) {
          console.log(e.message)
        }
        break
      case 'session':
        if (sessions) {
          const sessionModels = convertDraftSessionsToSessionModels(sessions)
          let newSessions = sessionModels.map((sessionModel) => {
            const { start_date, end_date } = sessionModel
            return {
              ...sessionModel,
              description_markup: editorContent,
              start_date: start_date ? getUTCDateTime(start_date) : null,
              end_date: end_date ? getUTCDateTime(end_date) : null,
            }
          })
          try {
            if (session) {
              const updatedCourse = await putSession(session, newSessions)

              if (updatedCourse) {
                dispatch(courseEdited())
              }
            }
          } catch (e: any) {
            toast.error(e.message, { theme: 'colored' })
          }
        }
        break
      case 'courseDesign':
        setIsLoading(true)
        const courseparams: Record<string, any> = {}
        courseparams['description_markup'] = editorContent
        try {
          api.put(`course_designs/${inputValue}`, courseparams).then(
            () => {
              toast.success(t('course-design.design-updated'), {
                theme: 'colored',
              })
              setIsLoading(false)
            },
            (error) => {
              console.log(error)
              setIsLoading(false)
            }
          )
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
        break
      case 'sessionDesign':
        setIsLoading(true)
        const sessionparams: Record<string, any> = {}
        sessionparams['description_markup'] = editorContent
        try {
          api
            .put(
              `course_designs/${courseInputValue}/session_designs/${inputValue}`,
              sessionparams
            )
            .then(
              () => {
                toast.success(t('course-design.session-design-updated'), {
                  theme: 'colored',
                })
                setIsLoading(false)
              },
              (error) => {
                console.log(error)
                setIsLoading(false)
              }
            )
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
        break
      case 'organization':
        setIsLoading(true)
        try {
          api
            .put(`organizations/${inputValue}`, {
              description_markup: editorContent,
            })
            .then(
              (response) => {
                toast.success(response.data.message, { theme: 'colored' })
                setIsLoading(false)
              },
              (error) => {
                console.log(error)
                setIsLoading(false)
              }
            )
        } catch (e: any) {
          console.log(e.message)
          setIsLoading(false)
        }
        break
    }
  }

  return (
    <div>
      <div>
        <select
          value={selectedType}
          onChange={(e) => handleTypeChange(e.target.value)}
        >
          <option value="course">Course</option>
          <option value="session">Session</option>
          <option value="courseDesign">Course Design</option>
          <option value="sessionDesign">Session Design</option>
          <option value="organization">Organization</option>
        </select>
        {selectedType == 'session' || selectedType == 'sessionDesign' ? (
          <input
            type="text"
            placeholder={'Course ID'}
            value={courseInputValue}
            onChange={handleCourseInputChange}
          />
        ) : null}
        <input
          type="text"
          placeholder={selectedType + ' ID'}
          value={inputValue}
          onChange={handleInputChange}
        />
        <button onClick={getApiData} disabled={isLoading}>
          Get description
        </button>
        <DescriptionMarkupEditor
          editorState={descriptionMarkupEditorState}
          setEditorState={setEditorState}
        />
        <ReactQuill
          theme="snow"
          value={editorContent}
          onChange={handleEditorChange}
          modules={editorModules}
        />
        <button onClick={updateApiData}>Put Description</button>
      </div>
    </div>
  )
}

export default DescriptionMigration
