import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Tag } from 'Component/Mail/NotificationServiceTypes'
import { useEffect, useState, useCallback } from 'react'
import { TagsTable } from './TagsTable'
import useApi from 'api/UseApi'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import Loader from 'Component/Loader'

export function Tags() {
  const { t } = useTranslation()
  const api = useApi(false)
  const [tags, setTags] = useState<Tag[]>([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const getTags = useCallback(() => {
    if (organization) {
      setIsLoaderVisible(true)
      try {
        api
          .get(`notification-service/tags`, {
            params: {
              organizationUuid: organization.notificationId,
            },
          })
          .then(
            (response) => {
              setTags(response.data.data)
              setIsLoaderVisible(false)
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        console.log(e.message)
        setIsLoaderVisible(false)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getTags()
  }, [getTags])

  return (
    <Box sx={{ mb: 20 }} className="">
      <Box className="">
        {' '}
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('mail.tags')}
        </Typography>
        <TagsTable
          tags={tags}
          onTagsUpdated={() => {
            getTags()
          }}
        />
      </Box>
      <Loader isVisible={isLoaderVisible} />
    </Box>
  )
}
