import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectForm } from './ProjectForm'
import { NavLink } from 'react-router-dom'

export function NewProject() {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20, mr: 5 }} className="">
      <Stack className="">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('funded-training.new-project')}
        </Typography>
        <NavLink to={`/funded-training/projects`} exact>
          <Link variant="body1">{t('funded-training.go-to-project-list')}</Link>
        </NavLink>
        <ProjectForm isNewProject={true} />
      </Stack>
    </Box>
  )
}
