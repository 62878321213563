//
import ImageAvatar from 'Component/ImageAvatar/ImageAvatar'

//
import { CustomFile } from 'Component/UploadAvatar/UploadAvatar'
// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null
}

export default function AvatarPreview({ file }: Props) {
  if (!file) {
    return null
  }

  const imgUrl = typeof file === 'string' ? file : file.preview

  return (
    <ImageAvatar
      alt="avatar"
      src={imgUrl}
      sx={{
        zIndex: 8,
        overflow: 'hidden',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 16px)`,
        height: `calc(100% - 16px)`,
      }}
    />
  )
}
