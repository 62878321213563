import useApi from 'api/UseApi'
import { AxiosResponse } from 'axios'
import { Config } from 'Component/ConfigRegistration/ConfigRegistration'
import { toast } from 'react-toastify'

type OrganizationIdResponse = {
  org_id: string
}

type CustomFieldResponse = {
  message: string
  custom_fields: Config[]
}

const OrganizationsService = () => {
  const api = useApi(false)
  const apiNoAuth = useApi(false, true)

  const getOrganizationId = async (org_id: string) => {
    try {
      const response: AxiosResponse<OrganizationIdResponse> =
        await apiNoAuth.get(`organizations/${org_id}/org_id`)
      return response.data.org_id
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getCustomFields = async (org_id: number) => {
    try {
      const response: AxiosResponse<CustomFieldResponse> = await apiNoAuth.get(
        `organizations/${org_id}/custom_fields`
      )
      const configServer = response.data.custom_fields
      const parsedConfigServer = configServer.map((c) => {
        return { ...c, name: c.name.toLocaleLowerCase().replaceAll(' ', '_') }
      })
      return parsedConfigServer
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const postCustomFields = async (
    org_id: number,
    custom_fields_attributes: Config[]
  ) => {
    try {
      const response: AxiosResponse<CustomFieldResponse> = await api.post(
        `organizations/${org_id}/custom_fields`,
        { custom_fields_attributes: custom_fields_attributes }
      )
      toast.success(response.data.message, { theme: 'colored' })
      return response.data.custom_fields
    } catch (error: any) {
      console.log(error.message)
      toast.error(error.message, { theme: 'colored' })
    }
  }

  return { getOrganizationId, getCustomFields, postCustomFields }
}

export default OrganizationsService
