import { Button } from '@mui/material'
import { styled } from '@mui/system'

export const StyledButton = styled(Button)({
  backgroundColor: '#0d6efd',
  fontFamily: 'nexa',
  borderRadius: '4px',
  color: 'white',
  padding: '10px 20px',
})

export const StyledButtonSuccess = styled(Button)({
  backgroundColor: '#3eab6b',
  fontFamily: 'nexa',
  borderRadius: '4px',
  color: 'white',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#3eab6b',
  },
})

export const StyledButtonGreen = styled(Button)({
  backgroundColor: '#218a4d',
  fontFamily: 'nexa',
  fontSize: '16px',
  borderRadius: '4px',
  color: 'white',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#218a4dee',
  },
})

export const StyledButtonRed = styled(Button)({
  backgroundColor: '#B44242',
  fontFamily: 'nexa',
  fontSize: '16px',
  borderRadius: '4px',
  color: 'white',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#792D2D',
    color: 'white',
  },
})

export const StyledRegistrationButton = styled(Button)({
  backgroundColor: 'rgba(21, 122, 255, 1)',
  padding: '1rem 2rem',
  borderRadius: '4px',
  color: 'white',
  fontSize: '1rem',
  border: 0,
  '&:hover': {
    backgroundColor: 'rgba(21, 122, 255, 1)',
  },
  '&:disabled': {
    backgroundColor: 'rgb(125, 180, 251)',
  },
})
