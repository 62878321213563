import { Trans, useTranslation } from 'react-i18next'
import { TransactionalTemplateName } from './Transactional/Utils'

type EmailPlaceholdersType = 'subject' | 'content'

type Props = {
  type: EmailPlaceholdersType
  transactionalTemplateName?: string
}

const EmailPlaceholders = ({ type, transactionalTemplateName }: Props) => {
  const { t } = useTranslation()

  const firstNamePlaceholder = t('mail.placeholders.first-name')
  const lastNamePlaceholder = t('mail.placeholders.last-name')
  const emailPlaceholder = t('mail.placeholders.email')
  const organizationNamePlaceholder = t('mail.placeholders.organization-name')
  const organizationEmailPlaceholder = t('mail.placeholders.organization-email')
  const courseTitlePlaceholder = t('mail.placeholders.course-title')
  const sessionTitlePlaceholder = t('mail.placeholders.session-title')
  const meetingAddressPlaceholder = t('mail.placeholders.meeting-address')
  const meetingLinkPlaceholder = t('mail.placeholders.meeting-link')
  const resourcesPlaceholder = t('mail.placeholders.resources')
  const preassessmentPlaceholder = t('mail.placeholders.preassessment')

  const firstNamePlaceholderDescription = t(
    'mail.placeholders.first-name-description'
  )
  const lastNamePlaceholderDescription = t(
    'mail.placeholders.last-name-description'
  )
  const emailPlaceholderDescription = t('mail.placeholders.email-description')

  const organizationNamePlaceholderDescription = t(
    'mail.placeholders.organization-name-description'
  )
  const organizationEmailPlaceholderDescription = t(
    'mail.placeholders.organization-email-description'
  )
  const courseTitlePlaceholderDescription = t(
    'mail.placeholders.course-title-description'
  )
  const sessionTitlePlaceholderDescription = t(
    'mail.placeholders.session-title-description'
  )

  const meetingAddressPlaceholderDescription = t(
    'mail.placeholders.meeting-address-description'
  )
  const meetingLinkPlaceholderDescription = t(
    'mail.placeholders.meeting-link-description'
  )
  const resourcesPlaceholderDescription = t(
    'mail.placeholders.resources-description'
  )
  const preassessmentPlaceholderDescription = t(
    'mail.placeholders.preassessment-description'
  )

  const getI18nKey = () => {
    if (transactionalTemplateName) {
      if (transactionalTemplateName === TransactionalTemplateName.Welcome) {
        return 'mail.placeholders-description-content-transactional-welcome'
      } else if (
        transactionalTemplateName === TransactionalTemplateName.Reminder24h
      ) {
        return 'mail.placeholders-description-content-transactional-reminder-24h'
      } else if (
        transactionalTemplateName === TransactionalTemplateName.Feedback
      ) {
        return 'mail.placeholders-description-content-transactional-feedback'
      }
    }

    if (type === 'content') {
      return 'mail.placeholders-description-content'
    } else if (type === 'subject') {
      return 'mail.placeholders-description-subject'
    }
    return ''
  }

  return (
    <strong style={{ color: 'black' }}>
      <Trans i18nKey={getI18nKey()}>
        {'placeholder '}
        <strong style={{ fontFamily: 'nexa', color: '#1976d2' }}>
          <>
            {{
              firstNamePlaceholder,
              lastNamePlaceholder,
              emailPlaceholder,
            }}
          </>
        </strong>

        {transactionalTemplateName && (
          <>
            {{
              organizationNamePlaceholder,
              organizationEmailPlaceholder,
              courseTitlePlaceholder,
              sessionTitlePlaceholder,
              meetingAddressPlaceholder,
              meetingLinkPlaceholder,
              resourcesPlaceholder,
              preassessmentPlaceholder,
            }}
          </>
        )}

        {{
          firstNamePlaceholderDescription,
          lastNamePlaceholderDescription,
          emailPlaceholderDescription,
        }}

        {transactionalTemplateName && (
          <>
            {{
              organizationNamePlaceholderDescription,
              organizationEmailPlaceholderDescription,
              courseTitlePlaceholderDescription,
              sessionTitlePlaceholderDescription,
              meetingAddressPlaceholderDescription,
              meetingLinkPlaceholderDescription,
              resourcesPlaceholderDescription,
              preassessmentPlaceholderDescription,
            }}
          </>
        )}

        {' placeholder'}
      </Trans>
    </strong>
  )
}

export default EmailPlaceholders
