import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CompanyForm } from 'Component/FundedTraining/Companies/CompanyForm'
import { NavLink } from 'react-router-dom'

export function NewCompany() {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20, mr: 5 }}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('funded-training.new-company')}
        </Typography>
        <NavLink to={`/funded-training/companies`} exact>
          <Link variant="body1">{t('funded-training.go-to-company-list')}</Link>
        </NavLink>
        <CompanyForm isNew={true} />
      </Stack>
    </Box>
  )
}
