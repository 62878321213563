import React, { useEffect, useState } from 'react';
import Loader from 'Component/Loader';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { DownloadFile, clearDownloadFile } from '../../Actions/Downloadfile';
import { uploadFile } from '../../Actions/UploadFile';
import { useTranslation } from 'react-i18next';
import useApi from '../../api/UseApi';
import { FILES } from 'common/LocalStorageKeys';

const Folders = ({ refresh, setrefresh, searchText }) => {
  const [filterFiles, setfilterFiles] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [singleData, setsingleData] = useState('');
  const [folderData, setfolderData] = useState([]);
  const [uniqueFolders, setuniqueFolders] = useState([]);
  const [contentVisible, setcontentVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const api = useApi();

  const getAllFolders = async () => {
    setIsLoaderVisible(true);

    try {
      api.get(`S3/GetFiles`).then(
        (response) => {
          const folderNames = {};
          const uniqueFolders = response.data.data.filter(
            (folder) =>
              !folderNames[folder.folderName] &&
              (folderNames[folder.folderName] = true)
          );

          setAllFolders(uniqueFolders);
          setFilteredFolders(uniqueFolders);

          window.localStorage.setItem(
            FILES,
            JSON.stringify(response.data.data)
          );
          setIsLoaderVisible(false);
          dispatch(uploadFile(''));
          dispatch(clearDownloadFile());
        },
        (error) => {
          console.log(error);
          setIsLoaderVisible(false);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getAllFolders();
  }, []);

  useEffect(() => {
    const lowercasedValue = searchText.toLowerCase().trim();

    if (lowercasedValue === '') {
      setFilteredFolders(allFolders);
    } else {
      const filteredData = allFolders.filter((item) => {
        if (item.folderName) {
          return Object.keys(item.folderName).some((key) =>
            item.folderName.toString().toLowerCase().includes(lowercasedValue)
          );
        }
      });
      setFilteredFolders(filteredData);
    }
  }, [searchText, allFolders]);

  const getfilesByfolder = async (data) => {
    setIsLoaderVisible(true);
    try {
      api
        .post(`S3/GetFilesByFolderName`, {
          folderPath: data.folderName,
          bucketName: data.bucketName,
        })
        .then(
          (response) => {
            response.data.data.forEach((element) => {
              try {
                element.color = false;
                element.ext = element.filename.split('.')[1];
              } catch (error) {
                console.log(error);
              }
            });

            const folderNames = {};
            const uniqueFolders = response.data.data.filter(
              (folder) =>
                !folderNames[folder.subFolderName] &&
                (folderNames[folder.subFolderName] = true)
            );

            setfilterFiles(response.data.data);
            setfolderData(response.data.data);
            setuniqueFolders(uniqueFolders);

            window.localStorage.setItem(
              FILES,
              JSON.stringify(response.data.data)
            );

            if (data.subfolder === true) {
              dispatch(
                uploadFile(
                  response.data.data[0].folderName +
                    '/' +
                    response.data.data[0].subFolderName
                )
              );
            } else {
              dispatch(uploadFile(response.data.data[0].folderName));
            }

            setrefresh(false);
            setIsLoaderVisible(false);
            dispatch(clearDownloadFile());
          },
          (error) => {
            console.log(error);
            setIsLoaderVisible(false);
            toast.error(error.response.data.message, { theme: 'colored' });
            window.localStorage.setItem(FILES, JSON.stringify(''));
          }
        );
    } catch (e) {
      console.log(e.message);
    }
  };

  const getFilterIndex = (index) => {
    filterFiles.forEach((element) => {
      element.color = false;
    });

    filterFiles[index].color = true;
    setfolderData(() => {
      return [...filterFiles];
    });
  };

  useEffect(() => {
    if (refresh === true) {
      if (contentVisible === false) {
        getfilesByfolder(singleData);
      } else {
        let subFolder = {
          folderName: `${singleData.folderName}/${
            singleData.subFolderName !== null ? singleData.subFolderName : ''
          }`,
          bucketName: singleData.bucketName,
          subfolder: true,
        };
        getfilesByfolder(subFolder);
      }
    }
  }, [refresh]);

  return (
    <div className="folders">
      <div className="row m-0">
        {singleData === '' &&
          filteredFolders.map(
            (data, index) =>
              data.folderName !== '' &&
              data.folderName !== null && (
                <div className="col-md-4 mb-3" key={index}>
                  <button
                    className="btn"
                    onClick={() => {
                      getfilesByfolder(data);
                      setsingleData(data);
                    }}
                  >
                    {data.folderName}
                  </button>
                </div>
              )
          )}
      </div>
      {filteredFolders.length === 0 && (
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'rgb(208 211 214)',
          }}
        >
          {t('NoRecordFound')}
        </h1>
      )}

      {singleData !== '' && (
        <div className="inSideFolder">
          <div className="settingsFlex">
            <div className="flexHeader">
              {contentVisible === false && (
                <i
                  className="fas fa-chevron-left"
                  style={{
                    fontSize: '1.3rem',
                  }}
                  onClick={() => {
                    setsingleData('');
                    dispatch(uploadFile(''));
                    getAllFolders();
                  }}
                ></i>
              )}

              {contentVisible === false && (
                <h5
                  style={{
                    color: '#a0a0a0',
                  }}
                  className="mb-0"
                >
                  {singleData.folderName}
                </h5>
              )}

              {contentVisible === true && (
                <i
                  className="fas fa-chevron-left"
                  style={{
                    fontSize: '1.3rem',
                  }}
                  onClick={() => {
                    setcontentVisible(false);
                    getfilesByfolder(singleData);
                  }}
                ></i>
              )}
              {contentVisible === true && (
                <h5
                  style={{
                    color: '#a0a0a0',
                  }}
                  className="mb-0"
                >
                  {singleData.subFolderName}
                </h5>
              )}
            </div>
          </div>
          <hr />

          <div className="row m-0">
            {folderData &&
              folderData.map(
                (data, index) =>
                  data.filename !== '' &&
                  data.filename !== null && (
                    <div className="col-md-3 mb-3" key={index}>
                      <div
                        className="card"
                        style={{
                          cursor: 'pointer',
                          background:
                            data.color === false ? '#fff' : '#80808045',
                        }}
                        onClick={() => {
                          if (contentVisible === false) {
                            dispatch(
                              DownloadFile({
                                download: true,
                                folderpath: data.folderName,
                                filename: data.filename,
                                bucketname: data.bucketname,
                              })
                            );
                          } else {
                            dispatch(
                              DownloadFile({
                                download: true,
                                folderpath: `${data.folderName}/${data.subFolderName}`,
                                filename: data.filename,
                                bucketname: data.bucketname,
                              })
                            );
                          }
                          getFilterIndex(index);
                        }}
                      >
                        <div className="card-body">
                          <h2 style={{ color: '#808080' }}>{data.ext}</h2>
                        </div>

                        <div
                          className="card-footer"
                          style={{
                            minHeight: '43px',
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {data.filename}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>

          {contentVisible === false && (
            <>
              <div className="settingsFlex mt-4">
                <div className="flexHeader">
                  <h5
                    style={{
                      color: '#a0a0a0',
                    }}
                    className="mb-0"
                  >
                    {t('library.SubFolders')}
                  </h5>
                </div>
              </div>
              <hr />
            </>
          )}

          {contentVisible === false && (
            <div className="row m-0">
              {uniqueFolders &&
                uniqueFolders.map(
                  (data, index) =>
                    data.subFolderName !== '' &&
                    data.subFolderName !== null && (
                      <div className="col-md-4" key={index}>
                        <button
                          className="btn"
                          onClick={() => {
                            setcontentVisible(true);
                            let subFolder = {
                              folderName: `${data.folderName}/${
                                data.subFolderName !== null
                                  ? data.subFolderName
                                  : ''
                              }`,
                              bucketName: data.bucketName,
                              subfolder: true,
                            };
                            getfilesByfolder(subFolder);
                            setsingleData(data);
                          }}
                        >
                          {data.subFolderName}
                        </button>
                      </div>
                    )
                )}
            </div>
          )}
        </div>
      )}

      <Loader isVisible={isLoaderVisible} />
    </div>
  );
};

export default Folders;
