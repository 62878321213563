import { ClassNames } from '@emotion/react'
import useWindowDimensions from 'common/hooks/useWindowDimensions'
import { Course, CourseStatusEnum } from 'Component/CourseRegistration/Models'
import { Breakpoints } from 'Component/TableCourses/TableCourses'

interface CourseStatusProp {
  course: Course
}

const CourseStatus = ({ course }: CourseStatusProp) => {
  const { width } = useWindowDimensions()

  const renderCourseStatus = (courseStatus: CourseStatusEnum) => {
    switch (courseStatus) {
      case CourseStatusEnum.LIVE:
        return (
          <span className="course_status" style={{ color: 'red' }}>
            <i className="fa fa-circle" aria-hidden="true"></i>
            &nbsp; {width >= Breakpoints.SM && courseStatus}
          </span>
        )
      case CourseStatusEnum.DRAFT:
        return (
          <span className="course_status" style={{ color: '#636363' }}>
            <i className="fa fa-circle" aria-hidden="true"></i>
            &nbsp; {width >= Breakpoints.SM && courseStatus}
          </span>
        )
      case CourseStatusEnum.COMPLETED:
        return (
          <span className="course_status" style={{ color: '#3FAA6B' }}>
            <i className="fa fa-circle" aria-hidden="true"></i>
            &nbsp; {width >= Breakpoints.SM && courseStatus}
          </span>
        )
      case CourseStatusEnum.COMING_NEXT:
        return (
          <span className="course_status" style={{ color: '#3682C0' }}>
            <i className="fa fa-circle" aria-hidden="true"></i>
            &nbsp; {width >= Breakpoints.SM && courseStatus}
          </span>
        )
      default:
        return null
    }
  }

  return <>{renderCourseStatus(course.status)}</>
}

export default CourseStatus
