import { Typography } from '@mui/material'
import { Container } from '@mui/system'
import FileListView, { IFile } from 'Component/FileListView/FileListView'
import Loader from 'Component/Loader'
import useTable from 'Component/Table/useTable'
import { selectLoader } from 'Reducers/LoaderReducer'
import CourseService from 'Service/CourseService'
import { useAppSelector } from 'Store'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ResourceClient,
  convertServerResourcesToClientResources,
} from 'utils/type-conversions/resource'
import { getComparator } from 'utils/utils'

/* const _allFiles: IFile[] = [
  {
    id: '1',
    name: 'Docs',
    size: 20000000,
    type: 'folder',
    url: 'www.google.com',
  },
  {
    id: '2',
    name: 'Cover-12.jpg',
    size: 8000000,
    type: 'jpg',
    url: 'www.google.com',
  },
  {
    id: '3',
    name: 'BYOB.mp3',
    size: 16000000,
    type: 'mp3',
    url: 'www.google.com',
  },
  {
    id: '4',
    name: 'manual.docx',
    size: 4200000,
    type: 'docx',
    url: 'www.google.com',
  },
  {
    id: '5',
    name: 'presentation.ppt',
    size: 2700000,
    type: 'ppt',
    url: 'www.google.com',
  },
  {
    id: '6',
    name: 'install.rar',
    size: 3400000,
    type: 'rar',
    url: 'www.google.com',
  },
  {
    id: '7',
    name: 'installasbdSKJBSDAFBDsfbdisuGFIDscòjdshFPIUDShpiufhdspIFUHdpuifhpdisuFPIUDgfpiudgFPIUdgfpiusdPFIUgsdpfiugdspIFUGdspiufgpdsiUG.rar',
    size: 3400000,
    type: 'rar',
    url: 'www.google.com',
  },
]
 */
function FileManager() {
  const [tableData, setTableData] = useState<ResourceClient[]>([])

  const table = useTable({ defaultRowsPerPage: 10 })
  const isloading = useAppSelector(selectLoader)
  const { slug } = useParams<{ slug: string }>()

  const { getResources } = CourseService()

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  })

  const getFiles = async () => {
    const files = await getResources(slug)
    setTableData(convertServerResourcesToClientResources(files))
  }

  useEffect(() => {
    getFiles()
  }, [])

  return isloading ? (
    <Loader isVisible={isloading}></Loader>
  ) : (
    <Container sx={{ mt: '5rem' }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: '700',
          lineHeight: '1.5',
          fontSize: '1.5rem',
          fontFamily: 'sans-serif',
          mb: '2rem',
          pl: '1rem',
        }}
      >
        File Manager
      </Typography>
      <FileListView
        table={table}
        tableData={tableData}
        dataFiltered={dataFiltered}
        onDeleteRow={() => {}}
        isNotFound={false}
      />
    </Container>
  )
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
}: {
  inputData: ResourceClient[]
  comparator: (a: any, b: any) => number
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const)

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map((el) => el[0])

  return inputData
}

export default FileManager
