import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

import { Dialog, DialogTitle, DialogContent, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  return (
    <div>
      <Dialog
        open={props.model}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            <span style={{ fontWeight: '600' }}>{props.title}</span>
          </DialogTitle>
          <DialogTitle>
            <CancelIcon onClick={props.close} style={{ cursor: 'pointer' }} />
          </DialogTitle>
        </div>

        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}
