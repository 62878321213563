import { memo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { FormHelperText, Link } from '@mui/material'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  label: string
  defaultValue?: string
  className?: string
}

/**
 * React-hook-form wrapped component to be included in a FormProvider.
 *
 * Used to represent text or email input.
 */
const FormPrivacyPolicyField = memo((props: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { t } = useTranslation()

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => (
        <div {...field} className={props.className || ''}>
          <Box>
            <input type="checkbox" className="mr-2" name={props.name} />
            <Link
              sx={{ marginLeft: 1 }}
              rel="noreferrer"
              target="_blank"
              href={t('common.privacy-policy-link')}
            >
              {props.label}
            </Link>
            <span className="text-danger">*</span>
          </Box>

          <FormHelperText error id="form-text-field-error">
            {(errors[props.name]?.message as string) || <br />}
          </FormHelperText>
        </div>
      )}
    />
  )
})

export default FormPrivacyPolicyField
