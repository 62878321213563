import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'Store'

interface CompanyState {
  shouldFetchCompany: boolean
  shouldPreselectProjectsTab: boolean
}

const initialState: CompanyState = {
  shouldFetchCompany: true,
  shouldPreselectProjectsTab: false,
}

export const CompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyFetched: (state) => {
      state.shouldFetchCompany = false
    },
    setShouldPreselectProjectsTab: (state, action: PayloadAction<boolean>) => {
      state.shouldPreselectProjectsTab = action.payload
    },
  },
})

// SELECTORS
export const selectShouldFetchProject = (state: RootState) =>
  state.Company.shouldFetchCompany

export const selectShouldPreselectProjectsTab = (state: RootState) =>
  state.Company.shouldPreselectProjectsTab

export const { companyFetched, setShouldPreselectProjectsTab } =
  CompanySlice.actions

export default CompanySlice.reducer
