export const SELECTED_ORGANIZATION_ID = 'SELECTED_ORGANIZATION_ID'
export const SHOULD_UPDATE_ACCESS_TOKEN_ORG = 'SHOULD_UPDATE_ACCESS_TOKEN_ORG'
export const TIMEZONE_KEY = 'TIMEZONE_KEY'
export const FILES = 'FILES'

export const getShouldUpdateAccessTokenOrg = () => {
  let shouldUpdateAccessTokenOrg =
    window.localStorage.getItem(SHOULD_UPDATE_ACCESS_TOKEN_ORG) || 'false'

  return JSON.parse(shouldUpdateAccessTokenOrg)
}
