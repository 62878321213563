import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import {
  HiOutlineBanknotes,
  HiOutlineCalendarDays,
  HiOutlineDocumentText,
} from 'react-icons/hi2'
import styles from './CardCatalog.module.css'
import { ServerCourse } from 'models/course'

interface CardCatalogProps {
  course: ServerCourse
}

const CardCatalog = ({ course }: CardCatalogProps) => {
  const { title, course_begin_date, course_Link, banner_url, price } = course

  //format currency symbol
  const formatVal = (val: number) =>
    new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: price.currency,
    }).format(val)

  const imagePlaceholder: string =
    'https://media.istockphoto.com/photos/elearning-education-concept-learning-online-picture-id1290864946?b=1&k=20&m=1290864946&s=170667a&w=0&h=zZq7rG5McSptSIpEm9f8iTGd3Mrdkcslakr91T7qTYM='

  //short format for date
  const changeTimeZone = (date: Date | string) => {
    const shortDate = new Date(date)

    return shortDate.toLocaleString(navigator.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    })
  }

  const date: string = course_begin_date
    ? changeTimeZone(course_begin_date)
    : ''

  return (
    <Card sx={{ width: 350 }}>
      <CardActionArea href={course_Link} className={styles.card_action_area}>
        <CardMedia
          className={styles.card_media}
          component="img"
          alt="image header"
          src={banner_url || imagePlaceholder}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            align="left"
            className={styles.title}
          >
            {title}
          </Typography>
          {date && (
            <Box className={styles.flex_container}>
              <HiOutlineCalendarDays color="#2477bc" />
              <Typography gutterBottom align="left" className={styles.date}>
                {date?.toString()}
              </Typography>
            </Box>
          )}
          {price.amount && (
            <Box className={styles.flex_container}>
              <HiOutlineBanknotes color="rgba(0, 0, 0, 0.6)" />
              <Typography
                variant="subtitle1"
                align="left"
                className={styles.subtitle}
              >
                {price.amount && formatVal(parseFloat(price.amount))}
              </Typography>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CardCatalog
