import '../assets/scss/crm.scss'
import { useTranslation } from 'react-i18next'
import CrmTraineesTable from 'View/CrmTraineesTable'
import { useState } from 'react'
import CrmTrainerTable from 'View/CrmTrainerTable'
import { styled } from '@mui/system'
import { AppBar, Tab, Tabs } from '@mui/material'
import { a11yProps, TabPanel } from './TabPanel/TabPanel'
import SearchBar from './SearchBar/SearchBar'
import ExportButton from './ExportButton/ExportButton'

enum RouteExportButton {
  'people',
  'hosts',
}

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}))

const CrmSection = () => {
  const [value, setValue] = useState(0)
  const [query, setQuery] = useState('')
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  const { t } = useTranslation()

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className="">
      <div className="viewCourse">
        <div className="mb-4 d-flex justify-content-between">
          <SearchBar setQuery={setQuery} />

          <ExportButton
            fileName="Trainees"
            route={RouteExportButton[value]}
            setIsLoaderVisible={setIsLoaderVisible}
          />
        </div>

        <div className="crm-title d-flex ">
          <h4>{t('common.crm')}</h4>
        </div>
        <div className="upTable">
          <div className="TabStart">
            <StyledDiv>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={t('common.trainees')} {...a11yProps(0)} />
                  <Tab label={t('common.trainers')} {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <>
                <TabPanel value={value} index={0}>
                  <div className="crmTable">
                    <CrmTraineesTable
                      query={query}
                      isLoaderVisible={isLoaderVisible}
                      setIsLoaderVisible={setIsLoaderVisible}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="crmTable">
                    <CrmTrainerTable
                      query={query}
                      isLoaderVisible={isLoaderVisible}
                      setIsLoaderVisible={setIsLoaderVisible}
                    />
                  </div>
                </TabPanel>
              </>
            </StyledDiv>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CrmSection
