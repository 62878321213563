import { ResourceServer } from 'Service/CourseService'

//mapping resources from server
export type ResourceClient = {
  id: number
  name: string
  size: number
  url: string
  options?: string[]
}

export const convertServerResourceToClientResource = (
  resource: ResourceServer
): ResourceClient => {
  const { id, filename, bytesize, url } = resource
  return {
    id: id,
    name: filename,
    size: bytesize,
    options: [],
    url: url,
  }
}

export const convertServerResourcesToClientResources = (
  resources: ResourceServer[]
): ResourceClient[] => resources.map(convertServerResourceToClientResource)
