import {
  DatePicker as DPicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment, { Moment } from 'moment-timezone'
import i18next from 'i18next'
import 'moment/min/locales'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

/**
 * Localized picker.
 *
 * Displays date string with format "29/10/2021".
 */

type Props = {
  value?: string | Moment | null
  onDateChange(date: MaterialUiPickersDate): void
}

const DatePicker = ({ value = null, onDateChange }: Props) => {
  return (
    <div className="datePicker">
      <div className="dateInsider">
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={i18next.language}
        >
          <DPicker
            value={value ? moment(value, 'L').locale('en') : null}
            format="L"
            allowKeyboardControl={false}
            onChange={(date) => {
              onDateChange(date)
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  )
}

export default DatePicker
