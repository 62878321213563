import { Box, Typography, Tabs, Tab, Link } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import useApi from 'api/UseApi'
import { Activity, ActivityParticipant } from 'Component/FundedTraining/types'
import { ActivityParticipantForm } from './ActivityParticipantForm'
import { useDispatch } from 'react-redux'
import ActivityParticipantTabPanel from './ActivityParticipantTabPanel'
import { ActivityParticipantOverview } from './ActivityParticipantOverview'

type RouteParams = {
  projectUuid: string
  activityUuid: string
  participantUuid: string
}

type LocationState = {
  activityName: string
  participantName: string
}

type Props = {
  isNew?: boolean
}

function a11yProps(index: number) {
  return {
    id: `activity-participant-tab-${index}`,
    'aria-controls': `activity-participant-tabpanel-${index}`,
  }
}

export function ActivityParticipantDetail(props: Props) {
  const { projectUuid, activityUuid, participantUuid } =
    useParams<RouteParams>()
  const { state } = useLocation<LocationState>()
  const { isNew = false } = props
  const { t } = useTranslation()

  const [participantName, setParticipantName] = useState<string>('')
  const [activityName, setActivityName] = useState<string>('')
  const [currentActivity, setCurrentActivity] = useState<null | Activity>(null)
  const [currentActivityParticipant, setCurrentActivityParticipant] =
    useState<null | ActivityParticipant>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  const [tabIndex, setTabIndex] = useState(0)

  const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex)
  }

  useEffect(() => {
    if (currentActivityParticipant) {
      if (participantName === '') {
        setParticipantName(currentActivityParticipant.name)
      }
    } else if (state) {
      setParticipantName(state.participantName)
    }
  }, [state, currentActivityParticipant, participantName])

  useEffect(() => {
    if (currentActivity) {
      setActivityName(currentActivity.title)
    } else if (state) {
      setActivityName(state.activityName)
    }
  }, [state, currentActivity, activityName])

  const getActivityParticipant = useCallback(() => {
    setIsLoading(true)
    try {
      api
        .get(
          `funded_training/projects/${projectUuid}/activities/${activityUuid}/employees/${participantUuid}`
        )
        .then(
          (response) => {
            setCurrentActivityParticipant(response.data)
            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [activityUuid, api, participantUuid, projectUuid])

  useEffect(() => {
    getActivityParticipant()
  }, [getActivityParticipant])

  useEffect(() => {
    if (currentActivityParticipant) {
      setCurrentActivity(currentActivityParticipant.activity)
    }
  }, [currentActivityParticipant])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3, color: '#000000' }}>
        <Trans i18nKey={'funded-training.activity-detail.participant-title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd' }}>
            <>{{ participantName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>

      <Typography variant="body1" sx={{ mb: 3, color: '#000000' }}>
        <Trans i18nKey={'funded-training.activity-detail.title'}>
          {'placeholder '}
          <strong style={{ color: '#0d6efd' }}>
            <>{{ activityName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>

      <NavLink
        to={`/funded-training/projects/${projectUuid}/activities/${activityUuid}`}
        exact
      >
        <Link variant="body1">{t('funded-training.go-to-activity')}</Link>
      </NavLink>

      {currentActivity && currentActivityParticipant && (
        <>
          <Tabs
            sx={{ mt: 4 }}
            value={tabIndex}
            onChange={onTabChange}
            aria-label="activity participants tabs"
          >
            <Tab label={t('common.overview')} {...a11yProps(0)} />
            <Tab label={t('common.config')} {...a11yProps(1)} />
          </Tabs>

          <ActivityParticipantTabPanel value={tabIndex} index={0}>
            <ActivityParticipantOverview
              activityParticipant={currentActivityParticipant}
            />
          </ActivityParticipantTabPanel>
          <ActivityParticipantTabPanel value={tabIndex} index={1}>
            <ActivityParticipantForm
              projectUuid={projectUuid}
              activityUuid={currentActivity.uuid}
              currentActivityParticipant={currentActivityParticipant}
              isNew={isNew}
              onActivityParticipantUpdated={(
                activtyParticipant: ActivityParticipant
              ) => {
                setParticipantName(activtyParticipant.name)
              }}
            />
          </ActivityParticipantTabPanel>
        </>
      )}
    </Box>
  )
}
