import { Box, Link, Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { ActivityParticipantForm } from './ActivityParticipantForm'
import { useCallback, useEffect, useState } from 'react'
import { Activity } from 'Component/FundedTraining/types'
import useApi from 'api/UseApi'

type RouteParams = {
  projectUuid: string
  activityUuid: string
}

type LocationState = {
  activityName: string
}

export function NewActivityParticipant() {
  const { state } = useLocation<LocationState>()

  const { projectUuid, activityUuid } = useParams<RouteParams>()
  const { t } = useTranslation()

  const [activityName, setActivityName] = useState<string>('')
  const [currentActivity, setCurrentActivity] = useState<null | Activity>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const api = useApi(false)

  useEffect(() => {
    if (currentActivity) {
      setActivityName(currentActivity.title)
    } else if (state) {
      setActivityName(state.activityName)
    }
  }, [state, currentActivity, activityName])

  const getActivity = useCallback(() => {
    setIsLoading(true)
    try {
      api
        .get(
          `funded_training/projects/${projectUuid}/activities/${activityUuid}`
        )
        .then(
          (response) => {
            setIsLoading(false)
            setCurrentActivity(response.data)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          }
        )
    } catch (e: any) {
      console.log(e.message)
      setIsLoading(false)
    }
  }, [api, projectUuid, activityUuid])

  useEffect(() => {
    getActivity()
  }, [getActivity])

  return (
    <Box sx={{ mb: 20, mr: 5 }}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('funded-training.activity-detail.new-participant')}
        </Typography>

        <Typography variant="body1" sx={{ mb: 3, color: '#000000' }}>
          <Trans i18nKey={'funded-training.activity-detail.title'}>
            {'placeholder '}
            <strong style={{ color: '#0d6efd' }}>
              <>{{ activityName }}</>
            </strong>
            {' placeholder'}
          </Trans>
        </Typography>

        <NavLink
          to={`/funded-training/projects/${projectUuid}/activities/${activityUuid}`}
          exact
        >
          <Link variant="body1">{t('funded-training.go-to-activity')}</Link>
        </NavLink>
        <ActivityParticipantForm
          projectUuid={projectUuid}
          activityUuid={activityUuid}
          isNew={true}
        />
      </Stack>
    </Box>
  )
}
