import { TFunction } from 'i18next'
import { string, object, SchemaOf, number } from 'yup'
import * as CodiceFiscaleUtils from '@marketto/codice-fiscale-utils'

export type ActivityParticipantFormData = {
  name: string
  surname: string
  fiscal_code: string
  hourly_cost: null | number
}

export const ActivityParticipantFormValidation = (t: TFunction) => {
  const { Validator } = CodiceFiscaleUtils

  const schema: SchemaOf<ActivityParticipantFormData> = object().shape({
    name: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.name'))
      .test(
        'is-valid-for-fiscal-code',
        t('forms.validation.fiscal-code.no-match.first-name'),
        (name, testContext) => {
          if (!name) {
            return false
          }

          const fiscalCode = testContext.parent.fiscal_code
          const isCFValid = Validator.codiceFiscale(fiscalCode).valid

          if (!isCFValid) return true

          const nameMatchesCF =
            Validator.codiceFiscale(fiscalCode).matchFirstName(name)

          return nameMatchesCF
        }
      ),
    surname: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.surname'))
      .test(
        'is-valid-for-fiscal-code',
        t('forms.validation.fiscal-code.no-match.last-name'),
        (surname, testContext) => {
          if (!surname) {
            return false
          }

          const fiscalCode = testContext.parent.fiscal_code
          const isCFValid = Validator.codiceFiscale(fiscalCode).valid

          if (!isCFValid) return true

          const surnameMatchesCF =
            Validator.codiceFiscale(fiscalCode).matchLastName(surname)

          return surnameMatchesCF
        }
      ),
    fiscal_code: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.fiscal-code'))
      .test(
        'is-valid-fiscal-code',
        t('forms.validation.fiscal-code.not-valid'),
        (fiscalCode) => {
          if (!fiscalCode) {
            return false
          }
          const isCFValid = Validator.codiceFiscale(fiscalCode).valid
          return isCFValid
        }
      ),
    hourly_cost: number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .default(null)
      .moreThan(-1, t('forms.validation.price.not-valid'))
      .label(t('funded-training.activity-detail.participant-hourly-cost')),
  })

  return schema
}
