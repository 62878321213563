import { useCallback, useEffect, useState } from 'react'
import { Course } from 'Component/CourseRegistration/Models'
import TableCourses from 'Component/TableCourses/TableCourses'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import useApi from 'api/UseApi'
import EditSettings from 'Actions/EditSettings'
import { useDispatch } from 'react-redux'
import Loader from 'Component/Loader'
import { Box, TablePagination } from '@mui/material'
import useWindowDimensions from 'common/hooks/useWindowDimensions'

const TestCourses = () => {
  const [courses, setCourses] = useState<Course[]>([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const organization = useAppSelector(selectCurrentOrganization)
  const api = useApi(false)
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getAllCourses = useCallback(() => {
    setIsLoaderVisible(true)
    if (organization) {
      try {
        api
          .get('courses.json', {
            params: {
              organization_id: organization.id,
            },
          })
          .then(
            (response) => {
              setCourses(response.data.courses)
              setIsLoaderVisible(false)
              dispatch(EditSettings(false))
            },
            (error) => {
              console.log(error)
              setIsLoaderVisible(false)
            }
          )
      } catch (e: any) {
        console.log(e.message)
      }
    }
  }, [organization, api, dispatch])

  useEffect(() => {
    getAllCourses()
  }, [getAllCourses])

  return (
    <>
      {/* TO DO HALF RESPONSIVENESS */}
      <Box sx={{ marginLeft: '200px', marginTop: '50px' }}>
        {' '}
        {/* TO DO DELETE */}
        width: {width} ~ height: {height}
      </Box>
      {!isLoaderVisible && (
        <>
          <TableCourses
            page={page}
            rowsPerPage={rowsPerPage}
            courses={courses}
            organization={organization}
            getAllCourses={getAllCourses}
            setIsLoaderVisible={setIsLoaderVisible}
          />
          <TablePagination
            sx={{ color: '#5e5e5e' }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={courses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <Loader isVisible={isLoaderVisible} />
    </>
  )
}

export default TestCourses
