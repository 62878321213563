import {
  Toolbar,
  List,
  Drawer,
  styled,
  ListItemIcon,
  ListItem,
  ListItemButton,
} from '@mui/material'

const drawerWidth = 180

export const StyledDiv = styled('div')({
  display: 'flex',
  height: '0px',
})

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: '120px',
  zIndex: theme.zIndex.drawer,
  marginTop: '2rem',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  width: drawerWidth,
  paper: { width: drawerWidth, flexDirection: 'unset' },
}))

export const StyledListDiv = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  alignItems: 'flex-start',
}))

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  padding: '20px 10px 20px 10px',
  minWidth: '40px',
}))

export const StyledList = styled(List)(({ theme }) => ({
  marginTop: '30px',
}))
