import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import { Subscriber } from 'Component/Mail/NotificationServiceTypes'
import { useLocation } from 'react-router-dom'
import { TagSubscribersTable } from 'Component/Mail/Tags/TagSubscribersTable'
import useApi from 'api/UseApi'

type RouteParams = {
  tagId: string
}

type LocationState = {
  tagName: string
}

export function TagSubscribers() {
  const api = useApi(false)
  const { tagId } = useParams<RouteParams>()
  const { state } = useLocation<LocationState>()

  const { t } = useTranslation()
  const [subscribers, setSubscribers] = useState<Subscriber[]>([])
  const [tagName, setTagName] = useState<string>('')

  useEffect(() => {
    if (state) {
      setTagName('#' + state.tagName)
    }
  }, [state])

  const getSubscriberForTag = useCallback(() => {
    if (tagId) {
      try {
        api.get(`tags/${tagId}/subscribers`).then(
          (response) => {
            setSubscribers(response.data.data)
          },
          (error) => {
            console.log(error)
          }
        )
      } catch (e: any) {
        console.log(e.message)
      }
    }
  }, [api, tagId])

  useEffect(() => {
    getSubscriberForTag()
  }, [getSubscriberForTag])

  return (
    <Box sx={{ mt: 18, ml: 30, mb: 20, mr: 10 }}>
      {' '}
      <Typography variant="h5" sx={{ mt: 5, mb: 3, color: '#0d6efd' }}>
        <Trans i18nKey={'mail.subscribers-for-tag'}>
          {'placeholder '}
          <strong style={{ color: '#000000', fontSize: '2rem' }}>
            <>{{ tagName }}</>
          </strong>
          {' placeholder'}
        </Trans>
      </Typography>
      <TagSubscribersTable subscribers={subscribers} />
    </Box>
  )
}
