import { Box, Collapse, Grid, TableCell, TableRow } from '@mui/material'
import {
  getLocalizedDateFromUTC,
  getLocalizedDateTimeFromUTC,
  getLocalizedTimeFromUTC,
} from 'common/DateTimeUtils'
import useWindowDimensions from 'common/hooks/useWindowDimensions'
import { StyledTableTypography } from 'common/styled-component/StyledTable'
import { Course } from 'Component/CourseRegistration/Models'
import TrainerAvatars from 'Component/TrainerAvatars'
import { useTranslation } from 'react-i18next'
import { Breakpoints } from './TableCourses'

interface ExpandibleTableRowProps {
  course: Course
  open: boolean
}

const ExpandableTableRow = ({ course, open }: ExpandibleTableRowProps) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  return (
    <TableRow sx={{ backgroundColor: 'trasparent' }}>
      <TableCell
        colSpan={7}
        sx={{
          border: '0px',
          padding: 0,
          backgroundColor: 'white',
          boxShadow: '0px 3px 5px #999',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          {course.meetings.length ? (
            <Box>
              {course.meetings.map((m) => {
                return (
                  <Grid
                    key={m.id}
                    container
                    sx={{
                      justifyContent: 'space-between',
                      paddingBottom: '32px',
                    }}
                    spacing={2}
                  >
                    <Grid xs={6} item>
                      <StyledTableTypography
                        sx={{ fontWeight: 700, marginBottom: '2rem' }}
                      >
                        {t('expandableTablerow.data')}
                      </StyledTableTypography>
                      <StyledTableTypography>
                        {m.start_date
                          ? getLocalizedDateTimeFromUTC(m.start_date)
                          : ''}
                      </StyledTableTypography>
                    </Grid>
                    {width <= Breakpoints.SM && (
                      <Grid xs={6} item>
                        <StyledTableTypography
                          sx={{ fontWeight: 700, marginBottom: '2rem' }}
                        >
                          {t('expandableTablerow.trainers')}
                        </StyledTableTypography>
                        <StyledTableTypography>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'start' }}
                          >
                            {' '}
                            {/* NEED TESTING WITH MORE THAN ONE TRAINER */}
                            <TrainerAvatars trainers={m.session_trainer} />
                          </Box>
                        </StyledTableTypography>
                      </Grid>
                    )}
                    {width <= Breakpoints.SM && (
                      <Grid xs={6} item>
                        <StyledTableTypography
                          sx={{ fontWeight: 700, marginBottom: '2rem' }}
                        >
                          {t('expandableTablerow.time')}
                        </StyledTableTypography>
                        <StyledTableTypography>
                          {m.start_date != null
                            ? getLocalizedDateFromUTC(m.start_date)
                            : null}
                        </StyledTableTypography>
                        <StyledTableTypography>
                          {m.end_date != null
                            ? getLocalizedDateFromUTC(m.end_date)
                            : null}
                        </StyledTableTypography>
                      </Grid>
                    )}
                    <Grid xs={6} item>
                      <StyledTableTypography
                        sx={{ fontWeight: 700, marginBottom: '2rem' }}
                      >
                        {t('expandableTablerow.trainees')}
                      </StyledTableTypography>
                      <StyledTableTypography>
                        {course.course_total_registrants}
                      </StyledTableTypography>
                    </Grid>
                  </Grid>
                )
              })}
            </Box>
          ) : (
            <Box sx={{ paddingY: '32px', paddingLeft: '32px' }}>
              <StyledTableTypography>
                {t('tableCourses.no-sessions')}
              </StyledTableTypography>
            </Box>
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default ExpandableTableRow
