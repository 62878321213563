import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  TextField,
  InputAdornment,
  Stack,
  Link,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { TableSortLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { Template } from 'Component/Mail/NotificationServiceTypes'
import SearchIcon from '@mui/icons-material/Search'
import Loader from 'Component/Loader'
import { useHistory } from 'react-router-dom'
import { getLocalizedTemplateName } from './Utils'

type Order = 'asc' | 'desc'

interface HeadCell {
  id: keyof Template
  label: string
}

interface Props {
  templates: Template[]
}

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#0d6efd',
    fontSize: 16,
  },
  '.MuiTableSortLabel-root.MuiTableSortLabel-active': {
    color: '#0d6efd',
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: '#5e5e5e',
    fontSize: 15,
  },
}))

export const TransactionalTemplatesTable = (props: Props) => {
  const { templates } = props

  const { t } = useTranslation()
  let history = useHistory()

  const [headCells, setHeadCells] = useState<HeadCell[]>([])
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([])
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Template>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [searchText, setSearchText] = useState('')
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  function stringComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => stringComparator(a, b, orderBy)
      : (a: any, b: any) => -stringComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Template
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const createSortHandler =
    (property: keyof Template) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setHeadCells([
      {
        id: 'name',
        label: t('settings.Name'),
      },
    ])
  }, [t])

  useEffect(() => {
    const cleanInputSearchText = searchText.toLowerCase().trim()
    let filtered: Template[] = templates

    if (cleanInputSearchText) {
      filtered = templates.filter((template) => {
        const matchesName =
          template.name &&
          template.name.toLowerCase().includes(cleanInputSearchText)

        return matchesName
      })
    }
    setFilteredTemplates(filtered)
    setPage(0)
  }, [searchText, templates])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          mb: 2,
          pr: 2,
        }}
      >
        <Box
          component="form"
          sx={{
            width: '25ch',
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="search-template-input"
            placeholder={t('common.search')}
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            inputProps={{
              sx: {
                p: 1,
              },
            }}
            InputProps={{
              sx: { backgroundColor: '#fefefe' },

              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Stack>

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 800, tableLayout: 'auto' }}
          aria-label="Templates table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <StyledTableHeadCell
                  key={headCell.id}
                  padding={'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredTemplates, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((template, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index}>
                    <StyledTableCell>
                      <Link
                        sx={{ cursor: 'pointer' }}
                        underline="none"
                        onClick={() => {
                          history.push(
                            `/Mail/Transactional/Templates/${template.uuid}`,
                            {
                              templateName: template.name,
                            }
                          )
                        }}
                      >
                        {getLocalizedTemplateName(template.name, t)}
                      </Link>
                    </StyledTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredTemplates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Loader isVisible={isLoaderVisible} />
    </>
  )
}
