import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as SurveyCore from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.css';
import useApi from 'api/UseApi';
import { useParams } from 'react-router-dom';
import Loader from './Loader';
import { toast } from 'react-toastify';
import Footer from './Footer2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import showdown from 'showdown';

SurveyCore.StylesManager.applyTheme('default');

export function SurveySubmission() {
  const { t } = useTranslation();
  const [survey, setSurvey] = useState({});
  const [surveyModel, setSurveyModel] = useState(new SurveyCore.Model({}));
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [locale, setLocale] = useState('en');
  const [locales, setLocales] = useState([]);
  const api = useApi(false, true);
  const { survey_id } = useParams();

  useEffect(() => {
    setIsLoaderVisible(true);
    try {
      api.get(`surveys/${survey_id}`).then(
        (response) => {
          setSurvey(response.data);
          setIsLoaderVisible(false);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (e) {
      console.log(e.message);
    }
  }, [api, survey_id]);

  useEffect(() => {
    var newSurveyModel = new SurveyCore.Model(survey.body);

    // Create showdown markdown converter
    var converter = new showdown.Converter();
    newSurveyModel.onTextMarkdown.add(function (model, options) {
      if (survey.isMarkdownEnabled) {
        // convert the markdown text to html
        var str = converter.makeHtml(options.text);
        if (str.indexOf('<p>') === 0) {
          // remove root paragraphs <p></p>
          str = str.substring(3);
          str = str.substring(0, str.length - 4);
        }
        // set html
        options.html = str;
      }
    });

    if (newSurveyModel.getUsedLocales()) {
      const newLocales = newSurveyModel.getUsedLocales();
      setLocales(newLocales);
    }

    // Set default locale
    if (newSurveyModel.locale) {
      setLocale(newSurveyModel.locale);
    }

    setSurveyModel(newSurveyModel);
  }, [survey]);

  const onComplete = async (result) => {
    var answers = [];
    var question = Object.keys(result.valuesHash);
    var answer = Object.values(result.valuesHash);
    for (let i = 0; i < question.length; i++) {
      answers.push({
        question: question[i],
        answer: answer[i],
      });
    }

    setIsLoaderVisible(true);
    try {
      await api
        .post('survey-service/surveys/results', {
          surveyId: survey_id,
          surveyVersion: survey.version,
          isComplete: true,
          body: surveyModel,
          answers: answers,
        })
        .then(
          (response) => {
            setIsLoaderVisible(false);
            toast.success(response.data.message, { theme: 'colored' });
            if (survey.hasOwnProperty('redirectUrl') && survey.redirectUrl) {
              window.open(survey.redirectUrl, '_self');
            }
          },
          (error) => {
            setIsLoaderVisible(false);
            console.log(error);
            toast.error(error.message, { theme: 'colored' });
          }
        );
    } catch (e) {
      setIsLoaderVisible(false);
      toast.error(e.message, { theme: 'colored' });
      console.log(e.message);
    }
  };

  const onSurveyLocaleSelected = (event) => {
    setLocale(event.target.value);
    surveyModel.locale = event.target.value;
  };

  return (
    <div translate="no" className="container mt-5">
      <Stack
        direction="row"
        justifyContent="right"
        sx={{
          marginBottom: 4,
        }}
      >
        <FormControl
          hidden={locales.length < 2}
          sx={{
            width: 100,
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgb(26, 179, 148)',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: 'rgb(26, 179, 148)',
              },
            '& .MuiInputLabel-root': {
              color: 'rgba(26, 179, 148, 0.9)',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'rgba(26, 179, 148, 0.9)',
            },
            '& .MuiOutlinedInput-input': {
              color: 'rgba(26, 179, 148, 0.9)',
            },
          }}
        >
          <InputLabel id="survey-locale-select-label">
            {t('common.language')}
          </InputLabel>
          <Select
            labelId="survey-locale-select-label"
            id="survey-locale-select"
            value={locale}
            label={t('common.language')}
            onChange={onSurveyLocaleSelected}
          >
            {locales &&
              locales.map((locale, index) => (
                <MenuItem key={index} value={locale}>
                  {locale.toUpperCase()}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
      <Survey model={surveyModel} onComplete={onComplete} />
      <Footer />
      <Loader isVisible={isLoaderVisible} />
    </div>
  );
}
