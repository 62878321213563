import React, { useEffect, useState } from 'react'
import useApi from 'api/UseApi'
import { useTranslation } from 'react-i18next'
import Loader from 'Component/Loader'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { useAppSelector } from 'Store'
import TableCourseMember from 'Component/TableCourseMember/TableCourseMember'
import Pagination from 'common/Pagination'
import MemberDetailDialog from 'Component/MemberDetailDialog/MemberDetailDialog'

interface CrmTrainerTableProp {
  query?: string
  isLoaderVisible: boolean
  setIsLoaderVisible: (value: boolean) => void
}

const CrmTrainerTable = ({
  query,
  isLoaderVisible,
  setIsLoaderVisible,
}: CrmTrainerTableProp) => {
  const [trainerList, setTrainerList] = useState([])
  const [alltrainees, setAlltrainees] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openMemberDialog, setOpenMemberDialog] = useState(false)
  const [detailIndex, setDetailIndex] = useState(0)

  const { t } = useTranslation()
  const api = useApi(false)
  const organization = useAppSelector(selectCurrentOrganization)

  const renderMemberDetail = (open: boolean, index: number) => {
    setOpenMemberDialog(open)
    setDetailIndex(index)
  }

  const SearchOnVal = (value: string) => {
    const lowercasedValue = value.toLowerCase().trim()
    if (lowercasedValue === '') setTrainerList(alltrainees)
    else {
      const filteredData = alltrainees.filter((item: any) => {
        return item.first_name != null
          ? Object.keys(item.first_name).some(
              (key) =>
                item.first_name
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue) ||
                item.last_name
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue) ||
                item.email.toString().toLowerCase().includes(lowercasedValue)
            )
          : null
      })
      setTrainerList(filteredData)
    }
  }

  useEffect(() => {
    if (query != null) {
      SearchOnVal(query)
    }
  }, [query])

  useEffect(() => {
    allTrainersGet()
  }, [])

  const allTrainersGet = async () => {
    setIsLoaderVisible(true)
    try {
      await api.get(`/organizations/${organization?.id}/hosts.json`).then(
        (response) => {
          setTrainerList(response.data.customers)
          setAlltrainees(response.data.customers)
          setIsLoaderVisible(false)
        },
        (error) => {
          console.log(error)
          setIsLoaderVisible(false)
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <TableCourseMember
        filteredRegistrants={trainerList}
        isLoading={isLoaderVisible}
        renderMemberDetail={renderMemberDetail}
        rowsPerPage={rowsPerPage}
        pageNo={pageNo}
      />

      <Pagination
        numberOfItems={trainerList?.length}
        rowsPerPage={rowsPerPage}
        pageNo={pageNo}
        onPageChanged={(pageNo: number) => {
          setPageNo(pageNo)
        }}
        onRowsPerPageChanged={(rowsPerPage: number) => {
          setRowsPerPage(rowsPerPage)
        }}
      />

      <MemberDetailDialog
        open={openMemberDialog}
        setOpen={setOpenMemberDialog}
        registrant={trainerList[detailIndex]}
      />

      <Loader isVisible={isLoaderVisible} />
    </>
  )
}

export default CrmTrainerTable
