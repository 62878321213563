import React from 'react';
import { styled } from '@mui/system';
import CourseGeneralSettings from './SettingsTab/CourseGeneralSettings';
import { TabPanel } from 'Component/TabPanel/TabPanel';

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  minHeight: '45vh',
}));

export default function CourseSettingsTab({ course, visibleLoader }) {
  const [value, setValue] = React.useState(0);

  return (
    <div className="tabSettings">
      <StyledDiv>
        <TabPanel value={value} index={0}>
          <CourseGeneralSettings
            course={course}
            visibleLoader={visibleLoader}
          />
        </TabPanel>
      </StyledDiv>
    </div>
  );
}
