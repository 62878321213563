import { TFunction } from 'i18next'
import { string, object, SchemaOf, number } from 'yup'

export type NoticeFormData = {
  title: string
  notice_code: null | string
  valid_course_min_valid_participants: null | number
  valid_participant_min_attended_hours_pct: null | number
}

export const NoticeFormValidation = (t: TFunction) => {
  const schema: SchemaOf<NoticeFormData> = object().shape({
    title: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.title')),
    notice_code: string()
      .nullable()
      .default(null)
      .label(t('funded-training.notice_code')),
    valid_participant_min_attended_hours_pct: number()
      .nullable()
      .default(null)
      .moreThan(-1, t('forms.validation.percentage.not-valid'))
      .lessThan(101, t('forms.validation.percentage.not-valid'))
      .label(
        t(
          'funded-training.notice-detail.valid-participant-min-attended-hours-pct'
        )
      ),
    valid_course_min_valid_participants: number()
      .nullable()
      .default(null)
      .moreThan(-1, t('forms.validation.percentage.not-valid'))
      .lessThan(101, t('forms.validation.percentage.not-valid'))
      .label(
        t('funded-training.notice-detail.valid-course-min-valid-participants')
      ),
  })

  return schema
}
