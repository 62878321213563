// @mui
import { Stack, TableRow, TableCell, Typography } from '@mui/material'
// utils
import { fData, fileTypeByUrl } from 'utils/utils'
// @types
import { IFileManager } from 'Component/FileListView/FileListView'
// components
import FileThumbnail from 'Component/FileThumbnail/FileThumbnail'
import { ResourceClient } from 'utils/type-conversions/resource'

// ----------------------------------------------------------------------

type Props = {
  row: ResourceClient
  selected: boolean
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

export default function FileTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, size } = row

  const handleClick = () => {
    window.open(row.url, '_self')
  }

  return (
    <>
      <TableRow
        sx={{
          borderRadius: 1,
          position: 'relative',
          '& .MuiTableCell-root': {
            bgcolor: 'background.default',
          },
          '&:hover': {
            backgroundColor: 'rgb(255, 255, 255)',
            boxShadow: 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px',
            transition:
              'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            zIndex: 1,
          },
        }}
      >
        {/*         <TableCell
          padding="checkbox"
          sx={{
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        >
          <Checkbox
            checked={selected}
            onDoubleClick={() => console.log('ON DOUBLE CLICK')}
            onClick={onSelectRow}
          />
        </TableCell>
 */}
        <TableCell
          onClick={handleClick}
          sx={{
            lineHeight: '1.57143',
            fontSize: '0.875rem',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            display: 'table-cell',
            verticalAlign: 'inherit',
            //borderBottom: '1px dashed rgb(241, 243, 244)',
            textAlign: 'left',
            padding: '16px',
            color: 'rgb(33, 43, 54)',
            borderTop: '1px solid rgba(145, 158, 171, 0.16)',
            borderBottom: '1px solid rgb(255 255 255)',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <FileThumbnail file={fileTypeByUrl(name)} />

            <Typography
              noWrap
              variant="inherit"
              sx={{ maxWidth: 360, cursor: 'pointer' }}
            >
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          align="left"
          onClick={handleClick}
          sx={{
            color: 'text.secondary',
            whiteSpace: 'nowrap',
            lineHeight: '1.57143',
            fontSize: '0.875rem',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            display: 'table-cell',
            verticalAlign: 'inherit',
            //borderBottom: '1px dashed rgb(241, 243, 244)',
            textAlign: 'left',
            padding: '16px',
            //color: 'rgb(33, 43, 54)',
            borderTop: '1px solid rgba(145, 158, 171, 0.16)',
            borderBottom: '1px solid rgb(255 255 255)',
          }}
        >
          {fData(size)}
        </TableCell>

        <TableCell
          align="center"
          onClick={handleClick}
          sx={{
            color: 'text.secondary',
            whiteSpace: 'nowrap',
            lineHeight: '1.57143',
            fontSize: '0.875rem',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            display: 'table-cell',
            verticalAlign: 'inherit',
            //borderBottom: '1px dashed rgb(241, 243, 244)',
            textAlign: 'left',
            padding: '16px',
            //color: 'rgb(33, 43, 54)',
            borderTop: '1px solid rgba(145, 158, 171, 0.16)',
            borderBottom: '1px solid rgb(255 255 255)',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          {fileTypeByUrl(name)}
        </TableCell>

        {/*                 <TableCell align="right" onClick={handleClick}>
                    <AvatarGroup
                        max={4}
                        sx={{
                            '& .MuiAvatarGroup-avatar': {
                                width: 24,
                                height: 24,
                                '&:first-of-type': {
                                    fontSize: 12,
                                },
                            },
                        }}
                    >
                        {shared &&
                            shared.map((person) => (
                                <Avatar key={person.id} alt={person.name} src={person.avatar} />
                            ))}
                    </AvatarGroup>
                </TableCell>

                <TableCell
                    align="right"
                    sx={{
                        whiteSpace: 'nowrap',
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                    }}
                >
                    <Checkbox
                        color="warning"
                        icon={<Iconify icon="eva:star-outline" />}
                        checkedIcon={<Iconify icon="eva:star-fill" />}
                        checked={favorited}
                        onChange={handleFavorite}
                        sx={{ p: 0.75 }}
                    />

                    <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                sx={{ width: 160 }}
            >
                <MenuItem
                    onClick={() => {
                        handleClosePopover();
                        handleCopy();
                    }}
                >
                    <Iconify icon="eva:link-2-fill" />
                    Copy Link
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClosePopover();
                        handleOpenShare();
                    }}
                >
                    <Iconify icon="eva:share-fill" />
                    Share
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={() => {
                        handleOpenConfirm();
                        handleClosePopover();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="eva:trash-2-outline" />
                    Delete
                </MenuItem>
            </MenuPopover>

            <FileDetailsDrawer
                item={row}
                favorited={favorited}
                onFavorite={handleFavorite}
                onCopyLink={handleCopy}
                open={openDetails}
                onClose={handleCloseDetails}
                onDelete={onDeleteRow}
            />

            <FileShareDialog
                open={openShare}
                shared={shared}
                inviteEmail={inviteEmail}
                onChangeInvite={handleChangeInvite}
                onCopyLink={handleCopy}
                onClose={() => {
                    handleCloseShare();
                    setInviteEmail('');
                }}
            />

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content="Are you sure want to delete?"
                action={
                    <Button variant="contained" color="error" onClick={onDeleteRow}>
                        Delete
                    </Button>
                }
            /> */}
      </TableRow>
    </>
  )
}
