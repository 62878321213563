import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import useApi from 'api/UseApi'
import {
  CampaignFormData,
  CampaignFormValidationSchema,
} from 'common/form-validation/CampaignFormValidation'
import FormTextField from 'Component/form-fields/FormTextField'
import { useEffect, useRef, useState, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import theme from 'theme'
import EmailEditor from 'react-email-editor'
import TagsAutoComplete from '../Tags/TagsAutocomplete'
import {
  Campaign,
  Tag,
  Template,
} from 'Component/Mail/NotificationServiceTypes'
import { CircularProgress } from '@mui/material'
import { useAppSelector } from 'Store'
import { selectCurrentOrganization } from 'Reducers/UserConfigReducer'
import { getTimezone, getUTCDateTime } from 'common/DateTimeUtils'
import { FormTextFieldInputBase } from 'common/styled-component/FormTextFieldInputBase'
import sampleTemplateJson from 'Component/Mail/Templates/sample-template.json'
import { StyledButtonGreen } from 'common/styled-component/StyledButton'
import ConfirmationDialog from 'Component/ConfirmationDialog'
import { useHistory } from 'react-router-dom'
import EmailPlaceholders from '../EmailPlaceholders'
import DateAndTimePicker from 'Component/DateAndTimePicker/DateAndTimePicker'
import {
  convertDateTimePickerModelToDateTimeString,
  convertDateTimeStringToDateTimePickerModel,
} from 'utils/type-conversions/datetime'
import i18next from 'i18next'

type Props = {
  currentCampaign?: null | Campaign
  isNewCampaign?: boolean
  onCampaignNameUpdated?(name: string): void
}

export function CampaignForm(props: Props) {
  const {
    currentCampaign = null,
    isNewCampaign = false,
    onCampaignNameUpdated,
  } = props
  const history = useHistory()
  const { t } = useTranslation()
  const formMethods = useForm<CampaignFormData>({
    resolver: yupResolver(CampaignFormValidationSchema(t)),
  })
  const api = useApi(false)
  const emailEditorRef = useRef<any>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCreatingOrUpdatingCampaign, setIsCreatingCampaign] =
    useState<boolean>(false)
  const [isFetchingTags, setIsFetchingTags] = useState<boolean>(false)
  const [isFetchingTemplates, setIsFetchingTemplates] = useState<boolean>(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState<
    undefined | string
  >(undefined)
  const [onSubmitClicked, setOnSubmitClicked] = useState(false)
  const [scheduledDateTime, setScheduledDateTime] = useState<any>(null)
  const [dateAndTime, setDateAndTime] = useState<{
    date: string
    time: string
  }>({
    date: '',
    time: new Date().toLocaleTimeString(i18next.language, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: getTimezone(),
    }),
  })
  const [serverTime, setServerTime] = useState<string>()
  const [originalDesign, setOriginalDesign] = useState<null | string>(null)
  const [templateChangeEvent, setTemplateChangeEvent] =
    useState<null | SelectChangeEvent>(null)
  const [isDesignLoaded, setIsDesignLoaded] = useState<boolean>(false)
  const [wasOnLoadCalled, setWasOnLoadCalled] = useState<boolean>(false)
  const [tagIds, setTagIds] = useState<string[]>([])
  const [organizationTags, setOrganizationTags] = useState<Tag[]>([])
  const [
    isResetContentConfirmationDialogOpen,
    setIsResetContentConfirmationDialogOpen,
  ] = useState(false)

  const [organizationTemplates, setOrganizationTemplates] = useState<
    Template[]
  >([])
  const [templatesMap, setTemplatesMap] = useState<{ [key: string]: Template }>(
    {}
  )

  const organization = useAppSelector(selectCurrentOrganization)

  const onDateChange = (date: string) => {
    setDateAndTime({ ...dateAndTime, date })
  }

  const onTimeChange = (time: string) => {
    setDateAndTime({ ...dateAndTime, time })
  }

  const getOrganizationTags = useCallback(() => {
    if (organization) {
      setIsFetchingTags(true)
      try {
        api
          .get(`tags`, {
            params: {
              organizationUuid: organization.notificationId,
              showTransactionalEventTags: true,
            },
          })
          .then(
            (response) => {
              setOrganizationTags(response.data.data)
              setIsFetchingTags(false)
            },
            (error) => {
              console.log(error)
              setIsFetchingTags(false)
            }
          )
      } catch (e: any) {
        console.log(e.message)
        setIsFetchingTags(false)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getOrganizationTags()
  }, [getOrganizationTags])

  useEffect(() => {
    if (currentCampaign) {
      formMethods.setValue('title', currentCampaign.name)
      formMethods.setValue('subject', currentCampaign.subject)
      setOriginalDesign(currentCampaign.design)
      setScheduledDateTime(currentCampaign.scheduledAt)
      const tagUuids = currentCampaign.tags.map((tag: Tag) => {
        return tag.uuid
      })
      setTagIds(tagUuids)
    }
  }, [formMethods, currentCampaign])

  useEffect(() => {
    setIsLoading(
      isFetchingTags || isFetchingTemplates || isCreatingOrUpdatingCampaign
    )
  }, [isFetchingTags, isFetchingTemplates, isCreatingOrUpdatingCampaign])

  const getOrganizationTemplates = useCallback(() => {
    if (organization) {
      setIsFetchingTemplates(true)
      try {
        api
          .get(`notification-service/templates`, {
            params: {
              organizationUuid: organization.notificationId,
            },
          })
          .then(
            (response) => {
              setOrganizationTemplates(response.data.data)
              setIsFetchingTemplates(false)
            },
            (error) => {
              console.log(error)
              setIsFetchingTemplates(false)
            }
          )
      } catch (e: any) {
        console.log(e.message)
        setIsFetchingTemplates(false)
      }
    }
  }, [api, organization])

  useEffect(() => {
    getOrganizationTemplates()
  }, [getOrganizationTemplates])

  useEffect(() => {
    let newTemplatesMap: { [key: string]: Template } = {}

    organizationTemplates.forEach((template: Template) => {
      newTemplatesMap[template.uuid] = template
    })

    setTemplatesMap(newTemplatesMap)
  }, [organizationTemplates])

  const onTemplateSelected = (event: SelectChangeEvent) => {
    setTemplateChangeEvent(event)
    setIsResetContentConfirmationDialogOpen(true)
  }

  useEffect(() => {
    if (
      (currentCampaign || isNewCampaign) &&
      wasOnLoadCalled &&
      !isDesignLoaded &&
      emailEditorRef?.current !== null &&
      emailEditorRef?.current !== undefined
    ) {
      if (isNewCampaign) {
        emailEditorRef.current.addEventListener('design:loaded', onDesignLoad)
        emailEditorRef.current.loadDesign(sampleTemplateJson)
      } else if (currentCampaign) {
        emailEditorRef.current.addEventListener('design:loaded', onDesignLoad)
        emailEditorRef.current.loadDesign(JSON.parse(currentCampaign.design))
      }
    }
  }, [
    emailEditorRef,
    wasOnLoadCalled,
    currentCampaign,
    isDesignLoaded,
    isNewCampaign,
  ])

  const onDesignLoad = (data: object) => {
    setIsDesignLoaded(true)
  }

  const onLoad = () => {
    setWasOnLoadCalled(true)
  }

  const onReady = () => {
    setIsLoading(false)
  }

  const onSubmit = (data: CampaignFormData) => {
    const { title, subject } = data

    if (emailEditorRef?.current) {
      emailEditorRef.current.exportHtml((data: any) => {
        const { design, html } = data

        if (isNewCampaign) {
          createCampaign(title, subject, html, design)
        } else {
          updateCampaign(title, subject, html, design)
        }
      })
    }
  }

  const updateCampaign = useCallback(
    (title: string, subject: string, html: string, design: any) => {
      setIsCreatingCampaign(true)
      const dateTimeString =
        convertDateTimePickerModelToDateTimeString(dateAndTime)

      try {
        api
          .put(`campaigns/${currentCampaign?.uuid}`, {
            organizationUuid: organization?.notificationId,
            name: title,
            subject: subject,
            tagUuids: tagIds,
            templateUuid: selectedTemplateId,
            scheduledAt: getUTCDateTime(dateTimeString),
            fromName: organization?.name,
            fromEmail: organization?.email,
            content: html,
            sendToAll: false,
            design: JSON.stringify(design),
          })
          .then(
            (response) => {
              setIsCreatingCampaign(false)
              toast.success(t('common.success'), { theme: 'colored' })
              if (onCampaignNameUpdated) {
                onCampaignNameUpdated(title)
              }
            },
            (error) => {
              console.log(error)
              setIsCreatingCampaign(false)
              toast.error(error.response.data.message, { theme: 'colored' })
            }
          )
      } catch (e) {
        if (e instanceof Error) {
          setIsCreatingCampaign(false)
          console.log(e.message)
        }
      }
    },
    [
      api,
      currentCampaign?.uuid,
      organization?.email,
      organization?.name,
      scheduledDateTime,
      dateAndTime,
      selectedTemplateId,
      tagIds,
    ]
  )

  const createCampaign = useCallback(
    (title: string, subject: string, html: string, design: any) => {
      setIsCreatingCampaign(true)
      const dateTimeString =
        convertDateTimePickerModelToDateTimeString(dateAndTime)
      try {
        api
          .post('campaigns', {
            organizationUuid: organization?.notificationId,
            name: title,
            subject: subject,
            tagUuids: tagIds,
            templateUuid: selectedTemplateId,
            scheduledAt: getUTCDateTime(dateTimeString),
            fromName: organization?.name,
            fromEmail: organization?.email,
            content: html,
            sendToAll: false,
            design: JSON.stringify(design),
          })
          .then(
            (response) => {
              setIsCreatingCampaign(false)
              toast.success(t('common.success'), { theme: 'colored' })
              history.goBack()
            },
            (error) => {
              console.log(error)
              setIsCreatingCampaign(false)
              toast.error(error.response.data.message, { theme: 'colored' })
            }
          )
      } catch (e) {
        if (e instanceof Error) {
          setIsCreatingCampaign(false)
          console.log(e.message)
        }
      }
    },
    [
      api,
      organization?.email,
      organization?.name,
      scheduledDateTime,
      dateAndTime,
      selectedTemplateId,
      tagIds,
    ]
  )

  //map utcdate from server into an object with date and time
  useEffect(() => {
    const dateTimePickerModel =
      convertDateTimeStringToDateTimePickerModel(scheduledDateTime)
    const { date, time } = dateTimePickerModel
    if (date && time) {
      setDateAndTime({ date, time })
      setServerTime(time)
    }
  }, [scheduledDateTime])

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={(e) => {
          setOnSubmitClicked(true)
          if (emailEditorRef?.current) {
            emailEditorRef.current.exportHtml((data: any) => {
              const { design, html } = data

              // console.log('Debug design: ', design)
              // console.log('Debug html: ', html)
            })
          }
          formMethods.handleSubmit(onSubmit)(e)
        }}
      >
        <CircularProgress
          hidden={!isLoading}
          style={{ height: '2rem', width: '2rem' }}
        />

        <Stack hidden={isLoading} sx={{ mt: 5 }}>
          <InputLabel
            hidden={
              currentCampaign === null ||
              (currentCampaign != null && currentCampaign?.status === 'active')
            }
            sx={{ marginLeft: 0.5, marginBottom: 2, color: 'red' }}
          >
            <Typography variant="body1">
              <Trans i18nKey="mail.campaign-sent-warning"></Trans>
            </Typography>
          </InputLabel>

          <FormTextField
            name="title"
            type="text"
            label={t('common.title')}
            mandatory={true}
          />
          <FormTextField
            name="subject"
            type="text"
            label={t('common.subject')}
            mandatory={true}
          />

          <InputLabel sx={{ marginLeft: 0.5, color: 'black' }}>
            <Typography sx={{ fontFamily: 'nexalight' }} variant="subtitle1">
              <EmailPlaceholders type="subject" />
            </Typography>
          </InputLabel>

          <InputLabel sx={{ marginTop: 5, color: '#1976d2' }}>
            <Typography variant="h6">{t('mail.tags')}</Typography>
          </InputLabel>
          <InputLabel sx={{ marginLeft: 0.5, color: 'black' }}>
            <Typography sx={{ fontFamily: 'nexalight' }} variant="subtitle1">
              <Trans i18nKey="mail.tag-field-description"></Trans>
            </Typography>
          </InputLabel>
          <TagsAutoComplete
            organizationTags={organizationTags}
            campaignTags={currentCampaign?.tags || []}
            onTagsUpdated={(newTagIds: string[]) => {
              setTagIds(newTagIds)
            }}
          ></TagsAutoComplete>

          <InputLabel sx={{ marginTop: 3.5, color: '#1976d2' }}>
            <Typography variant="h6">{t('mail.scheduled-sending')}</Typography>
          </InputLabel>

          <InputLabel sx={{ marginLeft: 0.5, color: 'black' }}>
            <Typography sx={{ fontFamily: 'nexalight' }} variant="subtitle1">
              <Trans i18nKey="mail.scheduled-sending-field-description"></Trans>
            </Typography>
          </InputLabel>

          <Box className="date-time-picker" sx={{ maxWidth: '470px' }}>
            <DateAndTimePicker
              date={dateAndTime?.date}
              time={dateAndTime?.time}
              serverTime={serverTime}
              onDateChange={(date) => onDateChange(date)}
              onTimeChange={(time) => onTimeChange(time)}
            />
            <br />
          </Box>

          <InputLabel sx={{ marginTop: 1, color: '#1976d2' }}>
            <Typography variant="h6">{t('common.content')}</Typography>
          </InputLabel>
          <InputLabel sx={{ marginLeft: 0.5, color: 'black' }}>
            <Typography sx={{ fontFamily: 'nexalight' }} variant="subtitle1">
              {t('mail.content-field-description')}
              <br />
              <EmailPlaceholders type="content" />
            </Typography>
          </InputLabel>

          <InputLabel sx={{ mt: 2, ml: 0.5, color: '#1976d2' }}>
            <Typography sx={{ fontFamily: 'nexa' }} variant="subtitle1">
              {t('mail.template-select-description-campaign')}
            </Typography>
          </InputLabel>
          <FormControl sx={{ ml: 0.5, width: 400 }}>
            <Select
              labelId="template-select-label"
              id="template-select"
              value={selectedTemplateId || ''}
              label={null}
              onChange={onTemplateSelected}
              input={<FormTextFieldInputBase placeholder="template" />}
            >
              {organizationTemplates.map((template, index) => (
                <MenuItem key={index} value={template.uuid}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>

            <Stack
              sx={{ marginTop: theme.spacing(4) }}
              spacing={2}
              direction="row"
              justifyContent="flex-start"
            >
              <Button
                hidden={isNewCampaign}
                variant="contained"
                type="button"
                onClick={() => {
                  setIsResetContentConfirmationDialogOpen(true)
                }}
              >
                {t('mail.reset-content')}
              </Button>
            </Stack>
          </FormControl>

          <Box
            sx={{
              mt: 3,
              p: 0.2,
              backgroundColor: '#white',
            }}
          >
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              onReady={onReady}
            />
          </Box>
          <Stack
            hidden={
              currentCampaign != null && currentCampaign?.status !== 'active'
            }
            sx={{ marginTop: theme.spacing(4) }}
            spacing={2}
            direction="row"
            justifyContent="flex-start"
          >
            <StyledButtonGreen
              type="submit"
              sx={{
                marginRight: '16px !important',
                marginLeft: '16px !important',
              }}
            >
              {isNewCampaign
                ? t('mail.create-campaign')
                : t('mail.update-campaign')}
            </StyledButtonGreen>
          </Stack>
        </Stack>
      </form>

      <ConfirmationDialog
        isOpen={isResetContentConfirmationDialogOpen}
        onClose={() => {
          setIsResetContentConfirmationDialogOpen(false)
        }}
        i18nKey="mail.reset-content-confirmation"
        onConfirm={() => {
          if (templateChangeEvent) {
            const selectedTemplateId: string = templateChangeEvent.target
              .value as string
            const selectedTemplate = templatesMap[selectedTemplateId]

            emailEditorRef.current.loadDesign(
              JSON.parse(selectedTemplate.design)
            )
            setTemplateChangeEvent(null)
            setSelectedTemplateId(selectedTemplateId)
          } else if (originalDesign) {
            emailEditorRef.current.loadDesign(JSON.parse(originalDesign))
            setSelectedTemplateId(undefined)
          }
        }}
      />
    </FormProvider>
  )
}
