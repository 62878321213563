import Quill from 'quill'
const Link = Quill.import('formats/link')
Link.sanitize = function (url: string) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`
  }
  return url
}
export const editorModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'link'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    // ['color', 'background', 'size', { 'align': [] }],
    // [{'script': 'sub'}, {'script': 'super' }],
    // ['code-block', 'blockquote', 'formula'],
    // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    // ['link', 'image'],
    // ['history', 'clean']
  ],
}
