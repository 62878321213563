import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { NoticeForm } from './NoticeForm'

export function NewNotice() {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 20, mr: 5 }}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('funded-training.new-notice')}
        </Typography>
        <NavLink to={`/funded-training/notices`} exact>
          <Link variant="body1">{t('funded-training.go-to-notices-list')}</Link>
        </NavLink>
        <NoticeForm isNew={true} />
      </Stack>
    </Box>
  )
}
