import { TFunction } from 'i18next'
import { string, object, SchemaOf, number, date } from 'yup'

export type ProjectFormData = {
  title: string
  description: string | null
  start_date: any | null
  end_date: any | null
  cup: string | null
  funder_project_id: string | null
  duration_hours: number | null
  min_attendance_pct: number | null
  min_participants_pct_with_min_attendance: number | null
}

export const ProjectFormValidation = (t: TFunction) => {
  const min_duration_hours: number = 0

  const schema: SchemaOf<ProjectFormData> = object().shape({
    title: string()
      .required(t('forms.validation.required-field'))
      .label(t('common.title')),
    description: string()
      .nullable()
      .default(null)
      .label(t('common.description')),
    start_date: string().nullable().default(null).label(t('common.start-date')),
    end_date: string().nullable().default(null).label(t('common.end-date')),
    cup: string().nullable().default(null).label(t('funded-training.cup')),
    funder_project_id: string()
      .nullable()
      .default(null)
      .label(t('funded-training.project-id')),
    duration_hours: number()
      .nullable()
      .default(null)
      .moreThan(
        min_duration_hours,
        t('forms.validation.more-than', { value: min_duration_hours })
      )
      .label(t('common.duration-hours')),
    min_attendance_pct: number()
      .nullable()
      .default(null)
      .moreThan(-1, t('forms.validation.percentage.not-valid'))
      .lessThan(101, t('forms.validation.percentage.not-valid'))
      .label(t('funded-training.project-detail.min-attendance-pct')),
    min_participants_pct_with_min_attendance: number()
      .nullable()
      .default(null)
      .moreThan(-1, t('forms.validation.percentage.not-valid'))
      .lessThan(101, t('forms.validation.percentage.not-valid'))
      .label(
        t(
          'funded-training.project-detail.min-participants-pct-with-min-attendance'
        )
      ),
  })

  return schema
}
