import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { TabPanel } from 'Component/TabPanel/TabPanel'

interface BasicTabsProp {
  labels: string[]
  values: React.ReactElement[]
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs({ labels, values }: BasicTabsProp) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {labels.map((l, i) => (
            <Tab label={l} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Box>
      {values.map((v, i) => (
        <TabPanel value={value} index={i}>
          {v}
        </TabPanel>
      ))}
    </Box>
  )
}
