import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { TransactionalTemplateName } from './Utils'

type Props = {
  transactionalTemplateName: string
}

const EditorComponentsDescriptions = ({ transactionalTemplateName }: Props) => {
  const { t } = useTranslation()

  const getI18nKey = () => {
    if (transactionalTemplateName) {
      if (transactionalTemplateName === TransactionalTemplateName.Welcome) {
        return 'mail.transactional-template-optional-components-description-preassessment'
      } else if (
        transactionalTemplateName === TransactionalTemplateName.Reminder24h
      ) {
        return 'mail.transactional-template-optional-components-description-reminder-24h'
      } else if (
        transactionalTemplateName === TransactionalTemplateName.Feedback
      ) {
        return 'mail.transactional-template-optional-components-description-feedback'
      }
    }

    return ''
  }

  const meetingAddress = t(
    'mail.transactional-components.optional-components.meeting-address'
  )
  const meetingAddressDescription = t(
    'mail.transactional-components.optional-components.meeting-address-description'
  )

  const meetingLink = t(
    'mail.transactional-components.optional-components.meeting-link'
  )
  const meetingLinkDescription = t(
    'mail.transactional-components.optional-components.meeting-link-description'
  )

  const preassessment = t(
    'mail.transactional-components.optional-components.preassessment'
  )
  const preassessmentDescription = t(
    'mail.transactional-components.optional-components.preassessment-description'
  )

  const resources = t(
    'mail.transactional-components.optional-components.resources'
  )
  const resourcesDescription = t(
    'mail.transactional-components.optional-components.resources-description'
  )

  const addEvent = t('mail.transactional-components.other-components.add-event')
  const addEventDescription = t(
    'mail.transactional-components.other-components.add-event-description'
  )

  return (
    <Box
      hidden={transactionalTemplateName === TransactionalTemplateName.Feedback}
    >
      <Typography sx={{ mt: 4, ml: 0.5 }} variant="h6">
        {t('mail.transactional-components.optional-category')}
      </Typography>
      <Typography
        sx={{
          mb: 4,
          ml: 0.5,
          color: 'black',
          fontFamily: 'nexalight',
        }}
        variant="subtitle1"
      >
        <Trans
          i18nKey={getI18nKey()}
          values={{
            meetingAddress: meetingAddress,
            meetingLink,
            preassessment: preassessment,
            resources: resources,
            addEvent: addEvent,
            meetingAddressDescription: meetingAddressDescription,
            meetingLinkDescription: meetingLinkDescription,
            preassessmentDescription: preassessmentDescription,
            resourcesDescription: resourcesDescription,
            addEventDescription: addEventDescription,
          }}
          components={{
            title: <strong style={{ fontFamily: 'nexa', color: '#1976d2' }} />,
            description: <strong style={{ color: 'black' }} />,
          }}
        />

        <Box
          hidden={
            transactionalTemplateName !== TransactionalTemplateName.Welcome
          }
        >
          <Typography sx={{ mt: 4, ml: 0.5 }} variant="h6">
            {t('mail.transactional-components.other-category')}
          </Typography>

          <Trans
            i18nKey="mail.transactional-template-other-components-description"
            values={{
              addEvent: addEvent,
              addEventDescription: addEventDescription,
            }}
            components={{
              component1: <strong style={{ color: 'black' }} />,
              component2: (
                <strong style={{ fontFamily: 'nexa', color: '#1976d2' }} />
              ),
            }}
          />
        </Box>
      </Typography>
    </Box>
  )
}

export default EditorComponentsDescriptions
