import { Box, Button, Grid, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PaymentsIcon } from 'assets/icon/payments.svg'
import { StyledButtonGreen } from 'common/styled-component/StyledButton'

export function PaymentsMailto() {
  const { t } = useTranslation()

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        sx={{
          mt: 5,
          padding: 4,
          background: '#ebebeb',
          borderRadius: 3,
          height: '100%',
          flexWrap: 'nowrap',
          maxWidth: 700,
        }}
      >
        <Grid item>
          <Typography variant="h5">
            {/* TODO */}
            {t('payments.messages.stripe-account-configured-title')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'nexalight',
              fontSize: 16,
              mt: 2,
              mb: 2,
            }}
          >
            {t('payments.setup-payments-profile-contact-question')}
          </Typography>
          <br />
          <Typography variant="body1">
            <Link
              href={`mailto:${process.env.REACT_APP_WYBLO_EMAIL}`}
              rel="noreferrer"
              target="_blank"
            >
              {t('payments.setup-payments-profile-contact-us')}
            </Link>
          </Typography>
        </Grid>
        <Box sx={{ width: 100 }}>
          <PaymentsIcon
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Grid>
    </>
  )
}
